export const PropertyNamesMap = {
  kids: {
    yearBorn: {
      label: 'Year Born',
      name: 'yearBorn',
      colSize: 3,
      type: 'year',
    },
    annualExpense: {
      label: 'Annual Expense',
      name: 'annualExpense',
      colSize: 3,
      type: 'currency',
    },
    collegeAge: {
      label: 'College Age',
      name: 'collegeAge',
      colSize: 3,
      type: 'number',
      defaultValue: 17,
    },
    collegeYears: {
      label: 'College Years',
      name: 'collegeYears',
      colSize: 3,
      type: 'number',
      defaultValue: 4,
    },
    annualCollegeExpenseNow: {
      label: 'Annual College Expense Now',
      name: 'annualCollegeExpenseNow',
      colSize: 3,
      type: 'currency',
    },
    collegeExpenseGrowth: {
      label: 'College Expense Growth',
      name: 'collegeExpenseGrowth',
      colSize: 3,
      type: 'percentage',
    },
    value529Plan: {
      label: '529 Plan Value',
      name: 'value529Plan',
      colSize: 3,
      type: 'currency',
    },
    peakReturn529Plan: {
      label: 'Peak Return for 529 Plan',
      name: 'peakReturn529Plan',
      colSize: 3,
      type: 'percentage',
    },
    useSavingsToBuild529: {
      label: 'Use Savings to Build 529',
      name: 'useSavingsToBuild529',
      colSize: 3,
      type: 'select',
      option: 'BooleanOptions',
    },
    useSavingsFor529Deficit: {
      label: 'Use Savings for 529 Deficit',
      name: 'useSavingsFor529Deficit',
      colSize: 3,
      type: 'select',
      option: 'BooleanOptions',
    },
    name: {
      label: 'Name',
      name: 'name',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'Invalid Name',
    },
  },
  kids529Plan: {
    collegeAge: {
      label: 'College Age',
      name: 'collegeAge',
      colSize: 3,
      type: 'number',
    },
    collegeYears: {
      label: 'College Years',
      name: 'collegeYears',
      colSize: 3,
      type: 'number',
    },
    annualCollegeExpenseNow: {
      label: 'Annual College Expense Now',
      name: 'annualCollegeExpenseNow',
      colSize: 3,
      type: 'currency',
    },
    collegeExpenseGrowth: {
      label: 'College Expense Growth',
      name: 'collegeExpenseGrowth',
      colSize: 3,
      type: 'percentage',
    },
    value529Plan: {
      label: '529 Plan Value',
      name: 'value529Plan',
      colSize: 3,
      type: 'currency',
    },
    peakReturn529Plan: {
      label: 'Peak Return for 529 Plan',
      name: 'peakReturn529Plan',
      colSize: 3,
      type: 'percentage',
    },
    useSavingsToBuild529: {
      label: 'Use Savings to Build 529',
      name: 'useSavingsToBuild529',
      colSize: 3,
      type: 'select',
      option: 'BooleanOptions',
    },
  },
  ekids: {
    annualExpense: {
      label: 'Annual Expense',
      name: 'annualExpense',
      colSize: 3,
      type: 'currency',
    },
    useSavingsFor529Deficit: {
      label: 'Use Savings for 529 Deficit',
      name: 'useSavingsFor529Deficit',
      colSize: 3,
      type: 'select',
      option: 'BooleanOptions',
    },
  },
  vacations: {
    vacationYear: {
      label: 'Vacation Year',
      name: 'vacationYear',
      colSize: 3,
      type: 'year',
      isMandatory: true,
      validationMessage: 'Only future dates are allowed',
    },
    vacationExpense: {
      label: 'Vacation Expense',
      name: 'vacationExpense',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Expenses should be greater than $0',
    },
    name: {
      label: 'Name',
      name: 'name',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'Invalid Name',
    },
  },
  vehicles: {
    name: {
      label: 'Name',
      name: 'name',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'Invalid Name',
    },
    apr: {
      label: 'APR',
      name: 'apr',
      colSize: 3,
      type: 'percentage',
      isMandatory: true,
    },
    balance: {
      label: 'Balance',
      name: 'balance',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Balance should be greater than or equal to $0',
    },
    compounding: {
      label: 'Compounding',
      name: 'compounding',
      colSize: 3,
      type: 'select',
      option: 'Compounding',
    },
    deprYrs: {
      label: 'Depr Years',
      name: 'deprYrs',
      colSize: 3,
      type: 'number',
      isMandatory: true,
      validationMessage: 'Depr Years should be greater than or equal to 0',
    },
    interestStartDate: {
      label: 'Interest Start Date',
      name: 'interestStartDate',
      colSize: 3,
      type: 'date',
      isMandatory: true,
      validationMessage:
        'Interest Start Date should be less than Payment Start Date',
    },
    loanAmount: {
      label: 'Loan amount',
      name: 'loanAmount',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage:
        'Loan amount should be greater than $0 and less than Purchase Price',
    },
    monthlyPayment: {
      label: 'Monthly Payment',
      name: 'monthlyPayment',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage:
        'Monthly Payment should be greater than $0 and less than loan amount and Purchase price',
    },
    paymentStartDate: {
      label: 'Payment Start Date',
      name: 'paymentStartDate',
      colSize: 3,
      type: 'date',
      isMandatory: true,
      validationMessage:
        'Payment Start Date should be greater than Interest Start Date',
    },
    purchasePrice: {
      label: 'Purchase Price',
      name: 'purchasePrice',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Purchase Price should be greater than loan amount',
    },
    purchaseDate: {
      label: 'Purchase Date',
      name: 'purchaseDate',
      colSize: 3,
      type: 'date',
      isMandatory: true,
      validationMessage:
        'Purchase Date should be lesser than Payment Start Date',
    },
    termMonths: {
      label: 'Term Months',
      name: 'termMonths',
      colSize: 3,
      type: 'number',
      isMandatory: true,
      validationMessage: 'Loan term months should be greater than 0',
    },
    value: {
      label: 'Value',
      name: 'value',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage:
        'Current Value should be less than or equal to purchase price',
    },
    maintenanceExp: {
      label: `Annual maintenance expense \n (do not include insurance)`,
      name: 'maintenanceExp',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Annual maintenance expense should be greater than 0',
    },
  },
  creditCards: {
    balance: {
      label: 'Balance',
      name: 'balance',
      colSize: 3,
      type: 'currency',
    },
    apr: {
      label: 'APR',
      name: 'apr',
      colSize: 3,
      type: 'percentage',
    },
    monthlyPayment: {
      label: 'Monthly Payment',
      name: 'monthlyPayment',
      colSize: 3,
      type: 'currency',
    },
    monthlySpend: {
      label: 'Monthly Spend',
      name: 'monthlySpend',
      colSize: 3,
      type: 'currency',
    },
    limit: {
      label: 'Limit',
      name: 'limit',
      colSize: 3,
      type: 'currency',
    },
    gracePeriod: {
      label: 'Grace Period',
      name: 'gracePeriod',
      colSize: 3,
      type: 'number',
    },
    compounding: {
      label: 'Compounding',
      name: 'compounding',
      colSize: 3,
      type: 'select',
      option: 'Compounding',
    },
    name: {
      label: 'Name',
      name: 'name',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'Invalid Name',
    },
  },
  studentLoans: {
    monthlyPayment: {
      label: 'Monthly Payment',
      name: 'monthlyPayment',
      colSize: 3,
      type: 'currency',
    },
    compounding: {
      label: 'Compounding',
      name: 'compounding',
      colSize: 3,
      type: 'select',
      option: 'Compounding',
    },
    paymentStartDate: {
      label: 'Payment Start Date',
      name: 'paymentStartDate',
      colSize: 3,
      type: 'date',
      validationMessage:
        'Payment start date should be greater than Interest start date.',
    },
    interestStartDate: {
      label: 'Interest Start Date',
      name: 'interestStartDate',
      colSize: 3,
      type: 'date',
    },
    termMonths: {
      label: 'Term Months',
      name: 'termMonths',
      colSize: 3,
      type: 'number',
    },
    apr: {
      label: 'APR',
      name: 'apr',
      colSize: 3,
      type: 'percentage',
    },
    balance: {
      label: 'Balance',
      name: 'balance',
      colSize: 3,
      type: 'currency',
    },
    loanAmount: {
      label: 'Loan amount',
      name: 'loanAmount',
      colSize: 3,
      type: 'currency',
    },
    name: {
      label: 'Name',
      name: 'name',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'Invalid Name',
    },
  },
  otherLoans: {
    loanAmount: {
      label: 'Loan amount',
      name: 'loanAmount',
      colSize: 3,
      type: 'currency',
    },
    balance: {
      label: 'Balance',
      name: 'balance',
      colSize: 3,
      type: 'currency',
    },
    termMonths: {
      label: 'Term Months',
      name: 'termMonths',
      colSize: 3,
      type: 'number',
    },
    apr: {
      label: 'APR',
      name: 'apr',
      colSize: 3,
      type: 'percentage',
    },
    interestStartDate: {
      label: 'Interest Start Date',
      name: 'interestStartDate',
      colSize: 3,
      type: 'date',
    },
    paymentStartDate: {
      label: 'Payment Start Date',
      name: 'paymentStartDate',
      colSize: 3,
      type: 'date',
      validationMessage:
        'Payment start date should be greater than Interest start date.',
    },
    monthlyPayment: {
      label: 'Monthly Payment',
      name: 'monthlyPayment',
      colSize: 3,
      type: 'currency',
    },
    compounding: {
      label: 'Compounding',
      name: 'compounding',
      colSize: 3,
      type: 'select',
      option: 'Compounding',
    },
    name: {
      label: 'Name',
      name: 'name',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'Invalid Name',
    },
  },
  termLifeIns: {
    name: {
      label: 'Name',
      name: 'name',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'Invalid Name',
    },
    insured: {
      label: 'Insured',
      name: 'insured',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'This is a mandatory field',
    },
    coverageStartYr: {
      label: 'Coverage Start Year',
      name: 'coverageStartYr',
      colSize: 3,
      type: 'year',
    },
    coverage: {
      label: 'Coverage',
      name: 'coverage',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Coverage should be greater than $0',
    },
    deductible: {
      label: 'Deductible',
      name: 'deductible',
      colSize: 3,
      type: 'currency',
    },
    premium: {
      label: 'Premium',
      name: 'premium',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Premium should be greater than $0',
    },
    coverageYrs: {
      label: 'Coverage Years',
      name: 'coverageYrs',
      colSize: 3,
      type: 'number',
      isMandatory: true,
      validationMessage: 'Coverage years should be greater than 0',
    },
    cashValueAnnual: {
      label: 'Cash Value Annual',
      name: 'cashValueAnnual',
      colSize: 3,
      type: 'currency',
    },
    cashValueDistributionYrs: {
      label: 'Cash Value Distribution Years',
      name: 'cashValueDistributionYrs',
      colSize: 3,
      type: 'number',
    },
    renewAtExpiry: {
      label: 'Renew at Expiry',
      name: 'renewAtExpiry',
      colSize: 3,
      type: 'boolean',
    },
    premiumGrowth: {
      label: 'Premium Growth',
      name: 'premiumGrowth',
      colSize: 3,
      type: 'percentage',
    },
  },
  wholeLifeIns: {
    name: {
      label: 'Name',
      name: 'name',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'Invalid Name',
    },
    insured: {
      label: 'Insured',
      name: 'insured',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'This is a mandatory field',
    },
    coverageStartYr: {
      label: 'Coverage Start Year',
      name: 'coverageStartYr',
      colSize: 3,
      type: 'year',
    },
    coverage: {
      label: 'Coverage',
      name: 'coverage',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Coverage should be greater than $0',
    },
    deductible: {
      label: 'Deductible',
      name: 'deductible',
      colSize: 3,
      type: 'currency',
    },
    premium: {
      label: 'Premium',
      name: 'premium',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Premium should be greater than $0',
    },
    coverageYrs: {
      label: 'Coverage Years',
      name: 'coverageYrs',
      colSize: 3,
      type: 'number',
      isMandatory: true,
      validationMessage: 'Coverage years should be greater than 0',
    },
    cashValueAnnual: {
      label: 'Cash Value Annual',
      name: 'cashValueAnnual',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Annual Cash Value should be greater than $0',
    },
    cashValueDistributionYrs: {
      label: 'Cash Value Distribution Years',
      name: 'cashValueDistributionYrs',
      colSize: 3,
      type: 'number',
      isMandatory: true,
      validationMessage:
        'Cash Value Distribution years should be greater than 0',
    },
    renewAtExpiry: {
      label: 'Renew at Expiry',
      name: 'renewAtExpiry',
      colSize: 3,
      type: 'boolean',
    },
    premiumGrowth: {
      label: 'Premium Growth',
      name: 'premiumGrowth',
      colSize: 3,
      type: 'percentage',
    },
  },
  ltcIns: {
    name: {
      label: 'Name',
      name: 'name',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'Invalid Name',
    },
    insured: {
      label: 'Insured',
      name: 'insured',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'This is a mandatory field',
    },
    coverageStartYr: {
      label: 'Coverage Start Year',
      name: 'coverageStartYr',
      colSize: 3,
      type: 'year',
      isMandatory: true,
      validationMessage: 'Coverage Start year cannot be in future',
    },
    coverage: {
      label: 'Coverage',
      name: 'coverage',
      colSize: 3,
      type: 'currency',
      isMandatory: false,
      isOptional: true,
      validationMessage: 'Coverage should be greater than $0',
    },
    deductible: {
      label: 'Deductible',
      name: 'deductible',
      colSize: 3,
      type: 'currency',
    },
    premium: {
      label: 'Premium',
      name: 'premium',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Premium should be greater than $0',
    },
    coverageYrs: {
      label: 'Coverage Years',
      name: 'coverageYrs',
      colSize: 3,
      type: 'number',
      isMandatory: false,
      isOptional: true,
      validationMessage: 'Coverage years should be greater than 0',
    },
    cashValueAnnual: {
      label: 'Cash Value Annual',
      name: 'cashValueAnnual',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Annual Cash Value should be greater than $0',
    },
    cashValueDistributionYrs: {
      label: 'Cash Value Distribution Years',
      name: 'cashValueDistributionYrs',
      colSize: 3,
      type: 'number',
      isMandatory: true,
      validationMessage:
        'Cash Value Distribution years should be greater than 0',
    },
    renewAtExpiry: {
      label: 'Renew at Expiry',
      name: 'renewAtExpiry',
      colSize: 3,
      type: 'boolean',
    },
    premiumGrowth: {
      label: 'Premium Growth',
      name: 'premiumGrowth',
      colSize: 3,
      type: 'percentage',
    },
  },
  umbrellaIns: {
    name: {
      label: 'Name',
      name: 'name',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'Invalid Name',
    },
    insured: {
      label: 'Insured',
      name: 'insured',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'This is a mandatory field',
    },
    coverageStartYr: {
      label: 'Coverage Start Year',
      name: 'coverageStartYr',
      colSize: 3,
      type: 'year',
      isMandatory: true,
      validationMessage: 'Coverage Start year cannot be in future',
    },
    coverage: {
      label: 'Coverage',
      name: 'coverage',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Coverage should be greater than $0',
    },
    deductible: {
      label: 'Deductible',
      name: 'deductible',
      colSize: 3,
      type: 'currency',
    },
    premium: {
      label: 'Premium',
      name: 'premium',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Premium should be greater than $0',
    },
    coverageYrs: {
      label: 'Coverage Years',
      name: 'coverageYrs',
      colSize: 3,
      type: 'number',
    },
    cashValueAnnual: {
      label: 'Cash Value Annual',
      name: 'cashValueAnnual',
      colSize: 3,
      type: 'currency',
    },
    cashValueDistributionYrs: {
      label: 'Cash Value Distribution Years',
      name: 'cashValueDistributionYrs',
      colSize: 3,
      type: 'number',
    },
    renewAtExpiry: {
      label: 'Renew at Expiry',
      name: 'renewAtExpiry',
      colSize: 3,
      type: 'boolean',
    },
    premiumGrowth: {
      label: 'Premium Growth',
      name: 'premiumGrowth',
      colSize: 3,
      type: 'percentage',
    },
  },
  propIns: {
    name: {
      label: 'Name',
      name: 'name',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'Invalid Name',
    },
    insured: {
      label: 'Insured',
      name: 'insured',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'This is a mandatory field',
    },
    coverageStartYr: {
      label: 'Coverage Start Year',
      name: 'coverageStartYr',
      colSize: 3,
      type: 'year',
      isMandatory: true,
      validationMessage: 'Coverage Start year cannot be in future',
    },
    coverage: {
      label: 'Coverage',
      name: 'coverage',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Coverage should be greater than $0',
    },
    deductible: {
      label: 'Deductible',
      name: 'deductible',
      colSize: 3,
      type: 'currency',
    },
    premium: {
      label: 'Premium',
      name: 'premium',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Premium should be greater than $0',
    },
    coverageYrs: {
      label: 'Coverage Years',
      name: 'coverageYrs',
      colSize: 3,
      type: 'number',
    },
    cashValueAnnual: {
      label: 'Cash Value Annual',
      name: 'cashValueAnnual',
      colSize: 3,
      type: 'currency',
    },
    cashValueDistributionYrs: {
      label: 'Cash Value Distribution Years',
      name: 'cashValueDistributionYrs',
      colSize: 3,
      type: 'number',
    },
    renewAtExpiry: {
      label: 'Renew at Expiry',
      name: 'renewAtExpiry',
      colSize: 3,
      type: 'boolean',
    },
    premiumGrowth: {
      label: 'Premium Growth',
      name: 'premiumGrowth',
      colSize: 3,
      type: 'percentage',
    },
  },
  autoIns: {
    name: {
      label: 'Name',
      name: 'name',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'Invalid Name',
    },
    insured: {
      label: 'Insured',
      name: 'insured',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'This is a mandatory field',
    },
    coverageStartYr: {
      label: 'Coverage Start Year',
      name: 'coverageStartYr',
      colSize: 3,
      type: 'year',
      isMandatory: true,
      validationMessage: 'Coverage Start year cannot be in future',
    },
    coverage: {
      label: 'Coverage',
      name: 'coverage',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Coverage should be greater than $0',
    },
    deductible: {
      label: 'Deductible',
      name: 'deductible',
      colSize: 3,
      type: 'currency',
    },
    premium: {
      label: 'Premium',
      name: 'premium',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Premium should be greater than $0',
    },
    coverageYrs: {
      label: 'Coverage Years',
      name: 'coverageYrs',
      colSize: 3,
      type: 'number',
    },
    cashValueAnnual: {
      label: 'Cash Value Annual',
      name: 'cashValueAnnual',
      colSize: 3,
      type: 'currency',
    },
    cashValueDistributionYrs: {
      label: 'Cash Value Distribution Years',
      name: 'cashValueDistributionYrs',
      colSize: 3,
      type: 'number',
    },
    renewAtExpiry: {
      label: 'Renew at Expiry',
      name: 'renewAtExpiry',
      colSize: 3,
      type: 'boolean',
    },
    premiumGrowth: {
      label: 'Premium Growth',
      name: 'premiumGrowth',
      colSize: 3,
      type: 'percentage',
    },
  },
  healthIns: {
    name: {
      label: 'Name',
      name: 'name',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'Invalid Name',
    },
    insured: {
      label: 'Insured',
      name: 'insured',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'This is a mandatory field',
    },
    coverageStartYr: {
      label: 'Coverage Start Year',
      name: 'coverageStartYr',
      colSize: 3,
      type: 'year',
      isMandatory: true,
      validationMessage: 'Coverage Start year cannot be in future',
    },
    coverage: {
      label: 'Coverage',
      name: 'coverage',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Coverage should be greater than $0',
    },
    premium: {
      label: 'Premium',
      name: 'premium',
      colSize: 3,
      type: 'currency',
      isMandatory: true,
      validationMessage: 'Premium should be greater than $0',
    },
    renewAtExpiry: {
      label: 'Renew at Expiry',
      name: 'renewAtExpiry',
      colSize: 3,
      type: 'boolean',
    },
    coverageYrs: {
      label: 'Coverage Years',
      name: 'coverageYrs',
      colSize: 3,
      type: 'number',
    },
    cashValueAnnual: {
      label: 'Cash Value Annual',
      name: 'cashValueAnnual',
      colSize: 3,
      type: 'currency',
    },
    cashValueDistributionYrs: {
      label: 'Cash Value Distribution Years',
      name: 'cashValueDistributionYrs',
      colSize: 3,
      type: 'number',
    },
    deductible: {
      label: 'Deductible',
      name: 'deductible',
      colSize: 3,
      type: 'currency',
    },
    premiumGrowth: {
      label: 'Premium Growth',
      name: 'premiumGrowth',
      colSize: 3,
      type: 'percentage',
      isMandatory: false,
      // validationMessage: 'Premium growth should be greater than 0%',
    },
  },
  heirs: {
    agiAtInheritance: {
      label: 'AGI at Inheritance',
      name: 'agiAtInheritance',
      colSize: 3,
      type: 'currency',
    },
    agiGrowth: {
      label: 'AGI Growth',
      name: 'agiGrowth',
      colSize: 3,
      type: 'percentage',
    },
    taxState: {
      label: 'Tax State',
      name: 'taxState',
      colSize: 3,
      type: 'select',
      option: 'taxStateOptions',
    },
  },
  model: {
    horizon: {
      label: 'Horizon',
      name: 'horizon',
      colSize: 3,
      type: 'select',
      option: '', // Replace with the actual type
    },
    displayTerm: {
      label: 'Display Term',
      name: 'displayTerm',
      colSize: 3,
      type: 'select',
      option: '', // Replace with the actual type
    },
    runCode: {
      label: 'Run Code',
      name: 'runCode',
      colSize: 3,
      type: 'number', // Replace with the actual type
    },
    youngerAgeAtEstateTransfer: {
      label: 'Younger Age at Estate Transfer',
      name: 'youngerAgeAtEstateTransfer',
      colSize: 3,
      type: 'number', // Replace with the actual type
    },
  },
  cashFlows: {
    defaultCashPercentInSavings: {
      label: 'Default Cash Percent in Savings',
      name: 'defaultCashPercentInSavings',
      colSize: 3,
      type: 'percentage', // Replace with the actual type
    },
  },
  emergencyFunds: {
    emCashReqYrs: {
      label: 'Emergency Cash Required Years',
      name: 'emCashReqYrs',
      colSize: 3,
      type: 'number', // Replace with the actual type
    },
    youngerAgeAtDivWD: {
      label: 'Younger Age at Dividend Withdrawal',
      name: 'youngerAgeAtDivWD',
      colSize: 3,
      type: 'number', // Replace with the actual type
    },
    emCashPercentForDiv: {
      label: 'Emergency Cash Percent for Dividend',
      name: 'emCashPercentForDiv',
      colSize: 3,
      type: 'percentage', // Replace with the actual type
    },
    divRate: {
      label: 'Dividend Rate',
      name: 'divRate',
      colSize: 3,
      type: 'percentage', // Replace with the actual type
    },
  },
  taxes: {
    itemizedDeduction: {
      label: 'Itemized Deduction',
      name: 'itemizedDeduction',
      colSize: 3,
      type: 'currency', // Replace with the actual type
    },
    itemizedDedGrowth: {
      label: 'Itemized Deduction Growth',
      name: 'itemizedDedGrowth',
      colSize: 3,
      type: 'percentage', // Replace with the actual type
    },
    qualifiedBusinessIncDeduction: {
      label: 'Qualified Business Income Deduction',
      name: 'qualifiedBusinessIncDeduction',
      colSize: 3,
      type: 'currency', // Replace with the actual type
    },
    qualifiedBusinessIncDedGrowth: {
      label: 'Qualified Business Income Deduction Growth',
      name: 'qualifiedBusinessIncDedGrowth',
      colSize: 3,
      type: 'percentage', // Replace with the actual type
    },
  },
  alerts: {
    debtRateRedThreshold: {
      label: 'Debt Rate Red Threshold',
      name: 'debtRateRedThreshold',
      colSize: 3,
      type: 'percentage', // Replace with the actual type
    },
    debtRateYellowThreshold: {
      label: 'Debt Rate Yellow Threshold',
      name: 'debtRateYellowThreshold',
      colSize: 3,
      type: 'percentage', // Replace with the actual type
    },
    invReturnRedHigh: {
      label: 'Investment Return Red High',
      name: 'invReturnRedHigh',
      colSize: 3,
      type: 'percentage', // Replace with the actual type
    },
    invReturnRedLow: {
      label: 'Investment Return Red Low',
      name: 'invReturnRedLow',
      colSize: 3,
      type: 'percentage', // Replace with the actual type
    },
    invReturnYellowHigh: {
      label: 'Investment Return Yellow High',
      name: 'invReturnYellowHigh',
      colSize: 3,
      type: 'percentage', // Replace with the actual type
    },
    invReturnYellowLow: {
      label: 'Investment Return Yellow Low',
      name: 'invReturnYellowLow',
      colSize: 3,
      type: 'percentage', // Replace with the actual type
    },
    insuranceRedThreshold: {
      label: 'Insurance Red Threshold',
      name: 'insuranceRedThreshold',
      colSize: 3,
      type: 'percentage', // Replace with the actual type
    },
    insuranceYellowThreshold: {
      label: 'Insurance Yellow Threshold',
      name: 'insuranceYellowThreshold',
      colSize: 3,
      type: 'percentage', // Replace with the actual type
    },
  },
  prop: {
    name: {
      label: 'Name',
      name: 'name',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'Invalid Name',
    },
    propDomicile: {
      label: 'Property Domicile',
      name: 'propDomicile',
      colSize: 3,
      type: 'select',
      isMandatory: true,
      option: 'taxStateOptions',
      validationMessage: 'This is a mandatory field',
    },
    purchaseDate: {
      label: 'Purchase Date',
      name: 'purchaseDate',
      colSize: 3,
      type: 'date',
      cascadedTo: ['origOrRefiLoanDate'],
    },
    purchasePrice: {
      label: 'Purchase Price',
      name: 'purchasePrice',
      colSize: 3,
      type: 'currency',
      cascadedTo: ['value', 'taxBasis'],
    },
    appreciationRate: {
      label: 'Appreciation Rate',
      name: 'appreciationRate',
      colSize: 3,
      type: 'percentage',
    },
    aggMaintenanceExp: {
      label: 'Aggregate Maintenance Expense',
      name: 'aggMaintenanceExp',
      colSize: 3,
      type: 'currency',
    },
    aggMaintenanceExpGrowth: {
      label: 'Maintenance Expense Growth',
      name: 'aggMaintenanceExpGrowth',
      colSize: 3,
      type: 'percentage',
    },
    origOrRefiLoanDate: {
      label: 'Original or Refinanced Loan Date',
      name: 'origOrRefiLoanDate',
      colSize: 3,
      type: 'date',
      validationMessage: 'Loan Start Date should be greater than purchase Date',
    },
    origOrRefiLoanAmount: {
      label: 'Original or Refinanced loan amount',
      name: 'origOrRefiLoanAmount',
      colSize: 3,
      type: 'currency',
      cascadedTo: ['loanAmountOutstanding'],
    },
    loanAmountOutstanding: {
      label: 'Loan amount outstanding',
      name: 'loanAmountOutstanding',
      colSize: 3,
      type: 'currency',
    },
    loanTerm: {
      label: 'Loan Term',
      name: 'loanTerm',
      colSize: 3,
      type: 'number',
    },
    fixedIntRate: {
      label: 'Fixed Interest Rate',
      name: 'fixedIntRate',
      colSize: 3,
      type: 'percentage',
    },
    taxBasis: {
      label: 'Tax Basis',
      name: 'taxBasis',
      colSize: 3,
      type: 'currency',
    },
    taxBasisGrowth: {
      label: 'Tax Basis Growth',
      name: 'taxBasisGrowth',
      colSize: 3,
      type: 'percentage',
    },
    taxRate: {
      label: 'Tax Rate',
      name: 'taxRate',
      colSize: 3,
      type: 'percentage',
    },
    value: {
      label: 'Current Value',
      name: 'value',
      colSize: 3,
      type: 'currency',
    },
  },
  props: {
    name: {
      label: 'Name',
      name: 'name',
      colSize: 3,
      type: 'text',
      isMandatory: true,
      validationMessage: 'Invalid Name',
    },
    propDomicile: {
      label: 'Property Domicile',
      name: 'propDomicile',
      colSize: 3,
      type: 'select',
      isMandatory: true,
      option: 'taxStateOptions',
      validationMessage: 'This is a mandatory field',
    },
    purchaseDate: {
      label: 'Purchase Date',
      name: 'purchaseDate',
      colSize: 3,
      type: 'date',
      cascadedTo: ['origOrRefiLoanDate'],
    },
    purchasePrice: {
      label: 'Purchase Price',
      name: 'purchasePrice',
      colSize: 3,
      type: 'currency',
      cascadedTo: ['value', 'taxBasis'],
    },
    appreciationRate: {
      label: 'Appreciation Rate',
      name: 'appreciationRate',
      colSize: 3,
      type: 'percentage',
    },
    aggMaintenanceExp: {
      label: 'Aggregate Maintenance Expense',
      name: 'aggMaintenanceExp',
      colSize: 3,
      type: 'currency',
    },
    aggMaintenanceExpGrowth: {
      label: 'Maintenance Expense Growth',
      name: 'aggMaintenanceExpGrowth',
      colSize: 3,
      type: 'percentage',
    },
    origOrRefiLoanDate: {
      label: 'Original or Refinanced Loan Date',
      name: 'origOrRefiLoanDate',
      colSize: 3,
      type: 'date',
      validationMessage: 'Loan Start Date should be greater than purchase Date',
    },
    origOrRefiLoanAmount: {
      label: 'Original or refinanced loan amount',
      name: 'origOrRefiLoanAmount',
      colSize: 3,
      type: 'currency',
      cascadedTo: ['loanAmountOutstanding'],
    },
    loanAmountOutstanding: {
      label: 'Loan amount outstanding',
      name: 'loanAmountOutstanding',
      colSize: 3,
      type: 'currency',
    },
    loanTerm: {
      label: 'Loan Term',
      name: 'loanTerm',
      colSize: 3,
      type: 'number',
    },
    fixedIntRate: {
      label: 'Fixed Interest Rate',
      name: 'fixedIntRate',
      colSize: 3,
      type: 'percentage',
    },
    taxBasis: {
      label: 'Tax Basis',
      name: 'taxBasis',
      colSize: 3,
      type: 'currency',
    },
    taxBasisGrowth: {
      label: 'Tax Basis Growth',
      name: 'taxBasisGrowth',
      colSize: 3,
      type: 'percentage',
    },
    taxRate: {
      label: 'Tax Rate',
      name: 'taxRate',
      colSize: 3,
      type: 'percentage',
    },
    value: {
      label: 'Current Value',
      name: 'value',
      colSize: 3,
      type: 'currency',
    },
  },
  invProps: {
    annualRent: {
      label: 'Annual Rent',
      name: 'annualRent',
      colSize: 3,
      type: 'currency',
    },
    annualRentGrowth: {
      label: 'Annual Rent Growth',
      name: 'annualRentGrowth',
      colSize: 3,
      type: 'percentage',
    },
    priorDeprAccumulated: {
      label: 'Accumulated Depreciation',
      name: 'priorDeprAccumulated',
      colSize: 3,
      type: 'currency',
    },
    prop: {
      name: {
        label: 'Name',
        name: 'name',
        colSize: 3,
        type: 'text',
        isMandatory: true,
        validationMessage: 'Invalid Name',
      },
      propDomicile: {
        label: 'Property Domicile',
        name: 'propDomicile',
        colSize: 3,
        type: 'select',
        isMandatory: true,
        option: 'taxStateOptions',
        validationMessage: 'This is a mandatory field',
      },
      purchaseDate: {
        label: 'Purchase Date',
        name: 'purchaseDate',
        colSize: 3,
        type: 'date',
        cascadedTo: ['origOrRefiLoanDate'],
      },
      purchasePrice: {
        label: 'Purchase Price',
        name: 'purchasePrice',
        colSize: 3,
        type: 'currency',
        cascadedTo: ['value', 'taxBasis'],
      },
      appreciationRate: {
        label: 'Appreciation Rate',
        name: 'appreciationRate',
        colSize: 3,
        type: 'percentage',
      },
      aggMaintenanceExp: {
        label: 'Aggregate Maintenance Expense',
        name: 'aggMaintenanceExp',
        colSize: 3,
        type: 'currency',
      },
      aggMaintenanceExpGrowth: {
        label: 'Maintenance Expense Growth',
        name: 'aggMaintenanceExpGrowth',
        colSize: 3,
        type: 'percentage',
      },
      origOrRefiLoanDate: {
        label: 'Original or Refinanced Loan Date',
        name: 'origOrRefiLoanDate',
        colSize: 3,
        type: 'date',
        validationMessage:
          'Loan Start Date should be greater than purchase Date',
      },
      origOrRefiLoanAmount: {
        label: 'Original or Refinanced Loan Amount',
        name: 'origOrRefiLoanAmount',
        colSize: 3,
        type: 'currency',
        cascadedTo: ['loanAmountOutstanding'],
      },
      loanAmountOutstanding: {
        label: 'Loan amount outstanding',
        name: 'loanAmountOutstanding',
        colSize: 3,
        type: 'currency',
      },
      loanTerm: {
        label: 'Loan Term',
        name: 'loanTerm',
        colSize: 3,
        type: 'number',
      },
      fixedIntRate: {
        label: 'Fixed Interest Rate',
        name: 'fixedIntRate',
        colSize: 3,
        type: 'percentage',
      },
      taxBasis: {
        label: 'Tax Basis',
        name: 'taxBasis',
        colSize: 3,
        type: 'currency',
      },
      taxBasisGrowth: {
        label: 'Tax Basis Growth',
        name: 'taxBasisGrowth',
        colSize: 3,
        type: 'percentage',
      },
      taxRate: {
        label: 'Tax Rate',
        name: 'taxRate',
        colSize: 3,
        type: 'percentage',
      },
      value: {
        label: 'Current Value',
        name: 'value',
        colSize: 3,
        type: 'currency',
      },
    },
  },
  // Add more mappings for other array objects as needed
};
