export const GET_COMPANY_ASSETS_UNDER_MANAGEMENT =
  'company:assets:underManagement';
export const GET_COMPANY_ASSETS_UNDER_MANAGEMENT_SUCCESS =
  'company:assets:underManagement:success';
export const GET_COMPANY_ASSETS_UNDER_MANAGEMENT_ERROR =
  'company:assets:underManagement:error';
export const COMPANY_ASSETS_LOADING = 'company:assets:loading';
export const GET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME =
  'investment:asset:allocations:company:name';
export const GET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME_ERROR =
  'investment:asset:allocations:company:name:error';
export const GET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME_SUCCESS =
  'investment:asset:allocations:company:name:success';
export const SET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME_LOADING_ACTION =
  'investment:asset:allocations:company:name:loading:action';
export const GET_INV_MARKET_CORRELATIONS_BY_COMPANY_NAME =
  'investment:market:correlations:company:name';
export const GET_INV_MARKET_CORRELATIONS_BY_COMPANY_NAME_SUCCESS =
  'investment:market:correlations:company:name:success';
export const GET_INV_MARKET_CORRELATIONS_BY_COMPANY_NAME_ERROR =
  'investment:market:correlations:company:name:error';
export const SET_INV_MARKET_CORRELATIONS_BY_COMPANY_NM_LOADING_ACTION =
  'investment:market:correlations:company:name:loading:action';
export const GET_INV_MARKET_EXPECTATIONS_BY_COMPANY_NAME =
  'inv:market:expectations:company:name';
export const GET_INV_MARKET_EXPECTATIONS_BY_COMPANY_NAME_SUCCESS =
  'inv:market:expectations:company:name:success';
export const GET_INV_MARKET_EXPECTATIONS_BY_COMPANY_NAME_ERROR =
  'inv:market:expectations:company:name:error';

export function getCompanyAssetsUnderManagementAction(payload) {
  return {
    type: GET_COMPANY_ASSETS_UNDER_MANAGEMENT,
    payload,
  };
}

export function getCompanyAssetsUnderManagementSuccessAction(payload) {
  return {
    type: GET_COMPANY_ASSETS_UNDER_MANAGEMENT_SUCCESS,
    payload,
  };
}

export function getCompanyAssetsUnderManagementErrorAction(payload) {
  return {
    type: GET_COMPANY_ASSETS_UNDER_MANAGEMENT_ERROR,
    payload,
  };
}

export function setCompanyAssetsLoadingAction(payload) {
  return {
    type: COMPANY_ASSETS_LOADING,
    payload,
  };
}

export function getInvAssetAllocationsByCompanyNameAction(payload) {
  return {
    type: GET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME,
    payload,
  };
}

export function getInvAssetAllocationsByCmpnyNmErrorAction(payload) {
  return {
    type: GET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME_ERROR,
    payload,
  };
}
export function getInvAssetAllocationsByCmpnyNmSuccessAction(payload) {
  return {
    type: GET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME_SUCCESS,
    payload,
  };
}
export function setInvAssetAllocationsByCmpnyNmLoadingAction(payload) {
  return {
    type: SET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME_LOADING_ACTION,
    payload,
  };
}

export function getInvMarketCorrelationsByCmpnyNmAction(payload) {
  return {
    type: GET_INV_MARKET_CORRELATIONS_BY_COMPANY_NAME,
    payload,
  };
}

export function getInvMarketCorrelationsByCmpnyNmErrorAction(payload) {
  return {
    type: GET_INV_MARKET_CORRELATIONS_BY_COMPANY_NAME_ERROR,
    payload,
  };
}
export function getInvMarketCorrelationsByCmpnyNmSuccessAction(payload) {
  return {
    type: GET_INV_MARKET_CORRELATIONS_BY_COMPANY_NAME_SUCCESS,
    payload,
  };
}

export function setInvMarketCorrelationsByCmpnyNmLoadingAction(payload) {
  return {
    type: SET_INV_MARKET_CORRELATIONS_BY_COMPANY_NM_LOADING_ACTION,
    payload,
  };
}

export function getInvMarketExpectationsByCompanyyNameAction(payload) {
  return {
    type: GET_INV_MARKET_EXPECTATIONS_BY_COMPANY_NAME,
    payload: payload,
  };
}

export function getInvMarketExpectationsByCmpnyNmSuccessAction(payload) {
  return {
    type: GET_INV_MARKET_EXPECTATIONS_BY_COMPANY_NAME_SUCCESS,
    payload,
  };
}
export function getInvMarketExpectationsByCmpnyNmErrorAction(payload) {
  return {
    type: GET_INV_MARKET_EXPECTATIONS_BY_COMPANY_NAME_ERROR,
    payload,
  };
}
