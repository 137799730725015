import React, { useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  renderFormInputRow,
  renderFooterSubmitCancelButtons,
  renderFormInputRowName,
  renderSelectAccountDropdown,
  renderFormDateField,
  DeleteConfirmation,
  renderFormSelectOption,
} from '../../helpers/BuildHelperComponentV3';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook
import { ExpenseVehicleAccordionNames } from '../../constants/BuildTabComponentV3';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const [VEHICLES, AUTOINS] = ExpenseVehicleAccordionNames;

export const BuildExpensesVehiclesComponent = (props) => {
  const {
    state,
    concatContent,
    allMenuItems,
    autoOptions,
    setLastStepInParent,
    isFormValid,
    formErrors,
    handleAccordionChange,
    expandedPanel,
  } = props;

  const moduleObject = {
    moduleTab: 'moduleFamily',
    mainTab: 'infoVehicles',
    subTab: 'vehicles',
    countVal: 'numVehicles',
  };

  const insuranceModuleObj = {
    moduleTab: 'moduleInsurance',
    mainTab: 'auto',
    subTab: 'autoIns',
    countVal: 'numVehicles',
  };
  const { formDetails, isEditable, isAddNew } = state;
  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.subTab] &&
      formDetails[moduleObject.subTab].name) ||
    '';

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    nameValidationError,
    setNameValidationError,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);
  return (
    <>
      <div className="faq-container-new">
        <Accordion
          expanded={expandedPanel === VEHICLES}
          className="faq-content-new"
          onChange={handleAccordionChange(VEHICLES)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Vehicle</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div
                className="Select_account_main_outer"
                style={{ marginBottom: 10 }}
              >
                {renderSelectAccountDropdown(
                  finalExistingItems,
                  defaultName,
                  openDeleteConfirmation,
                  props,
                  state,
                  moduleObject
                )}
              </div>
              {(isAddNew || isEditable) && (
                <div className="insured_main_outer">
                  {renderFormInputRowName(
                    'text',
                    'tlName',
                    'name',
                    'Vehicle Name',
                    'text',
                    state,
                    moduleObject,
                    props,
                    finalExistingItems,
                    true, //isMandatory field
                    formErrors,
                    isAddNew
                  )}
                </div>
              )}
              {(isAddNew ||
                (finalExistingItems && finalExistingItems.length > 0)) && (
                <div className="insured_main_outer">
                  {renderFormInputRow(
                    'text',
                    'tlPurchasePrice',
                    'purchasePrice',
                    'Purchase price',
                    'currency',
                    moduleObject,
                    state,
                    props
                  )}
                  {renderFormDateField(
                    'purchaseDate',
                    'Purchase Date',
                    'text',
                    moduleObject,
                    state,
                    props,
                    'date',
                    false,
                    false,
                    formErrors
                  )}
                  {renderFormInputRow(
                    'text',
                    'tlLoanAmount',
                    'loanAmount',
                    'Loan amount',
                    'currency',
                    moduleObject,
                    state,
                    props
                  )}
                  {renderFormInputRow(
                    'text',
                    'tlBalance',
                    'balance',
                    'Current balance',
                    'currency',
                    moduleObject,
                    state,
                    props
                  )}
                  {renderFormInputRow(
                    'text',
                    'tlApr',
                    'apr',
                    'Annual percentage rate(APR)',
                    'percentage',
                    moduleObject,
                    state,
                    props
                  )}
                  {renderFormInputRow(
                    'text',
                    'tlTermMonths',
                    'termMonths',
                    'Loan term months',
                    'number',
                    moduleObject,
                    state,
                    props
                  )}
                  {renderFormDateField(
                    'interestStartDate',
                    'Date when interest starts accruing',
                    'text',
                    moduleObject,
                    state,
                    props,
                    'date',
                    false,
                    false,
                    formErrors
                  )}
                  {renderFormDateField(
                    'paymentStartDate',
                    'Date when first payment is due',
                    'text',
                    moduleObject,
                    state,
                    props,
                    'date',
                    false,
                    false,
                    formErrors
                  )}
                  {isAddNew || isEditable ? (
                    <>
                      {renderFormSelectOption(
                        'text',
                        'tlCompounding',
                        'compounding',
                        'Interest will compound',
                        allMenuItems.compoundingMenuItems,
                        moduleObject,
                        state,
                        props
                      )}
                    </>
                  ) : (
                    <>
                      {renderFormInputRow(
                        'text',
                        'tlCompounding',
                        'compounding',
                        'Interest will compound',
                        'textDropdown',
                        moduleObject,
                        state,
                        props
                      )}
                    </>
                  )}
                  {renderFormInputRow(
                    'text',
                    'tlMonthlyPayment',
                    'monthlyPayment',
                    'Monthly payment (will be computed if left as zero)',
                    'currency',
                    moduleObject,
                    state,
                    props
                  )}
                  {renderFormInputRow(
                    'text',
                    'tlCurrenntValue',
                    'value',
                    'current value of vehicle',
                    'currency',
                    moduleObject,
                    state,
                    props
                  )}
                  {renderFormInputRow(
                    'text',
                    'tlDeprYrs',
                    'deprYrs',
                    'Number of years you want the value to be deprecated to zero',
                    'number',
                    moduleObject,
                    state,
                    props
                  )}
                  {renderFormInputRow(
                    'text',
                    'tlAMC',
                    'maintenanceExp',
                    'Annual maintenance expense \n (do not include insurance)',
                    'currency',
                    moduleObject,
                    state,
                    props
                  )}
                  {renderFooterSubmitCancelButtons(
                    state,
                    moduleObject,
                    props,
                    finalExistingItems,
                    isFormValid
                  )}
                </div>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="faq-content-new"
          expanded={expandedPanel === AUTOINS}
          onChange={handleAccordionChange(AUTOINS)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Insurance</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div
                className="Select_account_main_outer"
                style={{ marginBottom: 10 }}
              >
                {renderSelectAccountDropdown(
                  finalExistingItems,
                  defaultName,
                  openDeleteConfirmation,
                  props,
                  state,
                  insuranceModuleObj,
                  autoOptions && autoOptions.length === 0 ? true : false
                )}
              </div>
              {autoOptions && autoOptions.length === 0 && (
                <div className="insured_main_outer">
                  <Alert severity="warning">
                    <p className="select-bank-text">
                      Please add your vehicles to proceed further
                    </p>
                  </Alert>
                </div>
              )}
              {(isAddNew || isEditable) && (
                <div className="insured_main_outer">
                  {renderFormInputRowName(
                    'text',
                    'tlName',
                    'name',
                    'Short name of insurance',
                    'text',
                    state,
                    insuranceModuleObj,
                    props,
                    finalExistingItems,
                    true,
                    formErrors,
                    isAddNew
                  )}
                </div>
              )}
              {(isAddNew ||
                (finalExistingItems && finalExistingItems.length > 0)) && (
                <div className="insured_main_outer">
                  {isAddNew ? (
                    <>
                      {renderFormSelectOption(
                        'text',
                        'tlinsured',
                        'insured',
                        'Which vehicle is covered?',
                        autoOptions,
                        insuranceModuleObj,
                        state,
                        props,
                        true, //isMandatory
                        formErrors
                      )}
                    </>
                  ) : (
                    <>
                      {renderFormInputRow(
                        'text',
                        'tlinsured',
                        'insured',
                        'Which vehicle is covered?',
                        'textDropdown',
                        insuranceModuleObj,
                        state,
                        props,
                        undefined,
                        true, //isDisabled,
                        true, // isMandatory
                        formErrors
                      )}
                    </>
                  )}
                  {renderFormInputRow(
                    'text',
                    'tlCoverage',
                    'coverage',
                    'Liability Coverage Amount',
                    'currency',
                    insuranceModuleObj,
                    state,
                    props,
                    undefined,
                    !isEditable, //isDisabled,
                    true, // isMandatory
                    formErrors
                  )}
                  {renderFormDateField(
                    'coverageStartYr',
                    'Year when coverage started or expected to start',
                    'number',
                    insuranceModuleObj,
                    state,
                    props,
                    'year',
                    true
                  )}
                  {renderFormInputRow(
                    'text',
                    'tlDeductible',
                    'deductible',
                    'Deductible',
                    'currency',
                    insuranceModuleObj,
                    state,
                    props,
                    undefined,
                    !isEditable, //isDisabled,
                    true, // isMandatory
                    formErrors
                  )}
                  {renderFormInputRow(
                    'text',
                    'tlPremium',
                    'premium',
                    'Annual Premium',
                    'currency',
                    insuranceModuleObj,
                    state,
                    props,
                    undefined,
                    !isEditable, //isDisabled,
                    true, // isMandatory
                    formErrors
                  )}
                  {renderFormInputRow(
                    'text',
                    'tlPremiumGrowth',
                    'premiumGrowth',
                    'Annual growth of premium',
                    'percentage',
                    insuranceModuleObj,
                    state,
                    props,
                    undefined,
                    !isEditable, //isDisabled,
                    false, // isMandatory
                    formErrors
                  )}
                  {renderFooterSubmitCancelButtons(
                    state,
                    insuranceModuleObj,
                    props,
                    finalExistingItems,
                    isFormValid
                  )}
                </div>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="tab_main_nav">
        <div className="tab_left_nav">
          <button>
            <ArrowBackIcon /> Previous
          </button>
        </div>
        <div className="tab_right_nav">
          <button>
            Next <ArrowForwardIcon />
          </button>
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )}
    </>
  );
};

export default BuildExpensesVehiclesComponent;
