import { GET, POST, CUSTOMGET, CUSTOMPOST } from '../api/fetch_wrapper';
import { convertObjToQueryStr } from '../utils/apirequestutil';
import { API_HOST_URL } from './apiHost';

export async function getCompanyAssetsUnderManagement(companyName) {
  return GET(
    `${API_HOST_URL()}/selectAdvisorAumByCompanyName?companyName=${companyName}`
  );
}

export async function getInvAssetAllocationsByCompanyName(companyName) {
  return GET(
    `${API_HOST_URL()}/selectInvAssetAllocationsByCompanyName?companyName=${companyName}`
  );
}

export async function getAllAdvisorsByCompanyName(companyName) {
  return GET(
    `${API_HOST_URL()}/selectAdvisorsByCompanyName?companyName=${companyName}`
  );
}

export async function getACompanyAllAdvisorsByState(companyName, state) {
  return GET(
    `${API_HOST_URL()}/selectAdvisorsByStateByCompanyName?companyName=${companyName}&state=${state}`
  );
}

export async function getInvMarketCorrelationsByCompanyName(companyName) {
  return GET(
    `${API_HOST_URL()}/selectInvMarketCorrelationsByCompanyName?companyName=${companyName}`
  );
}

export async function updateInvMarketCorrelationsByCompanyNm(apiPayload) {
  return POST(
    `${API_HOST_URL()}/updateInvMarketCorrelationsByCompanyName`,
    apiPayload
  );
}

export async function getInvMarketExpectationsByCompanyName(companyName) {
  return GET(
    `${API_HOST_URL()}/selectInvMarketExpectationsByCompanyName?companyName=${companyName}`
  );
}

export async function updateInvMarketExpectationsByCompanyName(apiPayload) {
  return POST(
    `${API_HOST_URL()}/updateInvMarketExpectationsByCompanyName`,
    apiPayload
  );
}

export async function updateInvAssetAllocationsByRiskLevelByCompanyName(
  reqPayload
) {
  return POST(
    `${API_HOST_URL()}/updateInvAssetAllocationsByRiskLevelByCompanyName`,
    reqPayload
  );
}
