import { put, call, all, takeLatest } from 'redux-saga/effects';
import {
  GET_COMPANY_ASSETS_UNDER_MANAGEMENT,
  GET_INV_MARKET_CORRELATIONS_BY_COMPANY_NAME,
  GET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME,
  GET_INV_MARKET_EXPECTATIONS_BY_COMPANY_NAME,
  getCompanyAssetsUnderManagementSuccessAction,
  setCompanyAssetsLoadingAction,
  getCompanyAssetsUnderManagementErrorAction,
  setInvAssetAllocationsByCmpnyNmLoadingAction,
  getInvAssetAllocationsByCmpnyNmErrorAction,
  getInvAssetAllocationsByCmpnyNmSuccessAction,
  getInvMarketCorrelationsByCmpnyNmSuccessAction,
  setInvMarketCorrelationsByCmpnyNmLoadingAction,
  getInvMarketCorrelationsByCmpnyNmErrorAction,
  getInvMarketExpectationsByCmpnyNmSuccessAction,
  getInvMarketExpectationsByCmpnyNmErrorAction,
} from '../actions//businessInfoAction';
import {
  getCompanyAssetsUnderManagement,
  getInvAssetAllocationsByCompanyName,
  getInvMarketExpectationsByCompanyName,
  getInvMarketCorrelationsByCompanyName,
} from '../../services/businessDashboardSvc';

function* triggerGetCompanyAssetsUnderManagement(request) {
  try {
    yield put(setCompanyAssetsLoadingAction(true));
    const { status, data: companyAssetsAum } = yield call(
      getCompanyAssetsUnderManagement,
      request.payload
    );
    if (status === 200) {
      const companyAssetsUnderManagement = {
        ...companyAssetsAum,
      };
      yield put(setCompanyAssetsLoadingAction(false));
      yield put(
        getCompanyAssetsUnderManagementSuccessAction(
          companyAssetsUnderManagement
        )
      );
    } else {
      yield put(setCompanyAssetsLoadingAction(false));
      yield put(getCompanyAssetsUnderManagementErrorAction({}));
    }
  } catch (err) {
    yield put(setCompanyAssetsLoadingAction(false));
    yield put(getCompanyAssetsUnderManagementErrorAction({}));
  }
}

function* triggerGetInvAssetAllocationsByCompanyName(action) {
  try {
    yield put(setInvAssetAllocationsByCmpnyNmLoadingAction(true));
    const { status, data } = yield call(
      getInvAssetAllocationsByCompanyName,
      action.payload
    );
    if (status === 200) {
      yield put(setInvAssetAllocationsByCmpnyNmLoadingAction(false));
      yield put(getInvAssetAllocationsByCmpnyNmSuccessAction(data));
    } else {
      yield put(setInvAssetAllocationsByCmpnyNmLoadingAction(false));
      yield put(getInvAssetAllocationsByCmpnyNmErrorAction({}));
    }
  } catch (err) {
    yield put(setInvAssetAllocationsByCmpnyNmLoadingAction(false));
    yield put(getInvAssetAllocationsByCmpnyNmErrorAction({}));
  }
}

function* triggerGetInvMarketCorrelationsByCompanyName(action) {
  try {
    yield put(setInvMarketCorrelationsByCmpnyNmLoadingAction(true));
    const { status, data } = yield call(
      getInvMarketCorrelationsByCompanyName,
      action.payload
    );
    if (status === 200) {
      yield put(setInvMarketCorrelationsByCmpnyNmLoadingAction(false));
      yield put(getInvMarketCorrelationsByCmpnyNmSuccessAction(data));
    } else {
      yield put(setInvMarketCorrelationsByCmpnyNmLoadingAction(false));
      yield put(getInvMarketCorrelationsByCmpnyNmErrorAction({}));
    }
  } catch (err) {
    yield put(setInvMarketCorrelationsByCmpnyNmLoadingAction(false));
    yield put(getInvMarketCorrelationsByCmpnyNmErrorAction({}));
  }
}

function* triggerGetInvMarketExpectationsByCompanyName(request) {
  try {
    // yield put(setInvMarketExpectationsByAdvisorIdLoadingAction(true));
    const { status, data: invMarketExpectationsByAdvisorId } = yield call(
      getInvMarketExpectationsByCompanyName,
      request.payload
    );
    if (status === 200) {
      // yield put(setInvMarketExpectationsByAdvisorIdLoadingAction(false));
      yield put(
        getInvMarketExpectationsByCmpnyNmSuccessAction(
          invMarketExpectationsByAdvisorId
        )
      );
    } else {
      // yield put(setInvMarketExpectationsByAdvisorIdLoadingAction(false));
      yield put(getInvMarketExpectationsByCmpnyNmErrorAction({}));
    }
  } catch (err) {
    // yield put(setInvMarketExpectationsByAdvisorIdLoadingAction(false));
    yield put(getInvMarketExpectationsByCmpnyNmErrorAction({}));
  }
}

export function* watchBusinessInfoByAdvisor() {
  yield takeLatest(
    GET_COMPANY_ASSETS_UNDER_MANAGEMENT,
    triggerGetCompanyAssetsUnderManagement
  );
  yield takeLatest(
    GET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME,
    triggerGetInvAssetAllocationsByCompanyName
  );
  yield takeLatest(
    GET_INV_MARKET_CORRELATIONS_BY_COMPANY_NAME,
    triggerGetInvMarketCorrelationsByCompanyName
  );
  yield takeLatest(
    GET_INV_MARKET_EXPECTATIONS_BY_COMPANY_NAME,
    triggerGetInvMarketExpectationsByCompanyName
  );
}
