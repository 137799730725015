import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { planDetailsByPlanIdSelector } from '../../selectors/plannerDetailsSelector';
import { Grid } from '@mui/material'; // Import dialog components
import Controls from '../controls/Controls';
import { validateMemberNames } from '../../helpers/ClientHelper';
import { validateAgeFieId } from '../../utils/validators';
import MuiAlert from '@mui/material/Alert';
import { primaryHomeFormFields } from '../../constants/Client';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const PrimaryHomeContentGoals = (props) => {
  const { commonProps = {}, taxStateOptions = [] } = props;
  const {
    modalType,
    handleModalOpen,
    category,
    goal = {},
    setIsFormValid,
    setFormDetails,
  } = commonProps;

  const planDetailsByPlanId = useSelector((state) =>
    planDetailsByPlanIdSelector(state)
  );

  const { moduleInvestments: { primaryHome = {} } = {} } = planDetailsByPlanId;
  const [validatorMessage, setValidatorMessage] = useState('');
  const [primaryHomeInfo, setPrimaryHomeInfo] = useState(primaryHome);
  const [selectedPrimaryHome, setSelectedPrimaryHome] = useState({});
  const [primaryHomeFormFieldObjects, setPrimaryHomeFormFieldObjects] =
    useState(primaryHomeFormFields);

  useEffect(() => {
    let tempPrimaryHomeFormFields = [...primaryHomeFormFields];
    if (taxStateOptions && taxStateOptions.length > 0) {
      tempPrimaryHomeFormFields = tempPrimaryHomeFormFields.map((f) => {
        if (f.key === 'propDomicile') {
          return { ...f, options: taxStateOptions };
        }
        return f;
      });
    }
    setPrimaryHomeFormFieldObjects(tempPrimaryHomeFormFields);
  }, [setPrimaryHomeFormFieldObjects, taxStateOptions]);

  const isEditingRef = useRef(false);

  const currencyFormatToUI = (value) => {
    const numFormat = Intl.NumberFormat('en-US');
    if (value !== null && value !== undefined && value !== '') {
      let currencyFieldValue = value.toString().replace(/\D/g, '');
      if (!isNaN(currencyFieldValue)) {
        return `$${numFormat.format(currencyFieldValue)}`;
      }
    }
    return '$0';
  };

  // Optimized percentage formatting function
  const percentageConversionToUI = (val) => {
    if (
      val === null ||
      val === undefined ||
      val === '' ||
      isNaN(parseFloat(val))
    ) {
      return '0.00%';
    }
    let percentValue = parseFloat(val.toString().replace('%', '').trim()); // Remove '%' and trim spaces
    if (isNaN(percentValue)) {
      return '0.00%'; // Fallback in case of unexpected input
    }

    if (percentValue < 0) {
      return `0.00%`;
    }
    if (percentValue < 1) {
      return `${(percentValue * 100).toFixed(2)}%`; // Convert decimal to percentage
    }

    // If it's a whole number or already a percentage
    return `${percentValue.toFixed(2)}%`;
  };

  const formatValueByType = useCallback((value, dataType) => {
    switch (dataType) {
      case 'currency':
        return currencyFormatToUI(value);
      case 'percent':
        return percentageConversionToUI(value);
      default:
        return value; // Return as is for other types
    }
  }, []);

  const formatValuesForUI = useCallback(
    (primaryHome) => {
      const formattedPrimaryHome = { ...primaryHome };
      primaryHomeFormFieldObjects.forEach((field) => {
        if (primaryHome.hasOwnProperty(field.key)) {
          formattedPrimaryHome[field.key] = formatValueByType(
            primaryHome[field.key],
            field.dataType
          );
        }
      });
      return formattedPrimaryHome;
    },
    [formatValueByType, primaryHomeFormFieldObjects]
  );

  useEffect(() => {
    if (!isEditingRef.current) {
      const primaryHome = primaryHomeInfo?.prop;
      if (primaryHome) {
        setSelectedPrimaryHome(formatValuesForUI(primaryHome));
      }
    }
  }, [primaryHomeInfo.prop, goal, formatValuesForUI]);

  const getIsValidFieldValue = useCallback(
    (key) => {
      const value = selectedPrimaryHome[key];
      let isValid = true;
      switch (key) {
        case 'annualExpense':
        case 'annualCollegeExpenseNow':
        case 'value529Plan':
          isValid = value;
          break;
        case 'collegeExpenseGrowth':
        case 'peakReturn529Plan':
          isValid = value;
          break;
        case 'collegeYears':
          isValid = value && parseInt(value) > 0;
          break;
        case 'collegeAge':
          isValid = value && parseInt(value) > 17;
          break;
        case 'yearBorn':
          isValid = value && parseInt(value) > 1900;
          break;
        default:
          break;
      }
      return isValid;
    },
    [selectedPrimaryHome]
  );

  const checkFormValidity = useCallback(() => {
    const allFields = [
      'annualExpense',
      'annualCollegeExpenseNow',
      'value529Plan',
      'collegeExpenseGrowth',
      'peakReturn529Plan',
      'collegeYears',
      'collegeAge',
      'yearBorn',
      'name',
    ];
    //const isValid = allFields.every((field) => getIsValidFieldValue(field));
    const isValid = true;
    setIsFormValid(isValid);
  }, [getIsValidFieldValue, setIsFormValid]);

  const updateKidByName = (primaryHome, name, updatedValues) => {
    let tempPrimaryHome = { ...primaryHome };
    if (tempPrimaryHome.name === name) {
      tempPrimaryHome = {
        ...tempPrimaryHome,
        lockedInSolution: true,
        ...updatedValues,
      };
    }
    return tempPrimaryHome;
  };

  const handleBlur = (field) => {
    const fieldType = primaryHomeFormFieldObjects.find(
      (ft) => ft.key === field
    );
    let formattedValue = selectedPrimaryHome[field];
    if (fieldType && fieldType.dataType === 'percent') {
      // Apply the formatting only on blur
      formattedValue = percentageConversionToUI(selectedPrimaryHome[field]);
    }
    // Update the selected secondHome with the formatted value
    const updatedPrimaryHome = updateKidByName(
      primaryHomeInfo.prop,
      selectedPrimaryHome.name,
      {
        [field]: formattedValue,
      }
    );
    const updatedData = { ...primaryHomeInfo, prop: updatedPrimaryHome };
    setPrimaryHomeInfo(updatedData); // Update the local state
    setSelectedPrimaryHome({ ...selectedPrimaryHome, [field]: formattedValue }); // Update the selected secondHome state
    isEditingRef.current = false;
    // checkFormValidity();
    // onUpdate(updatedData); // Notify parent component
  };

  const handleOnChange = (event, key) => {
    isEditingRef.current = true;
    const { value } = event?.target || '';
    const fieldType = primaryHomeFormFieldObjects.find((ft) => ft.key === key);
    let formattedValue = value;
    if (fieldType) {
      if (fieldType.dataType === 'percent') {
        // Allow the raw value to be typed without adding "%" on every key press
        formattedValue = value; // Keep it raw for now during typing
      } else {
        formattedValue = formatValueByType(value, fieldType.dataType);
      }
    }
    const updatedPrimaryHome = updateKidByName(
      primaryHomeInfo.prop,
      selectedPrimaryHome.name,
      {
        [key]: formattedValue,
      }
    );

    // Set the updated object with the modified secondHomes array
    const updatedData = { ...primaryHomeInfo, prop: updatedPrimaryHome };
    setPrimaryHomeInfo(updatedData); // Update the local state with the new data
    const primaryHome = { ...selectedPrimaryHome, [key]: formattedValue };
    // Update selected secondHome state to reflect changes in UI
    setSelectedPrimaryHome(primaryHome);
    // checkFormValidity();
  };

  useEffect(() => {
    setFormDetails(primaryHomeInfo);
    checkFormValidity();
  }, [primaryHomeInfo, setFormDetails, setIsFormValid, checkFormValidity]);

  const isDisabledFn = ({ isDisabled = false, key = '' }) => {
    if (isDisabled) return true;
    if (key === 'purchaseDate' || key === 'purchasePrice') {
      const { purchaseDate = '' } = selectedPrimaryHome || {};
      return purchaseDate && new Date(purchaseDate) < new Date();
    }
    return false;
  };

  const handleDateChange = (date, field) => {
    if (date) {
      // Convert date to the appropriate format if necessary
      const formattedDate =
        field.dataType === 'year'
          ? date.getFullYear().toString() // For year view, use the year part only
          : date.toISOString().split('T')[0]; // For full date view, use 'yyyy-MM-dd'
      isEditingRef.current = true;
      const fieldType = primaryHomeFormFieldObjects.find(
        (ft) => ft.key === field.key
      );
      if (fieldType) {
        const primaryHome = {
          ...selectedPrimaryHome,
          [field.key]: formattedDate,
        };
        setSelectedPrimaryHome(primaryHome);
      }
    }
  };

  const getDateValue = (date, field) => {
    const formattedValue = (() => {
      if (field.dataType === 'year') {
        // For 'year' view, parse and return just the year part
        const year = parseInt(date, 10);
        return year ? new Date(year, 0, 1) : null; // January 1 of the given year, in UTC
      } else {
        // For 'yyyy-MM-dd' view, directly parse the value
        return date ? new Date(date) : null; // Ensure the date is treated as UTC
      }
    })();

    const parsedValue =
      formattedValue && !isNaN(formattedValue.getTime())
        ? formattedValue
        : null;
    return parsedValue;
  };

  return (
    <>
      <div className="signup-title-container">
        <span className="signup-title">
          {modalType === 'add' ? 'Add New Member' : 'Update Member'}
        </span>
        <CloseIcon
          className="signup-title-close-icon"
          onClick={() => handleModalOpen(false)}
        />
      </div>
      <div className="signup-body-container">
        <div className="insured_main_outer">
          {primaryHomeFormFieldObjects.map((field) => {
            switch (field.type) {
              case 'text':
                return (
                  <div className="row align-items-center">
                    <div
                      className={`col-lg-8 insured_content_outer labelStyle 
          }`}
                    >
                      <p className={`${field.isRequired ? 'required' : ''}`}>
                        {field.name}
                      </p>
                    </div>
                    <div className="col-lg-3 insured_radio_group">
                      <input
                        type={field.type}
                        id={field.key}
                        name={field.key}
                        required={true}
                        // disabled={modalType === 'edit' && field.isDisabled}
                        disabled={modalType === 'edit' && isDisabledFn(field)}
                        value={selectedPrimaryHome[field.key]}
                        className={'custom-input'} // Apply the appropriate class
                        onChange={(event) => handleOnChange(event, field.key)}
                        onBlur={() => handleBlur(field.key)}
                        placeholder=""
                      />
                    </div>
                    {selectedPrimaryHome &&
                      Object.keys(selectedPrimaryHome).length > 0 &&
                      !getIsValidFieldValue(field.key) && (
                        <p className="error-text">
                          {selectedPrimaryHome[field.key]
                            ? `Invalid ${field.name}`
                            : field.errorMessage}
                        </p>
                      )}
                  </div>
                );
              case 'select':
                return (
                  <div className="row align-items-center">
                    <div className={`col-lg-8 insured_content_outer`}>
                      <p className={`${field.isRequired ? 'required' : ''}`}>
                        {field.name}
                      </p>
                    </div>
                    <div className={`col-lg-3 insured_radio_group`}>
                      <Controls.Select
                        name={field.key}
                        id={field.key}
                        value={selectedPrimaryHome[field.key]}
                        onChange={(event, index) =>
                          handleOnChange(event, field.key)
                        }
                        className={'custom-input'}
                        // disabled={!state.isEditable}
                        options={field.options.map((c) => {
                          return {
                            value: c.value,
                            label: c.label,
                          };
                        })}
                      />
                    </div>
                    {/* {isMemberFieldInValid && (
                      <p className="error-text">
                        {isMemberFieldInValid.validationMessage}
                      </p>
                    )} */}
                  </div>
                );
              case 'date':
                return (
                  <div className="row align-items-center">
                    <div className="col-lg-8 insured_content_outer">
                      <p className={`${field.isRequired ? 'required' : ''}`}>
                        {field.name}
                      </p>
                    </div>
                    <div className="col-lg-3 insured_radio_group">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          views={[field.dataType]}
                          className="planner-input long-text-input"
                          format={
                            field.dataType === 'year' ? 'yyyy' : 'yyyy-MM-dd'
                          }
                          autoOk
                          variant="inline"
                          InputAdornmentProps={{ position: 'start' }}
                          value={getDateValue(
                            selectedPrimaryHome[field.key],
                            field
                          )}
                          onChange={(date) => handleDateChange(date, field)}
                          inputProps={{ style: { height: '39px' } }}
                          //disabled={!state.isEditable}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    {/* {isFieldInValid && (
                      <p className="error-text">
                        {isFieldInValid.validationMessage}
                      </p>
                    )} */}
                  </div>
                );
              default:
                return null;
            }
          })}
        </div>
      </div>
    </>
  );
};
export default PrimaryHomeContentGoals;
