import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import add from '../../images/add.svg';
import { tenures } from '../../constants/CompanyHome';
import Controls from '../controls/Controls';

const AdvisorReviewsFirstRowSection = ({
  firstRowCTAs,
  timeLineChecked,
  // selectedAllocationId,
  // allocation,
  timelineChartData,
  toggleTimelineToNetworth,
  // onAllocationChange,
  charts,
  selectedPlanId,
  plans,
  onPlanChange,
  isDirectUser,
  isDirectOrClientUser,
  tenure,
  triggerSetTenure,
  isClientsNotUpdatedLoading,
  finalClientsNotUpdated,
  selectedClientId,
  handleClientOnboardingModalOpen,
  onClientChange,
  setActiveCardState,
}) => (
  <>
    <div className="col-lg-12 col-md-12 col-sm-12 mobile_card justify-content-between">
      <div
        className={`card home_main_card_outer three mb-4 active`}
        onClick={() => setActiveCardState('advisor')}
      >
        <div className="card-body client_contact_recency_inner">
          <div className="Under_Management d-md-flex">
            <div className="Under_Management_content d-flex">
              {tenures.map((m) => {
                return (
                  <div className="Under_Management_content_right">
                    <p
                      className={m.value === tenure ? 'active' : ''}
                      onClick={() => {
                        triggerSetTenure(m.value);
                      }}
                    >
                      {m.label}
                    </p>
                    <h4>
                      <span className="value">
                        {m.value === tenure && !isClientsNotUpdatedLoading
                          ? finalClientsNotUpdated.length
                          : '-'}
                      </span>
                    </h4>
                  </div>
                );
              })}
            </div>
          </div>
          {isClientsNotUpdatedLoading ? (
            <div className="select_client_outer">
              <CircularProgress
                sx={{
                  color: '#fff',
                  position: 'absolute',
                  left: '50%',
                  top: '80%',
                }}
                size={20}
              />
            </div>
          ) : (
            <div className="select_client_outer">
              <form className="">
                <div className="select_client_outer_form_row">
                  <div className="client client-review-dd">
                    <div className="Select_Client">
                      <Controls.Select
                        className="js-example-basic-single"
                        name="Select_Client"
                        id="Select_Client"
                        placeholder="Select Advisor"
                        value={selectedClientId}
                        onChange={(event) => onClientChange(event)}
                        options={finalClientsNotUpdated.map((c) => {
                          return {
                            value: c.clientId,
                            label: c.clientName,
                          };
                        })}
                      />
                    </div>
                  </div>
                  <div className="plan client-review-dd advisor_add_btn_outer">
                    <button
                      type="button"
                      className="common-button"
                      onClick={() => handleClientOnboardingModalOpen(true)}
                    >
                      <img src={add} alt="" title="" />
                      Advisor
                    </button>
                    {/* <button
                        type="button"
                        className={
                          !selectedClientId
                            ? 'common-button secondary-button'
                            : 'common-button'
                        }
                        disabled={!selectedClientId || selectedClientId === ''}
                        onClick={() => handleAddPlanModalOpen(true)}
                      >
                        <img src={add} alt="" title="" />
                        Plan
                      </button> */}
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  </>
);

export default AdvisorReviewsFirstRowSection;
