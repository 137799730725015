import Highcharts from 'highcharts/highstock';
import {
  orange,
  yellow,
  green,
  red,
  grey,
  blue,
  brown,
} from '@mui/material/colors';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});
const getYData = (data, xData) => {
  if (data) {
    return data.map((y, index) => {
      return {
        y: y,
        drilldown: xData && xData[index] ? xData[index] : undefined,
        name: xData && xData[index] ? xData[index] : '',
        // displayNegative: true,
        // negativeColor: '#C70039',
      };
    });
  }
  return [];
};

const getArearangeYData = (yaxis, xData, type) => {
  let series = [];
  if (yaxis && yaxis.length > 0) {
    let yData = yaxis.map((m) => {
      return m.data;
    });
    const areaData = yData.splice(0, 2);
    const lineData = yData[0];
    const areaData1 = areaData[0];
    const areaData2 = areaData[1];
    if (type === 'line') {
      series = lineData.map((l, index) => {
        return [xData[index], l];
      });
    } else {
      series = areaData1.map((a, index) => {
        return [xData[index], a, areaData2[index]];
      });
    }
    return series;
  }
  return series;
};

const getAreaRangeYaxis = (yaxis, mainChartData) => {
  if (yaxis) {
    const series = [
      {
        type: 'line',
        name: yaxis[2].seriesName,
        zIndex: 1,
        marker: {
          fillColor: 'white',
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[0],
        },
        data:
          mainChartData &&
          mainChartData.xaxis &&
          mainChartData.xaxis.data &&
          mainChartData.xaxis.data.length > 0
            ? getArearangeYData(yaxis, mainChartData.xaxis.data, 'line')
            : [],
      },
      {
        type: 'arearange',
        name: `${yaxis[0].seriesName} - ${yaxis[1].seriesName}`,
        zIndex: 0,
        lineWidth: 0,
        linkedTo: ':previous',
        color: Highcharts.getOptions().colors[0],
        fillOpacity: 0.3,
        marker: { enabled: false },
        data:
          mainChartData &&
          mainChartData.xaxis &&
          mainChartData.xaxis.data &&
          mainChartData.xaxis.data.length > 0
            ? getArearangeYData(yaxis, mainChartData.xaxis.data, 'area')
            : [],
      },
    ];
    return series;
  }
};

const getYaxis = (yaxis, mainChartData, from, isShowRightAxis) => {
  if (yaxis) {
    const yaxisTemp = isShowRightAxis
      ? yaxis
      : yaxis.filter((y) => !y.rightAxis);
    const series = yaxisTemp.map((y, index) => {
      return {
        type: y.rightAxis ? 'spline' : 'column',
        name: y.seriesName || y.label,
        data:
          mainChartData &&
          mainChartData.xaxis &&
          mainChartData.xaxis.data &&
          mainChartData.xaxis.data.length > 0
            ? getYData(y.data, mainChartData.xaxis.data)
            : getYData(y.data) || [],
        drilldown: y.seriesName,
        // displayNegative: true,
        // negativeColor: '#C70039',
        yAxis: y.rightAxis ? 1 : 0,
        marker: {
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: 'white',
        },
      };
    });
    return series;
  }
};

const getData = (yaxis, index) => {
  if (yaxis && yaxis.length > 0) {
    return yaxis.map((m) => {
      return [m.seriesName, m.data[index]];
    });
  }
};

export const getDrillDownData = (mainChartData) => {
  if (
    mainChartData &&
    mainChartData.yaxis &&
    mainChartData.yaxis.length > 0 &&
    mainChartData.xaxis &&
    mainChartData.xaxis.data &&
    mainChartData.xaxis.data.length > 0
  ) {
    return mainChartData.xaxis.data.map((x, index) => {
      return {
        id: x,
        name: x,
        // displayNegative: true,
        // negativeColor: '#C70039',
        data: getData(mainChartData.yaxis, index) || [],
      };
    });
  }
  return [];
};

export const setMainChartOptionsArearange = (mainChartData) => {
  const comboOption = {
    title: {
      text: mainChartData.chartName,
      style: {
        fontWeight: '600',
        fontSize: '14px',
      },
    },
    xAxis: {
      type: 'category',
      title: {
        text: (mainChartData.xaxis && mainChartData.xaxis.seriesName) || '',
        style: {
          fontWeight: '600',
          fontSize: '14px',
        },
      },
    },
    yAxis: {
      title: {
        text: (mainChartData && mainChartData.yaxisLeftLabel) || '',
        style: {
          fontWeight: '600',
          fontSize: '14px',
        },
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    plotOptions: {
      series: {
        pointStart: 30,
      },
    },
    colors: [
      '#459270',
      '#52BE81',
      '#5AAE60',
      '#8AD184',
      '#DEE07F',
      '#7FCDBB',
      '#C7E9B4',
      '#EDF8B1',
      '#DFDF8B',
      '#41B6C4',
      '#465582',
    ],
    series:
      mainChartData && mainChartData.yaxis && mainChartData.yaxis.length > 0
        ? getAreaRangeYaxis(mainChartData.yaxis, mainChartData)
        : [],
  };
  return comboOption;
};

const getLineSeries = (yaxis) => {
  let series = yaxis.map((m) => {
    return {
      name: m.seriesName,
      data: m.data,
      // displayNegative: true,
      // negativeColor: '#C70039',
    };
  });
  return series;
};

export const setMainChartOptionsLine = (mainChartData) => {
  const options = {
    title: {
      text: (mainChartData && mainChartData.chartName) || '',
      style: { fontWeight: '600', fontSize: '14px' },
    },
    xAxis: {
      categories:
        (mainChartData && mainChartData.xaxis && mainChartData.xaxis.data) ||
        [],
      type: 'category',
      title: {
        text: (mainChartData.xaxis && mainChartData.xaxis.seriesName) || '',
        style: {
          fontWeight: '600',
          fontSize: '14px',
        },
      },
    },
    yAxis: {
      title: {
        text: (mainChartData && mainChartData.yaxisLeftLabel) || '',
      },
    },
    // legend: {
    //   layout: 'vertical',
    //   align: 'right',
    //   verticalAlign: 'middle',
    // },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
    colors: ['#7FCDBB', '#465582', '#41B6C4', '#DFDF8B', '#459270'],
    series:
      mainChartData && mainChartData.yaxis
        ? getLineSeries(mainChartData.yaxis)
        : [],
  };
  return options;
};

export const setMainChartOptions = (
  mainChartData,
  drillupCallback,
  drilldownCallback,
  isShowRightAxis
) => {
  const comboOption = {
    title: {
      text: mainChartData.chartName,
      style: { fontWeight: '600', fontSize: '14px' },
    },
    chart: {
      type: 'column',
      // height: '400',
      events: {
        drillup: function () {
          drillupCallback();
          var chart = this;
          chart.redraw();
        },
        drilldown: function (e) {
          drilldownCallback(e);
        },
      },
    },
    colors: [
      '#459270',
      '#52BE81',
      '#5AAE60',
      '#8AD184',
      '#DEE07F',
      '#7FCDBB',
      '#C7E9B4',
      '#EDF8B1',
      '#DFDF8B',
      '#41B6C4',
      '#465582',
    ],
    xAxis: {
      type: 'category',
      title: {
        text: (mainChartData.xaxis && mainChartData.xaxis.seriesName) || '',
        style: {
          fontWeight: '600',
          fontSize: '14px',
        },
      },
    },
    yAxis: [
      {
        title: {
          text: (mainChartData && mainChartData.yaxisLeftLabel) || '',
          style: {
            fontWeight: '600',
            fontSize: '14px',
          },
        },
        stackLabels: {
          enabled: false,
        },
      },
      {
        title: {
          text:
            (isShowRightAxis &&
              mainChartData &&
              mainChartData.yaxisRightLabel) ||
            '',
          style: {
            fontWeight: '600',
            fontSize: '14px',
          },
        },
        // opposite: true,
      },
    ],
    plotOptions: {
      column: {
        stacking:
          mainChartData.leftChartStyle === 'Stacked' ? 'normal' : undefined,
      },
    },
    series:
      mainChartData && mainChartData.yaxis && mainChartData.yaxis.length > 0
        ? getYaxis(mainChartData.yaxis, mainChartData, '', isShowRightAxis)
        : [],
    drilldown: {
      breadcrumbs: {
        position: {
          align: 'right',
        },
      },
      series:
        mainChartData.leftChartStyle === 'Stacked'
          ? getDrillDownData(mainChartData) || []
          : [],
    },
  };
  return comboOption;
};

export const setColumnChartOptions = (columnChartData) => {
  const options = {
    chart: {
      type: 'column',
    },
    colors: [
      '#459270',
      '#52BE81',
      '#5AAE60',
      '#8AD184',
      '#DEE07F',
      '#7FCDBB',
      '#C7E9B4',
      '#EDF8B1',
      '#DFDF8B',
      '#41B6C4',
      '#465582',
    ],
    xAxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },
    plotOptions: {
      series: {
        pointWidth: 20,
      },
    },
    series: [
      {
        data: [
          29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1,
          95.6, 54.4,
        ],
      },
    ],
  };
  return options;
};

export const setTimelineChartOptions = (
  chartOptions,
  heading = 'Key Events'
) => {
  const comboOptions = {
    chart: {
      type: 'timeline',
      height: 300,
    },
    // colors: [
    //   '#459270',
    //   '#52BE81',
    //   '#5AAE60',
    //   '#8AD184',
    //   '#DEE07F',
    //   '#7FCDBB',
    //   '#C7E9B4',
    //   '#EDF8B1',
    //   '#DFDF8B',
    //   '#41B6C4',
    //   '#465582',
    // ],
    tooltip: {
      enabled: false,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    title: {
      text: heading,
      style: {
        fontWeight: '600',
        fontSize: '14px',
      },
    },
    subtitle: {
      text: '',
    },
    series: [
      {
        data: chartOptions,
      },
    ],
  };
  return comboOptions;
};

const colors = ['#459270', '#52BE81'];
const colors2 = ['#FFB347', '#8AD184'];
// const colors = ['#459270', '#52BE81', '#5AAE60'];
// const colors2 = ['#8AD184', '#DEE07F', '#7FCDBB'];
const getBulletData = (data, idx, plotBandColors) => {
  let i = 0;
  const add = [];
  let newPlotBandsData = [];
  for (i = 0; i < data.length - 1; i++) {
    add.push({
      from: data[i],
      to: data[i + 1],
      color:
        idx === 0
          ? plotBandColors[i + 1]
            ? getColors(plotBandColors[i + 1])
            : colors[i]
          : plotBandColors[i + 1]
          ? getColors(plotBandColors[i + 1])
          : colors2[i],
    });
  }
  // while (i < data.length) {
  //   add.push({
  //     from: data[i],
  //     to: data[i + 1],
  //     color: colors[i],
  //   });
  //   add.push({
  //     from: data[i + 1],
  //     to: data[i + 2],
  //     color: colors[i + 1],
  //   });
  //   i += 2;
  // }
  newPlotBandsData = add.filter(
    (item) => item.from !== undefined && item.to !== undefined
  );
  return newPlotBandsData;
};

export const setBulletSeriesOptions = (data, index) => {
  const { current, name, target, plotBands, plotBandColors, ...rest } = data;
  let options = {};
  if (plotBands && plotBands.length > 0) {
    options = {
      chart: {
        inverted: true,
        height: 85,
        type: 'bullet',
      },
      title: { text: null },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      plotOptions: {
        series: {
          pointPadding: 0.35,
          borderWidth: 0,
          color: '#fff',
          targetOptions: {
            width: '400%',
            // color: '#1976d2',
          },
        },
      },
      xAxis: {
        categories: [`${data.name}`],
        opposite: true,
        labels: {
          useHTML: true,
          style: {
            whiteSpace: 'normal', // Allow labels to wrap
            textAlign: 'center', // Center align the labels
            fontSize: '12px',
          },
        },
        // labels: {
        //   align: 'center', // Align the labels to the center
        //   x: 0,
        //   y: -20, // Adjust this value to position the labels above the bullets
        //   style: {
        //     fontSize: '12px',
        //   },
        // },
      },
      yAxis: {
        // colorByPoint: true,
        gridLineWidth: 0,
        plotBands: getBulletData(plotBands, index, plotBandColors),
        // min: 0,
        max: plotBands[plotBands.length - 1],
        // labels: {
        //   format: '{value}',
        // },
        title: {
          text: null,
          style: {
            fontWeight: '600',
            fontSize: '14px',
          },
        },
      },
      series: [
        {
          data: [
            {
              y: data.current,
              target: data.target,
            },
          ],
        },
      ],
      tooltip: {
        pointFormat: '<b>{point.y}</b> (Target at {point.target})',
      },
    };
  }
  return options;
};

const getLollipopSeries = (series) => {
  const formattedSeries = series.map((m) => {
    return {
      name: m.name,
      data:
        m.data && m.data.length > 0
          ? m.data.map((m) => {
              return {
                name: m.element[0],
                low: m.element[1],
              };
            })
          : [],
    };
  });
  return formattedSeries;
};

export const setLollipopChartOptions = (data) => {
  const { xaxis, yaxis, series } = data;
  const options = {
    chart: {
      type: 'lollipop',
      height: 300,
    },
    colors: [
      '#459270',
      '#52BE81',
      '#5AAE60',
      '#8AD184',
      '#DEE07F',
      '#7FCDBB',
      '#C7E9B4',
      '#EDF8B1',
      '#DFDF8B',
      '#41B6C4',
      '#465582',
    ],
    legend: {
      enabled: true,
    },
    title: {
      text: '',
    },
    tooltip: {
      shared: true,
    },
    xAxis: {
      type: 'category',
      // reversed: true,
      title: {
        text: xaxis,
        style: {
          fontWeight: '600',
          fontSize: '14px',
        },
      },
    },
    yAxis: {
      title: {
        text: yaxis,
        style: {
          fontWeight: '600',
          fontSize: '14px',
        },
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: getLollipopSeries(series),
  };
  return options;
};

const getAUMDistributionData = (data) => {
  data = data.filter((f) => f.assetPercent !== 0);
  const aumData = data.map((m) => {
    return {
      name: m.assetType,
      y: m.assetPercent * 100,
      z: m.assetActualValue,
    };
  });
  return aumData;
};

export const setVariblePieChartOptions = (data) => {
  Highcharts.setOptions({
    lang: {
      thousandsSep: ',',
    },
  });
  const options = {
    chart: {
      type: 'variablepie',
    },
    legend: {
      itemDistance: 10,
    },
    title: {
      text: '',
      align: 'left',
    },
    tooltip: {
      useHTML: true,
      headerFormat: '',
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
        '<b>$ </b>' +
        '<b>{point.z:,.0f}</b><br/>' +
        '<b>{point.percentage:.0f}%</b><br/>',
    },
    series: [
      {
        size: 200,
        minPointSize: 10,
        innerSize: '20%',
        zMin: 0,
        showInLegend: true,
        dataLabels: { enabled: false },
        name: 'assetMix',
        borderRadius: 5,
        data: getAUMDistributionData(data) || [],
        colors: [
          '#7FCDBB',
          '#C7E9B4',
          '#EDF8B1',
          '#DFDF8B',
          '#459270',
          '#41B6C4',
          '#8AD184',
          '#465582',
          '#52BE81',
        ],
      },
    ],
  };
  return options;
};

const getSeriesData = (clientAgeStats, index) => {
  return clientAgeStats.map((c, i) => {
    return c.clientsPercent;
  });
};

const getSeriesSpiderWebData = (data) => {
  return data.map((c, i) => {
    return c.value;
  });
};

const getSpiderWebXaxisData = (data) => {
  return data.map((c, i) => {
    return c.label;
  });
};

const getSpiderXaxisData = (data, isAll) => {
  const clientAgeStats =
    data && data.length > 0
      ? isAll
        ? data
        : data[0].hasOwnProperty('clientAgeStats') && data[0].clientAgeStats
      : [];
  const xAxisData =
    clientAgeStats.length > 0 ? clientAgeStats.map((d) => d.clientAgeKey) : [];
  return xAxisData;
};

const getSpiderSeriesData = (data, isAll) => {
  const colors = [
    '#459270',
    '#52BE81',
    '#5AAE60',
    '#8AD184',
    '#DEE07F',
    '#7FCDBB',
    '#C7E9B4',
    '#EDF8B1',
    '#DFDF8B',
    '#41B6C4',
    '#465582',
  ];
  const seriesData =
    isAll && data && data.length > 0
      ? [
          {
            color: colors[0],
            fillOpacity: 0.3,
            type: 'area',
            name: 'All Client Age Stats',
            data: getSeriesData(data),
            pointPlacement: 'on',
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.y + '%';
              },
            },
          },
        ]
      : data &&
        data.length > 0 &&
        data.map((d, i) => {
          return {
            color: colors[i],
            fillOpacity: 0.3,
            type: 'area',
            name: d.clientTypeKey,
            data: getSeriesData(d.clientAgeStats, i),
            pointPlacement: 'on',
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.y + '%';
              },
            },
          };
        });
  return seriesData;
};

const getSpiderWebSeriesData = (data) => {
  const colors = [
    '#459270',
    '#52BE81',
    '#5AAE60',
    '#8AD184',
    '#DEE07F',
    '#7FCDBB',
    '#C7E9B4',
    '#EDF8B1',
    '#DFDF8B',
    '#41B6C4',
    '#465582',
  ];
  const seriesData =
    data && data.length > 0
      ? [
          {
            color: colors[0],
            fillOpacity: 0.3,
            type: 'area',
            name: 'Affordability across market conditions',
            data: getSeriesSpiderWebData(data),
            pointPlacement: 'on',
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.y + '%';
              },
            },
          },
        ]
      : [];

  return seriesData;
};

export const setSpiderChartOptions = (data, isAll) => {
  const options = {
    chart: {
      polar: true,
      type: 'line',
    },
    title: {
      text: '',
      x: -80,
    },
    pane: {
      size: '80%',
    },
    tooltip: {
      useHTML: true,
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> {series.name}</b><br/>' +
        '<b>{point.y:.0f} %</b><br/>',
    },
    xAxis: {
      categories: getSpiderXaxisData(data, isAll),
      tickmarkPlacement: 'on',
      lineWidth: 0,
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      // min: 0,
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
    },
    plotOptions: {
      series: {
        events: {
          mouseOver: function () {
            this.graph.attr({
              fill: '#C7E9B4',
            });
          },
          mouseOut: function () {
            this.graph.attr({
              fill: 'transparent',
            });
          },
        },
      },
    },
    series: getSpiderSeriesData(data, isAll),
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal',
            },
            pane: {
              size: '70%',
            },
          },
        },
      ],
    },
  };
  return options;
};

export const setSpiderWebChartOptions = (data) => {
  const { chartTitle = '', elements = [] } = data || {};
  const options = {
    chart: {
      polar: true,
      type: 'line',
    },
    title: {
      text: chartTitle,
      x: -80,
      style: {
        fontWeight: '600',
        fontSize: '14px',
      },
    },
    pane: {
      size: '80%',
    },
    tooltip: {
      useHTML: true,
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> {series.name}</b><br/>' +
        '<b>{point.y:.0f} %</b><br/>',
    },
    xAxis: {
      categories: getSpiderWebXaxisData(elements),
      tickmarkPlacement: 'on',
      lineWidth: 0,
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      // min: 0,
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
    },
    plotOptions: {
      series: {
        events: {
          mouseOver: function () {
            this.graph.attr({
              fill: '#C7E9B4',
            });
          },
          mouseOut: function () {
            this.graph.attr({
              fill: 'transparent',
            });
          },
        },
      },
    },
    series: getSpiderWebSeriesData(elements),
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal',
            },
            pane: {
              size: '70%',
            },
          },
        },
      ],
    },
  };
  return options;
};

const getCategories = (clientTypeStats) => {
  const cat = clientTypeStats.filter((d) => d.numClients > 0);
  return cat.map((c) => c.clientTypeKey);
};
const getRadialData = (m, i, length) => {
  const a = new Array(length).fill(0);
  a[i] = m;
  return a;
};
const getRadialSeriesData = (clientTypeStats) => {
  let seriesData = clientTypeStats.map((m, i) => {
    return {
      name: m.clientTypeKey,
      data: getRadialData(m.numClients, i, clientTypeStats.length),
    };
  });
  return seriesData;
};

export const setRadialCircularChartOptions = (data) => {
  const cts = data.filter((d) => d.numClients > 0);
  const options = {
    colors: [
      '#459270',
      '#52BE81',
      '#5AAE60',
      '#8AD184',
      '#DEE07F',
      '#7FCDBB',
      '#C7E9B4',
      '#EDF8B1',
      '#DFDF8B',
      '#41B6C4',
      '#465582',
    ],
    chart: {
      type: 'column',
      inverted: true,
      polar: true,
      marginTop: 0,
    },
    title: {
      text: '',
    },
    tooltip: {
      outside: true,
    },
    pane: {
      size: cts.length > 8 ? '90%' : '80%',
      innerSize: cts.length > 8 ? '10%' : '15%',
      endAngle: 300,
    },
    xAxis: {
      visible: false,
    },
    // xAxis: {
    //   visible: false,
    //   categories: getCategories(data),
    // },
    yAxis: {
      visible: false,
    },
    plotOptions: {
      column: {
        borderWidth: 1,
        pointPadding: cts.length > 3 ? -0.5 : 0.1,
        groupPadding: cts.length > 3 ? 0.05 : 0.03,
      },
    },
    series: getRadialSeriesData(cts),
  };
  return options;
};
const getBubbleData = (data) => {
  const finalData = [];
  if (data && data.length > 0) {
    data.forEach((f) => {
      if (finalData.indexOf(f.element) === -1) {
        finalData.push(Object.values(f.element));
      }
    });
  }
  return finalData;
};

const getBubbleSeriesData = (series) => {
  const seriesData = series.map((m) => {
    return { ...m, data: getBubbleData(Object.values(m.data)) };
  });
  return seriesData;
};

export const setBubbleChartOptions = (data) => {
  const options = {
    chart: {
      type: 'bubble',
      plotBorderWidth: 1,
      zoomType: 'xy',
      height: 290,
    },
    colors: [
      '#459270',
      '#465582',
      '#41B6C4',
      '#DFDF8B',
      '#7FCDBB',
      '#52BE81',
      '#DEE07F',
      '#C7E9B4',
      '#5AAE60',
      '#EDF8B1',
      '#8AD184',
    ],
    legend: {
      enabled: true,
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      gridLineWidth: 1,
      title: {
        text: data.xaxis,
        style: {
          fontWeight: '600',
          fontSize: '14px',
        },
      },
      //type: 'category',
      // labels: {
      //   format: '{value} gr',
      // },
    },
    yAxis: {
      startOnTick: false,
      endOnTick: false,
      title: {
        text: data.yaxis,
        style: {
          fontWeight: '600',
          fontSize: '14px',
        },
      },
      // labels: {
      //   format: '{value} gr',
      // },
      maxPadding: 0.2,
    },
    // tooltip: {
    //   useHTML: true,
    //   headerFormat: '<table>',
    //   pointFormat:
    //     '<tr><th colspan="2"><h3>{point.country}</h3></th></tr>' +
    //     '<tr><th>Fat intake:</th><td>{point.x}g</td></tr>' +
    //     '<tr><th>Sugar intake:</th><td>{point.y}g</td></tr>' +
    //     '<tr><th>Obesity (adults):</th><td>{point.z}%</td></tr>',
    //   footerFormat: '</table>',
    //   followPointer: true,
    // },
    // plotOptions: {
    //   series: {
    //     dataLabels: {
    //       enabled: true,
    //     },
    //   },
    // },
    series: getBubbleSeriesData(data.series.filter((d) => d)),
  };
  return options;
};

const getPieSeriesData = (subChartData) => {
  return subChartData.name.map((x, index) => {
    return {
      name: x,
      y: subChartData.dataY[index] > 0 ? subChartData.dataY[index] : null,
    };
  });
};

const getSeries = (subChartData) => {
  return [
    {
      name: subChartData.chartName,
      colorByPoint: true,
      data: getPieSeriesData(subChartData),
    },
  ];
};

const getIndividualPieSeriesData = (data, totalOrSurplusDataSeriesName) => {
  return data.map((x) => {
    if (x[0] !== totalOrSurplusDataSeriesName) {
      return {
        name: x[0],
        y: x[1] > 0 ? x[1] : null,
      };
    } else {
      return null;
    }
  });
};

const getPieSeries = (pieData, totalOrSurplusDataSeriesName) => {
  return [
    {
      name: pieData.name,
      colorByPoint: true,
      data: getIndividualPieSeriesData(
        pieData.data,
        totalOrSurplusDataSeriesName
      ),
    },
  ];
};

const getText = (yAxisLabel) => {
  const dn =
    (yAxisLabel !== '' &&
      yAxisLabel.includes('$') &&
      yAxisLabel.split('$ ')[1]) ||
    '';
  var dnType = '';
  switch (dn.toLowerCase().trim()) {
    case 'thousands':
      dnType = 'K';
      break;
    case 'millions':
      dnType = 'M';
      break;
    case 'billions':
      dnType = 'B';
      break;
    default:
      break;
  }
  return dnType;
};

const getTooltipText = (yAxisLabel, from) => {
  const dnType = getText(yAxisLabel);
  //const tooltip = `<b>{point.name}</b> <br/> $ {point.y} ${dnType} ({point.percentage:.0f}%)`;
  const tooltip = from
    ? `{point.name} ({point.percentage:.0f}%)`
    : `$ {point.y}${dnType} ({point.percentage:.0f}%)`;
  return tooltip;
};

const plotOptionsPie = (from, yAxisLeftLabel) => {
  const options = {
    size: from ? 120 : 230,
    allowPointSelect: true,
    cursor: 'pointer',
    dataLabels: {
      enabled: true,
      format: getTooltipText(yAxisLeftLabel, 'label'),
      distance: from ? 10 : -30,
    },
  };
  const { size, ...rest } = options; // removing size attribute as it is not useful for CTAs (rendering small pies in cta)
  //return from ? rest : options;
  return options;
};

export const setSubChartOptions = (subChartData, from, yAxisLeftLabel) => {
  const comboOption = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height: from && 280,
    },
    colors: [
      '#459270',
      '#52BE81',
      '#5AAE60',
      '#8AD184',
      '#DEE07F',
      '#7FCDBB',
      '#C7E9B4',
      '#EDF8B1',
      '#DFDF8B',
      '#41B6C4',
      '#465582',
    ],
    title: {
      style: {
        fontSize: from ? '14px' : '18px',
        fontWeight: '600',
      },
      text: subChartData.chartName,
    },
    tooltip: {
      pointFormat: getTooltipText(yAxisLeftLabel),
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: plotOptionsPie(from, yAxisLeftLabel),
    },
    series:
      subChartData &&
      subChartData.name &&
      subChartData.name.length > 0 &&
      subChartData.dataY &&
      subChartData.dataY.length > 0
        ? getSeries(subChartData)
        : [],
  };
  return comboOption;
};

export const setPieChartsData = (
  e,
  chartName,
  totalOrSurplusDataSeriesName,
  yAxisLeftLabel
) => {
  const comboOption = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    colors: [
      '#459270',
      '#52BE81',
      '#5AAE60',
      '#8AD184',
      '#DEE07F',
      '#7FCDBB',
      '#C7E9B4',
      '#EDF8B1',
      '#DFDF8B',
      '#41B6C4',
      '#465582',
    ],
    title: {
      text:
        e.seriesOptions &&
        e.seriesOptions &&
        e.seriesOptions.name &&
        chartName !== ''
          ? chartName +
            ' in ' +
            yAxisLeftLabel +
            ' (Age ' +
            e.seriesOptions.name +
            ')'
          : '',
    },
    tooltip: {
      pointFormat: getTooltipText(yAxisLeftLabel),
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format:
            '{point.name} ' +
            // `${getText(yAxisLeftLabel)} ` +
            '({point.percentage:.0f}%)',
          // distance: -30,
        },
      },
    },
    series:
      e &&
      e.seriesOptions &&
      e.seriesOptions.data &&
      e.seriesOptions.data.length > 0
        ? getPieSeries(e.seriesOptions, totalOrSurplusDataSeriesName)
        : [],
  };
  return comboOption;
};

const getColors = (color) => {
  switch (color && color.toLowerCase()) {
    case 'green':
      return '#00A36C';
    case 'lightgreen':
      return '#90EE90';
    case 'red':
      return '#C70039';
    case 'orange':
      return '#FFC300';
    case 'yellow':
      return yellow[600];
    case 'blue':
      return blue[600];
    case 'brown':
      return brown[600];
    default:
      return grey[600];
  }
};

const getPlotbands = (marker, minReading) => {
  let sortedMarker = marker.sort(function (a, b) {
    return a.reading - b.reading;
  });
  const plotbands = sortedMarker.map((m, i) => {
    return {
      from: i === 0 ? minReading : sortedMarker[i - 1].reading,
      to: m.reading,
      color: getColors(m.healthFlag), //i === 0 ? '#55BF3B' : i === 1 ? '#DDDF0D' : '#DF5353',
      thickness: 15,
    };
  });
  return plotbands;
};

export const setMeterChartOptions = (data, height) => {
  const {
    heading,
    current,
    marker,
    maxReading,
    minReading,
    numMarkers,
    subtabName,
    title = '',
  } = data;
  const meterHeading = heading.split(':');
  const options = {
    chart: {
      backgroundColor: '#ffffff',
      type: 'gauge',
      spacingBottom: 0,
      spacingTop: 10,
      plotBackgroundColor: '#ffffff',
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: height / 1.7, //setting this fraction as per window height
    },
    title: {
      text: title,
      style: { fontWeight: '600', fontSize: '14px' },
    },
    pane: {
      startAngle: -90,
      endAngle: 90,
      background: null,
      center: ['50%', '70%'],
      size: '110%',
    },
    // the value axis
    yAxis: {
      min: minReading,
      max: maxReading,
      center: ['50%', '50%'],
      tickPixelInterval: 50,
      tickPosition: 'inside',
      tickColor: 'Highcharts.defaultOptions.chart.backgroundColor' || '#FFFFFF',
      tickLength: 10,
      tickWidth: 1,
      minorTickInterval: null,
      labels: {
        distance: 10,
        style: {
          fontSize: '10px',
          fontColor: '#ffffff',
        },
      },
      lineWidth: 0,
      plotBands: getPlotbands(marker, minReading),
    },
    series: [
      {
        name: 'Networth Percentiles',
        data: [current],
        tooltip: {
          valueSuffix: ' %',
        },
        dataLabels: {
          useHTML: true,
          format: `
          <div style="text-align:center"><span style="color:#ffb31f;font-size:14px;">${meterHeading[1]}</span>
          <br/>
          <span style="color:#479573;font-size:12px;">${meterHeading[0]}</span>
          </div>
          `,
          borderWidth: 0,
          align: 'center', // Center horizontally
          verticalAlign: 'middle', // Center vertically
          y: 25,
          style: {
            textAlign: 'center', // Ensure text inside the div is centered
          },
        },
        dial: {
          radius: '70%',
          backgroundColor: 'gray',
          baseWidth: 10,
          baseLength: '0%',
          rearLength: '0%',
        },
        pivot: {
          backgroundColor: 'gray',
          radius: 6,
        },
      },
    ],
  };
  return options;
};
