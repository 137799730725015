import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import {
  planDetailsByPlanIdSelector,
  allPlannerMenuItemsSelector,
} from '../../selectors/plannerDetailsSelector';
import { Grid } from '@mui/material'; // Import dialog components
import Controls from '../controls/Controls';
import { validateMemberNames } from '../../helpers/ClientHelper';
import { validateAgeFieId } from '../../utils/validators';
import MuiAlert from '@mui/material/Alert';

const CoreComponentGoals = (props) => {
  const { commonProps = {} } = props;
  const {
    modalType,
    handleModalOpen,
    category,
    goal = {},
    setIsFormValid,
    setFormDetails,
  } = commonProps;
  const planDetailsByPlanId = useSelector((state) =>
    planDetailsByPlanIdSelector(state)
  );
  const allMenuItems = useSelector((state) =>
    allPlannerMenuItemsSelector(state)
  );
  const { moduleFamily: { infoCore = {} } = {} } = planDetailsByPlanId;
  const [basicsInfo, setBasicsInfo] = useState({
    name: '',
    isnameValid: true,
    age: '',
    isageValid: true,
    gender: '',
    retirementAge: '',
    isretirementAgeValid: true,
    lockedInSolution: false,
    isMemberOne: '', //identifier to find member one or two, it holds basicsFirst or basicsSecond
  });
  const [basicsInfoKey, setBasicsInfoKey] = useState('');
  const [validatorMessage, setValidatorMessage] = useState('');
  const [alertMessageOnAgeChange, setAlertMessageOnAgeChange] = useState('');
  const { genderMenuItems = [] } = allMenuItems;

  const handleFindMatchingObject = useCallback(() => {
    const findMatchingObject = () => {
      for (let key in infoCore) {
        if (infoCore[key].name === goal.name) {
          return { key: key, value: infoCore[key] };
        }
      }
      return null; // Return null if no match is found
    };
    if (Object.keys(infoCore).length > 0 && Object.keys(goal).length > 0) {
      const basicsInfo = findMatchingObject();
      if (basicsInfo) {
        setBasicsInfoKey(basicsInfo?.key || '');
        setBasicsInfo((old) => ({
          ...old,
          ...basicsInfo?.value,
          isMemberOne: basicsInfo?.key || '',
        }));
      }
    }
  }, [infoCore, goal]);

  useEffect(() => {
    setFormDetails(basicsInfo);
    const isAddMemberFormValid = () => {
      const {
        name,
        age,
        retirementAge,
        isnameValid,
        isageValid,
        isretirementAgeValid,
      } = basicsInfo || {};
      return (
        name &&
        age &&
        retirementAge &&
        isnameValid &&
        isageValid &&
        isretirementAgeValid
      );
    };
    const isFormValid = isAddMemberFormValid();
    setIsFormValid(isFormValid);
  }, [basicsInfo, setFormDetails, setIsFormValid]);

  useEffect(() => {
    handleFindMatchingObject();
  }, [handleFindMatchingObject]);

  const {
    name = '',
    age = '',
    retirementAge = '',
    gender = '',
    isnameValid = true,
    isageValid = true,
    isretirementAgeValid = true,
  } = basicsInfo || {}; //this will hold basicsFirst or basicsSecond Info

  const handleAddMemberFormFieldChange = (event, from) => {
    const { value = '' } = event && event.target;
    let isValid = true;
    let validatorMsg = '';
    switch (from && from.toLowerCase()) {
      case 'name':
        validatorMsg = validateMemberNames(
          value,
          'moduleFamily',
          'infoCore',
          planDetailsByPlanId,
          basicsInfoKey === 'basicsFirst' ? 'basicsSecond' : 'basicsFirst'
        );
        isValid = validatorMsg ? false : true;
        break;
      case 'age':
      case 'retirementage':
        isValid = validateAgeFieId(value, basicsInfo, from);
        break;
      case 'gender':
        isValid = true;
        break;
      default:
        break;
    }
    setBasicsInfo((old) => ({
      ...old,
      [from]: isValid ? value.replace(/\s+/g, ' ') : value,
      [`is${from}Valid`]: isValid,
    }));
    if (from === 'name') {
      setValidatorMessage(validatorMsg);
    }
    if (from === 'age' || from === 'retirementAge') {
      setAlertMessageOnAgeChange(
        'Any Change made to current age or retirement age will reset pension information that may need your attention!'
      );
    } else {
      setAlertMessageOnAgeChange('');
    }
  };

  return (
    <>
      <div className="signup-title-container">
        <span className="signup-title">
          {modalType === 'add' ? 'Add New Member' : 'Update Member'}
        </span>
        <CloseIcon
          className="signup-title-close-icon"
          onClick={() => handleModalOpen(false)}
        />
      </div>
      <div className="signup-body-container">
        <Grid container className="newplan-form-grid" spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Controls.Input
              label="Member Name"
              value={name}
              type="text"
              onChange={(event) => {
                handleAddMemberFormFieldChange(event, 'name');
              }}
              errorText={name ? validatorMessage : 'This is a mandatory field'}
              isValid={isnameValid}
              required
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Controls.Input
              label="Age"
              value={(!isNaN(age) && parseInt(age)) || ''}
              type="number"
              max="120"
              min="18"
              maxLength="3"
              onChange={(event) => {
                handleAddMemberFormFieldChange(event, 'age');
              }}
              required
              isValid={isageValid}
              errorText={age ? 'Invalid Age' : 'This is a mandatory field'}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Controls.Select
              name="clientType"
              label="Gender"
              value={gender?.toLowerCase()}
              options={genderMenuItems.map((c) => {
                return {
                  value: c.value?.toLowerCase(),
                  label: c.label, //taking value cos, label is not present in this response
                };
              })}
              onChange={(event) =>
                handleAddMemberFormFieldChange(event, 'gender')
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Controls.Input
              label="Retirement Age"
              value={(!isNaN(retirementAge) && parseInt(retirementAge)) || ''}
              type="number"
              max="120"
              min="18"
              maxLength="3"
              onChange={(event) => {
                handleAddMemberFormFieldChange(event, 'retirementAge');
              }}
              isValid={isretirementAgeValid}
              errorText={
                retirementAge
                  ? 'Invalid Retirement Age'
                  : 'This is a mandatory field'
              }
              required
            />
          </Grid>
        </Grid>
        {alertMessageOnAgeChange && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MuiAlert
                severity="warning"
                sx={{ width: '100%', backgroundColor: '#fdf0e6 !important' }}
              >
                <p>{alertMessageOnAgeChange}</p>
              </MuiAlert>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};
export default CoreComponentGoals;
