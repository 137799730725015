const getBusinessInfoSelector = (state) => state.businessInfoReducer;

export const getCompanyAssetUnderManagement = (state) =>
  getBusinessInfoSelector(state).companyAssetsUnderManagement;

export const getCompanyAsserUnderManagementLoading = (state) =>
  getBusinessInfoSelector(state).isCompanyAssetsLoading;

export const getCompanyInvestmentAllocation = (state) =>
  getBusinessInfoSelector(state).companyInvAssetAllocations;

export const getCompanyInvestmentAllocationLoading = (state) =>
  getBusinessInfoSelector(state).isCmpnyInvAssetAllocationsLoading;

export const getInvMarketCorrelationsByCompanyNmSelector = (state) =>
  getBusinessInfoSelector(state).invMarketCorrelations;

export const getInvMarketExpectationsByCompanyNameSelector = (state) =>
  getBusinessInfoSelector(state).invMarketExpectationsByCompanyName;
