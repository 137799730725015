import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { planDetailsByPlanIdSelector } from '../../selectors/plannerDetailsSelector';
import { Grid } from '@mui/material'; // Import dialog components
import Controls from '../controls/Controls';
import { validateMemberNames } from '../../helpers/ClientHelper';
import { validateAgeFieId } from '../../utils/validators';
import MuiAlert from '@mui/material/Alert';
import { invPropsFormFields } from '../../constants/Client';
import {
  generateProps,
  percentageConversionToUI,
  formatValueByType,
  formatValuesForInvPropsUI,
} from '../../helpers/SolutionsHelper';
import { PropertyNamesMap } from '../../constants/PropertyNames';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const InvPropsContentGoals = forwardRef((props, ref) => {
  const { commonProps = {}, taxStateOptions = [] } = props;
  const {
    modalType,
    handleModalOpen,
    category,
    goal = {},
    setIsFormValid,
    formDetails,
  } = commonProps;
  const planDetailsByPlanId = useSelector((state) =>
    planDetailsByPlanIdSelector(state)
  );

  const outerObjectKeys = useMemo(
    () => ['annualRent', 'annualRentGrowth', 'priorDeprAccumulated'],
    []
  );

  const [validatorMessage, setValidatorMessage] = useState('');
  const [selectedInvProp, setSelectedInvProp] = useState({});
  const [invPropertiesFormFields, setInvPropertiesFormFields] =
    useState(invPropsFormFields);

  useEffect(() => {
    let tempInvPropsFormFields = [...invPropsFormFields];
    if (taxStateOptions && taxStateOptions.length > 0) {
      tempInvPropsFormFields = tempInvPropsFormFields.map((f) => {
        if (f.key === 'propDomicile') {
          return { ...f, options: taxStateOptions };
        }
        return f;
      });
    }
    setInvPropertiesFormFields(tempInvPropsFormFields);
  }, [setInvPropertiesFormFields, taxStateOptions]);

  const isEditingRef = useRef(false);

  useImperativeHandle(ref, () => ({
    getFormObject() {
      return selectedInvProp;
    },
  }));

  const generatePropsMemoized = useCallback(
    (keyVal, key) => generateProps(keyVal, key, 'props', planDetailsByPlanId),
    [planDetailsByPlanId] // Dependencies for memoization
  );

  useEffect(() => {
    if (isEditingRef.current) return;
    const invProp = formDetails?.props.find((k) => k.prop.name === goal.name);
    let newRecord = {};
    if (
      !invProp &&
      modalType === 'add' &&
      Object.keys(selectedInvProp).length === 0
    ) {
      const propertyMap = PropertyNamesMap['invProps'];
      for (const key in propertyMap) {
        let keyVal = propertyMap[key];
        if (propertyMap.hasOwnProperty(key)) {
          if (key === 'prop') {
            newRecord[key] = {};
            for (const subKey in keyVal) {
              let subKeyVal = keyVal[subKey];
              if (keyVal.hasOwnProperty(subKey)) {
                let newRecordKey = generatePropsMemoized(subKeyVal, subKey);
                newRecord['prop'][subKey] = newRecordKey;
              }
            }
          } else {
            let newRecordKey = generatePropsMemoized(keyVal, key);
            newRecord[key] = newRecordKey;
          }
        }
      }

      if (Object.keys(selectedInvProp).length === 0) {
        const formattedNewRecord = formatValuesForInvPropsUI(
          newRecord,
          invPropsFormFields,
          outerObjectKeys
        );
        if (
          JSON.stringify(formattedNewRecord) !== JSON.stringify(selectedInvProp)
        ) {
          setSelectedInvProp(formattedNewRecord);
        }
      }
    }

    if (invProp && Object.keys(selectedInvProp).length === 0) {
      const formattedInvProp = formatValuesForInvPropsUI(
        invProp,
        invPropsFormFields,
        outerObjectKeys
      );
      if (
        JSON.stringify(formattedInvProp) !== JSON.stringify(selectedInvProp)
      ) {
        setSelectedInvProp(formattedInvProp); // Only update if necessary
      }
    }
  }, [
    formDetails.props,
    goal.name,
    modalType,
    selectedInvProp,
    generatePropsMemoized,
    outerObjectKeys,
  ]);

  const validateInvPropNames = useCallback(
    (value) => {
      const existingInvProp = formDetails?.props.find(
        (invProp) => invProp.prop.name === value
      );
      if (
        modalType === 'add' &&
        existingInvProp &&
        existingInvProp.prop.name === selectedInvProp.prop.name
      ) {
        return false;
      }
      return true;
    },
    [selectedInvProp, modalType, formDetails?.props]
  );

  const getIsValidFieldValue = useCallback(
    (key, val) => {
      const value = val ? val : selectedInvProp[key];
      let isValid = true;
      switch (key) {
        case 'annualExpense':
        case 'annualCollegeExpenseNow':
        case 'value529Plan':
          isValid = value;
          break;
        case 'collegeExpenseGrowth':
        case 'peakReturn529Plan':
          isValid = value;
          break;
        case 'collegeYears':
          isValid = value && parseInt(value) > 0;
          break;
        case 'collegeAge':
          isValid = value && parseInt(value) > 17;
          break;
        case 'yearBorn':
          isValid = value && parseInt(value) > 1900;
          break;
        case 'name':
          isValid = validateInvPropNames(value);
          break;
        default:
          break;
      }
      return isValid;
    },
    [selectedInvProp, validateInvPropNames]
  );

  const checkFormValidity = useCallback(() => {
    const allFields = [
      'annualExpense',
      'annualCollegeExpenseNow',
      'value529Plan',
      'collegeExpenseGrowth',
      'peakReturn529Plan',
      'collegeYears',
      'collegeAge',
      'yearBorn',
      'name',
    ];
    //const isValid = allFields.every((field) => getIsValidFieldValue(field));
    const isValid = true;
    setIsFormValid(isValid);
  }, [getIsValidFieldValue, setIsFormValid]);

  // const updateInvPropByName = (invPropsObject, name, updatedValues, key) => {
  //   return {
  //     ...invPropsObject,
  //     props: invPropsObject.props.map((invProp) => {
  //       if (invProp.prop.name === name) {
  //         if (outerObjectKeys.includes(key) && invProp.hasOwnProperty(key)) {
  //           return {
  //             ...invProp,
  //             ...updatedValues,
  //             prop: {
  //               ...invProp.prop,
  //               lockedInSolution: true,
  //             },
  //           };
  //         } else if (invProp.prop.hasOwnProperty(key)) {
  //           return {
  //             ...invProp,
  //             prop: {
  //               ...invProp.prop,
  //               lockedInSolution: true,
  //               ...updatedValues,
  //             },
  //           };
  //         }
  //       }
  //       return invProp;
  //     }),
  //   };
  // };

  const handleBlur = (event, field) => {
    const fieldType = invPropertiesFormFields.find((ft) => ft.key === field);
    // let formattedValue = selectedInvProp[field];
    let { value: formattedValue } = event && event.target;
    if (fieldType && fieldType.dataType === 'percent') {
      // Apply the formatting only on blur
      formattedValue = percentageConversionToUI(formattedValue);
    }
    // Update the selected secondHome with the formatted value
    // const updatedInvProps = updateInvPropByName(
    //   invPropsInfo,
    //   selectedInvProp?.prop?.name,
    //   {
    //     [field]: formattedValue,
    //   },
    //   field
    // );
    // setInvPropsInfo(updatedInvProps); // Update the local state
    const invProp = {
      ...selectedInvProp,
      ...(selectedInvProp.hasOwnProperty(field) && { [field]: formattedValue }),
      prop: {
        ...selectedInvProp.prop,
        ...(selectedInvProp.prop.hasOwnProperty(field) && {
          [field]: formattedValue,
        }),
      },
    };
    setSelectedInvProp(invProp);
    isEditingRef.current = false;
  };

  const handleOnChange = (event, key) => {
    isEditingRef.current = true;
    const { value } = event?.target || '';
    const fieldType = invPropertiesFormFields.find((ft) => ft.key === key);
    let formattedValue = value;
    if (fieldType) {
      if (fieldType.dataType === 'percent') {
        // Allow the raw value to be typed without adding "%" on every key press
        formattedValue = value; // Keep it raw for now during typing
      } else {
        formattedValue = formatValueByType(value, fieldType.dataType);
      }
    }
    // const updatedInvProps = updateInvPropByName(
    //   invPropsInfo,
    //   selectedInvProp?.prop?.name,
    //   {
    //     [key]: formattedValue,
    //   },
    //   key
    // );

    // Set the updated object with the modified secondHomes array
    //setInvPropsInfo(updatedInvProps); // Update the local state with the new data
    const invProp = {
      ...selectedInvProp,
      ...(selectedInvProp.hasOwnProperty(key) && { [key]: formattedValue }),
      prop: {
        ...selectedInvProp.prop,
        ...(selectedInvProp.prop.hasOwnProperty(key) && {
          [key]: formattedValue,
        }),
      },
    };
    // Update selected secondHome state to reflect changes in UI
    setSelectedInvProp(invProp);
    // checkFormValidity();
  };

  useEffect(() => {
    checkFormValidity();
  }, [formDetails, checkFormValidity, selectedInvProp]);

  const isDisabledFn = ({ isDisabled = false, key = '' }) => {
    if (isDisabled) return true;
    if (key === 'purchaseDate' || key === 'purchasePrice') {
      const { purchaseDate = '' } = selectedInvProp?.prop || {};
      return purchaseDate && new Date(purchaseDate) < new Date();
    }
    return false;
  };

  const handleDateChange = (date, field) => {
    if (date) {
      // Convert date to the appropriate format if necessary
      const formattedDate =
        field.dataType === 'year'
          ? date.getFullYear().toString() // For year view, use the year part only
          : date.toISOString().split('T')[0]; // For full date view, use 'yyyy-MM-dd'
      isEditingRef.current = true;
      const fieldType = invPropertiesFormFields.find(
        (ft) => ft.key === field.key
      );
      if (fieldType) {
        const invProp = {
          ...selectedInvProp,
          [field.key]: formattedDate,
        };
        setSelectedInvProp(invProp);
      }
    }
  };

  const getDateValue = (date, field) => {
    const formattedValue = (() => {
      if (field.dataType === 'year') {
        // For 'year' view, parse and return just the year part
        const year = parseInt(date, 10);
        return year ? new Date(year, 0, 1) : null; // January 1 of the given year, in UTC
      } else {
        // For 'yyyy-MM-dd' view, directly parse the value
        return date ? new Date(date) : null; // Ensure the date is treated as UTC
      }
    })();

    const parsedValue =
      formattedValue && !isNaN(formattedValue.getTime())
        ? formattedValue
        : null;
    return parsedValue;
  };

  return (
    <>
      <div className="signup-title-container">
        <span className="signup-title">
          {modalType === 'add' ? 'Add New Member' : 'Update Member'}
        </span>
        <CloseIcon
          className="signup-title-close-icon"
          onClick={() => handleModalOpen(false)}
        />
      </div>
      <div className="signup-body-container">
        <div className="insured_main_outer">
          {invPropertiesFormFields.map((field) => {
            const value = outerObjectKeys.includes(field.key)
              ? selectedInvProp[field.key]
              : selectedInvProp?.prop?.[field.key];
            switch (field.type) {
              case 'text':
                return (
                  <div className="row align-items-center">
                    <div
                      className={`col-lg-8 insured_content_outer labelStyle 
          }`}
                    >
                      <p className={`${field.isRequired ? 'required' : ''}`}>
                        {field.name}
                      </p>
                    </div>
                    <div className="col-lg-3 insured_radio_group">
                      <input
                        type={field.type}
                        id={field.key}
                        name={field.key}
                        required={true}
                        // disabled={modalType === 'edit' && field.isDisabled}
                        disabled={modalType === 'edit' && isDisabledFn(field)}
                        value={value}
                        className={'custom-input'} // Apply the appropriate class
                        onChange={(event) => handleOnChange(event, field.key)}
                        onBlur={(event) => handleBlur(event, field.key)}
                        placeholder=""
                      />
                    </div>
                    {selectedInvProp &&
                      Object.keys(selectedInvProp).length > 0 &&
                      !getIsValidFieldValue(field.key, value) && (
                        <p className="error-text">
                          {value ? `Invalid ${field.name}` : field.errorMessage}
                        </p>
                      )}
                  </div>
                );
              case 'select':
                return (
                  <div className="row align-items-center">
                    <div className={`col-lg-8 insured_content_outer`}>
                      <p className={`${field.isRequired ? 'required' : ''}`}>
                        {field.name}
                      </p>
                    </div>
                    <div className={`col-lg-3 insured_radio_group`}>
                      <Controls.Select
                        name={field.key}
                        id={field.key}
                        value={value}
                        onChange={(event, index) =>
                          handleOnChange(event, field.key)
                        }
                        className={'custom-input'}
                        // disabled={!state.isEditable}
                        options={field.options.map((c) => {
                          return {
                            value: c.value,
                            label: c.label,
                          };
                        })}
                      />
                    </div>
                    {/* {isMemberFieldInValid && (
                      <p className="error-text">
                        {isMemberFieldInValid.validationMessage}
                      </p>
                    )} */}
                  </div>
                );
              case 'date':
                return (
                  <div className="row align-items-center">
                    <div className="col-lg-8 insured_content_outer">
                      <p className={`${field.isRequired ? 'required' : ''}`}>
                        {field.name}
                      </p>
                    </div>
                    <div className="col-lg-3 insured_radio_group">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          views={[field.dataType]}
                          className="planner-input long-text-input"
                          format={
                            field.dataType === 'year' ? 'yyyy' : 'yyyy-MM-dd'
                          }
                          autoOk
                          variant="inline"
                          InputAdornmentProps={{ position: 'start' }}
                          value={getDateValue(value, field)}
                          onChange={(date) => handleDateChange(date, field)}
                          inputProps={{ style: { height: '39px' } }}
                          //disabled={!state.isEditable}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    {/* {isFieldInValid && (
                      <p className="error-text">
                        {isFieldInValid.validationMessage}
                      </p>
                    )} */}
                  </div>
                );
              default:
                return null;
            }
          })}
        </div>
      </div>
    </>
  );
});
export default InvPropsContentGoals;
