export const GET_CLIENTS_BY_ADVISOR_ID = 'clients:advisorId';
export const GET_CLIENTS_BY_ADVISOR_ID_ERROR = 'clients:advisorId:error';
export const GET_CLIENTS_BY_ADVISOR_ID_SUCCESS = 'clients:advisorId:success';
export const SET_SELECTED_CLIENT_ID = 'client:setselectedclientid';
export const SET_ALL_PLANNER_MENU_ITEMS = 'client:all:menuitems';
export const SET_ALL_TAX_SLABS = 'client:all:tax:slabs';
export const SET_ALL_GENDERS = 'client:all:genders';
export const SET_EXPENSE_FACTOR_DETAILS = 'client:get:expenseFactor:details';
export const CLEAR_SELECTED_CLIENT_ID = 'clear:selected:client:id';
export const GET_ADVISOR_ASSETS_UNDER_MANAGEMENT =
  'advisor:assets:underManagement';
export const GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_COMPANY_AUM =
  'advisor:assets:underManagement:company:aum';
export const GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_COMPANY_AUM_SUCCESS =
  'advisor:assets:underManagement:company:aum:success';
export const GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_COMPANY_AUM_ERROR =
  'advisor:assets:underManagement:company:aum:error';
export const GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_SUCCESS =
  'advisor:assets:underManagement:success';
export const GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_ERROR =
  'advisor:assets:underManagement:error';
export const GET_ADVISOR_STATS_BY_CLIENT_TYPE = 'advisor:stats:clientType';
export const GET_ADVISOR_STATS_BY_CLIENT_TYPE_SUCCESS =
  'advisor:stats:clientType:success';
export const GET_ADVISOR_STATS_BY_CLIENT_TYPE_ERROR =
  'advisor:stats:clientType:error';
export const ADVISOR_STATS_LOADING = 'advisor:stats:loading';
export const ADVISOR_ASSETS_LOADING = 'advisor:assets:loading';
// export const INV_MARKET_EXPECTATIONS_BY_ADVISOR_LOADING =
//   'inv:market:expectations:advisor';
export const GET_INV_MARKET_EXPECTATIONS_BY_ADVISOR =
  'inv:market:expectations:advisor';
export const GET_INV_MARKET_EXPECTATIONS_BY_ADVISOR_SUCCESS =
  'inv:market:expectations:advisor:success';
export const GET_INV_MARKET_EXPECTATIONS_BY_ADVISOR_ERROR =
  'inv:market:expectations:advisor:error';
export const GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS =
  'clients:not:updated:num_days';
export const GET_CLIENTS_MEETINGS_FOR_NUM_DAYS =
  'clients:meetings:for:num_days';
export const GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS_SUCCESS =
  'clients:not:updated:num_days:success';
export const GET_CLIENTS_MEETINGS_FOR_NUM_DAYS_SUCCESS =
  'clients:meetings:for:num_days:success';
export const GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS_ERROR =
  'clients:not:updated:num_days:error';
export const GET_CLIENTS_MEETINGS_FOR_NUM_DAYS_ERROR =
  'clients:meetings:for:num_days:error';
export const GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS_LOADING =
  'clients:not:updated:num_days:loading';
export const GET_CLIENTS_MEETINGS_FOR_NUM_DAYS_LOADING =
  'clients:meetings:for:num_days:loading';
export const SET_TENURE_ACTION = 'clients:tenure';
export const SET_CLIENT_TENURE_ACTION = 'clientmeetings:tenure';
export const GET_ASSET_ALLOC_LIST_BY_CLIENT_ID = 'asset:alloc:list:clientId';
export const GET_ASSET_ALLOC_LIST_BY_CLIENT_ID_SUCCESS =
  'asset:alloc:list:clientId:success';
export const GET_ASSET_ALLOC_LIST_BY_CLIENT_ID_ERROR =
  'asset:alloc:list:clientId:error';
export const GET_FORECAST_INVMKTEXP_LIST_BY_ADVISOR_ID =
  'forecast:invmktexp:list:advisorId';
export const GET_FORECAST_INVMKTEXP_LIST_BY_ADVISOR_ID_SUCCESS =
  'forecast:invmktexp:list:advisorId:success';
export const GET_FORECAST_INVMKTEXP_LIST_BY_ADVISOR_ID_ERROR =
  'forecast:invmktexp:list:advisorId:error';
export const SET_ALLOCATION_ID = 'set:allocation:id';
export const SET_FORECAST_ID = 'set:forecast:id';
export const SET_ASSET_ALLOC_LIST_BY_CLIENT_ID =
  'set:asset:alloclist:by:client:id';
export const SET_ASSET_ALLOC_LIST_BY_CLIENT_ID_LOADING_ACTION =
  'set:asset:alloclist:by:client:id:loading:action';
export const CLEAR_ADVISOR_ASSETS_UNDER_MANAGEMENT =
  'clear:advisor:assets:underManagement';

export function getAssetAllocListByClientIdAction(payload) {
  return {
    type: GET_ASSET_ALLOC_LIST_BY_CLIENT_ID,
    payload: payload,
  };
}

export function setAssetAllocListByClientIdLoadingAction(payload) {
  return {
    type: SET_ASSET_ALLOC_LIST_BY_CLIENT_ID_LOADING_ACTION,
    payload,
  };
}

export function setAssetAllocListByClientIdAction(payload) {
  return {
    type: SET_ASSET_ALLOC_LIST_BY_CLIENT_ID,
    payload: payload,
  };
}

export function getAssetAllocListByClientIdSuccessAction(payload) {
  return {
    type: GET_ASSET_ALLOC_LIST_BY_CLIENT_ID_SUCCESS,
    payload,
  };
}

export function getAssetAllocListByClientIdErrorAction(payload) {
  return {
    type: GET_ASSET_ALLOC_LIST_BY_CLIENT_ID_ERROR,
    payload,
  };
}

export function getForecastInvMktExpListByAdvisorIdAction(payload) {
  return {
    type: GET_FORECAST_INVMKTEXP_LIST_BY_ADVISOR_ID,
    payload: payload,
  };
}

export function getForecastInvMktExpListByAdvisorIdSuccessAction(payload) {
  return {
    type: GET_FORECAST_INVMKTEXP_LIST_BY_ADVISOR_ID_SUCCESS,
    payload,
  };
}

export function getForecastInvMktExpListByAdvisorIdErrorAction(payload) {
  return {
    type: GET_FORECAST_INVMKTEXP_LIST_BY_ADVISOR_ID_ERROR,
    payload,
  };
}

export function setAllocationIdAction(payload) {
  return {
    type: SET_ALLOCATION_ID,
    payload,
  };
}

export function setForecastIdAction(payload) {
  return {
    type: SET_FORECAST_ID,
    payload,
  };
}

export function getClientsByAdvisorIdAction(payload) {
  return {
    type: GET_CLIENTS_BY_ADVISOR_ID,
    payload: payload,
  };
}

export function getClientsByAdvisorIdSuccessAction(payload) {
  return {
    type: GET_CLIENTS_BY_ADVISOR_ID_SUCCESS,
    payload,
  };
}

export function getClientsByAdvisorIdErrorAction(payload) {
  return {
    type: GET_CLIENTS_BY_ADVISOR_ID_ERROR,
    payload,
  };
}

export function setTenureAction(payload) {
  return {
    type: SET_TENURE_ACTION,
    payload: payload,
  };
}

export function setClientTenureAction(payload) {
  return {
    type: SET_CLIENT_TENURE_ACTION,
    payload: payload,
  };
}

export function getClientsNotUpdatedForNumDaysLoadingAction(payload) {
  return {
    type: GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS_LOADING,
    payload: payload,
  };
}

export function getClientsMeetingsForNumDaysLoadingAction(payload) {
  return {
    type: GET_CLIENTS_MEETINGS_FOR_NUM_DAYS_LOADING,
    payload: payload,
  };
}

export function getClientsNotUpdatedForNumDaysAction(payload) {
  return {
    type: GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS,
    payload: payload,
  };
}

export function getClientsMeetingsForNumDaysAction(payload) {
  return {
    type: GET_CLIENTS_MEETINGS_FOR_NUM_DAYS,
    payload: payload,
  };
}

export function getClientsNotUpdatedForNumDaysSuccessAction(payload) {
  return {
    type: GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS_SUCCESS,
    payload,
  };
}

export function getClientsMeetingsForNumDaysSuccessAction(payload) {
  return {
    type: GET_CLIENTS_MEETINGS_FOR_NUM_DAYS_SUCCESS,
    payload,
  };
}

export function getClientsNotUpdatedForNumDaysErrorAction(payload) {
  return {
    type: GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS_ERROR,
    payload,
  };
}

export function getClientsMeetingsForNumDaysErrorAction(payload) {
  return {
    type: GET_CLIENTS_MEETINGS_FOR_NUM_DAYS_ERROR,
    payload,
  };
}

export function setSelectedClientIdAction(payload) {
  return {
    type: SET_SELECTED_CLIENT_ID,
    payload,
  };
}

export function getAllMenuItemsSuccess(payload) {
  return {
    type: SET_ALL_PLANNER_MENU_ITEMS,
    payload,
  };
}

export function getAllTaxSlabsSuccess(payload) {
  return {
    type: SET_ALL_TAX_SLABS,
    payload,
  };
}

export function getExpenseFactorDetailsSuccess(payload) {
  return {
    type: SET_EXPENSE_FACTOR_DETAILS,
    payload,
  };
}

export function clearSelectedClientId(payload) {
  return {
    type: CLEAR_SELECTED_CLIENT_ID,
  };
}

export function getAdvisorAssetsUnderManagementAction(payload) {
  return {
    type: GET_ADVISOR_ASSETS_UNDER_MANAGEMENT,
    payload,
  };
}

export function getAdvisorAssetsUnderManagementSuccessAction(payload) {
  return {
    type: GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_SUCCESS,
    payload,
  };
}

export function getAdvisorAssetsUnderManagementErrorAction(payload) {
  return {
    type: GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_ERROR,
    payload,
  };
}

export function getAdvisorAssetsUnderManagementCompanyAUMAction(payload) {
  return {
    type: GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_COMPANY_AUM,
    payload,
  };
}

export function getAdvisorAssetsUnderManagementCompanyAUMSuccessAction(
  payload
) {
  return {
    type: GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_COMPANY_AUM_SUCCESS,
    payload,
  };
}

export function getAdvisorAssetsUnderManagementCompanyAUMErrorAction(payload) {
  return {
    type: GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_COMPANY_AUM_ERROR,
    payload,
  };
}

export function getAdvisorStatsByClientTypeAction(payload) {
  return {
    type: GET_ADVISOR_STATS_BY_CLIENT_TYPE,
    payload,
  };
}

export function getAdvisorStatsByClientTypeSuccessAction(payload) {
  return {
    type: GET_ADVISOR_STATS_BY_CLIENT_TYPE_SUCCESS,
    payload,
  };
}

export function getAdvisorStatsByClientTypeErrorAction(payload) {
  return {
    type: GET_ADVISOR_STATS_BY_CLIENT_TYPE_ERROR,
    payload,
  };
}
export function setAdvisorStatsLoadingAction(payload) {
  return {
    type: ADVISOR_STATS_LOADING,
    payload,
  };
}

export function setAdvisorAssetsLoadingAction(payload) {
  return {
    type: ADVISOR_ASSETS_LOADING,
    payload,
  };
}

// export function setInvMarketExpectationsByAdvisorIdLoadingAction(payload) {
//   return {
//     type: INV_MARKET_EXPECTATIONS_BY_ADVISOR_LOADING,
//     payload,
//   };
// }

export function getInvMarketExpectationsByAdvisorIdAction(payload) {
  return {
    type: GET_INV_MARKET_EXPECTATIONS_BY_ADVISOR,
    payload: payload,
  };
}

export function getInvMarketExpectationsByAdvisorIdSuccessAction(payload) {
  return {
    type: GET_INV_MARKET_EXPECTATIONS_BY_ADVISOR_SUCCESS,
    payload,
  };
}
export function getInvMarketExpectationsByAdvisorIdErrorAction(payload) {
  return {
    type: GET_INV_MARKET_EXPECTATIONS_BY_ADVISOR_ERROR,
    payload,
  };
}

export function clearAdvisorAssetDetailsAction(payload) {
  return {
    type: CLEAR_ADVISOR_ASSETS_UNDER_MANAGEMENT,
    payload,
  };
}
