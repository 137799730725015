import {
  PLANS_DETAILS_LOADING,
  GET_PLANS_DETAILS_SUCCESS,
  GET_PLANS_DETAILS_ERROR,
  SAVE_PLAN_DETAILS_BY_PLANID_SUCCESS,
  SAVE_PLAN_DETAILS_BY_PLANID_ERROR,
  CLEAR_PLAN_DETAILS,
  PLAN_DETAILS_BY_PLANID_ERROR,
  SIMULATE_PLAN_DETAILS_BY_PLANID_SUCCESS,
  GET_EXPENSE_DETAILS_BY_LIFESTYLE_SUCCESS,
  RESET_EXPENSEDETAILS_BY_LIFESTYLE,
  TERM_LIFE_INSURANCE_PRODUCTS_SUCCESS,
  WHOLE_LIFE_INSURANCE_PRODUCTS_SUCCESS,
  LONG_TERM_CARE_INSURANCE_PRODUCTS_SUCCESS,
  UMBRELLA_INSURANCE_PRODUCTS_SUCCESS,
  PROPERTY_INSURANCE_PRODUCTS_SUCCESS,
  AUTO_INSURANCE_PRODUCTS_SUCCESS,
  SIMULATE_PLAN_DETAILS_BY_PLANID_LOADING,
  SET_PLAN_DETAILS_SIMULATED,
  SIMULATE_PLAN_DETAILS_BY_PLANID,
  SET_BEFORE_AFTER_ASSET_MIX,
  GET_RECOMMENDED_ASSET_ALLOCATION_BY_CLIENT_ID_SUCCESS,
  GET_RECOMMENDED_ASSET_ALLOCATION_BY_CLIENT_ID_ERROR,
  GET_ASSET_ALLOCATION_BY_PRODUCT_ID_SUCCESS,
  GET_ASSET_ALLOCATION_BY_PRODUCT_ID_ERROR,
  SET_ASSET_ALLOCATION_BY_PRODUCT_ID_LOADING_ACTION,
  GET_INVESTMENT_PRODUCTS_BY_CLIENT_ID_ERROR,
  GET_INVESTMENT_PRODUCTS_BY_CLIENT_ID_SUCCESS,
  GET_INV_ASSET_ALLOCATIONS_BY_ADVISOR_ID_SUCCESS,
  GET_INV_ASSET_ALLOCATIONS_BY_ADVISOR_ID_ERROR,
  SET_INV_ASSET_ALLOCATIONS_BY_ADVISOR_ID_LOADING_ACTION,
  GET_INV_MARKET_CORRELATIONS_BY_ADVISOR_ID_SUCCESS,
  GET_INV_MARKET_CORRELATIONS_BY_ADVISOR_ID_ERROR,
  SET_INV_MARKET_CORRELATIONS_BY_ADVISOR_ID_LOADING_ACTION,
} from '../actions/plannerDetailsAction';

const initialState = {
  isPlanDetailsLoading: false,
  planDetails: {},
  isPlanDetailsSaved: false,
  planDetailsSimulated: false,
  planDetailsError: false,
  expenseDetailsByLifestyle: {},
  termLifeInsuranceProducts: [],
  wholeLifeInsuranceProducts: [],
  longTermCareInsuranceProducts: [],
  umbrellaInsuranceProducts: [],
  propertyInsuranceProducts: [],
  autoInsuranceProducts: [],
  isSimulatePlanDetailsLoading: false,
  beforeAfterAssetMixValues: [],
  assetMixValues: {},
  recommendedAssetAllocationValues: [],
  assetAllocationByProductIdLoadingAction: false,
  investmentProducts: [],
  invAssetAllocations: [],
  isInvAssetAllocationsLoading: false,
  invMarketCorrelations: [],
  isInvMarketCorrelationsLoading: false,
};

export default function Plans(state = initialState, action) {
  switch (action.type) {
    case SET_BEFORE_AFTER_ASSET_MIX:
      return {
        ...state,
        beforeAfterAssetMixValues: action.payload,
      };
    case GET_INVESTMENT_PRODUCTS_BY_CLIENT_ID_SUCCESS:
    case GET_INVESTMENT_PRODUCTS_BY_CLIENT_ID_ERROR:
      return { ...state, investmentProducts: action.payload };
    case SET_ASSET_ALLOCATION_BY_PRODUCT_ID_LOADING_ACTION:
      return {
        ...state,
        assetAllocationByProductIdLoadingAction: action.payload,
      };
    case GET_INV_ASSET_ALLOCATIONS_BY_ADVISOR_ID_SUCCESS:
    case GET_INV_ASSET_ALLOCATIONS_BY_ADVISOR_ID_ERROR:
      return { ...state, invAssetAllocations: action.payload };
    case SET_INV_ASSET_ALLOCATIONS_BY_ADVISOR_ID_LOADING_ACTION:
      return {
        ...state,
        isInvAssetAllocationsLoading: action.payload,
      };
    case GET_INV_MARKET_CORRELATIONS_BY_ADVISOR_ID_SUCCESS:
    case GET_INV_MARKET_CORRELATIONS_BY_ADVISOR_ID_ERROR:
      return { ...state, invMarketCorrelations: action.payload };
    case SET_INV_MARKET_CORRELATIONS_BY_ADVISOR_ID_LOADING_ACTION:
      return {
        ...state,
        isInvMarketCorrelationsLoading: action.payload,
      };
    case GET_ASSET_ALLOCATION_BY_PRODUCT_ID_SUCCESS:
      return {
        ...state,
        assetMixValues: action.payload,
      };
    case GET_RECOMMENDED_ASSET_ALLOCATION_BY_CLIENT_ID_ERROR:
      return {
        ...state,
        recommendedAssetAllocationValues: action.payload,
      };
    case GET_RECOMMENDED_ASSET_ALLOCATION_BY_CLIENT_ID_SUCCESS:
      return {
        ...state,
        recommendedAssetAllocationValues: action.payload,
      };
    case GET_ASSET_ALLOCATION_BY_PRODUCT_ID_ERROR:
      return {
        ...state,
        assetMixValues: action.payload,
      };
    case PLANS_DETAILS_LOADING:
      return {
        ...state,
        isPlanDetailsLoading: action.payload,
        isPlanDetailsSaved: false,
        planDetailsError: false,
        //planDetailsSimulated: false,
      };
    case SET_PLAN_DETAILS_SIMULATED:
      return {
        ...state,
        planDetailsSimulated: false,
      };
    case SIMULATE_PLAN_DETAILS_BY_PLANID:
      return {
        ...state,
        planDetailsSimulated: false,
        isSimulatePlanDetailsLoading: true,
      };
    case SIMULATE_PLAN_DETAILS_BY_PLANID_LOADING:
      return {
        ...state,
        isSimulatePlanDetailsLoading: action.payload,
      };
    case GET_PLANS_DETAILS_SUCCESS:
      return {
        ...state,
        planDetails: action.payload,
      };
    case GET_PLANS_DETAILS_ERROR:
      return {
        ...state,
        plans: action.payload,
      };
    case SAVE_PLAN_DETAILS_BY_PLANID_SUCCESS: {
      const { plannerData } = action.payload;
      return {
        ...state,
        isPlanDetailsSaved: true,
        planDetails: { ...plannerData },
      };
    }
    case SIMULATE_PLAN_DETAILS_BY_PLANID_SUCCESS: {
      const { plannerData, from = '' } = action.payload;
      return {
        ...state,
        //planDetailsSimulated: from !== '' ? false : true,
        planDetailsSimulated: true,
        planDetails: { ...plannerData }, // commented out to make sure, for every page call simulate on load, when simulate is called, payload is specific to that page, if saved, we can retain data
        planDetailsError: false,
        // isSimulatePlanDetailsLoading: false,
      };
    }
    case PLAN_DETAILS_BY_PLANID_ERROR: {
      return {
        ...state,
        planDetailsError: action.payload,
      };
    }
    case GET_EXPENSE_DETAILS_BY_LIFESTYLE_SUCCESS:
      return {
        ...state,
        expenseDetailsByLifestyle: action.payload,
      };
    case RESET_EXPENSEDETAILS_BY_LIFESTYLE:
      return {
        ...state,
        expenseDetailsByLifestyle: {},
      };
    case CLEAR_PLAN_DETAILS:
      return {
        ...initialState,
      };
    case TERM_LIFE_INSURANCE_PRODUCTS_SUCCESS:
      return {
        ...state,
        termLifeInsuranceProducts: action.payload,
      };
    case WHOLE_LIFE_INSURANCE_PRODUCTS_SUCCESS:
      return {
        ...state,
        wholeLifeInsuranceProducts: action.payload,
      };
    case LONG_TERM_CARE_INSURANCE_PRODUCTS_SUCCESS:
      return {
        ...state,
        longTermCareInsuranceProducts: action.payload,
      };
    case UMBRELLA_INSURANCE_PRODUCTS_SUCCESS:
      return {
        ...state,
        umbrellaInsuranceProducts: action.payload,
      };
    case PROPERTY_INSURANCE_PRODUCTS_SUCCESS:
      return {
        ...state,
        propertyInsuranceProducts: action.payload,
      };
    case AUTO_INSURANCE_PRODUCTS_SUCCESS:
      return {
        ...state,
        autoInsuranceProducts: action.payload,
      };
    default:
      return state;
  }
}
