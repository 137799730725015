import React, { Component, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Grid } from '@material-ui/core';
import '../styles/plaidui.scss';
import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material';
import Controls from './controls/Controls';
import RadioGroup from './controls/RadioGroup';
import { getAllInstitutions } from '../services/dashboardSvc';
import { usePlaidLink } from 'react-plaid-link';
import {
  createPlaidLinkToken,
  setPlaidAccessToken,
  getPlaidAccountsBalance,
} from '../services/dashboardSvc';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {
  investmentFields,
  assetMixList,
  banksListObj,
  newAccountDetailsToBeAdded,
} from '../constants/dashboard';
import edit_pencil from '../images/edit_pencil.svg';
import delete_icon from '../images/delete_icon.svg';
import pdf_page_plus_icon from '../images/pdf_page_plus_icon.svg';
import { validateNamesWithoutSpaces } from '../utils/validators';

const PlaidLinkFn = ({
  // linkToken,
  setPlaidLinkSuccessAction,
  setPlaidLinkAccessToken,
  plaidText = 'Automatic (Plaid)',
  clearProcessedData,
  setToPlaid,
}) => {
  const [plaidLinkToken, setPlaidLinkToken] = useState('');
  const onSuccess = (public_token) => {
    const exchangePublicTokenForAccessToken = async () => {
      const { status, data } = await setPlaidAccessToken(public_token);
      if (status === 200) {
        setPlaidLinkAccessToken(data);
        setPlaidLinkSuccessAction(true);
      }
    };
    clearProcessedData();
    exchangePublicTokenForAccessToken();
  };

  let isOauth = false;

  const config = {
    token: plaidLinkToken,
    onSuccess,
  };

  if (window.location.href.includes('?oauth_state_id=')) {
    // TODO: figure out how to delete this ts-ignore
    // @ts-ignore
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }
  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  const createPlaidLinkAccessToken = async () => {
    const { status, data } = await createPlaidLinkToken();
    if (status === 200) {
      setPlaidLinkToken(data.linkToken);
    }
  };

  useEffect(() => {
    setPlaidLinkSuccessAction(false);
    createPlaidLinkAccessToken(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button
      onClick={() => {
        open();
        setToPlaid();
      }}
      style={{
        fontWeight: 700,
        fontSize: '12px',
        marginRight: '10px',
      }}
      disabled={!ready}
    >
      {plaidText}
    </Button>
  );
};

const valueAsofNowList = [
  {
    title: 'Now',
    id: 'now',
  },
  {
    title: 'Begining of the year',
    id: 'boy',
  },
];

const invReturnOptions = [
  {
    title: 'Generic',
    id: 'generic',
  },
  {
    title: 'Custom',
    id: 'custom',
  },
  {
    title: 'Products',
    id: 'products',
  },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class PlaidUI extends Component {
  state = {
    stepIndex: 0,
    selectedBankDetails: {},
    userName: '',
    password: '',
    stepNoIndex: 1,
    selectedYesEvent: false,
    selectedInvestmentDetails: [...investmentFields],
    assetMixListForInv: [...assetMixList],
    ...this.getNewMemberInitialState(),
    selectedMember: '',
    accountDetails: {},
    tempAccountDetails: {},
    plaidAccTabValue: 0,
    banksList: [...banksListObj],
    searchInst: {},
    plaidLinkToken: '',
    plaidAccountDetails: {},
    investmentTypeName: '',
    newNickName: '',
    isButtonDisabled: true,
    isAssetMixPercentNotEqualTo100: false,
    plaidAccountProcessedInfo: [],
    selectedAccount: '',
    accBalance: 0,
    selectedAccsIndex: 0,
    isEditable: false,
    isAddNew: false,
    selectedProductType: '',
    tickers: [],
    selectedTicker: '',
    tickersAddedToPlan: [],
    tickersAvailableInPlan: [],
    investmentAccounts: [],
    remainingBalance: '',
    selectedInvReturnOption: 'generic',
    inValidAmount: false,
    amountUsed: 0,
    buttonDisabledMsg: '',
    selectedInvestmentAccount: {},
    oldSelectedInvestmentAccount: {},
    availableInvestmentAccounts: [],
    selectedInvestmentBucket: '',
    oldInvestmentBucket: '',
  };

  getSelectedMemberState = (updatedMember) => {
    let { selectedMember, memberValues } = this.state;
    selectedMember = updatedMember ? updatedMember : selectedMember;
    const tempMemberValues = { ...memberValues };
    if (selectedMember === 'first') {
      return tempMemberValues.first;
    } else {
      return tempMemberValues.second;
    }
  };

  componentDidMount() {
    const { storeValues, setLastStepInParent, members } = this.props;
    if (
      storeValues &&
      Object.keys(storeValues).length > 0 &&
      members &&
      members.length > 0
    ) {
      const memberValues = { ...storeValues };
      this.setState({
        memberValues,
        selectedMember: members[0].key,
      });
    }
    setLastStepInParent(false);
  }

  checkForButtonStatus = (accs, accTabValue) => {
    const isSelectedAccNickNameEmpty =
      accs &&
      accs.length > 0 &&
      accs[accTabValue] &&
      accs[accTabValue].nickName === ''
        ? true
        : false;
    return isSelectedAccNickNameEmpty;
  };

  updateAccountDetails = () => {
    const {
      plaidAccountDetails: { accounts = [] },
      plaidAccTabValue,
      selectedYesEvent,
      availableInvestmentAccounts,
    } = this.state;
    let tempAvailableInvestmentAccounts = [...availableInvestmentAccounts];
    let existingPlaidAccountIndex = -1;
    const defaultInvestmentBucket = investmentFields[0].id || '';
    if (selectedYesEvent && accounts && accounts.length > 0) {
      const selectedPlaidAccount = accounts[plaidAccTabValue] || {};
      if (Object.keys(selectedPlaidAccount).length > 0) {
        existingPlaidAccountIndex = tempAvailableInvestmentAccounts.indexOf(
          tempAvailableInvestmentAccounts.filter(
            (f) =>
              f.nickName.toLowerCase() ===
              selectedPlaidAccount.name.toLowerCase()
          )[0]
        );
        if (existingPlaidAccountIndex > -1) {
          tempAvailableInvestmentAccounts[
            existingPlaidAccountIndex
          ].invBalance = selectedPlaidAccount.balances.current;
        } else {
          let newAccountDetails = {
            ...newAccountDetailsToBeAdded,
            nickName: selectedPlaidAccount.name,
            invBalance: selectedPlaidAccount.balances.current,
            id: `${selectedPlaidAccount.name}-${defaultInvestmentBucket}`,
            investmentBucket: defaultInvestmentBucket,
          };
          tempAvailableInvestmentAccounts = [
            ...[newAccountDetails],
            ...tempAvailableInvestmentAccounts,
          ];
        }
        const selectedInvestmentAccount =
          tempAvailableInvestmentAccounts.length > 0
            ? tempAvailableInvestmentAccounts.filter(
                (t) =>
                  t.nickName.toLowerCase() ===
                  selectedPlaidAccount?.name.toLowerCase()
              )[0] || tempAvailableInvestmentAccounts[0]
            : {};
        const tempAccTabValue =
          existingPlaidAccountIndex > -1 ? existingPlaidAccountIndex : 0;
        const isButtonDisabled = this.checkForButtonStatus(
          tempAvailableInvestmentAccounts,
          tempAccTabValue
        );
        const newAssetMixList = assetMixList.map((listItem) => {
          const value = selectedInvestmentAccount[listItem.name] || 0;
          let newPercentValue = this.getPercentValue(value);
          return {
            ...listItem,
            value: newPercentValue,
          };
        });
        const sumOfAssetMixValue = newAssetMixList.reduce(function (acc, val) {
          return acc + parseFloat(val.value);
        }, 0);
        const {
          invBalance = '$0',
          investmentBucket = '',
          nickName = '',
          assetProfile = 'generic',
          products = [],
        } = selectedInvestmentAccount;
        this.setState((prevState) => ({
          availableInvestmentAccounts: tempAvailableInvestmentAccounts,
          selectedInvestmentAccount,
          selectedInvestmentBucket: investmentBucket || defaultInvestmentBucket,
          isButtonDisabled,
          accBalance: invBalance || '$0',
          assetMixListForInv: newAssetMixList,
          isAssetMixPercentNotEqualTo100:
            sumOfAssetMixValue === 100 ? false : true,
          sumOfAssetMixValue,
          newNickName: nickName || '',
          selectedInvReturnOption:
            products.length > 0 ? 'products' : assetProfile,
          tickersAvailableInPlan: products,
          tickersAddedToPlan: [],
          remainingBalance: '',
        }));
      }
    }
  };

  loadTickersAddedInPlan = (updatedBalance) => {
    const { tickersAvailableInPlan, accBalance } = this.state;
    const { investmentProducts } = this.props;
    let availableProducts = [];
    investmentProducts.forEach((i) => {
      availableProducts = [...availableProducts, ...i.tickers];
    });
    const uniqueProducts = availableProducts.filter((obj, index) => {
      return (
        index === availableProducts.findIndex((o) => obj.ticker === o.ticker)
      );
    });
    const filteredArray = tickersAvailableInPlan.map((m) => {
      const matchFound = uniqueProducts.filter((f) => f.ticker === m.ticker);
      if (matchFound && matchFound.length > 0) {
        return {
          ticker: m.ticker,
          balance: this.transformValue(m.balance),
          desc: matchFound[0].desc,
        };
      } else {
        return {
          ticker: m.ticker,
          balance: this.transformValue(m.balance),
        };
      }
    });
    const tickersAddedToPlan = filteredArray.filter(
      (f) => f.ticker.toLowerCase() !== 'cash'
    );
    const balance = updatedBalance ? updatedBalance : accBalance;
    const remainingBalance = this.updateRemainingBalance(
      tickersAddedToPlan,
      balance
    );
    this.setState({
      tickersAddedToPlan,
      remainingBalance,
      selectedInvReturnOption:
        tickersAddedToPlan.length > 0 ? 'products' : 'generic',
    });
  };

  getPlaidAccountsBalance = async () => {
    const { plaidLinkAccessToken } = this.props;
    const { status, data: accountsResp } = await getPlaidAccountsBalance(
      plaidLinkAccessToken
    );
    if (status === 200) {
      this.setState({
        plaidAccountDetails: { ...accountsResp },
        selectedYesEvent: true,
        stepIndex: 1,
        selectedMember: '',
      });
    }
  };

  getAccounts = (selectedMemberState) => {
    let accounts = [];
    accounts = Object.entries(selectedMemberState).reduce(
      (acc, [key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            const index = acc.indexOf(
              acc.filter(
                (a) =>
                  a.id.toLowerCase() === `${item.nickName}-${key}`.toLowerCase()
              )[0]
            );
            if (index === -1) {
              acc.push({
                ...item,
                investmentBucket: key,
                invBalance: this.getCurrencyValue(item.invBalance),
                id: `${item.nickName}-${key}`,
              });
            }
          });
        }
        return acc;
      },
      []
    );
    return accounts;
  };

  getAllAccountsFromBuckets = (memberState) => {
    const { selectedInvestmentAccount } = this.state;
    const { nickName = '' } = selectedInvestmentAccount || {};
    const selectedMemberState = memberState
      ? memberState
      : this.getSelectedMemberState();
    const availableInvestmentAccounts =
      this.getAccounts(selectedMemberState) || [];
    const currentSelectedInvestmentAccount =
      availableInvestmentAccounts.filter((a) => a.nickName === nickName)[0] ||
      {};
    const investmentAccountSelected =
      currentSelectedInvestmentAccount &&
      Object.keys(currentSelectedInvestmentAccount).length > 0
        ? currentSelectedInvestmentAccount
        : availableInvestmentAccounts.length > 0
        ? availableInvestmentAccounts[0]
        : {};
    this.setState({
      availableInvestmentAccounts,
      selectedInvestmentAccount: investmentAccountSelected,
      selectedInvestmentBucket:
        investmentAccountSelected?.investmentBucket || '',
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { plaidLinkSuccess, members, investmentProducts } = this.props;
    const {
      plaidLinkSuccess: prevPlaidLinkSuccess,
      investmentProducts: prevInvestmentProducts,
    } = prevProps;
    const {
      selectedMember,
      selectedYesEvent,
      plaidAccountDetails: { accounts = [] },
      tickersAvailableInPlan,
      stepIndex,
    } = this.state;
    const {
      tickersAvailableInPlan: prevTickersAvailableInPlan,
      selectedMember: prevSelectedMember,
      stepIndex: prevStepIndex,
    } = prevState;
    if (selectedMember && selectedMember !== prevSelectedMember) {
      this.getAllAccountsFromBuckets();
    }
    if (
      selectedYesEvent &&
      accounts.length > 0 &&
      stepIndex > 1 &&
      stepIndex !== prevStepIndex
    ) {
      this.updateAccountDetails();
    }
    if (members && members.length > 0 && selectedMember === '') {
      this.setState({
        selectedMember: members[0].key,
      });
    }
    if (plaidLinkSuccess !== prevPlaidLinkSuccess && plaidLinkSuccess) {
      this.getPlaidAccountsBalance();
    }
    if (
      investmentProducts &&
      investmentProducts.length > 0 &&
      tickersAvailableInPlan &&
      tickersAvailableInPlan.length > 0 &&
      (!isEqual(investmentProducts, prevInvestmentProducts) ||
        !isEqual(tickersAvailableInPlan, prevTickersAvailableInPlan))
    ) {
      this.loadTickersAddedInPlan();
    }
  }

  getNewMemberInitialState() {
    const { storeValues } = this.props;
    return {
      memberValues: storeValues,
    };
  }

  resetAllAccountsState = (from) => {
    const { investmentTypeName, selectedMember } = this.state;
    const { members, setLastStepInParent } = this.props;
    if (from) {
      setLastStepInParent(true); // for member change and simulate, consider it as last page and show buttons
    } else {
      setLastStepInParent(false);
    }
    this.setState({
      userName: '',
      password: '',
      assetMixListForInv: [...assetMixList],
      investmentTypeName: from ? investmentFields[0].id : investmentTypeName,
      selectedMember: from ? members[0].key : selectedMember,
      tickersAddedToPlan: [],
      tickersAvailableInPlan: [],
      selectedAccsIndex: 0,
    });
  };

  // renderCredentialsForm = () => {
  //   const { selectedBankDetails, userName, password } = this.state;
  //   return (
  //     <Grid container className="user-credentials-form">
  //       <Grid item xs={8}>
  //         <p className="enter-cred-text">Enter your credentials</p>
  //         <p className="terms-cond-text">
  //           By Providing your credentials for <b>{selectedBankDetails.title}</b>{' '}
  //           you are enabling Green'r to fetch your information at{' '}
  //           <b>{selectedBankDetails.title}</b> through Plaid, a 3rd party
  //           integrator.
  //         </p>
  //         <p className="terms-cond-text">
  //           Enter your credentials for <b>{selectedBankDetails.title}</b> :
  //         </p>
  //         <Controls.Input
  //           name="userName"
  //           label="User Name"
  //           value={userName}
  //           onChange={this.onInputChange}
  //           required
  //           isValid
  //         />
  //         <Controls.Input
  //           name="password"
  //           label="Password (min 6 characters)"
  //           value={password}
  //           type="password"
  //           onChange={this.onInputChange}
  //           minlength="8"
  //           required
  //           errorText="Invalid Password"
  //           isValid
  //         />
  //         {/* <div className="simulate-buttons">
  //           <Button
  //             variant="contained"
  //             startIcon={<PublishIcon />}
  //             onClick={this.submitPlaidCred}
  //             // disabled={!this.validateForm('login')}
  //           >
  //             Submit{' '}
  //           </Button>
  //         </div> */}
  //       </Grid>
  //     </Grid>
  //   );
  // };

  // submitPlaidCred = () => {
  //   //call plaid api and get response
  //   this.setState({
  //     stepIndex: 3,
  //   });
  // };

  setStepLevelToTwo = () => {
    this.setState({
      stepIndex: 2,
      stepNoIndex: '',
    });
  };

  // setSelectedBankDetails = (event) => {
  //   const { banksList } = this.state;
  //   const selectedItem = banksList.find(
  //     (list) => list.id === event.target.value
  //   );
  //   this.setState({
  //     selectedBankDetails: selectedItem,
  //   });
  // };

  searchInstitutionCb = (options) => {
    return options.map((option) => ({
      value: option.institutionId,
      label: option.name,
    }));
  };

  // setSearchInstitution = (selectedOption) => {
  //   const newSelectedBankDetails = {
  //     id: selectedOption.value,
  //     title: selectedOption.label,
  //   };
  //   this.setState({
  //     selectedBankDetails: newSelectedBankDetails,
  //   });
  // };

  // searchInstitution = async (inputValue, callback) => {
  //   const { data = [] } = await getAllInstitutions(inputValue);
  //   if (data.length > 0) callback(this.searchInstitutionCb(data));
  //   else callback([]);
  // };

  // setStepBack = () => {
  //   const { stepIndex } = this.state;
  //   this.setState({
  //     stepIndex: stepIndex - 1,
  //   });
  // };

  selectedYesEvent = (selectedYesEvent) => {
    this.setState({
      selectedYesEvent,
      stepIndex: 1,
      banksList: [...banksListObj],
    });
  };

  selectedNoEvent = () => {
    this.resetAllAccountsState();
    this.setState({
      stepNoIndex: 1,
      selectedYesEvent: false,
      stepIndex: 0,
    });
  };

  // enterCredentialsEvent = () => {
  //   this.setState({
  //     stepIndex: 2,
  //   });
  // };

  setNextStepIndex1 = () => {
    this.setState({
      stepIndex: 1,
      selectedYesEvent: true,
    });
  };

  setNextStepNoIndex2 = (e, from) => {
    const {
      selectedMember,
      oldSelectedInvestmentAccount,
      selectedInvestmentAccount,
      isAddNew,
    } = this.state;
    const { setLastStepInParent, members } = this.props;
    let tempSelectedMember = selectedMember;
    if (!from && members && members.length > 0) {
      tempSelectedMember = members[0].key;
    }
    setLastStepInParent(true);
    this.setState((prevState) => ({
      stepNoIndex: 2,
      isAddNew: false,
      isEditable: false,
      selectedYesEvent: false,
      selectedTicker: '',
      selectedProductType: '',
      tickersAddedToPlan: [],
      tickersAvailableInPlan: [],
      selectedMember: tempSelectedMember,
      selectedInvestmentAccount:
        isAddNew || from === 'cancel'
          ? oldSelectedInvestmentAccount
          : selectedInvestmentAccount,
    }));
  };

  setPreviousStepNoIndex = () => {
    const { setLastStepInParent } = this.props;
    this.setState({ stepNoIndex: 1 });
    setLastStepInParent(false);
  };

  getPercentValue = (value) => {
    if (!value.toString().includes('%')) {
      const percentValue = parseFloat(parseFloat(value) * 100).toFixed(2);
      return `${percentValue}%`;
    }
    return value;
  };

  getCurrencyValue = (value) => {
    if (value !== '' && !value.toString().includes('$')) {
      const currencyValue = !isNaN(value) ? value : '';
      const numFormat = Intl.NumberFormat('en-US');
      const newNumformat = numFormat.format(currencyValue);
      return `$${newNumformat}`;
    }
    return value;
  };

  setNextStepNoIndex = (accountDetails) => {
    const { setLastStepInParent } = this.props;
    const { customReturn, customVol, customDivRate, invBalance } =
      accountDetails;
    let newCustRet = this.getPercentValue(customReturn);
    let newCustVol = this.getPercentValue(customVol);
    let newCustomDivRate = this.getPercentValue(customDivRate);
    let newInvBalance = this.getCurrencyValue(invBalance);
    const newAssetMixList = assetMixList.map((listItem) => {
      const value = accountDetails[listItem.name] || 0;
      let newPercentValue = this.getPercentValue(value);
      return {
        ...listItem,
        value: newPercentValue,
      };
    });
    const sumOfAssetMixValue = newAssetMixList.reduce(function (acc, val) {
      return acc + parseFloat(val.value);
    }, 0);
    setLastStepInParent(false);
    this.setState({
      stepNoIndex: 3,
      selectedYesEvent: false,
      assetMixListForInv: newAssetMixList,
      isAssetMixPercentNotEqualTo100: sumOfAssetMixValue === 100 ? false : true,
      sumOfAssetMixValue,
      selectedInvestmentAccount: {
        ...accountDetails,
        customReturn: newCustRet,
        customVol: newCustVol,
        customDivRate: newCustomDivRate,
        // invBalance: newInvBalance,
      },
    });
  };

  handleMemberChange = (e, type) => {
    let fieldValue = e.target.checked ? e.target.defaultValue : false;
    this.resetAllAccountsState('memberChange');
    this.setState({
      selectedMember: fieldValue,
      // investmentTypeName: investmentFields[0].id,
    });
  };

  renderMemberTabs = () => {
    const { members } = this.props;
    const { selectedMember } = this.state;
    return (
      <Controls.RadioGroup
        name="member-radio-buttons"
        value={selectedMember}
        rowType={true}
        onChange={(event) => this.handleMemberChange(event, 'radio')}
        items={members}
      ></Controls.RadioGroup>
    );
  };

  handlePlaidAccChange = (event) => {
    const { value } = event.target;
    this.setState({
      plaidAccTabValue: parseInt(value, 10),
    });
  };

  updateInvBalance = (event) => {
    let { value } = event && event.target;
    const { tickersAddedToPlan, selectedInvestmentAccount = {} } = this.state;
    let tempValue = 0;
    if (value && !isNaN(value) && value >= 0) {
      tempValue = value;
    } else if (value && isNaN(value) && value.includes('$')) {
      tempValue = value.split('$')[1].replace(/,/g, '');
    }
    const remainingBalance = this.updateRemainingBalance(
      tickersAddedToPlan,
      tempValue
    );
    this.setState({
      accBalance: tempValue,
      tickersAvailableInPlan: [],
      remainingBalance,
      selectedInvestmentAccount: {
        ...selectedInvestmentAccount,
        invBalance: tempValue,
      },
    });
  };

  updateInvAccNickName = (event) => {
    let { value } = (event && event.target) || '';
    const {
      availableInvestmentAccounts,
      selectedInvestmentAccount = {},
      selectedInvestmentBucket,
    } = this.state;
    const isNameValid = validateNamesWithoutSpaces(value);
    let index = -1;
    if (isNameValid) {
      index = availableInvestmentAccounts.indexOf(
        availableInvestmentAccounts.filter(
          (a) => a.nickName.toLowerCase() === value.toLowerCase()
        )[0]
      );
    }
    let isButtonDisabled = index === -1 ? false : true;
    this.setState({
      newNickName: value,
      selectedInvestmentAccount: {
        ...selectedInvestmentAccount,
        nickName: value,
        id:
          selectedInvestmentBucket !== ''
            ? `${value}-${selectedInvestmentBucket}`
            : '',
      },
      isButtonDisabled,
      buttonDisabledMsg: !isNameValid
        ? 'Invalid Name'
        : isButtonDisabled
        ? 'Name already exists'
        : '',
    });
  };

  setToPlaid = () => {
    const { updateInvValueAsOfInStore, invValueAsOf, setLastStepInParent } =
      this.props;
    if (invValueAsOf !== 'now') {
      updateInvValueAsOfInStore('', 'plaid');
    }
    setLastStepInParent(false);
  };

  renderPlaidAccountsInfo = () => {
    const {
      plaidAccTabValue,
      plaidAccountDetails: { accounts = [] },
      plaidLinkToken,
      plaidAccountProcessedInfo,
    } = this.state;
    const { current: avlAccBalance } =
      (accounts &&
        accounts[plaidAccTabValue] &&
        accounts[plaidAccTabValue].balances) ||
      0;
    const { setPlaidLinkSuccessAction, setPlaidLinkAccessToken } = this.props;
    const formattedBalance = parseFloat(avlAccBalance).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    });
    const isAddToInvestmentsDisabled =
      plaidAccountProcessedInfo.filter((p) => p.isPlaidAccountProcessed)
        .length === accounts.length
        ? true
        : false;
    return (
      <>
        <p>Accounts fetched from #Institution through Plaid:</p>
        <Grid container className="acc-list-container" spacing={4}>
          <Grid item xs={4} className="acc-list-items">
            <RadioGroup
              name="controlled-radio-buttons-group"
              value={plaidAccTabValue}
              rowType={false}
              onChange={(event) => this.handlePlaidAccChange(event)}
              items={
                accounts &&
                accounts.map((item, index) => ({
                  disabled:
                    plaidAccountProcessedInfo.filter(
                      (p) => p.plaidIndex === index && p.isPlaidAccountProcessed
                    ).length > 0
                      ? true
                      : false,
                  showProcessed:
                    plaidAccountProcessedInfo.filter(
                      (p) => p.plaidIndex === index && p.isPlaidAccountProcessed
                    ).length > 0
                      ? true
                      : false,
                  title: item.name,
                  id: index,
                }))
              }
            />
          </Grid>
          <Grid item xs={7}>
            <div>
              {plaidAccTabValue !== '' && accounts.length > 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Controls.Input
                      value={
                        (accounts[plaidAccTabValue] &&
                          accounts[plaidAccTabValue].type) ||
                        ''
                      }
                      label="Account Type"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Controls.Input
                      value={`${formattedBalance}`}
                      label="Balance"
                      disabled
                    />
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container className="automatic_button_group">
          <Grid item xs={4} className="simulate-buttons">
            <Button
              onClick={this.setStepLevelToTwo}
              variant="contained"
              disabled={isAddToInvestmentsDisabled}
              style={{
                color: '#fff',
                fontWeight: 700,
                fontSize: '12px',
                marginRight: '10px',
                backgroundColor: isAddToInvestmentsDisabled
                  ? 'gray'
                  : '#009fe3',
              }}
            >
              Add to Investments{' '}
            </Button>
          </Grid>
          <Grid item xs={4} className="simulate-buttons">
            <PlaidLinkFn
              setPlaidLinkSuccessAction={setPlaidLinkSuccessAction}
              setPlaidLinkAccessToken={setPlaidLinkAccessToken}
              clearProcessedData={this.clearProcessedData}
              plaidText="Next Institution"
              setToPlaid={this.setToPlaid}
            />
          </Grid>
          <Grid item xs={4} className="simulate-buttons">
            <Button
              onClick={(e) => this.setNextStepNoIndex2(e)}
              variant="contained"
              style={{
                color: '#fff',
                fontWeight: 700,
                fontSize: '12px',
                marginRight: '10px',
                backgroundColor: '#009fe3',
              }}
            >
              Done with Automatic
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  updateCountOnDelete = (member, availableInvestmentAccounts) => {
    let tempMember = { ...member };
    const { selectedInvestmentAccount: { investmentBucket = '' } = {} } =
      this.state;
    const availableInvestmentBucketAccounts =
      availableInvestmentAccounts.filter(
        (f) => f.investmentBucket === investmentBucket
      );
    const availableAccounts = availableInvestmentBucketAccounts || [];
    tempMember = {
      ...tempMember,
      [`num${this.capitalizeFirstLetter(investmentBucket)}`]:
        availableAccounts.length,
      [investmentBucket]: availableAccounts.map((acc) => {
        const {
          customReturn,
          customVol,
          customDivRate,
          invBalance,
          assetProfile,
        } = acc;
        return {
          ...acc,
          altPercent: this.percentageConversion(acc.altPercent),
          bondPercent: this.percentageConversion(acc.bondPercent),
          cashPercent: this.percentageConversion(acc.cashPercent),
          reitPercent: this.percentageConversion(acc.reitPercent),
          shortTermPercent: this.percentageConversion(acc.shortTermPercent),
          stockPercent: this.percentageConversion(acc.stockPercent),
          cryptoPercent: this.percentageConversion(acc.cryptoPercent),
          commoditiesPercent: this.percentageConversion(acc.commoditiesPercent),
          emktBondPercent: this.percentageConversion(acc.emktBondPercent),
          emktStockPercent: this.percentageConversion(acc.emktStockPercent),
          intlBondPercent: this.percentageConversion(acc.intlBondPercent),
          intlStockPercent: this.percentageConversion(acc.intlStockPercent),
          customVol:
            assetProfile === 'products' || assetProfile === 'generic'
              ? 0
              : this.percentageConversion(customVol),
          customReturn:
            assetProfile === 'products' || assetProfile === 'generic'
              ? 0
              : this.percentageConversion(customReturn),
          customDivRate:
            assetProfile === 'products' || assetProfile === 'generic'
              ? 0
              : this.percentageConversion(customDivRate),
          invBalance: this.transformBackToAPIType(invBalance),
        };
      }),
    };
    return tempMember;
  };

  updateAccountsOnDelete = (availableInvestmentAccounts) => {
    const { memberValues, selectedMember } = this.state;
    const { updateAssetMixInRedux, invValueAsOf } = this.props;
    const { first, second } = memberValues;
    const newMemberValues = {
      ...memberValues,
    };
    if (selectedMember === 'first') {
      newMemberValues.first = this.updateCountOnDelete(
        first,
        availableInvestmentAccounts
      );
      this.getAllAccountsFromBuckets(newMemberValues.first);
    } else if (selectedMember === 'second') {
      newMemberValues.second = this.updateCountOnDelete(
        second,
        availableInvestmentAccounts
      );
      this.getAllAccountsFromBuckets(newMemberValues.second);
    }
    this.setState({ memberValues: newMemberValues });
    updateAssetMixInRedux({
      invbalanceReq: newMemberValues,
      invValueAsOf,
    });
  };

  handleOnBlur = (event, type) => {
    let { value } = event && event.target;
    if (isNaN(value) && value.includes('$')) {
      value = !isNaN(value.split('$')[1].replace(/,/g, ''))
        ? parseFloat(value.split('$')[1].replace(/,/g, ''))
        : 0;
    } else {
      value = value !== '' && !isNaN(value) ? value : '';
    }
    const numFormat = Intl.NumberFormat('en-US');
    const newNumformat = numFormat.format(value);
    this.setState({
      accBalance: `$${newNumformat}`,
    });
  };

  transformValue = (value) => {
    if (value) {
      let numVal = value;
      if (isNaN(numVal) && numVal.toString().includes('$')) {
        numVal = parseFloat(numVal.split('$')[1].replace(/,/g, ''));
      }
      const numFormat = Intl.NumberFormat('en-US');
      const newNumformat = numFormat.format(numVal);
      return `$${newNumformat}`;
    } else if (value === 0) {
      return `$0`;
    } else {
      return '';
    }
  };

  onInvAccountChange = (event) => {
    const { availableInvestmentAccounts } = this.state;
    let { value: selectedAccount, index: selectedAccsIndex } = event.target;
    const selectedAccountInfo = availableInvestmentAccounts.filter(
      (f) => f.id.toLowerCase() === selectedAccount.toLowerCase()
    );
    let selectedInvestmentAccount = {};
    if (selectedAccountInfo && selectedAccountInfo.length > 0) {
      selectedInvestmentAccount = selectedAccountInfo[0];
    }
    this.setState({
      selectedAccount,
      selectedAccsIndex,
      selectedInvestmentAccount,
      tickersAvailableInPlan: [],
    });
  };

  handleEdit = (isEditable = true, isNewRec = false, name = '') => {
    const { selectedInvestmentAccount } = this.state;
    const {
      products = [],
      invBalance = '$0',
      nickName = '',
      assetProfile = '',
      investmentBucket = '',
    } = selectedInvestmentAccount;
    let tickersAvailableInPlan = isNewRec ? [] : products;
    let selectedInvReturnOption = isNewRec
      ? 'generic'
      : tickersAvailableInPlan.length > 0
      ? 'products'
      : assetProfile;
    let newNickName = isNewRec ? '' : nickName;
    let accBalance = isNewRec ? '$0' : invBalance;
    let accountDetails = isNewRec
      ? {
          ...newAccountDetailsToBeAdded,
          nickName: newNickName,
          invBalance: `${accBalance}`,
        }
      : { ...selectedInvestmentAccount };

    this.setState({
      isEditable,
      isAddNew: isNewRec,
      accBalance,
      newNickName,
      isButtonDisabled: false,
      tickersAvailableInPlan,
      selectedInvReturnOption,
      oldSelectedInvestmentAccount: selectedInvestmentAccount,
      selectedInvestmentBucket: isNewRec ? '' : investmentBucket,
    });
    this.setNextStepNoIndex(accountDetails);
  };

  openDeleteConfirmation = () => {
    this.setState({ isDeleteConfirmationOpen: true });
  };

  renderInputForm = (from, currentAcc) => {
    const { newNickName, isAddNew } = this.state;
    const { nickName } = currentAcc;
    return (
      <div className="row align-items-center">
        <div className="col-lg-12">
          <label className="required">Account Name</label>
          <input
            type={'text'}
            id={'accountName'}
            name={'accountName'}
            value={isAddNew ? newNickName || '' : nickName || ''}
            disabled={!isAddNew || from}
            className={'custom-input'} // Apply the appropriate class
            onChange={(e) => this.updateInvAccNickName(e)}
            placeholder={'Enter Account Name'}
          />
        </div>
      </div>
    );
  };

  renderInputFormOnBlur = () => {
    const { isAddNew, isEditable, accBalance } = this.state;
    const formattedBalance = this.getCurrencyValue(accBalance);
    return (
      <div className="row align-items-center">
        <div className="col-lg-12">
          <label className="required">Account Balance</label>
          <input
            type={'text'}
            id={'accountBalance'}
            name={'accountBalance'}
            value={formattedBalance || '$0'}
            className={'custom-input'} // Apply the appropriate class
            onChange={(e) => this.updateInvBalance(e)}
            onBlur={(event) => this.handleOnBlur(event, 'number')}
            placeholder={isAddNew ? 'Enter Amount' : ''}
          />
        </div>
      </div>
    );
  };

  getInvestmentBucketName = (investmentBucket) => {
    if (investmentBucket) {
      const investmentBucketName = investmentFields.find(
        (f) => f.id === investmentBucket
      );
      return investmentBucketName?.title || '';
    }
    return '';
  };

  renderInputFormBalance = () => {
    const { selectedInvestmentAccount } = this.state;
    const { invBalance = '$0', investmentBucket = '' } =
      selectedInvestmentAccount || {};
    const investmentBucketName = this.getInvestmentBucketName(investmentBucket);
    const formattedBalance = this.getCurrencyValue(invBalance);
    return (
      <div className="row align-items-center">
        <div className="col-lg-4">
          <label>Account Balance</label>
          <input
            type={'text'}
            id={`${invBalance}-accountBalance`}
            name={'accountBalance'}
            value={formattedBalance}
            disabled
            className={'custom-input'} // Apply the appropriate class
          />
        </div>
        <div className="col-lg-4">
          <label>Investment Bucket</label>
          <input
            type={'text'}
            id={investmentBucket}
            name={investmentBucket}
            value={investmentBucketName || ''}
            disabled
            className={'custom-input'} // Apply the appropriate class
          />
        </div>
      </div>
    );
  };

  renderSelectAccountDropdown = () => {
    const { availableInvestmentAccounts, selectedInvestmentAccount } =
      this.state;
    return (
      <div className="Select_account_main_outer" style={{ paddingTop: '10px' }}>
        <h5 className="mb-0 title-div">Select Account </h5>
        <div className="row align-items-center" style={{ paddingTop: '10px' }}>
          <div className="Select_account_dropdown col-lg-3 col-md-4 col-6">
            <Controls.Select
              className="js-example-basic-single"
              name="Account"
              maxMenuHeight={150}
              id="Account"
              value={selectedInvestmentAccount?.id}
              onChange={(e) => this.onInvAccountChange(e)}
              options={availableInvestmentAccounts.map((p, index) => {
                return { label: p.nickName, value: p.id, index: index };
              })}
            />
          </div>
          <div
            className="col-lg-8 col-md-8 col-5 button-group"
            style={{
              width: 'max-content',
            }}
          >
            {availableInvestmentAccounts &&
              availableInvestmentAccounts.length > 0 && (
                <>
                  <button
                    className="action-button"
                    onClick={(e) => {
                      this.handleEdit(true);
                    }}
                  >
                    <img src={edit_pencil} alt="" title="" />
                  </button>
                  <button
                    className="action-button"
                    onClick={(e) => this.openDeleteConfirmation('')}
                  >
                    <img src={delete_icon} alt="" title="" />
                  </button>
                </>
              )}
            <button
              className="action-button"
              onClick={(event) => {
                this.handleEdit(true, true);
              }}
            >
              <img src={pdf_page_plus_icon} alt="" title="" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  handleDeleteConfirm = () => {
    const { availableInvestmentAccounts, selectedInvestmentAccount } =
      this.state;
    const selectedIndex = availableInvestmentAccounts.indexOf(
      availableInvestmentAccounts.filter(
        (f) =>
          f.nickName.toLowerCase() ===
          selectedInvestmentAccount?.nickName.toLowerCase()
      )[0]
    );
    availableInvestmentAccounts.splice(selectedIndex, 1);
    this.setState(
      {
        isDeleteConfirmationOpen: false,
        tickersAddedToPlan: [],
        tickersAvailableInPlan: [],
        availableInvestmentAccounts,
      },
      () => {
        this.updateAccountsOnDelete(availableInvestmentAccounts);
      }
    );
  };

  closeDeleteConfirmation = () => {
    this.setState({ isDeleteConfirmationOpen: false });
  };

  deleteConfirmation = () => {
    const {
      isDeleteConfirmationOpen,
      selectedInvestmentAccount: { nickName = '' } = {},
    } = this.state;
    return (
      <Dialog
        open={isDeleteConfirmationOpen}
        onClose={this.closeDeleteConfirmation}
        className="delete_popup_outer"
      >
        <DialogTitle>Are you sure you want to delete "{nickName}"?</DialogTitle>
        <DialogActions>
          <Button
            onClick={this.closeDeleteConfirmation}
            color="primary"
            style={{
              padding: '7.5px 15px',
              color: '#fff',
              fontWeight: 700,
              fontSize: '12px',
              marginRight: '10px',
              backgroundColor: 'gray !important',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={this.handleDeleteConfirm}
            color="primary"
            style={{
              padding: '7.5px 15px',
              color: '#fff',
              fontWeight: 700,
              fontSize: '12px',
              marginRight: '10px',
              backgroundColor: '#009fe3',
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderInvestmentForm = () => {
    const { selectedInvestmentAccount } = this.state;
    return (
      <>
        {this.renderMemberTabs()}
        <Grid container className="financial-instn-container">
          <Grid item xs={10}>
            <p className="select-bank-text">
              Select the investment that you want to manually enter or update
              what you automatically fetched from your financial institutions:
            </p>
          </Grid>
        </Grid>
        {this.renderSelectAccountDropdown()}
        {this.deleteConfirmation()}
        {selectedInvestmentAccount &&
          Object.keys(selectedInvestmentAccount).length > 0 && (
            <div className="insured_main_outer">
              {this.renderInputFormBalance()}
            </div>
          )}
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            className="simulate-buttons next_previous_btn_group"
          >
            <Button
              onClick={this.setPreviousStepNoIndex}
              variant="contained"
              className="previous_button"
              style={{
                padding: '7.5px 15px',
                color: '#fff',
                fontWeight: 700,
                fontSize: '12px',
                marginRight: '10px',
                backgroundColor: '#009fe3',
              }}
            >
              Previous
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  setAssetMixValue = (event, name) => {
    let { value } = event.target;
    if (value.toString().includes('%')) {
      const percentFieldValue = value.split('%')[0];
      value =
        percentFieldValue !== '' && !isNaN(percentFieldValue)
          ? percentFieldValue
          : '';
    } else {
      value = value !== '' && !isNaN(value) ? value : '';
    }
    const { assetMixListForInv } = this.state;
    const newAssetMixList = assetMixListForInv.map((list) => {
      if (list.name === name) {
        return {
          ...list,
          value,
        };
      }
      return {
        ...list,
      };
    });
    this.setState({
      assetMixListForInv: [...newAssetMixList],
    });
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  percentageConversion = (field) => {
    if (field.toString().includes('%')) {
      let percentField = field.split('%')[0];
      percentField =
        percentField !== '' && !isNaN(percentField)
          ? parseFloat(percentField) / 100
          : 0;
      return percentField;
    }
    return field !== '' && !isNaN(field) ? field : 0;
  };

  convertedNewMemberObject = (member, from) => {
    let tempMember = { ...member };
    const {
      assetMixListForInv,
      selectedInvReturnOption,
      tickersAddedToPlan,
      remainingBalance,
      selectedInvestmentAccount,
      selectedInvestmentBucket,
      oldInvestmentBucket,
    } = this.state;
    const newAssetMixList = assetMixListForInv.map((list) => {
      const percentFieldValue = list.value.toString().split('%')[0];
      const newFieldValue =
        selectedInvReturnOption === 'products' && tickersAddedToPlan.length > 0
          ? 0
          : percentFieldValue !== '' &&
            !isNaN(percentFieldValue) &&
            parseFloat(percentFieldValue) / 100;
      return {
        ...list,
        value: newFieldValue,
      };
    });
    let products = [];
    if (selectedInvReturnOption === 'products') {
      products = tickersAddedToPlan.map((m) => {
        return {
          ticker: m.ticker,
          balance: this.transformBackToAPIType(m.balance),
        };
      });
      if (products.length > 0) {
        const cashObj = {
          ticker: 'Cash',
          balance: this.transformBackToAPIType(remainingBalance),
        };
        products.push(cashObj);
      }
    }
    const { id, investmentBucket, ...objectToMove } = selectedInvestmentAccount;

    const index =
      oldInvestmentBucket !== ''
        ? tempMember[oldInvestmentBucket].findIndex(
            (item) =>
              item.nickName.toLowerCase() ===
              objectToMove.nickName.toLowerCase()
          )
        : -1;
    if (
      index !== -1 &&
      oldInvestmentBucket.toLowerCase() !==
        selectedInvestmentBucket.toLowerCase()
    ) {
      // Remove the object from trad401k array
      const [removedObject] = tempMember[oldInvestmentBucket].splice(index, 1);
      // Add the removed object to the rsu array
      tempMember[selectedInvestmentBucket].push(objectToMove);
    } else if (index === -1) {
      const accounts = tempMember[selectedInvestmentBucket];
      if (accounts) {
        const newIndex = accounts.indexOf(
          accounts.filter(
            (f) =>
              f.nickName.toLowerCase() === objectToMove.nickName.toLowerCase()
          )[0]
        );
        if (newIndex === -1) {
          tempMember[selectedInvestmentBucket].push(objectToMove);
        }
      }
    }
    const availableAccounts = tempMember[selectedInvestmentBucket] || [];
    const availableAccountsAfterMoving = tempMember[oldInvestmentBucket] || [];
    tempMember = {
      ...tempMember,
      [`num${this.capitalizeFirstLetter(selectedInvestmentBucket)}`]:
        availableAccounts.length,
      [`num${this.capitalizeFirstLetter(oldInvestmentBucket)}`]:
        availableAccountsAfterMoving.length,
      [selectedInvestmentBucket]: availableAccounts.map((acc, index) => {
        const { customReturn, customVol, customDivRate, invBalance } = acc;
        if (
          acc.nickName.toLowerCase() === objectToMove.nickName.toLowerCase()
        ) {
          const {
            customReturn: updatedCustomReturn,
            customVol: updatedCustomVol,
            customDivRate: updatedCustomDivRate,
            invBalance: updatedInvBalance,
          } = objectToMove;
          return {
            ...acc,
            assetProfile: selectedInvReturnOption,
            altPercent: newAssetMixList.find((inv) => inv.name === 'altPercent')
              .value,
            bondPercent: newAssetMixList.find(
              (inv) => inv.name === 'bondPercent'
            ).value,
            cashPercent: newAssetMixList.find(
              (inv) => inv.name === 'cashPercent'
            ).value,
            reitPercent: newAssetMixList.find(
              (inv) => inv.name === 'reitPercent'
            ).value,
            shortTermPercent: newAssetMixList.find(
              (inv) => inv.name === 'shortTermPercent'
            ).value,
            stockPercent: newAssetMixList.find(
              (inv) => inv.name === 'stockPercent'
            ).value,
            cryptoPercent: newAssetMixList.find(
              (inv) => inv.name === 'cryptoPercent'
            ).value,
            commoditiesPercent: newAssetMixList.find(
              (inv) => inv.name === 'commoditiesPercent'
            ).value,
            emktBondPercent: newAssetMixList.find(
              (inv) => inv.name === 'emktBondPercent'
            ).value,
            emktStockPercent: newAssetMixList.find(
              (inv) => inv.name === 'emktStockPercent'
            ).value,
            intlBondPercent: newAssetMixList.find(
              (inv) => inv.name === 'intlBondPercent'
            ).value,
            intlStockPercent: newAssetMixList.find(
              (inv) => inv.name === 'intlStockPercent'
            ).value,
            customVol:
              selectedInvReturnOption === 'products' ||
              selectedInvReturnOption === 'generic'
                ? 0
                : this.percentageConversion(updatedCustomVol),
            customReturn:
              selectedInvReturnOption === 'products' ||
              selectedInvReturnOption === 'generic'
                ? 0
                : this.percentageConversion(updatedCustomReturn),
            customDivRate:
              selectedInvReturnOption === 'products' ||
              selectedInvReturnOption === 'generic'
                ? 0
                : this.percentageConversion(updatedCustomDivRate),
            invBalance: this.transformBackToAPIType(updatedInvBalance),
            products: products,
          };
        } else {
          return {
            ...acc,
            altPercent: this.percentageConversion(acc.altPercent),
            bondPercent: this.percentageConversion(acc.bondPercent),
            cashPercent: this.percentageConversion(acc.cashPercent),
            reitPercent: this.percentageConversion(acc.reitPercent),
            shortTermPercent: this.percentageConversion(acc.shortTermPercent),
            stockPercent: this.percentageConversion(acc.stockPercent),
            cryptoPercent: this.percentageConversion(acc.cryptoPercent),
            commoditiesPercent: this.percentageConversion(
              acc.commoditiesPercent
            ),
            emktBondPercent: this.percentageConversion(acc.emktBondPercent),
            emktStockPercent: this.percentageConversion(acc.emktStockPercent),
            intlBondPercent: this.percentageConversion(acc.intlBondPercent),
            intlStockPercent: this.percentageConversion(acc.intlStockPercent),
            customVol:
              selectedInvReturnOption === 'products' ||
              selectedInvReturnOption === 'generic'
                ? 0
                : this.percentageConversion(customVol),
            customReturn:
              selectedInvReturnOption === 'products' ||
              selectedInvReturnOption === 'generic'
                ? 0
                : this.percentageConversion(customReturn),
            customDivRate:
              selectedInvReturnOption === 'products' ||
              selectedInvReturnOption === 'generic'
                ? 0
                : this.percentageConversion(customDivRate),
            invBalance: this.transformBackToAPIType(invBalance),
          };
        }
      }),
    };
    return tempMember;
  };

  getNextPlaidAccTabValue = (plaidAccountProcessedInfo) => {
    const {
      plaidAccountDetails: { accounts = [] },
    } = this.state;
    const remainingIndexes = accounts
      .map((a, idx) => {
        return { index: idx };
      })
      .filter((f) => {
        return !plaidAccountProcessedInfo.find((p) => {
          return p.plaidIndex === f.index;
        });
      });
    return remainingIndexes.length > 0
      ? parseInt(remainingIndexes[0].index)
      : 0;
  };

  updateAssetMix = () => {
    const {
      memberValues,
      selectedMember,
      plaidAccTabValue,
      plaidAccountDetails: { accounts = [] },
      plaidAccountProcessedInfo,
      selectedYesEvent,
    } = this.state;
    const { updateAssetMixInRedux, invValueAsOf, setLastStepInParent } =
      this.props;
    const { first, second } = memberValues;
    const newMemberValues = {
      ...memberValues,
    };
    if (selectedMember === 'first') {
      newMemberValues.first = this.convertedNewMemberObject(first);
      this.getAllAccountsFromBuckets(newMemberValues.first);
    } else if (selectedMember === 'second') {
      newMemberValues.second = this.convertedNewMemberObject(second);
      this.getAllAccountsFromBuckets(newMemberValues.second);
    }
    if (selectedYesEvent && accounts.length > 0) {
      let tempPlaidAccountProcessedInfo = [...plaidAccountProcessedInfo];
      if (
        tempPlaidAccountProcessedInfo.indexOf(
          tempPlaidAccountProcessedInfo.filter(
            (f) =>
              f.plaidIndex === plaidAccTabValue && f.isPlaidAccountProcessed
          )[0]
        ) === -1
      ) {
        tempPlaidAccountProcessedInfo.push({
          plaidIndex: plaidAccTabValue,
          isPlaidAccountProcessed: true,
        });
      }
      this.setState({
        selectedYesEvent: true,
        stepIndex: 1,
        newNickName: '',
        plaidAccTabValue:
          accounts.length === tempPlaidAccountProcessedInfo.length
            ? 0
            : this.getNextPlaidAccTabValue(tempPlaidAccountProcessedInfo),
        plaidAccountProcessedInfo: [...tempPlaidAccountProcessedInfo],
        memberValues: newMemberValues,
        tickersAddedToPlan: [],
        tickersAvailableInPlan: [],
      });
    } else {
      this.setState({
        stepNoIndex: 2,
        isEditable: false,
        isAddNew: false,
        selectedYesEvent: false,
        newNickName: '',
        tickersAddedToPlan: [],
        tickersAvailableInPlan: [],
        memberValues: newMemberValues,
      });
    }
    setLastStepInParent(true);
    updateAssetMixInRedux({
      invbalanceReq: newMemberValues,
      invValueAsOf,
    });
  };

  setSelectedInvReturnOption = (tab) => {
    this.setState({
      selectedInvReturnOption: tab.id,
    });
  };

  handleBlur = (event, field) => {
    const { value } = event && event.target;
    const { selectedInvestmentAccount, assetMixListForInv } = this.state;
    let newCustValue = value;
    if (!value.toString().includes('%')) {
      const percentCustVal =
        value !== '' && !isNaN(value) ? parseFloat(value).toFixed(2) : 0;
      newCustValue = `${percentCustVal}%`;
    }
    const newAssetMixList = assetMixListForInv.map((list) => {
      if (list.name === field) {
        return {
          ...list,
          value: newCustValue,
        };
      }
      return {
        ...list,
      };
    });
    const sumOfAssetMixValue = newAssetMixList.reduce(function (acc, val) {
      return acc + parseFloat(val.value);
    }, 0);

    const tempSelectedInvestmentAccount = {
      ...selectedInvestmentAccount,
      [field]: newCustValue,
    };
    this.setState({
      assetMixListForInv: [...newAssetMixList],
      isAssetMixPercentNotEqualTo100: sumOfAssetMixValue === 100 ? false : true,
      sumOfAssetMixValue,
      selectedInvestmentAccount: tempSelectedInvestmentAccount,
    });
  };

  setSelectedInvReturnValue = (event, fieldKey) => {
    let { value } = event.target;
    const { selectedInvestmentAccount } = this.state;
    if (value.toString().includes('%')) {
      const percentFieldValue = value.split('%')[0];
      value =
        percentFieldValue !== '' && !isNaN(percentFieldValue)
          ? percentFieldValue
          : '';
    } else {
      value = value !== '' && !isNaN(value) ? value : '';
    }
    const tempSelectedInvestmentAccount = {
      ...selectedInvestmentAccount,
      [fieldKey]: value,
    };
    this.setState({
      selectedInvestmentAccount: tempSelectedInvestmentAccount,
    });
  };

  getConvertedValue = (value) => {
    let usedBalance = value;
    if (
      usedBalance &&
      isNaN(usedBalance) &&
      usedBalance !== '$' &&
      usedBalance.includes('$')
    ) {
      const tempUsedBalance = usedBalance.split('$')[1].replace(/,/g, '');
      usedBalance =
        tempUsedBalance && !isNaN(tempUsedBalance)
          ? parseFloat(tempUsedBalance)
          : 0;
    } else {
      usedBalance = usedBalance && !isNaN(usedBalance) ? usedBalance : 0;
    }
    return usedBalance;
  };

  handleOnBlurTickers = (event, index) => {
    let { value } = event && event.target;
    const numFormat = Intl.NumberFormat('en-US');
    let newNumformat = numFormat.format(0);
    let currencyFieldValue = '';
    if (value !== null && value !== undefined && value !== '') {
      if (value.toString().includes('$')) {
        currencyFieldValue = value.split('$')[1];
        currencyFieldValue = currencyFieldValue.replace(/,/g, '');
        if (
          currencyFieldValue !== '' &&
          !isNaN(currencyFieldValue) &&
          Number(currencyFieldValue) >= 0
        ) {
          newNumformat = numFormat.format(currencyFieldValue);
        }
      } else if (!isNaN(value) && Number(value) >= 0) {
        newNumformat = numFormat.format(value);
      }
      value = `$${newNumformat}`;
    } else {
      value = `$${newNumformat}`;
    }
    const { tickersAddedToPlan } = this.state;
    let tempTickersAddedToPlan = [...tickersAddedToPlan];
    tempTickersAddedToPlan.forEach((f, i) => {
      if (i === index) {
        f.balance = value;
      }
    });
    this.setState({
      tickersAddedToPlan: tempTickersAddedToPlan,
    });
  };

  updateRemainingBalance = (tempTickersAddedToPlan, balance) => {
    const amounts = tempTickersAddedToPlan
      .map(
        (m) => this.getConvertedValue(m.balance) //to remove empty values
      )
      .filter((a) => a);
    //taking only amounts property
    const amountUsed = amounts.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    ); //now adding them (a + b is nthg but actual values like 10 + 20)
    let tempBalance = this.getConvertedValue(balance);
    const remainingBalance =
      amountUsed !== '' && !isNaN(amountUsed)
        ? parseFloat(tempBalance) - parseFloat(amountUsed)
        : 0;
    let inValidAmount = false;
    if (amountUsed > tempBalance) {
      inValidAmount = true;
    }
    let amountUsedFormatted = '$0';
    if (amountUsed !== '' && !isNaN(amountUsed)) {
      const numFormat = Intl.NumberFormat('en-US');
      amountUsedFormatted = numFormat.format(amountUsed);
    }
    this.setState({ inValidAmount, amountUsed: `$${amountUsedFormatted}` });
    return remainingBalance;
  };

  purchaseTicker = (e, i, balance) => {
    let { value: usedBalance } = e && e.target;
    const { selectedInvReturnOption } = this.state;
    let tempUsedBalance = usedBalance;
    if (tempUsedBalance.toString().includes('$')) {
      let currencyFieldValue = tempUsedBalance.split('$')[1];
      currencyFieldValue = currencyFieldValue.replace(/,/g, '');
      if (
        currencyFieldValue !== '' &&
        !isNaN(currencyFieldValue) &&
        parseFloat(currencyFieldValue) >= 0
      ) {
        tempUsedBalance = `$${currencyFieldValue}`;
      }
    } else if (
      tempUsedBalance &&
      !isNaN(tempUsedBalance) &&
      parseFloat(tempUsedBalance) >= 0
    ) {
      tempUsedBalance = `$${tempUsedBalance}`;
    } else {
      tempUsedBalance = `$0`;
    }
    const { tickersAddedToPlan } = this.state;
    let tempTickersAddedToPlan = [...tickersAddedToPlan];
    tempTickersAddedToPlan.forEach((f, index) => {
      if (i === index) {
        f.balance = tempUsedBalance;
      }
    });
    const remainingBalance = this.updateRemainingBalance(
      tempTickersAddedToPlan,
      balance
    );
    this.setState({
      remainingBalance,
      tickersAddedToPlan: tempTickersAddedToPlan,
      selectedTicker: '',
      selectedProductType: '',
      selectedInvReturnOption:
        tempTickersAddedToPlan.length > 0
          ? 'products'
          : selectedInvReturnOption,
    });
  };

  onTickerChange = (event, balance) => {
    let { value: selectedTicker } = event.target;
    const { tickersAddedToPlan, tickers } = this.state;
    let tempTickersAddedToPlan = [...tickersAddedToPlan];
    const tickerInfo = tickers.filter((t) => t.ticker === selectedTicker);
    if (tickerInfo && tickerInfo.length > 0) {
      if (
        tempTickersAddedToPlan.indexOf(
          tempTickersAddedToPlan.filter((t) => t.ticker === selectedTicker)[0]
        ) === -1
      ) {
        tempTickersAddedToPlan.unshift({
          ticker: tickerInfo[0].ticker,
          desc: tickerInfo[0].desc,
          balance: '',
        });
      }
    }
    const remainingBalance = this.updateRemainingBalance(
      tempTickersAddedToPlan,
      balance
    );
    this.setState({
      selectedTicker,
      tickersAddedToPlan: tempTickersAddedToPlan,
      remainingBalance,
    });
  };

  onProductTypeChange = (event) => {
    let { value: selectedProductType } = event.target;
    const { investmentProducts } = this.props;
    const selectedProductTickersInfo = investmentProducts.filter(
      (i) => i.productType === selectedProductType
    );
    let tickers = [];
    if (selectedProductTickersInfo && selectedProductTickersInfo.length > 0) {
      tickers = selectedProductTickersInfo[0].tickers;
    }
    this.setState({ selectedProductType, tickers });
  };

  deleteTicker = (e, i, balance) => {
    const { tickersAddedToPlan } = this.state;
    let tempTickersAddedToPlan = [...tickersAddedToPlan];
    if (tempTickersAddedToPlan && tempTickersAddedToPlan.length > 0) {
      tempTickersAddedToPlan.splice(i, 1);
    }
    const remainingBalance = this.updateRemainingBalance(
      tempTickersAddedToPlan,
      balance
    );
    this.setState({
      tickersAddedToPlan: tempTickersAddedToPlan,
      remainingBalance,
      selectedTicker: '',
      selectedProductType: '',
      selectedInvReturnOption:
        tempTickersAddedToPlan.length > 0 ? 'products' : 'generic',
    });
  };

  transformBackToAPIType = (value) => {
    if (value) {
      let numVal = value;
      if (isNaN(numVal) && numVal.toString().includes('$')) {
        numVal = parseFloat(numVal.split('$')[1].replace(/,/g, ''));
      }
      return numVal;
    } else if (value === 0 || value === '') {
      return 0;
    }
  };

  fnToRemoveCommaAndParse = (value) => {
    let valueWithComma = '';
    if (isNaN(value) && value.toString().includes('$')) {
      valueWithComma = value.split('$')[1];
    } else if (!isNaN(value)) {
      valueWithComma = value;
    } else {
      valueWithComma = '';
    }
    const valueWithoutComma = valueWithComma.replace(/,/g, '');
    return !isNaN(valueWithoutComma) && parseFloat(valueWithoutComma);
  };

  onInvBucketChange = (event) => {
    let { value } = event.target;
    const { selectedInvestmentAccount = {}, newNickName = '' } = this.state;
    const selectedAccountInfo = investmentFields.filter(
      (f) => f.id.toLowerCase() === value.toLowerCase()
    );
    let selectedInvestmentBucket = '';
    if (selectedAccountInfo && selectedAccountInfo.length > 0) {
      selectedInvestmentBucket = selectedAccountInfo[0].id;
    }
    this.setState({
      selectedInvestmentBucket,
      oldInvestmentBucket: selectedInvestmentAccount?.investmentBucket || '',
      selectedInvestmentAccount: {
        ...selectedInvestmentAccount,
        investmentBucket: selectedInvestmentBucket,
        id:
          newNickName !== ''
            ? `${newNickName}-${selectedInvestmentBucket}`
            : '',
      },
    });
  };

  renderInvestmentBuckets = () => {
    const { selectedInvestmentBucket } = this.state;
    return (
      <div className="row align-items-center">
        <div className="col-lg-12">
          <Controls.Select
            className="js-example-basic-single"
            name="Account"
            required
            label={'Investment Type'}
            maxMenuHeight={150}
            id="Account"
            value={selectedInvestmentBucket || ''}
            onChange={(e) => this.onInvBucketChange(e)}
            options={investmentFields.map((p, index) => {
              return { label: p.title, value: p.id, index: index };
            })}
          />
        </div>
      </div>
    );
  };

  renderAssetMixForm = (from) => {
    const {
      assetMixListForInv,
      selectedMember,
      selectedYesEvent,
      isAssetMixPercentNotEqualTo100,
      selectedProductType,
      selectedTicker,
      tickers,
      tickersAddedToPlan,
      remainingBalance,
      sumOfAssetMixValue,
      selectedInvReturnOption,
      accBalance,
      inValidAmount,
      amountUsed,
      isButtonDisabled,
      buttonDisabledMsg,
      newNickName,
      selectedInvestmentAccount,
      availableInvestmentAccounts,
      selectedInvestmentBucket,
    } = this.state;
    const {
      customVol = '0.00%',
      customReturn = '0.00%',
      customDivRate = '0.00%',
    } = selectedInvestmentAccount || {};
    const { members: selectedMemberObj = [], investmentProducts } = this.props;
    const memberInfo =
      selectedMember &&
      selectedMemberObj.length > 0 &&
      selectedMemberObj.filter(
        (f) => f.key.toLowerCase() === selectedMember.toLowerCase()
      )[0];
    const { title = '' } = memberInfo || {};
    const selectedInvDetailLabel = investmentFields.find(
      (invDetail) =>
        invDetail.id.toLowerCase() === selectedInvestmentBucket.toLowerCase()
    );
    const currentAcc = selectedInvestmentAccount;
    const formattedBalance = isNaN(accBalance)
      ? accBalance
      : parseFloat(accBalance).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
        });
    let availableBalance = accBalance;
    if (tickersAddedToPlan && tickersAddedToPlan.length > 0) {
      availableBalance = remainingBalance;
    }
    availableBalance =
      availableBalance !== '' && isNaN(availableBalance)
        ? this.fnToRemoveCommaAndParse(availableBalance)
        : availableBalance;
    const investmentLabelText = (
      <>
        Enter Asset Profile and Asset Mix for (Risk/Return characteristics){' '}
        <b>{(title && title.toUpperCase()) || ''}</b>'s{' '}
        {selectedInvDetailLabel?.title} Account{' '}
        <b> {(currentAcc && currentAcc.nickName) || ''}</b> with balance{' '}
        <b> {formattedBalance}</b> of type <b>{selectedInvestmentBucket}</b>:
      </>
    );
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={6} md={4}>
            <div className="insured_main_outer">
              {this.renderInputForm(from, currentAcc)}
            </div>
          </Grid>
          <Grid item xs={6} md={4}>
            <div className="insured_main_outer">
              {this.renderInvestmentBuckets()}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6} md={4}>
            {availableInvestmentAccounts &&
            availableInvestmentAccounts.length > 0 &&
            from ? (
              <div className="insured_main_outer">
                <Controls.Input value={formattedBalance} disabled={true} />
              </div>
            ) : (
              <div className="insured_main_outer">
                {this.renderInputFormOnBlur()}
              </div>
            )}
          </Grid>
        </Grid>
        {inValidAmount && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
                <Alert severity="warning">
                  <p>
                    Account has Products with {amountUsed}, if you need to
                    reduce the account value to below {amountUsed}, then please
                    adjust the below details{' '}
                  </p>
                </Alert>
              </Stack>
            </Grid>
          </Grid>
        )}
        {isButtonDisabled && buttonDisabledMsg && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
                <Alert severity="error">
                  <p>{buttonDisabledMsg}</p>
                </Alert>
              </Stack>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p className="select-bank-text">{investmentLabelText}</p>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="chart_filter">
              <ul className="tabs">
                {invReturnOptions.map((m) => {
                  return (
                    <li
                      className={
                        selectedInvReturnOption === m.id ? 'tab active' : 'tab'
                      }
                      disabled={
                        m.id !== 'products' && tickersAddedToPlan.length > 0
                      }
                    >
                      <button
                        onClick={() => this.setSelectedInvReturnOption(m)}
                        disabled={
                          m.id !== 'products' && tickersAddedToPlan.length > 0
                        }
                      >
                        {m.title}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {selectedInvReturnOption === 'products' && (
            <>
              <Grid item xs={12}>
                <div className="select_product_main_outer">
                  <div className="select_product_dropdown_outer">
                    <div className="select_product_title">
                      <h5 className="mb-0 title-div">Select Product</h5>
                    </div>
                    <Controls.Select
                      className="js-example-basic-single"
                      name="select_product"
                      id="select_product"
                      value={selectedProductType}
                      onChange={this.onProductTypeChange}
                      options={investmentProducts.map((t) => {
                        return { label: t.productType, value: t.productType };
                      })}
                    />
                  </div>
                  <div className="select_product_dropdown_outer">
                    <div className="select_product_title">
                      <h5 className="mb-0 title-div">Add Ticker</h5>
                    </div>
                    <Controls.Select
                      className="js-example-basic-single"
                      name="select_ticker"
                      id="select_ticker"
                      value={selectedTicker}
                      options={tickers.map((t) => {
                        return { label: t.ticker, value: t.ticker };
                      })}
                      onChange={(e) => this.onTickerChange(e, accBalance)}
                    />
                  </div>
                </div>
                <div className="select_product_table_main_outer">
                  {tickersAddedToPlan &&
                    tickersAddedToPlan.length > 0 &&
                    tickersAddedToPlan.map((ticker, index) => {
                      return (
                        <div className="select_product_table_inner">
                          <div className="select_product_table_content">
                            <p className="value">{ticker.ticker}</p>
                            <p>{ticker.desc}</p>
                          </div>
                          <div className="select_product_table_amount">
                            <Controls.InputWithBlur
                              id={`tickerAmount-${index}`}
                              placeholder="Amount $"
                              value={ticker.balance || ''}
                              onChange={(e) => {
                                this.purchaseTicker(e, index, accBalance);
                              }}
                              onBlur={(event) => {
                                this.handleOnBlurTickers(event, index);
                              }}
                            />
                          </div>
                          <div className="select_product_table_delete">
                            <button>
                              <img
                                src={delete_icon}
                                title=""
                                alt=""
                                onClick={(e) =>
                                  this.deleteTicker(e, index, accBalance)
                                }
                              />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  <div className="select_product_table_inner">
                    <div className="select_product_table_content">
                      {/* <p className="value">&nbsp;</p> */}
                      <p>Cash Left</p>
                    </div>
                    <div className="select_product_table_amount">
                      <input
                        type="text"
                        id="remaining_Balance"
                        name="Remaining Balance"
                        value={this.transformValue(availableBalance)}
                        disabled
                      />
                    </div>
                    {/* <div className="select_product_table_delete">
                      <button>
                        <img src={add_green} title="" alt="" />
                      </button>
                    </div> */}
                  </div>
                </div>
              </Grid>
              {availableBalance < 0 && (
                <Grid item xs={6}>
                  <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
                    <Alert severity="error">
                      <p>Insufficient Cash</p>
                    </Alert>
                  </Stack>
                </Grid>
              )}
            </>
          )}
          <div className="asset-mix-input-outer">
            {selectedInvReturnOption !== 'products' && (
              <Grid item xs={12}>
                <div className="asset-mix-input">
                  {assetMixListForInv.map((list) => (
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        className="planner-label"
                        style={{ padding: '10px 0' }}
                      >
                        {list.label}
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: '10px' }}>
                        <input
                          className="planner-input"
                          name={list.name}
                          value={list.value || ''}
                          label={list.label}
                          onChange={(event) =>
                            this.setAssetMixValue(event, list.name)
                          }
                          onBlur={(event) => this.handleBlur(event, list.name)}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </Grid>
            )}

            {selectedInvReturnOption !== 'products' &&
              isAssetMixPercentNotEqualTo100 && (
                <Grid item xs={12}>
                  <Stack sx={{ width: '100%', padding: '2px' }}>
                    <Alert severity="error">
                      <p>
                        Asset mix should add upto 100%, currently it is at{' '}
                        {sumOfAssetMixValue}%{' '}
                      </p>
                    </Alert>
                  </Stack>
                </Grid>
              )}
          </div>

          {selectedInvReturnOption === 'custom' && (
            <Grid item>
              <>
                <Grid container xs={12} md={6}>
                  <Grid
                    item
                    xs={6}
                    className="planner-label"
                    style={{ padding: '10px 0' }}
                  >
                    Custom Return(%)
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: '10px' }}>
                    <input
                      label="Custom Return"
                      className="planner-input"
                      type="text"
                      value={customReturn}
                      onChange={(event) =>
                        this.setSelectedInvReturnValue(event, 'customReturn')
                      }
                      onBlur={(event) => this.handleBlur(event, 'customReturn')}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className="planner-label"
                    style={{ padding: '10px 0' }}
                  >
                    Custom Volatality(%)
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: '10px' }}>
                    <input
                      label="Custom Volatality"
                      className="planner-input"
                      type="text"
                      value={customVol}
                      onChange={(event) =>
                        this.setSelectedInvReturnValue(event, 'customVol')
                      }
                      onBlur={(event) => this.handleBlur(event, 'customVol')}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className="planner-label"
                    style={{ padding: '10px 0' }}
                  >
                    Custom Dividend(%)
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: '10px' }}>
                    <input
                      label="Custom Dividend"
                      className="planner-input"
                      type="text"
                      value={customDivRate}
                      onChange={(event) =>
                        this.setSelectedInvReturnValue(event, 'customDivRate')
                      }
                      onBlur={(event) =>
                        this.handleBlur(event, 'customDivRate')
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  spacing={2}
                  style={{ marginTop: '10px' }}
                >
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      <p className="select-bank-text">
                        Please make sure to enter Custom Return, Custom
                        Volatality and Custom Dividend Rate
                      </p>
                    </Alert>
                  </Grid>
                </Grid>
              </>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} className={`${'save_button'}`}>
            <div className="plaid_save_button_group">
              <button
                onClick={(e) => {
                  selectedYesEvent
                    ? this.setNextStepIndex1(e)
                    : this.setNextStepNoIndex2(e, 'cancel');
                }}
              >
                Cancel
              </button>

              <button
                className={
                  (isAssetMixPercentNotEqualTo100 &&
                    selectedInvReturnOption !== 'products') ||
                  availableBalance < 0 ||
                  !newNickName ||
                  !selectedInvestmentBucket
                    ? 'secondary-button'
                    : ''
                }
                onClick={this.updateAssetMix}
                disabled={
                  (isAssetMixPercentNotEqualTo100 &&
                    selectedInvReturnOption !== 'products') ||
                  availableBalance < 0 ||
                  !newNickName ||
                  !selectedInvestmentBucket
                }
              >
                Update
              </button>
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  renderBalanceOptions = () => {
    const { updateInvValueAsOfInStore, invValueAsOf } = this.props;
    return (
      <Grid container className="user-credentials-form">
        <p className="intro-text">
          Balances that you will be entering will be as of:
        </p>
        <Grid container>
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={(invValueAsOf && invValueAsOf.toLowerCase()) || 'now'}
            onChange={(event) => updateInvValueAsOfInStore(event)}
            items={valueAsofNowList}
          />
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            className="simulate-buttons next_previous_btn_group"
          >
            <Button
              onClick={(e) => this.setNextStepNoIndex2(e)}
              variant="contained"
              className="next_button"
              style={{
                padding: '7.5px 15px',
                color: '#fff',
                fontWeight: 700,
                fontSize: '12px',
                marginRight: '10px',
                backgroundColor: '#009fe3',
              }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  clearProcessedData = () => {
    this.setState({ plaidAccountProcessedInfo: [], plaidAccTabValue: 0 });
  };

  render() {
    const { stepIndex, selectedYesEvent, stepNoIndex, plaidLinkToken } =
      this.state;
    const {
      setPlaidLinkSuccessAction,
      setPlaidLinkAccessToken,
      plaidLinkSuccess,
    } = this.props;
    return (
      <div className="plaid-container">
        <>
          {(stepNoIndex === 1 || stepIndex === 1) && (
            <div className="chart_filter">
              <ul className="tabs">
                <li className={selectedYesEvent ? 'tab active' : 'tab'}>
                  <PlaidLinkFn
                    linkToken={plaidLinkToken}
                    setPlaidLinkSuccessAction={setPlaidLinkSuccessAction}
                    setPlaidLinkAccessToken={setPlaidLinkAccessToken}
                    clearProcessedData={this.clearProcessedData}
                    setToPlaid={this.setToPlaid}
                  />
                </li>
                <li className={!selectedYesEvent ? 'tab active' : 'tab'}>
                  <Button
                    onClick={this.selectedNoEvent}
                    style={{
                      fontWeight: 700,
                      fontSize: '12px',
                    }}
                  >
                    MANUAL
                  </Button>
                </li>
              </ul>
            </div>
          )}
        </>
        {selectedYesEvent && (
          <>
            {stepIndex === 1 && this.renderPlaidAccountsInfo()}
            {stepIndex === 2 && this.renderAssetMixForm('plaid')}
          </>
        )}
        {!selectedYesEvent && (
          <>
            {stepNoIndex === 1 && this.renderBalanceOptions()}
            {stepNoIndex === 2 && this.renderInvestmentForm()}
            {stepNoIndex === 3 && this.renderAssetMixForm()}
          </>
        )}
      </div>
    );
  }
}

export default PlaidUI;
