import { all } from 'redux-saga/effects';
import { watchUserDetails } from './userSaga';
import { watchClientsByAdvisor } from './clientsInfoSaga';
import { watchPlansByClient } from './plansSaga';
import { watchCompaniesInfo } from './companiesInfoSaga';
import { watchPlannerDetailsByPlanID } from './plannerDetailsSaga';
import { watchOutputResponseByPlanId } from './outputResponseDetailsSaga';
import { watchClientReportByReportId } from './reportsSaga';
import { watchBusinessInfoByAdvisor } from './businessInfoSaga';

export default function* rootSaga() {
  yield all([
    watchUserDetails(),
    watchClientsByAdvisor(),
    watchPlansByClient(),
    watchCompaniesInfo(),
    watchPlannerDetailsByPlanID(),
    watchOutputResponseByPlanId(),
    watchClientReportByReportId(),
    watchBusinessInfoByAdvisor(),
  ]);
}
