import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import ChartContainer from '../ChartContainer';

const AntSwitch = styled(Switch)(({ theme, dual }) => ({
  width: '28px  !important',
  height: '16px  !important',
  padding: '0px  !important',
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: '12px  !important',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    color: '#fff',
    padding: '2px  !important',
    position: 'absolute !important',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#6ccea6' : '#225ea8',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: '12px  !important',
    height: '12px  !important',
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : dual
        ? '#225ea8'
        : 'grey',
    boxSizing: 'border-box',
  },
}));

const AssetsFirstRowSection = ({
  isCompanyAssetsLoading,
  aumDistributionChartOptions,
  clientTypeDistributionOptions,
  allClientAgeStats,
  ageDistributionOptions,
  toggleAllClientAgeStats,
  companyAUM,
  advisorAssetsUnderManagement: { aum = '' },
  transformValue,
  setActiveCardState,
}) => (
  <>
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mobile_card justify-content-between">
      <div
        className={`card home_main_card_outer one mb-4 active`}
        onClick={() => setActiveCardState('assets')}
      >
        <div className="card-body assets_under_management_inner">
          <div className="Under_Management d-md-flex">
            {isCompanyAssetsLoading ? (
              <CircularProgress
                sx={{
                  color: '#fff',
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                }}
                size={20}
              />
            ) : (
              <div>
                <div className="Under_Management_content d-md-flex">
                  <div className="Under_Management_content_left">
                    <p>Company AUM</p>
                    <h4>
                      <span className="value">
                        {(companyAUM && transformValue(companyAUM)) || '$0'}
                      </span>
                    </h4>
                  </div>
                  {/* <div className="Under_Management_content_right">
                      <p>Company AUM</p>
                      <h4>
                        <span className="value">
                          {(companyAUM && transformValue(companyAUM)) || '$0'}
                        </span>
                      </h4>
                    </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
      <div className="card h-100">
        <div className="card-body">
          <h5>AUM Distribution</h5>
          <div className="chart_div">
            {isCompanyAssetsLoading ? (
              <CircularProgress
                sx={{
                  color: '#479573',
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                }}
                size={20}
              />
            ) : (
              <ChartContainer
                chartType="variablepie"
                selectedChart={'aumDistribution'}
                options={aumDistributionChartOptions}
              />
            )}
          </div>
        </div>
      </div>
    </div>
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
      <div className="card h-100">
        <div className="card-body">
          <div className="h-100">
            <h5>Client TYPE DISTRIBUTION</h5>
            {isCompanyAssetsLoading ? (
              <CircularProgress
                sx={{
                  color: '#479573',
                  position: 'relative',
                  left: '45%',
                  top: '50%',
                }}
                size={20}
              />
            ) : (
              <ChartContainer
                chartType="radial"
                selectedChart={'clientTypeDistribution'}
                options={clientTypeDistributionOptions}
              />
            )}
          </div>
        </div>
      </div>
    </div>
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
      <div className="card h-100 mb-0">
        <div className="card-body">
          <div className="client_age_distribution_outer">
            <h5>Client age distribution (%)</h5>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <span>All Clients</span>
              <AntSwitch
                sx={{ mr: 1, ml: 1 }}
                inputProps={{ 'aria-label': 'ant design' }}
                checked={!allClientAgeStats}
                onChange={toggleAllClientAgeStats}
                dual={true}
              />
              <span>In-Detail</span>
            </div>
            {isCompanyAssetsLoading ? (
              <CircularProgress
                sx={{
                  color: '#479573',
                  position: 'absolute',
                  left: '45%',
                  top: '50%',
                }}
                size={20}
              />
            ) : (
              <ChartContainer
                chartType="spider"
                selectedChart={'ageDistribution'}
                options={ageDistributionOptions}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  </>
);

export default AssetsFirstRowSection;
