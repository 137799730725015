import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from 'react-redux';
import { getLoggedInUserDetails } from '../selectors/loginUserSelector';
import BarChartIcon from '@mui/icons-material/BarChart';
import { PropertyNamesMap } from '../constants/PropertyNames';
import DescriptionIcon from '@mui/icons-material/Description';
import VerticalTabs from '../components/verticalTabs';
import {
  plannerDetailsLoadingSelector,
  plannerDetailsSaveStatus,
  planDetailsByPlanIdSelector,
  expenseFactorDetailsSelector,
  plannerDetailsSimulateStatus,
  termLifeInsuranceProductsSelector,
  wholeLifeInsuranceProductsSelector,
  longTermCareInsuranceProductsSelector,
  umbrellaInsuranceProductsSelector,
  propertyInsuranceProductsSelector,
  autoInsuranceProductsSelector,
  selectedDisplayTermForClientIdSelector,
  selectedHorizonTermForClientIdSelector,
  selectedPlanIdForClientIdSelector,
  simulatePlanDetailsLoadingSelector,
  getPushPinCharts,
  selectedChartSelector,
  beforeAfterAssetMixValuesSelector,
  assetAllocationByProductIdLoadingSelector,
  getAssetAllocationByProductIdSelector,
  getRecommendedAssetAllocationByClientIdSelector,
  getInvestmentProductsByClientIdSelector,
} from '../selectors/plannerDetailsSelector';
import {
  getAssetAllocListByClientIdAction,
  setAssetAllocListByClientIdAction,
} from '../redux/actions/clientsInfoAction';
import { selectedClientIdSelector } from '../selectors/clientsInfoSelector';
import {
  outputResponseLoadingSelector,
  outputResponseChartsByPlanIdSelector,
  outputResponseTablesByPlanIdSelector,
  outputResponseDataByPlanIdSelector,
  outputResponseSolutionsByPlanIdSelector,
  outputResponsePlannerDetailsByPlanIdSelector,
} from '../selectors/outputResponseDetailsSelector';
import {
  setSelectedChartAction,
  setSelectedTableAction,
  setSelectedDataAction,
  pushPinCharts,
} from '../redux/actions/plansAction';
import {
  newSolutionsList,
  assetMixListValues,
  investmentFields,
  assetMixValuesTemplate,
} from '../constants/dashboard';
import HorizonDetails from './shared/HorizonDetails';
import TabsComponent from './shared/TabsComponent';
import { isEqual } from 'lodash';
import { Grid, FormControlLabel, FormGroup } from '@material-ui/core';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SolutionsJourney from './SolutionsJourney';
import {
  simulatePlanDetailsByPlanIdSuccessAction,
  termLifeInsuranceProductsSuccessAction,
  wholeLifeInsuranceProductsSuccessAction,
  longTermCareInsuranceProductsSuccessAction,
  umbrellaInsuranceProductsSuccessAction,
  propertyInsuranceProductsSuccessAction,
  autoInsuranceProductsSuccessAction,
  simulatePlanDetailsByPlanId,
  getPlansDetailsAction,
  setBeforeAfterAssetMixAction,
  saveAndUpdatePlanDetailsByPlanId,
  getAssetAllocationByProductId,
  getRecommendedAssetAllocListByClientId,
  getInvestmentProductsByClientIdAction,
} from '../redux/actions/plannerDetailsAction';
import { getInfoExpenseStoreValuesV2 } from '../redux/transformers/apiToClientResponseTransformer';
import CloseIcon from '@mui/icons-material/Close';
import NewChartsContainer from './NewChartsContainer';
import Controls from './controls/Controls';
import { Button } from '@mui/material';
import {
  getWholeLifeInsuranceProducts,
  getTermLifeInsuranceProducts,
  getUmbrellaInsuranceProducts,
  getLongTermCareInsuranceProducts,
  getPropertyInsuranceProducts,
  getAutoInsuranceProducts,
} from '../services/dashboardSvc';
import history from '../history';
import Simulate_icon_1 from '../images/Simulate_icon_1.svg';
import WholeLifeContent from './WholeLifeContent';
import { LongTermCareContent } from './LongTermCareContent';
import { UmbrellaLifeContent } from './UmbrellaContent';
import { TermLifeContent } from './TermLifeContent';
import { PropertyContent } from './PropertyComponent';
import { AutoContent } from './AutoComponent';
import CustomChart from './shared/CustomChartComponent';
import CustomTable from './shared/CustomTableComponent';
import CustomData from './shared/CustomDataComponent';
import { generateTableHTML } from '../utils/renderHtmlUtil';
import Simulate_icon_2 from '../images/Simulate_icon_2.svg';
import Save_icon from '../images/save.svg';
import delete_icon from '../images/delete_icon.svg';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { validateFormDetails } from '../helpers/ClientHelper';
import { Checkbox } from '@material-ui/core';

const _ = require('lodash');

const COLUMN_CONFIG = [4, 4, 4];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const dataInfoColumns = [
  {
    headerName: 'Label',
    field: 'label',
    width: '150',
  },
  {
    headerName: 'Value',
    field: 'value',
    width: '100',
  },
];

class NewSolutions extends Component {
  state = {
    showLeftNav: false,
    showRightNav: true,
    solutionsParentTab: '',
    solutionsSubTab: 0,
    parentTabValue: 0,
    subTabValue: 0,
    solutionsTabs: [],
    chartsMenu: [],
    tabInfo: [],
    selectedAccount: '',
    selectedMember: '',
    selectedPropertyMember: '',
    selectedAutoMember: '',
    investmentTypeName: '',
    memberOptions: [],
    memberPropertyRadioOptions: [],
    memberAutoRadioOptions: [],
    existingInsurances: [],
    selectionModel: {},
    pushPinLabel: 'Pin it',
    isComputed: false,
    renderType: 'Custom',
    selectedAllocationInfo: {},
    selectedRecommendedAssetAllocId: '',
    localPlanDetailsByPlanId: {},
    selectedMemberInvestmentTypeInfo: {},
    assetMixBuildInfo: [],
    targetAssetMixBuildInfo: [],
    selectedProductType: '',
    tickers: [],
    selectedTicker: '',
    tickersAddedToPlan: [],
    tickersAvailableInPlan: [],
    investmentAccounts: [],
    remainingBalance: '',
    formDetails: {
      termLifeIns: { insured: '', name: '', coverageStartYr: new Date() },
      wholeLifeIns: { insured: '', name: '', coverageStartYr: new Date() },
      ltcIns: { insured: '', name: '', coverageStartYr: new Date() },
      umbrellaIns: {
        insured: 'family',
        name: '',
        coverageStartYr: new Date(),
      },
      autoIns: {
        insured: '',
        name: '',
        coverageStartYr: new Date(),
      },
      propIns: {
        insured: '',
        name: '',
        coverageStartYr: new Date(),
      },
    },
    isEditable: false,
    isAddNew: false,
    deleteInsurance: false,
    isLastStep: false,
    isProductsAlterted: false,
    isSimulateOnInvestmentsClicked: false,
    isMC: false,
    noChangeLoader: false,
    currentIndex: 0,
    selectedDDNameIndex: 0,
    isFormValid: true,
    formErrors: [],
    availableInvestmentAccounts: [],
    selectedInvestmentAccount: {},
    selectedInvestmentBucket: '',
    selectedMemberOption: '',
    isOptimizedClicked: false,
    isRiskLevelAdaptive: false,
    isSimulateClicked: false, //used in goals to clear out the selected goal which is used to grey out the goal until clicked on simulate
  };

  // Mobile scroll
  carouselContainerRef = React.createRef();

  showNextItem = () => {
    this.setState({
      showLeftNav: true,
      showRightNav: false,
    });
    const { currentIndex } = this.state;
    if (currentIndex < 1) {
      this.setState({ currentIndex: currentIndex + 1 }, () => {
        this.scrollToIndex(this.state.currentIndex);
      });
    }
  };

  showPreviousItem = () => {
    this.setState({
      showRightNav: true,
      showLeftNav: false,
    });
    const { currentIndex } = this.state;
    if (currentIndex > 0) {
      this.setState({ currentIndex: currentIndex - 1 }, () => {
        this.scrollToIndex(this.state.currentIndex);
      });
    }
  };

  scrollToIndex = (index) => {
    const carouselContainer = this.carouselContainerRef.current;
    if (carouselContainer) {
      carouselContainer.scrollTo({
        left: carouselContainer.offsetWidth * index,
        behavior: 'smooth',
      });
    }
  };

  noChangeLoaderFn = () => {
    this.setState({ noChangeLoader: true });
    this.timer = setTimeout(() => {
      this.setState({ noChangeLoader: false });
    }, 3000);
  };

  getSelectedPlanIdDetails = () => {
    const { selectedPlan: planId, getSavedSelectedPlanIdDetails } = this.props;
    const version = 'v2';
    if (planId && planId !== '') {
      getSavedSelectedPlanIdDetails({ planId, version });
    }
  };

  handleSimulate = (isMC) => {
    const { planDetailsByPlanId, triggerSimulatePlanDetailsByPlanId } =
      this.props;
    const { localPlanDetailsByPlanId } = this.state;
    let tempPlanDetailsByPlanId =
      Object.keys(localPlanDetailsByPlanId).length > 0
        ? { ...localPlanDetailsByPlanId }
        : { ...planDetailsByPlanId };
    const { moduleSettings: { model = {} } = {}, moduleSettings = {} } =
      tempPlanDetailsByPlanId;
    let tempModel = {};
    const runCode = this.getRunCode(isMC);
    tempModel = { ...model, runCode: runCode };
    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      moduleSettings: {
        ...moduleSettings,
        model: { ...tempModel },
      },
    };
    this.setState({ isMC, isOptimizedClicked: isMC, isSimulateClicked: true });
    triggerSimulatePlanDetailsByPlanId({
      displayTermChanged: false,
      chrono: {},
      plannerFormReq: tempPlanDetailsByPlanId,
      version: 'v2',
    });
  };

  getRunCode = (isMC, onload) => {
    const { solutionsParentTab } = this.state;
    let runCode = 0;
    switch (solutionsParentTab) {
      case 0:
        runCode = isMC ? 501 : onload ? 0 : 500;
        break;
      case 1:
        runCode = isMC ? 401 : 400;
        break;
      case 2:
        runCode = isMC ? 901 : 900;
        break;
      case 3:
        runCode = isMC ? 601 : 600;
        break;
      case 4:
        runCode = isMC ? 701 : 700;
        break;
      case 5:
        runCode = isMC ? 801 : 800;
        break;
      default:
        runCode = isMC ? 100 : 0;
        break;
    }
    return runCode;
  };

  preparePlanDetailsAndSimulate = () => {
    const { solutionsParentTab } = this.state;
    const { planDetailsByPlanId, triggerSimulatePlanDetailsByPlanId } =
      this.props;
    const { moduleSettings: { model = {} } = {}, moduleSettings = {} } =
      planDetailsByPlanId;
    const runCode = this.getRunCode(undefined, 'onload');
    let tempModel = { ...model, runCode: runCode };
    const tempPlanDetailsByPlanId = {
      ...planDetailsByPlanId,
      moduleSettings: { ...moduleSettings, model: { ...tempModel } },
    };
    if (
      solutionsParentTab !== 4 &&
      solutionsParentTab !== 5 &&
      solutionsParentTab !== 0 &&
      solutionsParentTab !== 6
    ) {
      //remove this condition once Annuities, Goals and Debt is implemented
      triggerSimulatePlanDetailsByPlanId({
        displayTermChanged: false,
        chrono: {},
        plannerFormReq: tempPlanDetailsByPlanId,
        version: 'v2',
      });
      this.setState({
        isSimulateOnInvestmentsClicked: false,
        isOptimizedClicked: solutionsParentTab === 1 ? true : false,
      });
    }
  };

  loadDefaultsAndCallSimulateOnLoad = () => {
    const { memberOptions, solutionsParentTab } = this.state;
    if (memberOptions.length === 0) {
      this.getMemberOptions(); //load radio and check boxes
    }
    //2, 4 and 5 can be removed from down here when we are implementing it, however 0 should be here as we don't need simulate on load in investments
    if (
      solutionsParentTab && // empty or 0 for investments
      // solutionsParentTab !== 2 && //goals
      solutionsParentTab !== 6 && //temp goals, remove this after implementing goals
      solutionsParentTab !== 4 && //annuities
      solutionsParentTab !== 5 // debt
    ) {
      //this will execute for Expenses, Insurances for now
      this.preparePlanDetailsAndSimulate();
    } else {
      // this is temporary till debts and annuities is implemented
      if (solutionsParentTab === 4 || solutionsParentTab === 5) {
        this.setState({
          // localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
          isSimulateOnInvestmentsClicked: false,
        });
      }
    }
  };

  componentWillUnmount() {
    const {
      triggerSetBeforeAfterAssetMixValues,
      triggerSetAssetAllocListByClientIdAction,
    } = this.props;
    triggerSetBeforeAfterAssetMixValues([]);
    triggerSetAssetAllocListByClientIdAction([]);
  }

  getParentTabValues = (tabName) => {
    switch (tabName && tabName.toLowerCase()) {
      case 'investments':
        return 0;
      case 'expenses':
        return 1;
      case 'goals':
        return 2;
      case 'insurance':
        return 3;
      case 'annuities':
        return 4;
      case 'debt':
        return 5;
      default:
        return 0;
    }
  };

  componentDidMount() {
    const { selectedClientId, selectedPlan, isV2 } = this.props;
    const queryParams = new URLSearchParams(this.props.location.search);
    let tab = queryParams.get('tab') || 'Investments'; // Default to tab 0 if no tab param
    let { state } = (history && history.location) || {};
    if (!selectedClientId && !selectedPlan) {
      history.push({ pathname: '/home' });
    }
    const selectedTab = state ? state : tab;
    const solutionsParentTab = this.getParentTabValues(selectedTab);
    this.setState({
      solutionsTabs: newSolutionsList,
      solutionsParentTab: solutionsParentTab || 0, // for time being until goals are implemented
      solutionsSubTab: 0,
    });
  }

  getTabNames = (tab) => {
    switch (tab) {
      case 0:
        return 'termLifeIns';
      case 1:
        return 'wholeLifeIns';
      case 2:
        return 'ltcIns';
      case 3:
        return 'umbrellaIns';
      case 4:
        return 'propIns';
      case 5:
        return 'autoIns';
      default:
        return 'termLifeIns';
    }
  };

  loadInsuranceTabInfo = () => {
    const {
      solutionsSubTab,
      formDetails,
      isComputed,
      localPlanDetailsByPlanId,
      memberOptions,
      selectedMember,
    } = this.state;
    let selectedInsuredInfo = {};
    switch (solutionsSubTab) {
      case 0:
        selectedInsuredInfo =
          formDetails &&
          formDetails['termLifeIns'] &&
          formDetails['termLifeIns'].insured !== ''
            ? formDetails['termLifeIns']
            : {};
        break;
      case 1:
        selectedInsuredInfo =
          formDetails &&
          formDetails['wholeLifeIns'] &&
          formDetails['wholeLifeIns'].insured !== ''
            ? formDetails['wholeLifeIns']
            : {};
        break;
      case 2:
        selectedInsuredInfo =
          formDetails &&
          formDetails['ltcIns'] &&
          formDetails['ltcIns'].insured !== ''
            ? formDetails['ltcIns']
            : {};
        break;
      case 3:
        selectedInsuredInfo =
          formDetails && formDetails['umbrellaIns']
            ? formDetails['umbrellaIns']
            : {};
        break;
      case 4:
        selectedInsuredInfo =
          formDetails && formDetails['propIns'] ? formDetails['propIns'] : {};
        break;
      case 5:
        selectedInsuredInfo =
          formDetails && formDetails['autoIns'] ? formDetails['autoIns'] : {};
        break;
      default:
        break;
    }
    let selectedMemberVal = selectedMember;
    if (formDetails.insured === 'family' && memberOptions.length > 0) {
      formDetails.insured = memberOptions[0].id;
      selectedMemberVal = memberOptions[0].id;
    }
    if (
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      memberOptions &&
      memberOptions.length > 0 &&
      Object.keys(selectedInsuredInfo).length > 0 &&
      !isComputed
    ) {
      this.getInsuranceProducts(solutionsSubTab);
      const tempFormDetails = this.updateFormDetails(
        selectedInsuredInfo,
        formDetails
      );
      this.setState({
        deleteInsurance: false,
        formDetails: tempFormDetails,
        renderType: 'Custom',
        selectedMember: selectedMemberVal,
        isMC: false,
      });
    }
  };

  loadAnnuitiesTabInfo = () => {
    this.setState({ renderType: 'Custom', isMC: false });
  };

  loadDebtTabInfo = () => {
    this.setState({ renderType: 'Custom', isMC: false });
  };

  loadGoalsTabInfo = () => {
    this.setState({ isLastStep: true, renderType: 'Custom', isMC: false });
  };

  loadExpensesTabInfo = () => {
    this.setState({
      renderType: 'Custom',
      isMC: false,
      isLastStep: true,
    });
  };

  setIsLastStep = (val) => {
    this.setState({ isLastStep: val });
  };

  loadInvestmentTabInfo = () => {
    const {
      triggerSelectInvestmentProductsByClientId,
      triggerGetRecommendedAssetAllocListByClientId,
      investmentProducts,
      planDetailsByPlanId,
      recommendedAssetAllocationValues,
    } = this.props;
    const { clientid } = planDetailsByPlanId;
    if (
      recommendedAssetAllocationValues &&
      recommendedAssetAllocationValues.length === 0
    ) {
      triggerGetRecommendedAssetAllocListByClientId(clientid); //loading risklevel recom dropdown
    }
    if (investmentProducts && investmentProducts.length === 0) {
      triggerSelectInvestmentProductsByClientId(clientid);
    }
    this.setState({
      renderType: 'Custom',
      isLastStep: true,
      isMC: false,
    });
  };

  loadTabSpecificInfo = () => {
    const { solutionsParentTab } = this.state;
    switch (solutionsParentTab) {
      case 0:
        this.loadInvestmentTabInfo();
        break;
      case 1:
        this.loadExpensesTabInfo();
        break;
      case 2:
        this.loadGoalsTabInfo();
        break;
      case 3:
        this.loadInsuranceTabInfo();
        break;
      case 4:
        this.loadAnnuitiesTabInfo();
        break;
      case 5:
        this.loadDebtTabInfo();
        break;
      case 6:
        this.loadGoalsTabInfo();
        break;
      default:
        break;
    }
  };

  loadCurrentTargetValuesInInvestments = () => {
    const {
      data,
      beforeAfterAssetMixValues,
      triggerSetBeforeAfterAssetMixValues,
    } = this.props;
    const { assetMixBuildInfo, targetAssetMixBuildInfo } = this.state;
    let beforeAfterValues = [];
    if (
      data &&
      data.length > 0 &&
      beforeAfterAssetMixValues.length === 0 &&
      assetMixBuildInfo.length > 0 &&
      data.filter((d) => d.tabName === 'Investments').length > 0
    ) {
      beforeAfterValues = assetMixBuildInfo.map((m) => {
        return {
          name: m.name,
          elements: m.elements.map((e) => {
            return {
              label: e.label,
              current: e.value,
            };
          }),
        };
      });
    }
    if (beforeAfterValues.length > 0 && targetAssetMixBuildInfo.length > 0) {
      beforeAfterValues = beforeAfterValues.map((m) => {
        const matchFound = targetAssetMixBuildInfo.find(
          (t) => t.name === m.name
        );
        return {
          name: m.name,
          elements:
            m.elements &&
            m.elements.length > 0 &&
            matchFound &&
            matchFound.elements &&
            matchFound.elements.length > 0
              ? m.elements.map((e) => {
                  let s = matchFound.elements.filter(
                    (y) => y.label.toLowerCase() === e.label.toLowerCase()
                  );
                  if (s && s.length > 0) {
                    return {
                      label: e.label,
                      current: e.current,
                      target: s[0].value,
                    };
                  }
                  return { label: e.label, current: e.current, target: '0.0%' };
                })
              : [],
        };
      });
      triggerSetBeforeAfterAssetMixValues(beforeAfterValues);
    }
  };

  setRecommendedRiskLevel = () => {
    const {
      recommendedAssetAllocationValues,
      triggerGetAssetAllocListByClientId,
    } = this.props;
    const { localPlanDetailsByPlanId } = this.state;
    const { moduleInvestments: { markets = {} } = {}, clientid } =
      localPlanDetailsByPlanId;
    const { recommendedRiskLevel, isRiskLevelAdaptive } = markets;
    const tempSelectedRecommendedAssetAlloc =
      recommendedAssetAllocationValues.filter(
        (f) => f.riskLevel === recommendedRiskLevel
      );
    let selectedRecommendedAssetAllocId = '';
    if (
      tempSelectedRecommendedAssetAlloc &&
      tempSelectedRecommendedAssetAlloc.length > 0
    ) {
      selectedRecommendedAssetAllocId =
        tempSelectedRecommendedAssetAlloc[0].value; //this is nthg but assetAllocId
    }
    //call selectAllocListByClientId" with riskLevel that is selected in "Recommended Risk Level" dropdown.
    const req = {
      clientid: clientid,
      recommendedRiskLevel: recommendedRiskLevel,
    };
    this.setState(
      {
        selectedRecommendedAssetAllocId,
        isRiskLevelAdaptive, // setting this property here
        selectedAllocationInfo: {},
      },
      () => {
        triggerGetAssetAllocListByClientId(req);
      }
    );
  };

  setAllocationIdBasedOnRiskLevel = () => {
    const { allocation } = this.props;
    const {
      isMC,
      assetAllocId: stateAssetAllocId,
      localPlanDetailsByPlanId,
    } = this.state;
    const {
      moduleInvestments: { markets = {} } = {},
      moduleInvestments = {},
      moduleSettings: { model = {} } = {},
      moduleSettings = {},
    } = localPlanDetailsByPlanId;
    const { assetAllocId } = markets;
    let tempMarkets = { ...markets };
    tempMarkets = this.updateProductsToPlan(markets);
    let tempSelectedAllocationInfo =
      allocation.filter((f) => f.value === stateAssetAllocId)[0] || {};
    const tempSelectedAllocation = allocation.filter(
      (f) => f.label?.toLowerCase() === 'advisor recommended'
    );
    if (
      Object.keys(tempSelectedAllocationInfo).length === 0 &&
      tempSelectedAllocation &&
      tempSelectedAllocation.length > 0
    ) {
      const advisorRecommendedOption = tempSelectedAllocation.filter(
        (f) => f.label.toLowerCase() === 'advisor recommended'
      );
      if (advisorRecommendedOption && advisorRecommendedOption.length > 0) {
        tempSelectedAllocationInfo = advisorRecommendedOption[0];
      } else {
        tempSelectedAllocationInfo = tempSelectedAllocation[0];
      }
    }
    tempMarkets = {
      ...tempMarkets,
      assetAllocId:
        Object.keys(tempSelectedAllocationInfo).length > 0
          ? tempSelectedAllocationInfo.value
          : assetAllocId,
    };
    const runCode = this.getRunCode(isMC);
    let tempModel = { ...model, runCode: runCode };
    const tempPlanDetailsByPlanId = {
      ...localPlanDetailsByPlanId,
      moduleInvestments: {
        ...moduleInvestments,
        markets: { ...tempMarkets },
      },
      moduleSettings: { ...moduleSettings, model: { ...tempModel } },
    };
    this.setState({
      selectedAllocationInfo: tempSelectedAllocationInfo,
      localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
    });
  };

  loadTickersAddedInPlan = () => {
    const { tickersAvailableInPlan = [] } = this.state;
    const { investmentProducts } = this.props;
    let availableProducts = [];
    investmentProducts.forEach((i) => {
      availableProducts = [...availableProducts, ...i.tickers];
    });
    const uniqueProducts = availableProducts.filter((obj, index) => {
      return (
        index === availableProducts.findIndex((o) => obj.ticker === o.ticker)
      );
    });
    const filteredArray = tickersAvailableInPlan.map((m) => {
      const matchFound = uniqueProducts.filter((f) => f.ticker === m.ticker);
      if (matchFound && matchFound.length > 0) {
        return {
          ticker: m.ticker,
          balance: this.transformValue(m.balance, 'currency'),
          desc: matchFound[0].desc,
        };
      } else {
        return {
          ticker: m.ticker,
          balance: this.transformValue(m.balance, 'currency'),
        };
      }
    });
    const tickersAddedToPlan = filteredArray.filter(
      (f) => f.ticker.toLowerCase() !== 'cash'
    );
    const remainingBalanceInfo = filteredArray.filter(
      (f) => f.ticker.toLowerCase() === 'cash'
    );
    let remainingBalance = '';
    if (remainingBalanceInfo && remainingBalanceInfo.length > 0) {
      remainingBalance = remainingBalanceInfo[0].balance;
    }
    this.setState({ tickersAddedToPlan, remainingBalance });
  };

  loadTickersAvailableInPlan = () => {
    const { investmentAccounts } = this.state;
    let selectedAccount = '';
    let tickersAvailableInPlan = [];
    if (investmentAccounts && investmentAccounts.length > 0) {
      selectedAccount = investmentAccounts[0].nickName; //on load account
      tickersAvailableInPlan = investmentAccounts[0].products || [];
    }
    this.setState({ selectedAccount, tickersAvailableInPlan });
  };

  updateProductsToPlan = (markets, from) => {
    const {
      memberOptions,
      selectedMember,
      tickersAddedToPlan,
      remainingBalance,
      selectedMemberOption,
      selectedInvestmentAccount,
      isRiskLevelAdaptive,
    } = this.state;
    const selectedMemberInfo = memberOptions.find(
      (m) => m.id === selectedMember
    );
    if (!selectedMemberInfo) {
      return markets;
    }
    if (
      selectedInvestmentAccount &&
      Object.keys(selectedInvestmentAccount).length > 0 &&
      markets &&
      Object.keys(markets).length > 0
    ) {
      const selectedMemberInvestmentTypeInfo =
        markets[selectedMemberOption] || {};
      const {
        nickName = '',
        invBalance = '$0',
        assetProfile = 'generic',
        products: availableProducts = [],
      } = selectedInvestmentAccount || {};
      const products = tickersAddedToPlan.map((m) => ({
        ticker: m.ticker,
        balance: this.transformBackToAPIType(m.balance),
      }));
      const cashObj = {
        ticker: 'Cash',
        balance: this.transformBackToAPIType(remainingBalance),
      };
      const updatedProducts = products.length > 0 ? [...products, cashObj] : [];
      const {
        investmentBucket = '',
        id = '',
        ...rest
      } = selectedInvestmentAccount || {};
      const tempSelectedInvestmentAccount = {
        ...rest,
        products: from ? updatedProducts : availableProducts,
        invBalance: this.transformBackToAPIType(invBalance),
        assetProfile: updatedProducts.length > 0 ? 'products' : assetProfile,
      };
      const accounts = markets[selectedMemberOption][investmentBucket] || [];
      const indexToBeUpdated = accounts.indexOf(
        accounts.filter(
          (f) => f.nickName.toLowerCase() === nickName.toLowerCase()
        )[0]
      );
      const updatedAccounts = [...accounts];
      if (indexToBeUpdated > -1) {
        updatedAccounts[indexToBeUpdated] = tempSelectedInvestmentAccount;
      }
      const updatedMarkets = {
        ...markets,
        isRiskLevelAdaptive,
        [selectedMemberOption]: {
          ...selectedMemberInvestmentTypeInfo,
          [investmentBucket]: updatedAccounts,
        },
      };
      return updatedMarkets;
    }
    return markets;
  };

  runSimulateForTargets = () => {
    const { triggerSimulatePlanDetailsByPlanId } = this.props;
    const { selectedAllocationInfo, isMC, localPlanDetailsByPlanId } =
      this.state;
    const {
      moduleInvestments: { markets = {} } = {},
      moduleInvestments = {},
      moduleSettings: { model = {} } = {},
      moduleSettings = {},
    } = localPlanDetailsByPlanId;
    let tempMarkets = { ...markets };
    tempMarkets = {
      ...tempMarkets,
      assetAllocId: selectedAllocationInfo.value,
    };
    const runCode = this.getRunCode(isMC);
    let tempModel = { ...model, runCode: runCode };
    const tempPlanDetailsByPlanId = {
      ...localPlanDetailsByPlanId,
      moduleInvestments: {
        ...moduleInvestments,
        markets: { ...tempMarkets },
      },
      moduleSettings: { ...moduleSettings, model: { ...tempModel } },
    };
    triggerSimulatePlanDetailsByPlanId({
      displayTermChanged: false,
      chrono: {},
      plannerFormReq: tempPlanDetailsByPlanId,
      version: 'v2',
    });
  };

  getAllAccountsFromBuckets = () => {
    const { selectedInvestmentAccount, selectedMemberOption, memberOptions } =
      this.state;
    const { nickName = '' } = selectedInvestmentAccount || {};
    const tempSelectedMemberKey =
      selectedMemberOption !== ''
        ? selectedMemberOption
        : memberOptions[0]?.key || '';
    const selectedMemberState = this.getSelectedMemberInvestmentTypeAccounts(
      tempSelectedMemberKey
    );
    const availableInvestmentAccounts =
      this.getAccounts(selectedMemberState) || [];
    const currentSelectedInvestmentAccount =
      availableInvestmentAccounts.filter((a) => a.nickName === nickName)[0] ||
      {};
    const investmentAccountSelected =
      currentSelectedInvestmentAccount &&
      Object.keys(currentSelectedInvestmentAccount).length > 0
        ? currentSelectedInvestmentAccount
        : availableInvestmentAccounts.length > 0
        ? availableInvestmentAccounts[0]
        : {};
    this.setState({
      availableInvestmentAccounts,
      // tickersAddedToPlan: [],
      tickersAvailableInPlan: investmentAccountSelected?.products || [],
      selectedInvestmentAccount: investmentAccountSelected,
      selectedInvestmentBucket:
        investmentAccountSelected?.investmentBucket || '',
      selectedAccount: investmentAccountSelected?.nickName || '',
    });
  };

  updateProductsToRedux = (from) => {
    const { localPlanDetailsByPlanId } = this.state;
    let clonedPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
    const { moduleInvestments: { markets = {} } = {}, moduleInvestments = {} } =
      clonedPlanDetailsByPlanId;
    let tempPlanDetailsByPlanId = { ...clonedPlanDetailsByPlanId };
    let tempMarkets = { ...markets };
    tempMarkets = this.updateProductsToPlan(markets, from);
    if (!isEqual(tempMarkets, markets)) {
      tempPlanDetailsByPlanId = {
        ...clonedPlanDetailsByPlanId,
        moduleInvestments: {
          ...moduleInvestments,
          markets: { ...tempMarkets },
        },
      };
      this.setState((prevState) => ({
        ...prevState,
        localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
      }));
    }
  };

  updatePlanDetailsOnInvestments = () => {
    const { localPlanDetailsByPlanId } = this.state;
    const {
      solutions,
      recommendedAssetAllocationValues,
      triggerGetAssetAllocListByClientId,
    } = this.props;
    const { investments = {} } = solutions || {};
    const { recommendedRiskLevel } = investments;
    const {
      clientid,
      moduleInvestments: { markets = {} } = {},
      moduleInvestments = {},
    } = localPlanDetailsByPlanId;
    let tempRecommendedRiskLevel = '';
    let selectedRecommendedAssetAllocId = '';
    const tempSelectedRecommendedAssetAlloc =
      recommendedAssetAllocationValues.filter(
        (f) => f.riskLevel === recommendedRiskLevel
      );
    if (
      tempSelectedRecommendedAssetAlloc &&
      tempSelectedRecommendedAssetAlloc.length > 0
    ) {
      tempRecommendedRiskLevel = tempSelectedRecommendedAssetAlloc[0].riskLevel;
      selectedRecommendedAssetAllocId =
        tempSelectedRecommendedAssetAlloc[0].value;
    }
    const req = {
      clientid: clientid,
      recommendedRiskLevel: tempRecommendedRiskLevel,
    };
    let tempMarkets = {
      ...markets,
      assetAllocId: selectedRecommendedAssetAllocId, //updating assetAllocId in plan
      recommendedRiskLevel: tempRecommendedRiskLevel, //updating recommendedRiskLevel in plan
    };
    const tempPlanDetailsByPlanId = {
      ...localPlanDetailsByPlanId,
      moduleInvestments: {
        ...moduleInvestments,
        markets: { ...tempMarkets },
      },
    };
    this.setState(
      (prevState) => ({
        ...prevState,
        localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
        selectedAllocationInfo: {},
        assetAllocId: '',
        selectedRecommendedAssetAllocId,
        isOptimizedClicked: false,
      }),
      () => {
        triggerGetAssetAllocListByClientId(req);
      }
    );
  };

  updatePlanDetailsOnExpenses = () => {
    const { localPlanDetailsByPlanId } = this.state;
    const { solutions, triggerUpdatePlannerDetailsinRedux } = this.props;
    const { expenses = {} } = solutions || {};
    const { affordabilityFactor } = expenses;
    const { moduleFamily: { infoExpenses = {} } = {}, moduleFamily = {} } =
      localPlanDetailsByPlanId;
    let tempInfoExpenses = {
      ...infoExpenses,
      affordabilityFactor: affordabilityFactor, //updating assetAllocId in plan
    };
    const tempPlanDetailsByPlanId = {
      ...localPlanDetailsByPlanId,
      moduleFamily: {
        ...moduleFamily,
        infoExpenses: { ...tempInfoExpenses },
      },
    };
    triggerUpdatePlannerDetailsinRedux({
      plannerData: tempPlanDetailsByPlanId,
    });
    this.setState((prevState) => ({
      ...prevState,
      localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
      isOptimizedClicked: false,
    }));
  };

  updatePlanDetailsOnSolutions = () => {
    const { solutionsParentTab } = this.state;
    switch (solutionsParentTab) {
      case 0:
        this.updatePlanDetailsOnInvestments();
        break;
      case 1:
        this.updatePlanDetailsOnExpenses();
        break;
      default:
        break;
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      planDetailsByPlanId,
      charts,
      tables,
      data,
      recommendedAssetAllocationValues,
      allocation,
      investmentProducts,
      triggerUpdatePlannerDetailsinRedux,
      solutions,
    } = this.props;
    const {
      solutionsParentTab,
      renderType,
      selectedAllocationInfo,
      localPlanDetailsByPlanId,
      assetMixBuildInfo,
      targetAssetMixBuildInfo,
      selectedRecommendedAssetAllocId,
      solutionsSubTab,
      deleteInsurance,
      memberOptions,
      tickersAvailableInPlan,
      isSimulateOnInvestmentsClicked,
      isMC,
      selectedMember,
      formDetails,
      isEditable,
      isAddNew,
      tickersAddedToPlan,
      remainingBalance,
      selectedInvestmentAccount,
      isOptimizedClicked,
    } = this.state;
    const {
      renderType: prevRenderType,
      selectedAllocationInfo: prevSelectedAllocationInfo,
      localPlanDetailsByPlanId: prevLocalPlanDetailsByPlanId,
      solutionsParentTab: prevSolutionsParentTab,
      assetMixBuildInfo: prevAssetMixBuildInfo,
      targetAssetMixBuildInfo: prevTargetAssetMixBuildInfo,
      solutionsSubTab: prevSolutionsSubTab,
      tickersAvailableInPlan: prevTickersAvailableInPlan,
      formDetails: prevFormDetails,
      selectedMember: prevSelectedMember,
      tickersAddedToPlan: prevTickersAddedToPlan,
      remainingBalance: prevRemainingBalance,
      selectedInvestmentAccount: prevSelectedInvestmentAccount,
    } = prevState;
    const {
      charts: prevCharts,
      tables: prevTables,
      data: prevData,
      recommendedAssetAllocationValues: prevRecommendedAssetAllocationValues,
      allocation: prevAllocation,
      investmentProducts: prevInvestmentProducts,
      planDetailsByPlanId: prevPlanDetailsByPlanId,
      solutions: prevSolutions,
    } = prevProps;

    const queryParams = new URLSearchParams(this.props.location.search);
    let tab = queryParams.get('tab');
    if (tab) {
      const tempSolutionsParentTab = this.getParentTabValues(tab);
      if (tempSolutionsParentTab !== solutionsParentTab) {
        const solutionsParentTabName = this.getParentTabNames(
          tempSolutionsParentTab
        );
        history.push(`/solutions?tab=${solutionsParentTabName}`);
        this.setState({ solutionsParentTab: tempSolutionsParentTab });
      }
    }
    if (
      planDetailsByPlanId &&
      Object.keys(planDetailsByPlanId).length > 0 &&
      !isEqual(planDetailsByPlanId, prevPlanDetailsByPlanId)
    ) {
      this.getMemberOptions();
      this.setState({ localPlanDetailsByPlanId: planDetailsByPlanId });
    }
    if (
      selectedMember &&
      solutionsParentTab === 0 &&
      memberOptions.length > 0 &&
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      (selectedMember !== prevSelectedMember ||
        !isEqual(localPlanDetailsByPlanId, prevLocalPlanDetailsByPlanId))
    ) {
      this.getAllAccountsFromBuckets();
      triggerUpdatePlannerDetailsinRedux({
        plannerData: localPlanDetailsByPlanId,
      });
    }
    if (
      planDetailsByPlanId &&
      Object.keys(planDetailsByPlanId).length > 0 &&
      (memberOptions.length === 0 ||
        solutionsParentTab !== prevSolutionsParentTab)
    ) {
      this.loadDefaultsAndCallSimulateOnLoad(); // first simulate
    }
    if (
      planDetailsByPlanId &&
      Object.keys(planDetailsByPlanId).length > 0 &&
      (!isEqual(prevRenderType, renderType) ||
        (charts && charts.length > 0 && !isEqual(charts, prevCharts)) ||
        (data && data.length > 0 && !isEqual(data, prevData)) ||
        (tables && tables.length > 0 && !isEqual(tables, prevTables)))
    ) {
      this.loadDefaultValues();
    }
    if (
      solutionsParentTab === 0 &&
      data &&
      data.length > 0 &&
      assetMixBuildInfo &&
      assetMixBuildInfo.length > 0 &&
      targetAssetMixBuildInfo &&
      targetAssetMixBuildInfo.length > 0 &&
      !isEqual(assetMixBuildInfo, prevAssetMixBuildInfo) &&
      !isEqual(targetAssetMixBuildInfo, prevTargetAssetMixBuildInfo)
    ) {
      this.loadCurrentTargetValuesInInvestments();
    }
    if (
      solutionsParentTab === 0 &&
      recommendedAssetAllocationValues &&
      recommendedAssetAllocationValues.length > 0 &&
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      (!isEqual(
        recommendedAssetAllocationValues,
        prevRecommendedAssetAllocationValues
      ) ||
        selectedRecommendedAssetAllocId === '')
    ) {
      this.setRecommendedRiskLevel(); //first dd in investments
    }
    if (
      solutionsParentTab === 0 &&
      allocation &&
      allocation.length > 0 &&
      !isEqual(allocation, prevAllocation) &&
      selectedRecommendedAssetAllocId &&
      (Object.keys(selectedAllocationInfo).length === 0 ||
        !isEqual(selectedAllocationInfo, prevSelectedAllocationInfo))
    ) {
      this.setAllocationIdBasedOnRiskLevel(); //last dropdown in investments
    }
    if (
      (isOptimizedClicked || solutionsParentTab === 1) && // for expenses, we need to update the plan irrespective of optimize or simulate as affordability factor should always be read from the response
      solutions &&
      Object.keys(solutions).length > 0 &&
      !isEqual(solutions, prevSolutions)
    ) {
      this.updatePlanDetailsOnSolutions();
    }
    if (
      solutionsParentTab !== prevSolutionsParentTab ||
      solutionsSubTab !== prevSolutionsSubTab ||
      deleteInsurance === true
    ) {
      this.loadTabSpecificInfo();
    }
    if (
      solutionsParentTab === 0 &&
      allocation.length > 0 &&
      selectedAllocationInfo &&
      Object.keys(selectedAllocationInfo).length > 0 &&
      localPlanDetailsByPlanId &&
      !isSimulateOnInvestmentsClicked &&
      Object.keys(localPlanDetailsByPlanId).length > 0
    ) {
      this.runSimulateForTargets();
      this.setState({
        isSimulateOnInvestmentsClicked: true,
        isOptimizedClicked: isMC,
      });
    }
    if (
      solutionsParentTab === 0 &&
      investmentProducts &&
      investmentProducts.length > 0 &&
      (!isEqual(investmentProducts, prevInvestmentProducts) ||
        !isEqual(tickersAvailableInPlan, prevTickersAvailableInPlan) ||
        !isEqual(selectedInvestmentAccount, prevSelectedInvestmentAccount))
    ) {
      this.loadTickersAddedInPlan();
    }
    if (!isEqual(prevFormDetails, formDetails) && (isAddNew || isEditable)) {
      this.validateAllFormDetails();
    }
    if (
      solutionsParentTab === 0 &&
      isEqual(solutionsParentTab, prevSolutionsParentTab) &&
      (!isEqual(tickersAddedToPlan, prevTickersAddedToPlan) ||
        remainingBalance !== prevRemainingBalance)
    ) {
      this.updateProductsToRedux('tickersChange');
    }
  }

  validateTabwiseFormDetails = (
    selectedFormDetails,
    validateFields,
    existingItems
  ) => {
    const { isAddNew, isEditable, selectedDDNameIndex } = this.state;
    let validationErrors = {};
    validationErrors = validateFormDetails(
      selectedFormDetails,
      validateFields,
      existingItems,
      isAddNew,
      isEditable,
      selectedDDNameIndex
    );
    if (validationErrors && validationErrors.length > 0) {
      this.setState({ formErrors: validationErrors, isFormValid: false });
    } else {
      this.setState({ formErrors: [], isFormValid: true });
    }
  };

  concatContent = (mainTab, subTab, objectArray) => {
    const { localPlanDetailsByPlanId } = this.state;
    let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
    // Dynamically fetch the moduleSettings object based on tab
    const tabModule = tempPlanDetailsByPlanId[mainTab] || {};
    const itemArray = tabModule[subTab]
      ? tabModule[subTab][objectArray] || []
      : [];
    let existingItemsOptions = [];
    if (Array.isArray(itemArray)) {
      existingItemsOptions = itemArray?.map((m) => {
        return {
          title: subTab === 'invProps' ? m.prop?.name : m.name,
          id: subTab === 'invProps' ? m.prop?.name : m.name,
        };
      });
    } else {
      if (Object.keys(itemArray).length > 0) {
        existingItemsOptions = [
          {
            title: itemArray.name,
            id: itemArray.name,
          },
        ];
      }
    }
    return existingItemsOptions.filter((f) => f.title && f.id); //removing nulls
  };

  validateAllFormDetails = () => {
    const { formDetails, solutionsSubTab, solutionsParentTab } = this.state;
    let mandatoryFields = [];
    let formFields = {};
    let existingItems = [];
    let moduleTab = '';
    let mainTab = '';
    let subTab = '';
    switch (solutionsParentTab) {
      case 3:
        moduleTab = 'moduleInsurance';
        switch (solutionsSubTab) {
          case 0:
            mainTab = 'termLife';
            subTab = 'termLifeIns';
            mandatoryFields = Object.entries(PropertyNamesMap['termLifeIns'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['termLifeIns'];
            break;
          case 1:
            mainTab = 'wholeLife';
            subTab = 'wholeLifeIns';
            mandatoryFields = Object.entries(PropertyNamesMap['wholeLifeIns'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['wholeLifeIns'];
            break;
          case 2:
            mainTab = 'longTermCare';
            subTab = 'ltcIns';
            mandatoryFields = Object.entries(PropertyNamesMap['ltcIns'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['ltcIns'];
            break;
          case 3:
            mainTab = 'umbrella';
            subTab = 'umbrellaIns';
            mandatoryFields = Object.entries(PropertyNamesMap['umbrellaIns'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['umbrellaIns'];
            break;
          case 4:
            mainTab = 'property';
            subTab = 'propIns';
            mandatoryFields = Object.entries(PropertyNamesMap['propIns'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['propIns'];
            break;
          case 5:
            mainTab = 'auto';
            subTab = 'autoIns';
            mandatoryFields = Object.entries(PropertyNamesMap['autoIns'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['autoIns'];
            break;
          case 6:
            mainTab = 'health';
            subTab = 'healthIns';
            mandatoryFields = Object.entries(PropertyNamesMap['healthIns'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['healthIns'];
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    existingItems = this.concatContent(moduleTab, mainTab, subTab);
    this.validateTabwiseFormDetails(formFields, mandatoryFields, existingItems);
  };

  getInitialFormDetails = () => {
    const formDetails = {
      termLifeIns: { insured: '', name: '', coverageStartYr: new Date() },
      wholeLifeIns: { insured: '', name: '', coverageStartYr: new Date() },
      longTermCare: { insured: '', name: '', coverageStartYr: new Date() },
      umbrellaIns: {
        insured: 'umbrella',
        name: '',
        coverageStartYr: new Date(),
      },
    };
    return formDetails;
  };

  resolveData = (data) => {
    return data.map((m) => {
      return {
        ...m,
        id: m.productId,
      };
    });
  };

  getInsuranceProducts = async (subTabValue) => {
    const {
      triggerSetLongTermCareInsuranceProducts,
      triggerSetTermInsuranceProducts,
      triggerSetUmbrellaInsuranceProducts,
      triggerSetWholeInsuranceProducts,
      triggerSetPropertyInsuranceProducts,
      triggerSetAutoInsuranceProducts,
      termLifeInsuranceProducts,
      wholeLifeInsuranceProducts,
      umbrellaInsuranceProducts,
      longTermCareInsuranceProducts,
      propertyInsuranceProducts,
      autoInsuranceProducts,
    } = this.props;
    switch (subTabValue) {
      case 0:
        if (
          termLifeInsuranceProducts &&
          termLifeInsuranceProducts.length === 0
        ) {
          let { data: termInsProducts } = await getTermLifeInsuranceProducts();
          termInsProducts = this.resolveData(termInsProducts);
          triggerSetTermInsuranceProducts(termInsProducts);
        }
        break;
      case 1:
        if (
          wholeLifeInsuranceProducts &&
          wholeLifeInsuranceProducts.length === 0
        ) {
          let { data: wholeLifeInsProducts } =
            await getWholeLifeInsuranceProducts();
          wholeLifeInsProducts = this.resolveData(wholeLifeInsProducts);
          triggerSetWholeInsuranceProducts(wholeLifeInsProducts);
        }
        break;
      case 2:
        if (
          longTermCareInsuranceProducts &&
          longTermCareInsuranceProducts.length === 0
        ) {
          let { data: ltcProducts } = await getLongTermCareInsuranceProducts();
          ltcProducts = this.resolveData(ltcProducts);
          triggerSetLongTermCareInsuranceProducts(ltcProducts);
        }
        break;
      case 3:
        if (
          umbrellaInsuranceProducts &&
          umbrellaInsuranceProducts.length === 0
        ) {
          let { data: umbrellaProducts } = await getUmbrellaInsuranceProducts();
          umbrellaProducts = this.resolveData(umbrellaProducts);
          triggerSetUmbrellaInsuranceProducts(umbrellaProducts);
        }
        break;
      case 4:
        if (
          propertyInsuranceProducts &&
          propertyInsuranceProducts.length === 0
        ) {
          let { data: propertyProducts } = await getPropertyInsuranceProducts();
          propertyProducts = this.resolveData(propertyProducts);
          triggerSetPropertyInsuranceProducts(propertyProducts);
        }
        break;
      case 5:
        if (autoInsuranceProducts && autoInsuranceProducts.length === 0) {
          let { data: autoProducts } = await getAutoInsuranceProducts();
          autoProducts = this.resolveData(autoProducts);
          triggerSetAutoInsuranceProducts(autoProducts);
        }
        break;
      default:
        break;
    }
  };

  addMissingPropertiesToElements = (elements) => {
    const result = { ...assetMixValuesTemplate };
    elements.forEach((item) => {
      result[item.label] = item.value;
    });
    return Object.keys(assetMixValuesTemplate).map((key) => ({
      label: key,
      value: result[key],
    }));
  };

  updateArray = (arr) => {
    return arr.map((item) => ({
      ...item,
      elements: this.addMissingPropertiesToElements(item.elements),
    }));
  };

  getAssetMixBuildInfo = () => {
    const { data } = this.props;
    let assetMixBuildInfo = [];
    let targetAssetMixBuildInfo = [];
    const assetMixInfo = data
      .filter((d) => d.tabName === 'Investments')[0]
      .subtabs.filter((s) => s.subtabName === 'Asset Mix(Current)');
    const targetAssetMixInfo = data
      .filter((d) => d.tabName === 'Investments')[0]
      .subtabs.filter((s) => s.subtabName === 'Asset Mix(Target)');
    if (
      assetMixInfo &&
      assetMixInfo.length > 0 &&
      targetAssetMixInfo &&
      targetAssetMixInfo.length > 0
    ) {
      assetMixBuildInfo =
        assetMixInfo[0].build &&
        assetMixInfo[0].build.main &&
        assetMixInfo[0].build.main.length > 0
          ? this.updateArray(assetMixInfo[0].build.main)
          : [];
      targetAssetMixBuildInfo =
        targetAssetMixInfo[0].build &&
        targetAssetMixInfo[0].build.main &&
        targetAssetMixInfo[0].build.main.length > 0
          ? this.updateArray(targetAssetMixInfo[0].build.main)
          : [];
    }
    this.setState({
      assetMixBuildInfo,
      targetAssetMixBuildInfo,
    });
  };

  getSelectedMemberInvestmentTypeAccounts = (selectedMember) => {
    const { localPlanDetailsByPlanId } = this.state;
    const { moduleInvestments: { markets = {} } = {} } =
      localPlanDetailsByPlanId;
    const { first = {}, second = {} } = markets;
    if (selectedMember === 'first') return first;
    return second;
  };

  getMemberOptions = () => {
    const { planDetailsByPlanId } = this.props;
    const { formDetails, solutionsParentTab } = this.state;
    let tempFormDetails = { ...formDetails };
    const { moduleFamily: { infoCore = {}, infoVehicles = {} } = {} } =
      planDetailsByPlanId;
    const { basicsFirst = {}, basicsSecond = {} } = infoCore;
    let tempMemberOptions = [];
    if (basicsFirst && Object.keys(basicsFirst).length > 0) {
      const { name = '', age = '', retirementAge = '' } = basicsFirst;
      if (
        tempMemberOptions.indexOf(
          tempMemberOptions.filter((f) => f.key === 'first')
        ) === -1
      ) {
        tempMemberOptions.push({
          key: 'first',
          id: name,
          title: name,
          isSelected: true,
          age: age,
          retirementAge: retirementAge,
        });
      }
    }
    if (
      basicsSecond &&
      Object.keys(basicsSecond).length > 0 &&
      basicsSecond['name'] &&
      basicsSecond['age']
    ) {
      const { name = '', age = '', retirementAge = '' } = basicsSecond;
      if (
        tempMemberOptions.indexOf(
          tempMemberOptions.filter((f) => f.key === 'second')
        ) === -1
      ) {
        tempMemberOptions.push({
          key: 'second',
          id: name,
          title: name,
          isSelected: true,
          age: age,
          retirementAge: retirementAge,
        });
      }
    }
    let selectedPropertyMember = '';
    let selectedAutoMember = '';
    let selectedMember = '';
    const {
      moduleInvestments: { primaryHome, secondHomes, invProps },
    } = planDetailsByPlanId;
    let tempPropertyRadioOptions = [];
    // Define a function to add options to tempPropertyRadioOptions
    const addOptions = (items, prefix) => {
      if (items && items.length > 0) {
        tempPropertyRadioOptions.push(
          ...items.map((item, index) => ({
            key: `${prefix}_${index}`,
            id: item.name,
            title: item.name,
          }))
        );
      }
    };
    // Calculate tempPropertyRadioOptions for primaryHome
    if (primaryHome && primaryHome.prop) {
      const { name } = primaryHome.prop;
      tempPropertyRadioOptions.push({
        key: 'primaryHome',
        id: name,
        title: name,
      });
    }
    // Calculate tempPropertyRadioOptions for secondaryHomes
    secondHomes && addOptions(secondHomes.props, 'secondaryHome'); // revert this after checking with Reva

    // Calculate tempPropertyRadioOptions for invProps
    if (invProps && invProps.props && Array.isArray(invProps.props)) {
      // Loop through the props array and access the name property in each prop object
      invProps.props.forEach((item, index) => {
        if (item && item.prop && item.prop.name) {
          tempPropertyRadioOptions.push({
            key: `invProps_${index}`,
            id: item.prop.name,
            title: item.prop.name,
          });
        }
      });
    }

    const tempAutoRadioOptions = [];
    const addAutoRadioOptions = (vehicles) => {
      if (Array.isArray(vehicles) && vehicles.length > 0) {
        vehicles.forEach((vehicle, index) => {
          tempAutoRadioOptions.push({
            key: `vehicle_${index}`,
            id: vehicle.name, // Replace with the actual property that should be used as 'id'
            title: vehicle.name, // Replace with the actual property that should be used as 'title'
          });
        });
      }
    };
    infoVehicles && addAutoRadioOptions(infoVehicles.vehicles);
    let selectedMemberOption = '';
    if (tempMemberOptions.length > 0) {
      selectedMemberOption = tempMemberOptions[0].key;
      selectedMember =
        this.state.selectedMember === ''
          ? tempMemberOptions[0].id
          : this.state.selectedMember;
    }
    if (tempPropertyRadioOptions.length > 0) {
      selectedPropertyMember =
        this.state.selectedPropertyMember === ''
          ? tempPropertyRadioOptions[0].id
          : this.state.selectedPropertyMember;
    }
    if (tempAutoRadioOptions.length > 0) {
      selectedAutoMember =
        this.state.selectedAutoMember === ''
          ? tempAutoRadioOptions[0].id
          : this.state.selectedAutoMember;
    }
    if (selectedMember) {
      // Update termLifeIns, wholeLifeIns, and ltcIns with selectedMember
      tempFormDetails = {
        ...tempFormDetails,
        termLifeIns: {
          ...tempFormDetails['termLifeIns'],
          insured: selectedMember,
        },
        wholeLifeIns: {
          ...tempFormDetails['wholeLifeIns'],
          insured: selectedMember,
        },
        ltcIns: {
          ...tempFormDetails['ltcIns'],
          insured: selectedMember,
        },
      };
    }

    if (selectedPropertyMember) {
      // Update propIns with selectedPropertyMember
      tempFormDetails = {
        ...tempFormDetails,
        propIns: {
          ...tempFormDetails['propIns'],
          insured: selectedPropertyMember,
        },
      };
    }

    if (selectedAutoMember) {
      // Update autoIns with selectedPropertyMember
      tempFormDetails = {
        ...tempFormDetails,
        autoIns: {
          ...tempFormDetails['autoIns'],
          insured: selectedAutoMember,
        },
      };
    }
    this.setState({
      formDetails: tempFormDetails,
      memberOptions: tempMemberOptions,
      memberPropertyRadioOptions: tempPropertyRadioOptions,
      memberAutoRadioOptions: tempAutoRadioOptions,
      selectedMember,
      selectedMemberOption, // key of the member option, e.g. first or second
      selectedPropertyMember,
      selectedAutoMember,
      // solutionsParentTab: 0, // for time being until goals are implemented
      localPlanDetailsByPlanId: planDetailsByPlanId,
    });
  };

  loadDefaultValues = () => {
    const { renderType, solutionsParentTab } = this.state;
    const { data, beforeAfterAssetMixValues } = this.props;
    switch (renderType) {
      case 'Chart':
        this.getChartsMenu();
        break;
      case 'Table':
        this.getTableMenu();
        break;
      case 'Data':
        this.getDataMenu();
        break;
      default:
        break;
    }
    if (data && data.length > 0 && solutionsParentTab === 0) {
      if (beforeAfterAssetMixValues.length === 0) {
        this.getAssetMixBuildInfo();
      }
    }
  };

  getExistingInsurances = (subTabValue, selectedInsuredInfo) => {
    const { planDetailsByPlanId } = this.props;
    const {
      moduleInsurance: {
        termLife = {},
        wholeLife = {},
        longTermCare = {},
        umbrella = {},
        auto = {},
        property = {},
      },
    } = planDetailsByPlanId;
    const termLifeIns = (termLife && termLife.termLifeIns) || [];
    const wholeLifeIns = (wholeLife && wholeLife.wholeLifeIns) || [];
    const ltcIns = (longTermCare && longTermCare.ltcIns) || [];
    const umbrellaIns = (umbrella && umbrella.umbrellaIns) || [];
    const autoIns = (auto && auto.autoIns) || [];
    const propIns = (property && property.propIns) || [];
    let existingInsurances = [];
    switch (subTabValue) {
      case 0:
        existingInsurances = termLifeIns.filter(
          (t) => t.insured === selectedInsuredInfo.insured
        );
        break;
      case 1:
        existingInsurances = wholeLifeIns.filter(
          (t) => t.insured === selectedInsuredInfo.insured
        );
        break;
      case 2:
        existingInsurances = ltcIns.filter(
          (t) => t.insured === selectedInsuredInfo.insured
        );
        break;
      case 3:
        existingInsurances = umbrellaIns.filter(
          (t) => t.insured === selectedInsuredInfo.insured
        );
        break;
      case 4:
        existingInsurances = propIns.filter(
          (t) => t.insured === selectedInsuredInfo.insured
        );
        break;
      case 5:
        existingInsurances = autoIns.filter(
          (t) => t.insured === selectedInsuredInfo.insured
        );
        break;
      default:
        break;
    }
    return existingInsurances;
  };

  categorizeAndTransformEvents = (events, memberOptions) => {
    const retirementEvents = [];
    const otherEvents = [];
    events.forEach((e) => {
      if (e.goalCategory?.toLowerCase() === 'retirement') {
        retirementEvents.push({
          ...e,
          retirementAge:
            memberOptions.find((o) => o.id === e.name)?.retirementAge || '',
        });
      } else {
        otherEvents.push(e);
      }
    });
    return [...retirementEvents, ...otherEvents];
  };

  setIsSimulateClicked = (value) => {
    this.setState({ isSimulateClicked: value });
  };

  renderGoalsContainer = () => {
    const { solutions = {} } = this.props;
    const { memberOptions = [], isSimulateClicked } = this.state;
    const { goals = {} } = solutions || {};
    const { milestones = {} } = goals || {};
    const { event = [] } = milestones || {};
    const updatedEvents = this.categorizeAndTransformEvents(
      event,
      memberOptions
    );
    return (
      <div className="chrono-charts-container">
        <VerticalTabs
          goals={updatedEvents}
          isSimulateClicked={isSimulateClicked}
          setIsSimulateClicked={this.setIsSimulateClicked}
        />
      </div>
    );
  };

  renderFutureImplementationContainer = () => {
    return (
      <div className="chrono-charts-container">For Future Implementation</div>
    );
  };

  getChartsMenu = () => {
    const { charts, triggerSetSelectedChart } = this.props;
    const { parentTabValue, subTabValue } = this.state;
    let chartInfo = [];
    if (charts && charts.length > 0) {
      chartInfo = charts.map((c) => {
        return {
          tabName: c.tabName,
          subMenu: c.subtabs,
        };
      });
      const selectedChart =
        (chartInfo[parentTabValue] &&
          chartInfo[parentTabValue].subMenu &&
          chartInfo[parentTabValue].subMenu.length > 0 &&
          chartInfo[parentTabValue].subMenu[subTabValue].subtabName) ||
        '';
      triggerSetSelectedChart(selectedChart);
    }

    this.setState({
      tabInfo: chartInfo,
    });
  };

  getTableMenu = () => {
    const { tables, triggerSetSelectedTable } = this.props;
    const { parentTabValue, subTabValue } = this.state;
    let tableInfo = [];
    if (tables && tables.length > 0) {
      tableInfo = tables.map((c) => {
        return {
          tabName: c.tabName,
          subMenu: c.subtabs,
        };
      });
      const selectedTable =
        (tableInfo[parentTabValue] &&
          tableInfo[parentTabValue].subMenu &&
          tableInfo[parentTabValue].subMenu.length > 0 &&
          tableInfo[parentTabValue].subMenu[subTabValue].subtabName) ||
        '';
      triggerSetSelectedTable(selectedTable);
    }
    this.setState({
      tabInfo: tableInfo,
    });
  };

  getDataMenu = () => {
    const { data, triggerSetSelectedData } = this.props;
    const { parentTabValue, subTabValue } = this.state;
    let dataInfo = [];
    if (data && data.length > 0) {
      dataInfo = data.map((c) => {
        return {
          tabName: c.tabName,
          subMenu: c.subtabs,
        };
      });
      const selectedData =
        (dataInfo[parentTabValue] &&
          dataInfo[parentTabValue].subMenu &&
          dataInfo[parentTabValue].subMenu.length > 0 &&
          dataInfo[parentTabValue].subMenu[subTabValue].subtabName) ||
        '';
      triggerSetSelectedData(selectedData);
    }
    this.setState({
      tabInfo: dataInfo,
    });
  };

  transformToApiType = (fieldValue) => {
    let convertedValue = fieldValue;
    if (isNaN(fieldValue) && fieldValue.includes('$')) {
      convertedValue = parseFloat(fieldValue.split('$')[1].replace(/,/g, ''));
    }
    return convertedValue;
  };

  handleDeleteInsurance = (tab, mainTab, countVal, selectedAccountName) => {
    const { existingInsurances, formDetails, selectedMember } = this.state;
    let { planDetailsByPlanId, triggerSimulatePlanDetailsByPlanId } =
      this.props;
    let { moduleInsurance } = { ...planDetailsByPlanId };
    let tempModuleInsurance = { ...moduleInsurance };
    let tempInsurances = [...tempModuleInsurance[mainTab][tab]];
    let tempCountVal = tempModuleInsurance[mainTab][countVal];

    // Find the index of the insurance entry to be deleted based on selectedAccountName
    const indexToDelete = tempInsurances.findIndex((ins) =>
      mainTab &&
      ['termLife', 'wholeLife', 'longTermCare', 'propIns', 'autoIns'].includes(
        mainTab
      )
        ? ins.insured === selectedMember &&
          ins.name.toLowerCase() === selectedAccountName.toLowerCase()
        : ins.name.toLowerCase() === selectedAccountName.toLowerCase()
    );

    if (indexToDelete !== -1) {
      // Remove the insurance entry from the temporary array and log it
      const tempDeletedInsurance = tempInsurances.splice(indexToDelete, 1);
      console.log('Deleted Insurance:', tempDeletedInsurance);
      // Update the moduleInsurance object with the modified insurances
      tempModuleInsurance[mainTab][tab] = tempInsurances;
      tempModuleInsurance[mainTab][countVal] = tempCountVal - 1;

      // Filter the existingInsurances outside of setState
      const updatedExistingInsurances = existingInsurances.filter(
        (ins) =>
          ins.insured !== selectedMember ||
          ins.name.toLowerCase() !== selectedAccountName.toLowerCase()
      );
      // Update the state and trigger a simulation
      this.setState(
        (prevState) => ({
          ...prevState,
          moduleInsurance: tempModuleInsurance,
          existingInsurances: updatedExistingInsurances,
          deleteInsurance: true,
        }),
        () => {
          // Trigger the simulation with the updated plan details
          triggerSimulatePlanDetailsByPlanId({
            displayTermChanged: false,
            chrono: {},
            plannerFormReq: {
              ...planDetailsByPlanId,
              moduleInsurance: tempModuleInsurance,
            },
            version: 'v2',
          });
        }
      );
    }
  };

  getTabInfo = () => {
    const { solutionsSubTab, solutionsParentTab } = this.state;
    if (solutionsParentTab === 3) {
      switch (solutionsSubTab) {
        case 0:
          return { tab: 'termLifeIns', mainTab: 'termLife' };
        case 1:
          return { tab: 'wholeLifeIns', mainTab: 'wholeLife' };
        case 2:
          return { tab: 'umbrellaIns', mainTab: 'umbrella' };
        case 3:
          return { tab: 'ltcIns', mainTab: 'longTermCare' };
        case 4:
          return { tab: 'propIns', mainTab: 'property' };
        case 5:
          return { tab: 'autoIns', mainTab: 'auto' };
        default:
          break;
      }
    }
  };

  handleAddToPortfolio = (tab, mainTab) => {
    const {
      formDetails,
      existingInsurances,
      selectedMember,
      selectedPropertyMember,
      selectedAutoMember,
      localPlanDetailsByPlanId,
    } = this.state;
    let { triggerUpdatePlannerDetailsinRedux } = this.props;
    let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
    let {
      moduleInsurance: {
        termLife = {},
        wholeLife = {},
        longTermCare = {},
        umbrella = {},
        auto = {},
        property = {},
      },
      moduleInsurance = {},
      moduleSettings: { model = {} } = {},
      moduleSettings = {},
    } = tempPlanDetailsByPlanId;
    const termLifeIns = (termLife && termLife.termLifeIns) || [];
    const wholeLifeIns = (wholeLife && wholeLife.wholeLifeIns) || [];
    const ltcIns = (longTermCare && longTermCare.ltcIns) || [];
    const umbrellaIns = (umbrella && umbrella.umbrellaIns) || [];
    const autoIns = (auto && auto.autoIns) || [];
    const propIns = (property && property.propIns) || [];
    let tempTermLifeIns = [...termLifeIns];
    let tempWholeLifeIns = [...wholeLifeIns];
    let tempLongTermCare = [...ltcIns];
    let tempLiabilityIns = [...umbrellaIns];
    let tempPropIns = [...propIns];
    let tempAutoIns = [...autoIns];
    let tempInsuredDetails = formDetails[tab];
    let tempModel = { ...model, runCode: 600 };
    let addNew = false;
    tempInsuredDetails = {
      ...tempInsuredDetails,
      premium:
        (tempInsuredDetails.premium &&
          this.transformToApiType(tempInsuredDetails.premium)) ||
        0,
      coverage:
        (tempInsuredDetails.coverage &&
          this.transformToApiType(tempInsuredDetails.coverage)) ||
        0,
      cashValueAnnual:
        (tempInsuredDetails.cashValueAnnual &&
          this.transformToApiType(tempInsuredDetails.cashValueAnnual)) ||
        0,
      coverageStartYr: tempInsuredDetails.coverageStartYr?.getFullYear(),
    };
    switch (tab) {
      case 'termLifeIns':
        let existingTermInsuredInfoIndex = tempTermLifeIns.indexOf(
          tempTermLifeIns.filter(
            (t) =>
              t.insured &&
              tempInsuredDetails.insured &&
              t.insured.toLowerCase() ===
                tempInsuredDetails.insured.toLowerCase() &&
              t.name.toLowerCase() === tempInsuredDetails.name.toLowerCase()
          )[0]
        );
        if (existingTermInsuredInfoIndex > -1) {
          let existingInsuredInfo =
            tempTermLifeIns[existingTermInsuredInfoIndex];
          existingInsuredInfo = {
            ...existingInsuredInfo,
            ...tempInsuredDetails,
          };
          tempTermLifeIns[existingTermInsuredInfoIndex] = existingInsuredInfo;
        } else {
          addNew = true;
          const otherFields = {
            cashValueAnnual: 0,
            cashValueDistributionYrs: 0,
            deductible: 0,
            premiumGrowth: 0,
            renewAtExpiry: false,
          };
          const completeObject = { ...tempInsuredDetails, ...otherFields };
          tempTermLifeIns.push(completeObject);
        }
        break;
      case 'ltcIns':
        const existingLTCInsuredInfoIndex = tempLongTermCare.indexOf(
          tempLongTermCare.filter(
            (t) =>
              t.insured &&
              tempInsuredDetails.insured &&
              t.insured.toLowerCase() ===
                tempInsuredDetails.insured.toLowerCase() &&
              t.name.toLowerCase() === tempInsuredDetails.name.toLowerCase()
          )[0]
        );
        if (existingLTCInsuredInfoIndex > -1) {
          let existingInsuredInfo =
            tempLongTermCare[existingLTCInsuredInfoIndex];
          existingInsuredInfo = {
            ...existingInsuredInfo,
            ...tempInsuredDetails,
          };
          tempLongTermCare[existingLTCInsuredInfoIndex] = existingInsuredInfo;
        } else {
          addNew = true;
          const otherFields = {
            deductible: 0,
            premiumGrowth: 0,
            renewAtExpiry: false,
            coverage: 0,
            coverageYrs: 0,
          };
          const completeObject = { ...tempInsuredDetails, ...otherFields };
          tempLongTermCare.push(completeObject);
        }
        break;
      case 'umbrellaIns':
        let existingLiabilityInsuredInfoIndex = tempLiabilityIns.indexOf(
          tempLiabilityIns.filter(
            (t) =>
              t.insured &&
              tempInsuredDetails.insured &&
              t.insured.toLowerCase() ===
                tempInsuredDetails.insured.toLowerCase() &&
              t.name.toLowerCase() === tempInsuredDetails.name.toLowerCase()
          )[0]
        );
        if (existingLiabilityInsuredInfoIndex > -1) {
          let existingInsuredInfo =
            tempLiabilityIns[existingLiabilityInsuredInfoIndex];
          existingInsuredInfo = {
            ...existingInsuredInfo,
            ...tempInsuredDetails,
          };
          tempLiabilityIns[existingLiabilityInsuredInfoIndex] =
            existingInsuredInfo;
        } else {
          addNew = true;
          const otherFields = {
            cashValueAnnual: 0,
            coverageYrs: 1,
            cashValueDistributionYrs: 0,
            deductible: 0,
            premiumGrowth: 0,
            renewAtExpiry: true,
            insured: 'family',
          };
          const completeObject = { ...tempInsuredDetails, ...otherFields };
          tempLiabilityIns.push(completeObject);
        }
        break;
      case 'wholeLifeIns':
        let existingWholeLifeInsuredInfoIndex = tempWholeLifeIns.indexOf(
          tempWholeLifeIns.filter(
            (t) =>
              t.insured &&
              tempInsuredDetails.insured &&
              t.insured.toLowerCase() ===
                tempInsuredDetails.insured.toLowerCase() &&
              t.name.toLowerCase() === tempInsuredDetails.name.toLowerCase()
          )[0]
        );
        if (existingWholeLifeInsuredInfoIndex > -1) {
          let existingInsuredInfo =
            tempWholeLifeIns[existingWholeLifeInsuredInfoIndex];
          existingInsuredInfo = {
            ...existingInsuredInfo,
            ...tempInsuredDetails,
          };
          tempWholeLifeIns[existingWholeLifeInsuredInfoIndex] =
            existingInsuredInfo;
        } else {
          addNew = true;
          const otherFields = {
            cashValueDistributionYrs: 1,
            deductible: 0,
            premiumGrowth: 0,
            renewAtExpiry: false,
          };
          const completeObject = { ...tempInsuredDetails, ...otherFields };
          tempWholeLifeIns.push(completeObject);
        }
        break;
      case 'propIns':
        let existingPropertyInsuredInfoIndex = tempPropIns.indexOf(
          tempPropIns.filter(
            (t) =>
              t.insured &&
              tempInsuredDetails.insured &&
              t.insured.toLowerCase() ===
                tempInsuredDetails.insured.toLowerCase() &&
              t.name.toLowerCase() === tempInsuredDetails.name.toLowerCase()
          )[0]
        );
        if (existingPropertyInsuredInfoIndex > -1) {
          let existingInsuredInfo =
            tempPropIns[existingPropertyInsuredInfoIndex];
          existingInsuredInfo = {
            ...existingInsuredInfo,
            ...tempInsuredDetails,
          };
          tempPropIns[existingPropertyInsuredInfoIndex] = existingInsuredInfo;
        } else {
          addNew = true;
          const otherFields = {
            cashValueAnnual: 0,
            cashValueDistributionYrs: 0,
            deductible: 0,
            premiumGrowth: 0,
            renewAtExpiry: false,
          };
          const completeObject = { ...tempInsuredDetails, ...otherFields };
          tempPropIns.push(completeObject);
        }
        break;
      case 'autoIns':
        let existingAutoInsuredInfoIndex = tempAutoIns.indexOf(
          tempAutoIns.filter(
            (t) =>
              t.insured &&
              tempInsuredDetails.insured &&
              t.insured.toLowerCase() ===
                tempInsuredDetails.insured.toLowerCase() &&
              t.name.toLowerCase() === tempInsuredDetails.name.toLowerCase()
          )[0]
        );
        if (existingAutoInsuredInfoIndex > -1) {
          let existingInsuredInfo = tempAutoIns[existingAutoInsuredInfoIndex];
          existingInsuredInfo = {
            ...existingInsuredInfo,
            ...tempInsuredDetails,
          };
          tempAutoIns[existingAutoInsuredInfoIndex] = existingInsuredInfo;
        } else {
          addNew = true;
          const otherFields = {
            cashValueAnnual: 0,
            cashValueDistributionYrs: 0,
            deductible: 0,
            premiumGrowth: 0,
            renewAtExpiry: false,
          };
          const completeObject = { ...tempInsuredDetails, ...otherFields };
          tempAutoIns.push(completeObject);
        }
        break;
      default:
        break;
    }
    termLife = {
      ...termLife,
      termLifeIns: [...tempTermLifeIns],
      numTermLifeIns: tempTermLifeIns.length,
    };
    wholeLife = {
      ...wholeLife,
      wholeLifeIns: [...tempWholeLifeIns],
      numWholeLifeIns: tempWholeLifeIns.length,
    };
    umbrella = {
      ...umbrella,
      umbrellaIns: [...tempLiabilityIns],
      numUmbrellaIns: tempLiabilityIns.length,
    };
    longTermCare = {
      ...longTermCare,
      ltcIns: [...tempLongTermCare],
      numLtcIns: tempLongTermCare.length,
    };
    property = {
      ...property,
      propIns: [...tempPropIns],
      numPropIns: tempPropIns.length,
    };
    auto = {
      ...auto,
      autoIns: [...tempAutoIns],
      numAutoIns: tempAutoIns.length,
    };
    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      moduleInsurance: {
        ...moduleInsurance,
        termLife: { ...termLife },
        wholeLife: { ...wholeLife },
        umbrella: { ...umbrella },
        longTermCare: { ...longTermCare },
        property: { ...property },
        auto: { ...auto },
      },
      moduleSettings: {
        ...moduleSettings,
        model: { ...tempModel },
      },
      // infoInputsCompleted: {
      //   ...infoInputsCompleted,
      //   termLifeIns: tempTermLifeIns.length > 0 ? true : false,
      //   wholeLifeIns: tempWholeLifeIns.length > 0 ? true : false,
      //   umbrellaIns: tempLiabilityIns.length > 0 ? true : false,
      //   longTermIns: tempLongTermCare.length > 0 ? true : false,
      // },
    };
    let existingIns = existingInsurances;
    if (addNew === true) {
      let filterValue =
        tab === 'umbrellaIns'
          ? 'family'
          : tab === 'propIns'
          ? selectedPropertyMember
          : tab === 'autoIns'
          ? selectedAutoMember
          : selectedMember;
      existingIns = tempPlanDetailsByPlanId.moduleInsurance[mainTab][
        tab
      ].filter((t) => t.insured === filterValue);
    }
    //const newFormDetails = this.getInitialFormDetails();
    this.setState(
      (prevState) => ({
        ...prevState,
        existingInsurances: existingIns,
        isComputed: false,
        isEditable: false,
        isAddNew: false,
        selectionModel: {
          ...prevState.selectionModel,
          [tab]: undefined,
        },
        localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
      }),
      () => {
        triggerUpdatePlannerDetailsinRedux({
          plannerData: tempPlanDetailsByPlanId,
        });
      }
    );
  };

  handleCancelInsurance = (tab, from) => {
    const { isEditable, isAddNew, formDetails, localPlanDetailsByPlanId } =
      this.state;
    let tempFormDetails = { ...formDetails };
    let selectedInsuredInfo =
      tempFormDetails &&
      tempFormDetails[tab] &&
      tempFormDetails[tab][from] !== ''
        ? tempFormDetails[tab]
        : {};
    if (isEditable && tab !== '' && from !== '') {
      tempFormDetails = this.updateFormDetails(
        selectedInsuredInfo,
        tempFormDetails
      );
      this.setState({
        isEditable: false,
        isAddNew: false,
        formDetails: tempFormDetails,
        isFormValid: true,
        formErrors: [],
      });
    }
  };

  getCurrencyValue = (value) => {
    if (value !== '' && !value.toString().includes('$')) {
      const currencyValue = !isNaN(value) ? value : '';
      const numFormat = Intl.NumberFormat('en-US');
      const newNumformat = numFormat.format(currencyValue);
      return `$${newNumformat}`;
    }
    return value;
  };

  getAccounts = (selectedMemberState) => {
    let accounts = [];
    accounts = Object.entries(selectedMemberState).reduce(
      (acc, [key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            const index = acc.indexOf(
              acc.filter(
                (a) =>
                  a.id.toLowerCase() === `${item.nickName}-${key}`.toLowerCase()
              )[0]
            );
            if (index === -1) {
              acc.push({
                ...item,
                investmentBucket: key,
                invBalance: this.getCurrencyValue(item.invBalance),
                id: `${item.nickName}-${key}`,
              });
            }
          });
        }
        return acc;
      },
      []
    );
    return accounts;
  };

  handleMemberOptionChange = (e, type) => {
    const { memberOptions } = this.state;
    let fieldValue =
      e && type === 'radio'
        ? e.target.checked
          ? e.target.defaultValue
          : false
        : e.target.value;
    const selectedMemberOption =
      memberOptions.filter((m) => m.id === fieldValue)[0]?.key || '';
    this.setState({
      selectedMember: fieldValue,
      selectedMemberOption,
      selectedInvestmentAccount: {},
      selectedProductType: '',
      tickers: [],
      selectedTicker: '',
      tickersAddedToPlan: [],
      tickersAvailableInPlan: [],
      remainingBalance: '',
    });
  };

  handleCheckboxFieldChange = (e, index) => {
    const { assetMixBuildInfo, memberOptions } = this.state;
    let { checked } = e && e.target;
    let tempMemberCheckboxOptions = [...memberOptions];
    tempMemberCheckboxOptions[index].isSelected = checked;
    let selectedMembers = tempMemberCheckboxOptions.filter((f) => f.isSelected);
    let selectedMember = '';
    if (selectedMembers && selectedMembers.length > 0) {
      if (selectedMembers.length === tempMemberCheckboxOptions.length) {
        selectedMember = 'family';
      } else {
        selectedMember = selectedMembers[0].id;
      }
      const selectedAssetMixInfo = assetMixBuildInfo.filter(
        (a) => a.name.toLowerCase() === selectedMember.toLowerCase()
      );
      this.setState({
        memberOptions: tempMemberCheckboxOptions,
        selectedAssetMix:
          selectedAssetMixInfo.length > 0
            ? selectedAssetMixInfo[0].elements
            : [],
      });
    }
  };

  transformValue = (val, valueType) => {
    let value = val;
    if (valueType === 'currency') {
      const numFormat = Intl.NumberFormat('en-US');
      let newNumformat = numFormat.format(0);
      let currencyFieldValue = '';
      if (value !== null && value !== undefined && value !== '') {
        if (value.toString().includes('$')) {
          currencyFieldValue = value.split('$')[1];
          currencyFieldValue = currencyFieldValue.replace(/,/g, '');
          if (currencyFieldValue !== '' && !isNaN(currencyFieldValue)) {
            newNumformat = numFormat.format(currencyFieldValue);
          }
        } else if (!isNaN(value)) {
          newNumformat = numFormat.format(value);
        }
        value = `$${newNumformat}`;
      } else {
        value = `$${newNumformat}`;
      }
    } else if (valueType === 'number') {
      if (value !== '' && value !== null && value !== undefined) {
        if (!isNaN(value) && Number(value) >= 0) {
          value = Number(value);
        } else {
          value = 0;
        }
      } else {
        value = 0;
      }
    }
    return value;
  };

  rowClick = (params, event, details, tab) => {
    const { row } = params;
    if (row && Object.keys(row).length > 0) {
      this.setState((prevState) => ({
        ...prevState,
        formDetails: {
          ...prevState.formDetails,
          [tab]: {
            ...prevState.formDetails[tab],
            coverage: row.coverage,
            coverageYrs: row.coverageYrs,
            premium: row.premium,
            cashValueAnnual: row.cashValueAnnual,
            cashValueDistributionYrs: row.cashValueDistributionYrs,
          },
        },
      }));
    }
  };

  setSelectionModel = (newSelectionModel, tab) => {
    this.setState((prevState) => ({
      ...prevState,
      selectionModel: {
        ...prevState.selectionModel,
        [tab]: newSelectionModel[0],
      },
    }));
  };

  concatInsurances = (type) => {
    const { formDetails, existingInsurances } = this.state;
    const existingInsuranceOptions = existingInsurances.map((m) => {
      return {
        title: m.name,
        id: m.name,
      };
    });
    // const finalInsurances =
    //   formDetails && formDetails[type] && formDetails[type].insured !== ''
    //     ? [...[{ title: 'Add New', id: 'addNew' }], ...existingInsuranceOptions]
    //     : existingInsuranceOptions;
    return existingInsuranceOptions;
  };

  updateFormDetails = (selectedInsuredInfo, tempFormDetails) => {
    const { solutionsSubTab } = this.state;
    let existingInsurances = this.getExistingInsurances(
      solutionsSubTab,
      selectedInsuredInfo
    );
    const selectedInsurance = existingInsurances.filter(
      (f) => f.insured === selectedInsuredInfo.insured
    )[0];
    const tab = this.getTabNames(solutionsSubTab);
    if (selectedInsurance && Object.keys(selectedInsurance).length > 0) {
      tempFormDetails = {
        ...tempFormDetails,
        [tab]: {
          ...tempFormDetails[tab],
          coverage: (selectedInsurance && selectedInsurance.coverage) || '',
          coverageYrs:
            (selectedInsurance && selectedInsurance.coverageYrs) || '',
          coverageStartYr:
            (selectedInsurance &&
              new Date(`${selectedInsurance.coverageStartYr}`)) ||
            new Date(),
          premium: (selectedInsurance && selectedInsurance.premium) || '',
          cashValueAnnual:
            (selectedInsurance && selectedInsurance.cashValueAnnual) || '',
          cashValueDistributionYrs:
            (selectedInsurance && selectedInsurance.cashValueDistributionYrs) ||
            '',
          name: (selectedInsurance && selectedInsurance.name) || '',
        },
      };
    } else {
      tempFormDetails = {
        ...tempFormDetails,
        [tab]: {
          ...tempFormDetails[tab],
          coverage: '',
          coverageYrs: '',
          coverageStartYr: new Date(),
          premium: '',
          cashValueAnnual: '',
          cashValueDistributionYrs: '',
          name: '',
        },
      };
    }
    this.setState({
      existingInsurances,
    });
    return tempFormDetails;
  };

  handleBlur = (
    event,
    from,
    type,
    modelObj,
    tab,
    subTab,
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    valueType,
    memberFieldType,
    idName,
    member,
    state,
    setFormDetailsObj
  ) => {
    let { value } = event && event.target;
    let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
    if (valueType === 'percentage') {
      let percentFieldValue = '';
      if (value !== null && value !== undefined && value !== '') {
        if (value.toString().includes('%')) {
          percentFieldValue = value.split('%')[0];
          if (
            percentFieldValue !== '' &&
            !isNaN(percentFieldValue) &&
            Number(percentFieldValue) >= 0
          ) {
            percentFieldValue = parseFloat(percentFieldValue).toFixed(2);
            value = `${percentFieldValue}%`;
          } else {
            value = `${0}%`;
          }
        } else if (!isNaN(value) && Number(value) >= 0) {
          percentFieldValue = parseFloat(value).toFixed(2);
          value = `${percentFieldValue}%`;
        } else {
          value = `${0}%`;
        }
      } else {
        value = `${0}%`;
      }
    }
    if (valueType === 'currency') {
      const numFormat = Intl.NumberFormat('en-US');
      let newNumformat = numFormat.format(0);
      let currencyFieldValue = '';
      if (value !== null && value !== undefined && value !== '') {
        if (value.toString().includes('$')) {
          currencyFieldValue = value.split('$')[1];
          currencyFieldValue = currencyFieldValue.replace(/,/g, '');
          if (
            currencyFieldValue !== '' &&
            !isNaN(currencyFieldValue) &&
            Number(currencyFieldValue) >= 0
          ) {
            newNumformat = numFormat.format(currencyFieldValue);
          }
        } else if (!isNaN(value) && Number(value) >= 0) {
          newNumformat = numFormat.format(value);
        }
        value = `$${newNumformat}`;
      } else {
        value = `$${newNumformat}`;
      }
    }
    if (valueType === 'number') {
      if (value !== '' && value !== null && value !== undefined) {
        if (!isNaN(value) && Number(value) >= 0) {
          value = Number(value);
        } else {
          value = 0;
        }
      } else {
        value = 0;
      }
    }
    let valueToApi = '';
    if (type === 'input') {
      if (value !== null && value !== undefined && value !== '') {
        if (isNaN(value) && value.includes('%')) {
          const percentValue = value.split('%')[0];
          if (!isNaN(percentValue)) {
            valueToApi = parseFloat(parseFloat(percentValue) / 100);
          } else {
            valueToApi = 0;
          }
        } else if (isNaN(value) && value.includes('$')) {
          let dollarValue = value.split('$')[1];
          dollarValue = dollarValue.replace(/,/g, '');
          if (!isNaN(dollarValue)) {
            valueToApi = Number(dollarValue);
          } else {
            valueToApi = 0;
          }
        } else if (!isNaN(value)) {
          valueToApi = Number(value);
        }
      } else {
        valueToApi = 0;
      }
    }
    // Dynamically fetch the moduleTab object based on tab
    const tabModule = tempPlanDetailsByPlanId[tab] || {}; // Tab will be module Family
    const memberModule = tabModule[subTab]
      ? tabModule[subTab][memberFieldType] || {}
      : {};

    let tempModelObj = {};
    if (memberFieldType) {
      tempModelObj = {
        ...modelObj,
        [memberFieldType]: { ...memberModule, [from]: value },
      };
    } else {
      tempModelObj = {
        ...modelObj,
        [from]: value,
      };
    }
    setFormDetailsObj(tempModelObj);
    if (memberFieldType) {
      tempPlanDetailsByPlanId = {
        ...tempPlanDetailsByPlanId,
        [tab]: {
          ...tabModule,
          [subTab]: {
            ...tabModule[subTab],
            [memberFieldType]: {
              ...memberModule,
              [from]: valueToApi,
            },
          },
        },
      };
    } else {
      tempPlanDetailsByPlanId = {
        ...tempPlanDetailsByPlanId,
        [tab]: {
          ...tabModule,
          [subTab]: {
            ...tabModule[subTab],
            [from]: valueToApi,
          },
        },
      };
    }
    let ageModified = false;
    if (from === 'age' || from === 'retirementAge') {
      ageModified = true;
    }
    setLocalPlanDetailsByPlanId(tempPlanDetailsByPlanId, ageModified);
  };

  handleFormFieldChangeInsurance = (e, from, type, tab, addNew = false) => {
    const {
      formDetails,
      solutionsSubTab,
      selectedMember,
      selectedPropertyMember,
      selectedAutoMember,
      selectedInsurance,
      existingInsurances,
    } = this.state;
    let tempFormDetails = { ...formDetails };
    let fieldValue =
      e && type === 'radio'
        ? e.target.checked
          ? e.target.defaultValue
          : false
        : e && type === 'datepicker'
        ? e
        : e.target.value;
    let selectedMemberValue = selectedMember;
    let selectedPropertyValue = selectedPropertyMember;
    let selectedAutoValue = selectedAutoMember;
    if (type === 'radio') {
      selectedMemberValue = fieldValue;
      if (tab === 'propIns') {
        selectedPropertyValue = fieldValue;
      }
      if (tab === 'autoIns') {
        selectedAutoValue = fieldValue;
      }
    }
    tempFormDetails = {
      ...tempFormDetails,
      [tab]: {
        ...tempFormDetails[tab],
        [from]: fieldValue,
      },
    };
    if (type === 'input') {
      fieldValue =
        fieldValue !== '' && fieldValue !== undefined && fieldValue !== null
          ? isNaN(fieldValue) && fieldValue.includes('%')
            ? parseFloat(parseFloat(fieldValue.split('%')[0]) / 100)
            : isNaN(fieldValue) && fieldValue.includes('$')
            ? fieldValue.split('$')[1].replace(/,/g, '') &&
              !isNaN(fieldValue.split('$')[1].replace(/,/g, ''))
              ? parseFloat(fieldValue.split('$')[1].replace(/,/g, ''))
              : fieldValue
            : !isNaN(fieldValue)
            ? Number(fieldValue)
            : fieldValue
          : '';
    } else if (type === 'radio' && from === 'insured') {
      let selectedInsuredInfo =
        tempFormDetails &&
        tempFormDetails[tab] &&
        tempFormDetails[tab][from] !== ''
          ? tempFormDetails[tab]
          : {};
      tempFormDetails = this.updateFormDetails(
        selectedInsuredInfo,
        tempFormDetails
      );
    }
    if (from !== 'name' && from === 'insured' && addNew === true) {
      const fieldValue =
        tab === 'umbrellaIns'
          ? 'family'
          : tab === 'propIns'
          ? selectedPropertyMember
          : tab === 'autoIns'
          ? selectedAutoMember
          : selectedMember;

      tempFormDetails = {
        ...tempFormDetails,
        insured:
          tab === 'umbrellaIns'
            ? 'family'
            : tab === 'propIns'
            ? selectedPropertyMember
            : tab === 'autoIns'
            ? selectedAutoMember
            : selectedMember,
        [tab]: {
          [from]: fieldValue,
          coverage: '', // clear other values except insured when change of insured value (e.g. mom or dad)
          coverageYrs: '',
          premium: '',
          cashValueAnnual: '',
          cashValueDistributionYrs: '',
          coverageStartYr: new Date(),
          name: '',
        },
      };
    }
    // else {
    //   // update individual field on change event by retaining existing fields
    //   tempFormDetails = {
    //     ...tempFormDetails,
    //     [tab]: {
    //       ...tempFormDetails[tab],
    //       [from]: fieldValue,
    //     },
    //   };
    // }

    this.setState((prevState) => ({
      ...prevState,
      selectedPropertyMember:
        tab === 'propIns'
          ? selectedPropertyValue
          : prevState.selectedPropertyMember,
      selectedAutoMember:
        tab === 'autoIns' ? selectedAutoMember : prevState.selectedAutoMember,
      selectedMember:
        tab !== 'propIns' || tab !== 'autoIns'
          ? selectedMemberValue
          : prevState.selectedMember,
      isComputed:
        type === 'radio' && from === 'insured' ? false : prevState.isComputed,
      formDetails: { ...tempFormDetails },
      selectionModel: {
        ...prevState.selectionModel,
        [tab]: type === 'radio' && from === 'insured' ? undefined : [tab],
      },
    }));
    if (type === 'radio') {
      this.setState({ isEditable: false, isAddNew: false });
    } else if (addNew === true) {
      this.setState({ isEditable: true, isAddNew: true });
    }
  };

  renderTermLifeContent = (selectedChart) => {
    return (
      <div>
        <TermLifeContent
          termLifeInsuranceProducts={this.props.termLifeInsuranceProducts}
          state={this.state}
          concatInsurances={this.concatInsurances}
          transformValue={this.transformValue}
          handleFormFieldChangeInsurance={this.handleFormFieldChangeInsurance}
          handleBlur={this.handleBlur}
          onAccountChange={this.onAccountChange}
          setSelectionModel={this.setSelectionModel}
          rowClick={this.rowClick}
          handleEditInsurance={this.handleEditInsurance}
          handleAddToPortfolio={this.handleAddToPortfolio}
          handleDeleteInsurance={this.handleDeleteInsurance}
          handleCancelInsurance={this.handleCancelInsurance}
          setLastStepInParent={this.setIsLastStep}
        />
      </div>
    );
  };

  renderWholeLifeContent = () => {
    return (
      <div>
        <WholeLifeContent
          wholeLifeInsuranceProducts={this.props.wholeLifeInsuranceProducts}
          state={this.state}
          concatInsurances={this.concatInsurances}
          transformValue={this.transformValue}
          handleFormFieldChangeInsurance={this.handleFormFieldChangeInsurance}
          onAccountChange={this.onAccountChange}
          setSelectionModel={this.setSelectionModel}
          rowClick={this.rowClick}
          handleEditInsurance={this.handleEditInsurance}
          handleAddToPortfolio={this.handleAddToPortfolio}
          handleDeleteInsurance={this.handleDeleteInsurance}
          handleCancelInsurance={this.handleCancelInsurance}
          setLastStepInParent={this.setIsLastStep}
        />
      </div>
    );
  };

  renderUmbrellaContent = () => {
    return (
      <div>
        <UmbrellaLifeContent
          umbrellaInsuranceProducts={this.props.umbrellaInsuranceProducts}
          state={this.state}
          concatInsurances={this.concatInsurances}
          transformValue={this.transformValue}
          handleFormFieldChangeInsurance={this.handleFormFieldChangeInsurance}
          onAccountChange={this.onAccountChange}
          setSelectionModel={this.setSelectionModel}
          rowClick={this.rowClick}
          handleEditInsurance={this.handleEditInsurance}
          handleAddToPortfolio={this.handleAddToPortfolio}
          handleDeleteInsurance={this.handleDeleteInsurance}
          handleCancelInsurance={this.handleCancelInsurance}
          setLastStepInParent={this.setIsLastStep}
        />
      </div>
    );
  };

  renderLongTermCareContent = () => {
    return (
      <div>
        <LongTermCareContent
          longTermCareInsuranceProducts={
            this.props.longTermCareInsuranceProducts
          }
          state={this.state}
          concatInsurances={this.concatInsurances}
          transformValue={this.transformValue}
          handleFormFieldChangeInsurance={this.handleFormFieldChangeInsurance}
          onAccountChange={this.onAccountChange}
          setSelectionModel={this.setSelectionModel}
          rowClick={this.rowClick}
          handleEditInsurance={this.handleEditInsurance}
          handleAddToPortfolio={this.handleAddToPortfolio}
          handleDeleteInsurance={this.handleDeleteInsurance}
          handleCancelInsurance={this.handleCancelInsurance}
          setLastStepInParent={this.setIsLastStep}
        />
      </div>
    );
  };

  renderPropertyContent = () => {
    return (
      <div>
        <PropertyContent
          propertyInsuranceProducts={this.props.propertyInsuranceProducts}
          state={this.state}
          concatInsurances={this.concatInsurances}
          transformValue={this.transformValue}
          handleFormFieldChangeInsurance={this.handleFormFieldChangeInsurance}
          onAccountChange={this.onAccountChange}
          setSelectionModel={this.setSelectionModel}
          rowClick={this.rowClick}
          handleEditInsurance={this.handleEditInsurance}
          handleAddToPortfolio={this.handleAddToPortfolio}
          handleDeleteInsurance={this.handleDeleteInsurance}
          onPropertyChange={this.onPropertyChange}
          handleCancelInsurance={this.handleCancelInsurance}
          setLastStepInParent={this.setIsLastStep}
        />
      </div>
    );
  };
  renderAutoContent = () => {
    return (
      <div>
        <AutoContent
          autoInsuranceProducts={this.props.autoInsuranceProducts}
          state={this.state}
          concatInsurances={this.concatInsurances}
          transformValue={this.transformValue}
          handleFormFieldChangeInsurance={this.handleFormFieldChangeInsurance}
          onAccountChange={this.onAccountChange}
          setSelectionModel={this.setSelectionModel}
          rowClick={this.rowClick}
          handleEditInsurance={this.handleEditInsurance}
          handleAddToPortfolio={this.handleAddToPortfolio}
          handleDeleteInsurance={this.handleDeleteInsurance}
          onAutoChange={this.onAutoChange}
          handleCancelInsurance={this.handleCancelInsurance}
          setLastStepInParent={this.setIsLastStep}
        />
      </div>
    );
  };

  renderChartContainer = () => {
    const { charts, selectedChart, triggerPushPinCharts } = this.props;
    const { pushPinLabel, parentTabValue, subTabValue, tabInfo } = this.state;
    if (charts && charts.length > 0) {
      const chartsData =
        (tabInfo[parentTabValue] &&
          tabInfo[parentTabValue].subMenu &&
          tabInfo[parentTabValue].subMenu.length > 0 &&
          tabInfo[parentTabValue].subMenu[subTabValue]) ||
        {};
      return (
        <div className="client_chart_main_div Chart">
          <div className="client_chart_inner">
            <NewChartsContainer
              selectedChart={selectedChart}
              chartsData={chartsData}
              triggerPushPinCharts={triggerPushPinCharts}
              isDashboard={false}
              pushPinLabel={pushPinLabel}
            />
          </div>
          <div className="client_chart_inner">
            <NewChartsContainer
              isAux={true}
              selectedChart={selectedChart}
              chartsData={chartsData}
              triggerPushPinCharts={triggerPushPinCharts}
              isDashboard={false}
              pushPinLabel={pushPinLabel}
            />
          </div>
        </div>
      );
    }
  };

  deriveTableAuxAndMain = (tablesData) => {
    const { subtabType } = tablesData || {};
    const { [subtabType]: { aux = [], main = [] } = {} } = tablesData || {};
    const auxAndMain = { aux, main };
    return auxAndMain;
  };

  renderTableContainer = () => {
    const { tables, selectedTable } = this.props;
    const { parentTabValue, subTabValue, tabInfo } = this.state;
    if (tables && tables.length > 0) {
      const parentTabName =
        (tabInfo[parentTabValue] && tabInfo[parentTabValue].tabName) || '';
      const tablesData =
        (tabInfo[parentTabValue] &&
          tabInfo[parentTabValue].subMenu &&
          tabInfo[parentTabValue].subMenu.length > 0 &&
          tabInfo[parentTabValue].subMenu[subTabValue]) ||
        {};
      const auxAndMain = this.deriveTableAuxAndMain(tablesData);
      const { aux = [], main = [] } = auxAndMain;
      if ((main && main.length > 0) || (aux && aux.length > 0)) {
        return (
          <div className="client_chart_main_div Table">
            <div className="client_chart_inner">
              <div className="Table_main_outer d-lg-flex">
                {main.map((val, index) => {
                  return generateTableHTML(val, parentTabName);
                })}
              </div>
            </div>
            <div className="client_chart_inner">
              <div className="Table_main_outer d-lg-flex">
                {aux.map((val, index) => {
                  return generateTableHTML(val, parentTabName);
                })}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  renderDataContainer = () => {
    const { data, selectedData } = this.props;
    const { parentTabValue, subTabValue, tabInfo } = this.state;
    if (data && data.length > 0) {
      const dataInfo =
        (tabInfo[parentTabValue] &&
          tabInfo[parentTabValue].subMenu &&
          tabInfo[parentTabValue].subMenu.length > 0 &&
          tabInfo[parentTabValue].subMenu[subTabValue]) ||
        {};
      const { subtabName, subtabType, build, input } = dataInfo || {};
      let mainData = [];
      let auxData = [];
      if (subtabType === 'input' && input) {
        const {
          main: { heading, rows = [] },
          aux,
        } = input;
        if (aux && aux.rows && aux.rows.length > 0) {
          auxData = [
            {
              name: aux.heading,
              elements: aux.rows.filter((a) => a && a.label && a.value),
            },
          ];
        }
        mainData =
          rows && rows.length > 0
            ? [
                {
                  name: heading,
                  elements: rows.filter((r) => r && r.label && r.value),
                },
              ]
            : [];
      } else if (subtabType === 'build' && build) {
        const { main = [], aux = [] } = build;
        const tempMainData =
          main &&
          main.filter(
            (m) => m.elements && m.elements.find((v) => v.label && v.value)
          );
        mainData =
          tempMainData.length > 0
            ? tempMainData.map((m, i) => {
                return {
                  name: m.name || 'Heading',
                  elements: m.elements.filter((v) => v && v.label && v.value),
                };
              })
            : [];
        const tempAuxData =
          (aux &&
            aux.filter(
              (m) =>
                m && m.elements && m.elements.find((v) => v.label && v.value)
            )) ||
          [];
        auxData =
          tempAuxData.length > 0
            ? aux.map((m, i) => {
                return {
                  name: m.name || 'Heading',
                  elements: m.elements,
                };
              })
            : [];
      }
      if (mainData.length > 0 || auxData.length > 0) {
        return (
          <div className="client_chart_main_div">
            <div className="client_chart_inner">
              <div className="Table_main_outer d-lg-flex">
                {mainData.map((val, index) => {
                  return (
                    <table
                      className={
                        mainData.length > 2
                          ? 'small-width-grid'
                          : 'full-width-grid'
                      }
                    >
                      {val && val.name && (
                        <thead>
                          <tr>
                            <th className="left">{''}</th>
                            <th className="center-data">{val?.name}</th>
                            <th className="left">{''}</th>
                          </tr>
                        </thead>
                      )}
                      {val.elements &&
                        val.elements.map((ele) => {
                          return (
                            <tbody>
                              <tr>
                                <td className="data-label">{ele.label}</td>
                                <td></td>
                                <td className="data-value">{ele.value}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </table>
                  );
                })}
              </div>
            </div>
            <div className="client_chart_inner">
              <div className="Table_main_outer d-lg-flex">
                {auxData.map((val, index) => {
                  return (
                    <table
                      className={
                        auxData.length > 2
                          ? 'small-width-grid'
                          : 'full-width-grid'
                      }
                    >
                      {val && val.name && (
                        <thead>
                          <tr>
                            <th className="left">{''}</th>
                            <th className="center-data">{val?.name}</th>
                            <th className="left">{''}</th>
                          </tr>
                        </thead>
                      )}
                      {val.elements &&
                        val.elements.map((ele) => {
                          return (
                            <tbody>
                              <tr>
                                <td className="data-label">{ele.label}</td>
                                <td></td>
                                <td className="data-value">{ele.value}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </table>
                  );
                })}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  renderCustomChart = (renderWhatData) => {
    const { charts } = this.props;
    return <CustomChart charts={charts} renderWhatData={renderWhatData} />;
  };

  renderCustomTable = (renderWhatData) => {
    const { tables } = this.props;
    return <CustomTable tables={tables} renderWhatData={renderWhatData} />;
  };

  renderCustomData = (renderWhatData) => {
    const { data } = this.props;
    return <CustomData data={data} renderWhatData={renderWhatData} />;
  };

  renderCustomContentContainer = ({ renderTabType, data }) => {
    switch (renderTabType && renderTabType.toLowerCase()) {
      case 'chart':
        return this.renderCustomChart(data);
      case 'table':
        return this.renderCustomTable(data);
      case 'data':
        return this.renderCustomData(data);
      default:
        break;
    }
  };

  deriveAuxMainAndProfile = () => {
    const { solutions } = this.props;
    const { solutionsParentTab, solutionsSubTab } = this.state;
    let aux = {};
    let main = {};
    let profile = {};
    switch (solutionsParentTab) {
      case 0:
        const { investments: { rebalance = {} } = {} } = solutions;
        aux = rebalance.aux || {};
        main = rebalance.main || {};
        profile = rebalance.profile || {};
        break;
      case 1:
        const { expenses: { optimize = {} } = {} } = solutions;
        aux = optimize.aux || {};
        main = optimize.main || {};
        profile = optimize.profile || {};
        break;
      case 2:
        const { goals: { optimize: goalsOptimize = {} } = {} } = solutions;
        aux = goalsOptimize.aux || {};
        main = goalsOptimize.main || {};
        profile = goalsOptimize.profile || {};
        break;
      case 3:
        const {
          insurance: {
            termLife = {},
            wholeLife = {},
            longTermCare = {},
            umbrella = {},
            property = {},
            auto = {},
          } = {},
        } = solutions;
        switch (solutionsSubTab) {
          case 0:
            aux = termLife.aux || {};
            main = termLife.main || {};
            profile = termLife.profile || {};
            break;
          case 1:
            aux = wholeLife.aux || {};
            main = wholeLife.main || {};
            profile = wholeLife.profile || {};
            break;
          case 2:
            aux = longTermCare.aux || {};
            main = longTermCare.main || {};
            profile = longTermCare.profile || {};
            break;
          case 3:
            aux = umbrella.aux || {};
            main = umbrella.main || {};
            profile = umbrella.profile || {};
            break;
          case 4:
            aux = property.aux || {};
            main = property.main || {};
            profile = property.profile || {};
            break;
          case 5:
            aux = auto.aux || {};
            main = auto.main || {};
            profile = auto.profile || {};
            break;
          default:
            break;
        }
        break;
      case 4:
        break;
      case 5:
        break;
      default:
        break;
    }
    const auxMainAndProfile = { aux, main, profile };
    return auxMainAndProfile;
  };

  renderCustomContainer = () => {
    const auxMainAndProfile = this.deriveAuxMainAndProfile();
    const { aux = {}, main = {}, profile = {} } = auxMainAndProfile;
    const { tabType: auxTabType = '' } = aux;
    const { tabType: mainTabType = '' } = main;
    const { tabType: profileTabType = '' } = profile;
    return (
      <div className="client_chart_main_div">
        {['profile', 'main', 'aux'].map((p) => {
          let obj = {};
          if (p === 'main') {
            obj = { renderTabType: mainTabType, data: main };
          } else if (p === 'aux') {
            obj = { renderTabType: auxTabType, data: aux };
          } else if (p === 'profile') {
            obj = { renderTabType: profileTabType, data: profile };
          }
          return (
            <div className="client_chart_inner">
              {this.renderCustomContentContainer(obj)}
            </div>
          );
        })}
      </div>
    );
  };

  renderContentContainer = () => {
    const { renderType } = this.state;
    switch (renderType) {
      case 'Chart':
        return this.renderChartContainer();
      case 'Table':
        return this.renderTableContainer();
      case 'Data':
        return this.renderDataContainer();
      case 'Custom':
        return this.renderCustomContainer();
      default:
        break;
    }
  };

  renderInsuranceContentContainer = (from) => {
    switch (from) {
      case 'termLife':
        return this.renderTermLifeContent();
      case 'wholeLife':
        return this.renderWholeLifeContent();
      case 'umbrella':
        return this.renderUmbrellaContent();
      case 'longTermCare':
        return this.renderLongTermCareContent();
      case 'property':
        return this.renderPropertyContent();
      case 'auto':
        return this.renderAutoContent();
      default:
        return this.renderTermLifeContent();
    }
  };

  renderExpensesContainer = (from) => {
    const { expenseFactorDetails, planDetailsByPlanId } = this.props;
    const { localPlanDetailsByPlanId } = this.state;
    const { moduleFamily: { infoExpenses = {} } = {} } = planDetailsByPlanId;
    return (
      <Grid container>
        <Grid item xs={12}>
          <SolutionsJourney
            updateExpenseDetailsSolutionsInRedux={
              this.updateExpenseDetailsSolutionsInRedux
            }
            storeValues={getInfoExpenseStoreValuesV2(
              infoExpenses,
              expenseFactorDetails
            )}
          />
        </Grid>
      </Grid>
    );
  };

  onClickDismiss = (key) => () => {
    const { closeSnackbar } = this.props;
    closeSnackbar(key);
  };

  handleDisplayToast = () => {
    const { enqueueSnackbar, isPlanDetailsSaved, planDetailsSimulated } =
      this.props;
    const toasterMsg = 'Updated Plan details, click on Simulate';
    this.key = enqueueSnackbar(toasterMsg, {
      variant: 'success',
      autoHideDuration: 5000,
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
      action: (key) => <CloseIcon onClick={this.onClickDismiss(key)} />,
    });
  };

  updateExpenseDetailsSolutionsInRedux = (
    expenseDetails,
    stepName,
    isLastStep
  ) => {
    const { triggerUpdatePlannerDetailsinRedux } = this.props;
    const { localPlanDetailsByPlanId } = this.state;
    const {
      moduleFamily: {
        infoExpenses: { retFactor = {}, horFactor = {} } = {},
        infoExpenses = {},
      } = {},
      moduleFamily = {},
    } = localPlanDetailsByPlanId;
    let tempInfoExpenses = { ...infoExpenses };
    let tempRetFactor = { ...retFactor };
    let tempHorFactor = { ...horFactor };
    if (
      stepName &&
      stepName !== '' &&
      stepName === 'retirement' &&
      expenseDetails &&
      Object.keys(expenseDetails).length > 0
    ) {
      tempRetFactor = {
        ...tempRetFactor,
        rental: Number(expenseDetails.retirementRentalExpense),
      };
      tempInfoExpenses = {
        ...tempInfoExpenses,
        discRetFactor1: Number(expenseDetails.retirementFactor1),
        discRetFactor2: Number(expenseDetails.retirementFactor2),
        discRetFactor3: Number(expenseDetails.retirementFactor3),
        useDiscretionaryFlag: expenseDetails.discSameFactorAcross,
      };
    } else if (stepName === 'horizon') {
      tempInfoExpenses = {
        ...tempInfoExpenses,
        useDiscretionaryFlag: expenseDetails.discSameFactorAcross,
        discHorFactor: Number(expenseDetails.horizonFactorPercent),
      };
      tempHorFactor = {
        ...tempHorFactor,
        rental: Number(expenseDetails.horizonRentalExpense),
      };
    } else if (stepName === 'affordabilityFactor') {
      tempInfoExpenses = {
        ...tempInfoExpenses,
        affordabilityFactor: expenseDetails.affordabilityFactor,
      };
    } else {
      tempHorFactor = {
        ...tempHorFactor,
        [stepName]: Number(expenseDetails.horizonRentalExpense),
      };
      tempRetFactor = {
        ...tempRetFactor,
        [stepName]: Number(expenseDetails.retPercentValue),
      };
    }
    tempInfoExpenses = {
      ...tempInfoExpenses,
      horFactor: { ...tempHorFactor },
      retFactor: { ...tempRetFactor },
    };
    let tempPlanDetailsByPlanId = {
      ...localPlanDetailsByPlanId,
      moduleFamily: {
        ...moduleFamily,
        infoExpenses: { ...tempInfoExpenses },
      },
    };
    triggerUpdatePlannerDetailsinRedux({
      plannerData: tempPlanDetailsByPlanId,
    });
    if (isLastStep) {
      this.handleDisplayToast();
    }
  };

  handleSubTabChange = (event, value) => {
    const {
      triggerSetSelectedChart,
      triggerSetSelectedTable,
      triggerSetSelectedData,
    } = this.props;
    const { parentTabValue, tabInfo, renderType } = this.state;
    const selectedItem =
      (tabInfo[parentTabValue] &&
        tabInfo[parentTabValue].subMenu &&
        tabInfo[parentTabValue].subMenu.length > 0 &&
        tabInfo[parentTabValue].subMenu[value].subtabName) ||
      '';
    switch (renderType) {
      case 'Chart':
        triggerSetSelectedChart(selectedItem);
        break;
      case 'Table':
        triggerSetSelectedTable(selectedItem);
        break;
      case 'Data':
        triggerSetSelectedData(selectedItem);
        break;
      default:
        break;
    }
    this.setState({
      subTabValue: value,
      isEditable: false,
    });
  };

  handleParentTabChange = (event, value) => {
    this.setState({
      parentTabValue: value,
      subTabValue: 0,
    });
  };

  getParentTabNames = (tabValue) => {
    switch (tabValue) {
      case 0:
        return 'investments';
      case 1:
        return 'expenses';
      case 2:
        return 'goals';
      case 3:
        return 'insurance';
      case 4:
        return 'annuities';
      case 5:
        return 'debt';
      default:
        return 'investments';
    }
  };

  handleSolutionsParentTabChange = (event, value) => {
    const {
      triggerSetBeforeAfterAssetMixValues,
      triggerSetAssetAllocListByClientIdAction,
    } = this.props;
    const isLastStep = value === 0 ? true : false;
    const solutionsParentTabName = this.getParentTabNames(value);
    history.push(`/solutions?tab=${solutionsParentTabName}`);
    this.setState({
      solutionsParentTab: value,
      solutionsSubTab: 0,
      isEditable: false,
      isLastStep,
      selectedRecommendedAssetAllocId: '',
      assetMixBuildInfo: [],
      targetAssetMixBuildInfo: [],
      selectedAllocationInfo: {},
      selectedDDNameIndex: 0,
      isFormValid: true,
      formErrors: [],
    });
    triggerSetBeforeAfterAssetMixValues([]); // to re-calculate before after values on tab change
    triggerSetAssetAllocListByClientIdAction([]);
  };

  handleSolutionsSubTabChange = (event, value) => {
    this.setState({
      solutionsSubTab: value,
      isComputed: false,
      isEditable: false,
      selectedDDNameIndex: 0,
      isFormValid: true,
      formErrors: [],
    });
  };

  renderSubMenuContainer = () => {
    const { subTabValue, parentTabValue, tabInfo } = this.state;
    const subMenus = tabInfo[parentTabValue]
      ? tabInfo[parentTabValue].subMenu
      : [];
    return (
      <div className="chrono-sub-tab">
        <Tabs
          value={subTabValue}
          onChange={this.handleSubTabChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          {subMenus &&
            subMenus.map((item, index) => (
              <Tab
                label={item.subtabName}
                {...a11yProps(index)}
                sx={{ padding: '10px !important' }}
              />
            ))}
        </Tabs>
      </div>
    );
  };

  updateRenderType = (e, renderType) => {
    this.setState({
      renderType,
      parentTabValue: 0,
      subTabValue: 0,
      tabInfo: [],
    });
  };

  handleEditInsurance = (e, solutionSubTab, isNewRec = false) => {
    this.setState({ isEditable: true, isAddNew: isNewRec });
  };

  onInvAccountChange = (event) => {
    const { availableInvestmentAccounts } = this.state;
    let { value: selectedAccount } = event.target;
    const selectedAccountInfo = availableInvestmentAccounts.filter(
      (f) => f.id.toLowerCase() === selectedAccount.toLowerCase()
    );
    let selectedInvestmentAccount = {};
    if (selectedAccountInfo && selectedAccountInfo.length > 0) {
      selectedInvestmentAccount = selectedAccountInfo[0];
    }
    this.setState({
      selectedAccount,
      selectedInvestmentAccount,
      tickersAvailableInPlan: selectedInvestmentAccount?.products || [],
      balance: this.transformValue(selectedInvestmentAccount?.invBalance),
      selectedProductType: '',
      //   tickersAddedToPlan: [],
      tickers: [],
      selectedTicker: '',
      //   remainingBalance: '',
    });
  };
  onAutoChange = (event, properties, tab, from) => {
    let { value: selectedAuto } = event.target;
    const {
      formDetails,
      existingInsurances,
      selectedMember,
      selectedAutoMember,
    } = this.state;
    let tempFormDetails = { ...formDetails };

    let selectedAutoValue = selectedAutoMember;
    if (tab === 'autoIns') {
      selectedAutoValue = selectedAuto;
    }

    tempFormDetails = {
      ...tempFormDetails,
      [tab]: {
        ...tempFormDetails[tab],
        [from]: selectedAuto,
      },
    };
    let selectedInsuredInfo =
      tempFormDetails &&
      tempFormDetails[tab] &&
      tempFormDetails[tab][from] !== ''
        ? tempFormDetails[tab]
        : {};
    tempFormDetails = this.updateFormDetails(
      selectedInsuredInfo,
      tempFormDetails
    );
    this.setState((prevState) => ({
      ...prevState,
      selectedAutoMember: selectedAutoValue,
      isComputed: from === 'insured' ? false : prevState.isComputed,
      formDetails: { ...tempFormDetails },
      isEditable: false,
      isAddNew: false,
      selectionModel: {
        ...prevState.selectionModel,
        [tab]: from === 'insured' ? undefined : [tab],
      },
    }));
  };

  onPropertyChange = (event, properties, tab, from) => {
    let { value: selectedProperty } = event.target;
    const {
      formDetails,
      existingInsurances,
      selectedMember,
      selectedPropertyMember,
    } = this.state;
    let tempFormDetails = { ...formDetails };

    let selectedPropertyValue = selectedPropertyMember;
    if (tab === 'propIns') {
      selectedPropertyValue = selectedProperty;
    }

    tempFormDetails = {
      ...tempFormDetails,
      [tab]: {
        ...tempFormDetails[tab],
        [from]: selectedProperty,
      },
    };
    let selectedInsuredInfo =
      tempFormDetails &&
      tempFormDetails[tab] &&
      tempFormDetails[tab][from] !== ''
        ? tempFormDetails[tab]
        : {};
    tempFormDetails = this.updateFormDetails(
      selectedInsuredInfo,
      tempFormDetails
    );
    this.setState((prevState) => ({
      ...prevState,
      selectedPropertyMember: selectedPropertyValue,
      isComputed: from === 'insured' ? false : prevState.isComputed,
      formDetails: { ...tempFormDetails },
      isEditable: false,
      isAddNew: false,
      selectionModel: {
        ...prevState.selectionModel,
        [tab]: from === 'insured' ? undefined : [tab],
      },
    }));
  };

  onAccountChange = (event, accounts, tab) => {
    let { value: selectedAccount, index } = event.target;
    const { formDetails, existingInsurances, selectedMember } = this.state;
    let tempFormDetails = { ...formDetails };
    const selectedInsurance = existingInsurances.filter(
      (f) => f.name === selectedAccount
    )[0];
    if (selectedInsurance && Object.keys(selectedInsurance).length > 0) {
      tempFormDetails = {
        ...tempFormDetails,
        insured: tab !== 'umbrellaIns' ? selectedMember : 'family',
        [tab]: {
          ...tempFormDetails[tab],
          coverage: (selectedInsurance && selectedInsurance.coverage) || '',
          coverageYrs:
            (selectedInsurance && selectedInsurance.coverageYrs) || '',
          coverageStartYr:
            (selectedInsurance &&
              new Date(`${selectedInsurance.coverageStartYr}`)) ||
            new Date(),
          premium: (selectedInsurance && selectedInsurance.premium) || '',
          cashValueAnnual:
            (selectedInsurance && selectedInsurance.cashValueAnnual) || '',
          cashValueDistributionYrs:
            (selectedInsurance && selectedInsurance.cashValueDistributionYrs) ||
            '',
          name: (selectedInsurance && selectedInsurance.name) || '',
        },
      };
    }
    this.setState({
      isEditable: false,
      formDetails: { ...tempFormDetails },
      selectedDDNameIndex: index,
    });
  };

  onRecommendedAssetAllocationChange = (event) => {
    const {
      triggerGetAssetAllocListByClientId,
      recommendedAssetAllocationValues,
      triggerSetBeforeAfterAssetMixValues,
    } = this.props;
    const { localPlanDetailsByPlanId } = this.state;
    const {
      clientid,
      moduleInvestments: { markets = {} } = {},
      moduleInvestments = {},
    } = localPlanDetailsByPlanId;
    let { value: selectedRecommendedAssetAllocId } = event.target;
    let recommendedRiskLevel = '';
    const tempSelectedRecommendedAssetAlloc =
      recommendedAssetAllocationValues.filter(
        (f) => f.value === selectedRecommendedAssetAllocId
      );
    if (
      tempSelectedRecommendedAssetAlloc &&
      tempSelectedRecommendedAssetAlloc.length > 0
    ) {
      recommendedRiskLevel = tempSelectedRecommendedAssetAlloc[0].riskLevel;
    }
    const req = {
      clientid: clientid,
      recommendedRiskLevel: recommendedRiskLevel,
    };
    let tempMarkets = {
      ...markets,
      recommendedRiskLevel: recommendedRiskLevel,
    };
    const tempPlanDetailsByPlanId = {
      ...localPlanDetailsByPlanId,
      moduleInvestments: {
        ...moduleInvestments,
        markets: { ...tempMarkets },
      },
    };
    this.setState(
      (prevState) => ({
        ...prevState,
        selectedProductType: '',
        tickers: [],
        selectedTicker: '',
        isProductsAlterted: false,
        localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
        assetMixBuildInfo: [],
        targetAssetMixBuildInfo: [],
        selectedAllocationInfo: {},
        assetAllocId: '',
        selectedRecommendedAssetAllocId,
        isMC: false,
        isSimulateOnInvestmentsClicked: false,
      }),
      () => {
        triggerSetBeforeAfterAssetMixValues([]);
        triggerGetAssetAllocListByClientId(req);
      }
    );
  };

  onAllocationChange = (event) => {
    let { value: id } = event.target;
    const { allocation, triggerSetBeforeAfterAssetMixValues } = this.props;
    const { localPlanDetailsByPlanId } = this.state;
    const selectedAllocationInfo =
      allocation.filter((f) => f.id === id)[0] || {};
    const { moduleInvestments: { markets = {} } = {}, moduleInvestments = {} } =
      localPlanDetailsByPlanId;
    const selectedAssetAllocId =
      Object.keys(selectedAllocationInfo).length > 0
        ? selectedAllocationInfo?.value
        : '';
    let tempMarkets = { ...markets };
    tempMarkets = this.updateProductsToPlan(markets);
    tempMarkets = {
      ...tempMarkets,
      assetAllocId: selectedAssetAllocId, //updating plan
    };
    const tempPlanDetailsByPlanId = {
      ...localPlanDetailsByPlanId,
      moduleInvestments: {
        ...moduleInvestments,
        markets: { ...tempMarkets },
      },
    };
    this.setState(
      (prevState) => ({
        ...prevState,
        selectedProductType: '',
        tickers: [],
        selectedTicker: '',
        isProductsAlterted: false,
        localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
        assetMixBuildInfo: [],
        targetAssetMixBuildInfo: [],
        selectedAllocationInfo,
        assetAllocId: selectedAssetAllocId,
        isMC: false,
        isSimulateOnInvestmentsClicked: false,
      }),
      () => {
        triggerSetBeforeAfterAssetMixValues([]);
      }
    );
  };

  renderSolutionSubMenuContainer = () => {
    const { solutionsSubTab, solutionsParentTab, solutionsTabs } = this.state;
    const subMenus = solutionsTabs[solutionsParentTab]
      ? solutionsTabs[solutionsParentTab].subMenu
      : [];
    return (
      <div className="chrono-sub-tab">
        <Tabs
          value={solutionsSubTab}
          onChange={this.handleSolutionsSubTabChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          {subMenus &&
            subMenus.map((item, index) => (
              <Tab
                label={item.name}
                {...a11yProps(index)}
                sx={{ padding: '10px !important' }}
              />
            ))}
        </Tabs>
      </div>
    );
  };

  getClassOperator = ({ current, target }) => {
    let currentValue = current * 100;
    let targetValue = target * 100;
    const operator =
      currentValue > targetValue
        ? 'red_text'
        : currentValue < targetValue
        ? 'orange_text'
        : 'green_text';
    return operator;
  };

  handleOnBlur = (event, index) => {
    let { value } = event && event.target;
    const numFormat = Intl.NumberFormat('en-US');
    let newNumformat = numFormat.format(0);
    let currencyFieldValue = '';
    if (value !== null && value !== undefined && value !== '') {
      if (value.toString().includes('$')) {
        currencyFieldValue = value.split('$')[1];
        currencyFieldValue = currencyFieldValue.replace(/,/g, '');
        if (
          currencyFieldValue !== '' &&
          !isNaN(currencyFieldValue) &&
          Number(currencyFieldValue) >= 0
        ) {
          newNumformat = numFormat.format(currencyFieldValue);
        }
      } else if (!isNaN(value) && Number(value) >= 0) {
        newNumformat = numFormat.format(value);
      }
      value = `$${newNumformat}`;
    } else {
      value = `$${newNumformat}`;
    }
    const { tickersAddedToPlan } = this.state;
    let tempTickersAddedToPlan = [...tickersAddedToPlan];
    tempTickersAddedToPlan.forEach((f, i) => {
      if (i === index) {
        f.balance = value;
      }
    });
    this.setState({
      tickersAddedToPlan: tempTickersAddedToPlan,
    });
  };

  getConvertedValue = (value) => {
    let usedBalance = value;
    if (isNaN(usedBalance) && usedBalance.includes('$')) {
      const tempUsedBalance = usedBalance.split('$')[1].replace(/,/g, '');
      usedBalance =
        tempUsedBalance !== '' && !isNaN(tempUsedBalance)
          ? parseFloat(tempUsedBalance)
          : 0;
    } else {
      usedBalance =
        usedBalance !== '' && !isNaN(usedBalance) ? parseFloat(usedBalance) : 0;
    }
    return usedBalance;
  };

  purchaseTicker = (e, i, balance) => {
    let { value: usedBalance } = e.target;
    if (isNaN(usedBalance) && usedBalance.includes('$')) {
      const tempUsedBalance = usedBalance.split('$')[1].replace(/,/g, '');
      usedBalance =
        tempUsedBalance !== '' && !isNaN(tempUsedBalance) ? tempUsedBalance : 0;
    } else {
      usedBalance = usedBalance !== '' && !isNaN(usedBalance) ? usedBalance : 0;
    }
    const { tickersAddedToPlan } = this.state;
    let tempTickersAddedToPlan = [...tickersAddedToPlan];
    tempTickersAddedToPlan.forEach((f, index) => {
      if (i === index) {
        f.balance = usedBalance;
      }
    });
    const remainingBalance = this.updateRemainingBalance(
      tempTickersAddedToPlan,
      balance
    );
    this.setState({
      tickersAddedToPlan: tempTickersAddedToPlan,
      selectedProductType: '',
      selectedTicker: '',
      isProductsAlterted: true,
      remainingBalance,
    });
  };

  updateRemainingBalance = (tempTickersAddedToPlan, balance) => {
    const amounts = tempTickersAddedToPlan
      .map(
        (m) => this.getConvertedValue(m.balance) //to remove empty values
      )
      .filter((a) => a);
    //taking only amounts property
    const amountUsed = amounts.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    ); //now adding them (a + b is nthg but actual values like 10 + 20)
    let tempBalance = this.getConvertedValue(balance);
    const remainingBalance =
      amountUsed !== '' && !isNaN(amountUsed)
        ? parseFloat(tempBalance) - parseFloat(amountUsed)
        : 0;
    return remainingBalance;
  };

  deleteTicker = (e, i, balance) => {
    const { tickersAddedToPlan } = this.state;
    let tempTickersAddedToPlan = [...tickersAddedToPlan];
    if (tempTickersAddedToPlan && tempTickersAddedToPlan.length > 0) {
      tempTickersAddedToPlan.splice(i, 1);
    }
    const remainingBalance = this.updateRemainingBalance(
      tempTickersAddedToPlan,
      balance
    );
    this.setState({
      tickersAddedToPlan: tempTickersAddedToPlan,
      remainingBalance,
      isProductsAlterted: true,
    });
  };

  onTickerChange = (event, balance) => {
    let { value: selectedTicker } = event.target;
    const { tickersAddedToPlan, tickers } = this.state;
    let tempTickersAddedToPlan = [...tickersAddedToPlan];
    const tickerInfo = tickers.filter((t) => t.ticker === selectedTicker);
    if (tickerInfo && tickerInfo.length > 0) {
      if (
        tempTickersAddedToPlan.indexOf(
          tempTickersAddedToPlan.filter((t) => t.ticker === selectedTicker)[0]
        ) === -1
      ) {
        tempTickersAddedToPlan.unshift({
          ticker: tickerInfo[0].ticker,
          desc: tickerInfo[0].desc,
          balance: '',
        });
      }
    }
    const remainingBalance = this.updateRemainingBalance(
      tempTickersAddedToPlan,
      balance
    );
    this.setState({
      selectedTicker,
      tickersAddedToPlan: tempTickersAddedToPlan,
      remainingBalance,
    });
  };

  onProductTypeChange = (event) => {
    let { value: selectedProductType } = event.target;
    const { investmentProducts } = this.props;
    const selectedProductTickersInfo = investmentProducts.filter(
      (i) => i.productType === selectedProductType
    );
    let tickers = [];
    if (selectedProductTickersInfo && selectedProductTickersInfo.length > 0) {
      tickers = selectedProductTickersInfo[0].tickers;
    }
    this.setState({ selectedProductType, tickers });
  };

  transformBackToAPIType = (value) => {
    if (value) {
      let numVal = value;
      if (isNaN(numVal) && numVal.includes('$')) {
        numVal = parseFloat(numVal.split('$')[1].replace(/,/g, ''));
      }
      return numVal;
    } else if (value === 0 || value === '') {
      return 0;
    }
  };

  handleAddProductsToPortfolio = (isMC) => {
    const { triggerSetBeforeAfterAssetMixValues } = this.props;
    const { isMC: isTempMC, localPlanDetailsByPlanId } = this.state;
    let clonedPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
    const { moduleInvestments: { markets = {} } = {}, moduleInvestments = {} } =
      clonedPlanDetailsByPlanId;
    let tempPlanDetailsByPlanId = { ...clonedPlanDetailsByPlanId };
    let tempMarkets = { ...markets };
    tempMarkets = this.updateProductsToPlan(markets);
    //if (!isEqual(tempMarkets, markets) || isMC !== isTempMC) {
    tempPlanDetailsByPlanId = {
      ...clonedPlanDetailsByPlanId,
      moduleInvestments: {
        ...moduleInvestments,
        markets: { ...tempMarkets },
      },
    };
    this.setState(
      (prevState) => ({
        ...prevState,
        //selectedRecommendedAssetAllocId: '', revisit this if something is breaking - important check this
        selectedProductType: '',
        tickers: [],
        selectedTicker: '',
        isProductsAlterted: false,
        isMC,
        localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
        assetMixBuildInfo: [],
        targetAssetMixBuildInfo: [],
        isSimulateOnInvestmentsClicked: false,
      }),
      () => {
        triggerSetBeforeAfterAssetMixValues([]);
      }
    );
  };

  fnToRemoveCommaAndParse = (value) => {
    let valueWithComma = '';
    if (isNaN(value) && value.toString().includes('$')) {
      valueWithComma = value.split('$')[1];
    } else if (!isNaN(value)) {
      valueWithComma = value;
    } else {
      valueWithComma = '';
    }
    const valueWithoutComma = valueWithComma.replace(/,/g, '');
    return !isNaN(valueWithoutComma) && parseFloat(valueWithoutComma);
  };

  handleOnChangeRiskLevelAdaptive = (e) => {
    let { checked } = e && e.target;
    this.setState({ isRiskLevelAdaptive: checked });
  };

  getInvestmentBucketName = (investmentBucket) => {
    if (investmentBucket) {
      const investmentBucketName = investmentFields.find(
        (f) => f.id === investmentBucket
      );
      return investmentBucketName?.title || '';
    }
    return '';
  };

  renderInvestmentContainer = () => {
    const {
      allocation,
      beforeAfterAssetMixValues,
      recommendedAssetAllocationValues,
      investmentProducts,
    } = this.props;
    const {
      selectedAllocationInfo,
      selectedRecommendedAssetAllocId,
      memberOptions,
      selectedMember,
      investmentTypeName,
      selectedAccount,
      selectedMemberInvestmentTypeInfo,
      selectedProductType,
      selectedTicker,
      tickers,
      tickersAddedToPlan,
      remainingBalance,
      investmentAccounts,
      selectedInvestmentAccount,
      availableInvestmentAccounts,
      isRiskLevelAdaptive,
    } = this.state;
    const {
      investmentBucket = '',
      invBalance = '$0',
      id = '',
    } = selectedInvestmentAccount || {};
    const investmentBucketName = this.getInvestmentBucketName(investmentBucket);
    let fields_per_column = [];
    let selectedCBMembers = memberOptions.filter((f) => f.isSelected);
    let selectedCBMember = '';
    if (selectedCBMembers && selectedCBMembers.length > 0) {
      if (selectedCBMembers.length === memberOptions.length) {
        selectedCBMember = 'family';
      } else {
        selectedCBMember = selectedCBMembers[0].id;
      }
    }
    const selectedMemberAssetMix = beforeAfterAssetMixValues.filter(
      (a) => a.name.toLowerCase() === selectedCBMember.toLowerCase()
    );
    let selectedFieldValues = [];
    if (selectedMemberAssetMix.length > 0) {
      selectedFieldValues =
        (selectedMemberAssetMix[0].elements &&
          selectedMemberAssetMix[0].elements) ||
        [];
    }
    const heading = { label: 'Current', label1: 'Target' };
    const tempSelectedFieldValues = assetMixListValues.map((m) => {
      const x = selectedFieldValues.filter(
        (s) => s.label.toLowerCase() === m.label.toLowerCase()
      );
      if (x && x.length > 0) {
        return {
          ...m,
          current: x[0].current,
          target: x[0].target,
          operator: this.getClassOperator(x[0]),
        };
      }
      return { ...m };
    });
    const fieldValues = [...tempSelectedFieldValues];
    const num_of_columns = Math.round(tempSelectedFieldValues.length / 4);
    if (tempSelectedFieldValues.length > 0) {
      for (let index = 0; index < num_of_columns; index += 1) {
        fields_per_column.push(fieldValues.splice(0, 4));
      }
    }
    let availableBalance = invBalance;
    if (tickersAddedToPlan && tickersAddedToPlan.length > 0) {
      availableBalance = remainingBalance;
    }
    availableBalance =
      availableBalance !== '' && isNaN(availableBalance)
        ? this.fnToRemoveCommaAndParse(availableBalance)
        : availableBalance;
    return (
      <>
        <div className="Choose_Risk_Appetite_outer d-lg-flex">
          <div className="Choose_Risk_Appetite_title">
            <h5 className="mb-0 title-div">Asset Mix</h5>
            {/* <p className="mb-0">for</p> */}
            <div className="Choose_Risk_Appetite_member">
              <FormGroup aria-label="members" row={false}>
                {memberOptions.map((cb, i) => (
                  <FormControlLabel
                    key={cb.id}
                    control={
                      <input
                        type="checkbox"
                        name={`members[${i}]`}
                        checked={cb.isSelected}
                        onChange={(event) =>
                          this.handleCheckboxFieldChange(event, i)
                        }
                      />
                    }
                    label={cb.title}
                  />
                ))}
              </FormGroup>
            </div>
          </div>
          <div className="Choose_Risk_Appetite_title">
            <h5 className="mb-0 title-div">Recommended Risk Level</h5>
            <div className="Choose_Risk_Appetite_dropdown select_client_outer">
              <Controls.Select
                className="js-example-basic-single"
                name="Moderately_Conservative"
                id="Moderately_Conservative"
                value={selectedRecommendedAssetAllocId}
                onChange={this.onRecommendedAssetAllocationChange}
                options={recommendedAssetAllocationValues.map((t) => {
                  return { label: t.label, value: t.value };
                })}
              />
            </div>
          </div>
          <div className="Choose_Risk_Appetite_title">
            <h5 className="mb-0 title-div">Adaptive?</h5>
            <div className="Choose_Risk_Appetite_dropdown select_client_outer">
              <FormControlLabel
                control={
                  <Checkbox
                    name="riskLevelAdaptive"
                    color="primary"
                    checked={isRiskLevelAdaptive || false}
                    onChange={(event) =>
                      this.handleOnChangeRiskLevelAdaptive(event)
                    }
                  />
                }
              />
            </div>
          </div>
          <div className="Choose_Risk_Appetite_title">
            <h5 className="mb-0 title-div">Model Portfolio</h5>
            <div className="Choose_Risk_Appetite_dropdown select_client_outer">
              <Controls.Select
                className="js-example-basic-single"
                name="Moderately_Conservative"
                id="Moderately_Conservative"
                value={selectedAllocationInfo.id || ''}
                onChange={this.onAllocationChange}
                options={allocation.map((p) => {
                  return { label: p.label, value: p.id };
                })}
              />
            </div>
          </div>
        </div>
        <div className="Current_Target_main_div d-lg-flex">
          {fields_per_column.map((column, index) => {
            return (
              <table>
                <thead>
                  <tr>
                    <th className="left">{heading.label}</th>
                    <th className="center"></th>
                    <th className="left">{heading.label1}</th>
                  </tr>
                </thead>
                {column.map((a) => {
                  return (
                    <tbody>
                      <tr>
                        <td className={`left ${a.operator}`}>{a.current}</td>
                        <td className="center">{a.label}</td>
                        <td className="rights">{a.target}</td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            );
          })}
        </div>

        <div className="balance_portfolio_outr">
          <div className="balance_portfolio_radio_group">
            <div className="balance_portfolio_title">
              <h5 className="mb-0 title-div">Balance portfolio for</h5>
            </div>
            <Controls.RadioGroup
              name="member-radio-buttons"
              value={selectedMember}
              rowType={true}
              onChange={(event) =>
                this.handleMemberOptionChange(event, 'radio')
              }
              items={memberOptions}
            ></Controls.RadioGroup>
          </div>
        </div>
        <div className="Select_account_main_outer">
          <div className="select_account_dropdown-outer">
            <div className="Select_account_dropdown">
              <h5 className="mb-10 title-div">Select Account </h5>
              <Controls.Select
                className="js-example-basic-single"
                name="Account"
                id="Account"
                value={id}
                onChange={(e) => this.onInvAccountChange(e)}
                options={availableInvestmentAccounts.map((p, index) => {
                  return { label: p.nickName, value: p.id, index: index };
                })}
              />
            </div>
            <div className="Balance_input_div">
              <h5 className="mb-10 title-div">Account Balance </h5>
              <div className="Balance_input_div_inner">
                <input
                  type="text"
                  id="total_Balance"
                  name="Total Balance"
                  //value={this.transformValue(balance, 'currency')}
                  value={invBalance || '$0'}
                  disabled
                />
              </div>
            </div>
            <div className="Balance_input_div">
              <h5 className="mb-10 title-div">Investment Bucket </h5>
              <div className="Balance_input_div_inner">
                <input
                  type={'text'}
                  id={investmentBucket}
                  name={investmentBucket}
                  value={investmentBucketName || ''}
                  disabled
                  className={'custom-input'} // Apply the appropriate class
                />
              </div>
            </div>
          </div>
        </div>
        <div className="select_product_main_outer">
          <div className="select_product_dropdown_outer">
            <div className="select_product_title">
              <h5 className="mb-0 title-div">Select Product</h5>
            </div>
            <Controls.Select
              className="js-example-basic-single"
              name="select_product"
              id="select_product"
              value={selectedProductType}
              onChange={this.onProductTypeChange}
              options={investmentProducts.map((t) => {
                return { label: t.productType, value: t.productType };
              })}
            />
          </div>
          <div className="select_product_dropdown_outer">
            <div className="select_product_title">
              <h5 className="mb-0 title-div">Add Ticker</h5>
            </div>
            <Controls.Select
              className="js-example-basic-single"
              name="select_ticker"
              id="select_ticker"
              value={selectedTicker}
              options={tickers.map((t) => {
                return { label: t.ticker, value: t.ticker };
              })}
              onChange={(e) => this.onTickerChange(e, invBalance)}
            />
          </div>
        </div>

        <div className="select_product_table_main_outer">
          {tickersAddedToPlan.map((ticker, index) => {
            return (
              <div className="select_product_table_inner">
                <div className="select_product_table_content">
                  <p className="value">{ticker.ticker}</p>
                  <p>{ticker.desc}</p>
                </div>
                <div className="select_product_table_amount">
                  <Controls.InputWithBlur
                    id={`tickerAmount-${index}`}
                    placeholder="Amount $"
                    value={ticker.balance || ''}
                    //value={this.transformValue(ticker.balance)}
                    onChange={(e) => {
                      this.purchaseTicker(e, index, invBalance);
                    }}
                    onBlur={(event) => {
                      this.handleOnBlur(event, index);
                    }}
                  />
                </div>
                <div className="select_product_table_delete">
                  <button>
                    <img
                      src={delete_icon}
                      title=""
                      alt=""
                      onClick={(e) => this.deleteTicker(e, index, invBalance)}
                    />
                  </button>
                </div>
              </div>
            );
          })}
          <div className="select_product_table_inner">
            <div className="select_product_table_content">
              <p className="value">&nbsp;</p>
              <p>Cash</p>
            </div>
            <div className="select_product_table_amount">
              <input
                type="text"
                id="remaining_Balance"
                name="Remaining Balance"
                value={this.transformValue(availableBalance, 'currency')}
                disabled
              />
            </div>
            {/* <div className="select_product_table_delete">
              <button>
                <img src="images/add_green.svg" title="" alt="" />
              </button>
            </div> */}
          </div>
        </div>
        {availableBalance < 0 && (
          <Grid item xs={6}>
            <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
              <Alert severity="error">
                <p>Insufficient Cash</p>
              </Alert>
            </Stack>
          </Grid>
        )}
      </>
    );
  };

  renderLeftContentContainer = () => {
    const { solutionsParentTab, solutionsSubTab } = this.state;
    switch (solutionsParentTab) {
      case 0:
        switch (solutionsSubTab) {
          case 0:
            return this.renderInvestmentContainer();
          default:
            return this.renderInvestmentContainer();
        }
      case 1:
        switch (solutionsSubTab) {
          case 0:
            return this.renderExpensesContainer('expenses');
          default:
            return this.renderExpensesContainer('expenses');
        }
      case 2:
        switch (solutionsSubTab) {
          case 0:
            return this.renderGoalsContainer();
          default:
            return this.renderGoalsContainer();
        }
      case 3:
        switch (solutionsSubTab) {
          case 0:
            return this.renderInsuranceContentContainer('termLife');
          case 1:
            return this.renderInsuranceContentContainer('wholeLife');
          case 2:
            return this.renderInsuranceContentContainer('longTermCare');
          case 3:
            return this.renderInsuranceContentContainer('umbrella');
          case 4:
            return this.renderInsuranceContentContainer('property');
          case 5:
            return this.renderInsuranceContentContainer('auto');
          default:
            return this.renderInsuranceContentContainer('termLife');
        }
      case 4:
        switch (solutionsSubTab) {
          case 0:
          case 1:
          case 2:
            return this.renderFutureImplementationContainer();
          default:
            return this.renderFutureImplementationContainer();
        }
      case 5:
        switch (solutionsSubTab) {
          case 0:
          case 1:
          case 2:
            return this.renderFutureImplementationContainer();
          default:
            return this.renderFutureImplementationContainer();
        }
      default:
        break;
    }
  };

  renderSolutionsContainer = () => {
    const {
      parentTabValue,
      solutionsTabs,
      renderType,
      tabInfo,
      solutionsParentTab,
      solutionsSubTab,
      isLastStep,
      remainingBalance,
    } = this.state;
    const { showLeftNav, showRightNav } = this.state;
    return (
      <div className="client_main product_main outer">
        <div className="carousel-container" ref={this.carouselContainerRef}>
          <div key="left" className="carousel-itemm">
            <div className="inner">
              <div
                className={
                  isLastStep
                    ? 'client_left product_left solution_tab_content'
                    : 'client_left product_left solution_tab_content solution_action_height_full'
                }
              >
                <div className="chrono-tabs-wrapper">
                  <div className="chrono-parent-tab">
                    <Tabs
                      value={solutionsParentTab}
                      onChange={this.handleSolutionsParentTabChange}
                      aria-label="basic tabs example"
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      TabIndicatorProps={{
                        style: {
                          display: 'none',
                        },
                      }}
                    >
                      {solutionsTabs &&
                        solutionsTabs.map((item, index) => (
                          <Tab
                            iconPosition="start"
                            label={item.name}
                            {...a11yProps(index)}
                            sx={{ padding: '10px !important' }}
                          />
                        ))}
                    </Tabs>
                  </div>
                  {solutionsTabs &&
                    solutionsTabs.length > 0 &&
                    this.renderSolutionSubMenuContainer()}
                </div>
                <div className="product_left_content">
                  {this.renderLeftContentContainer()}
                </div>
                {isLastStep && solutionsParentTab !== 0 && (
                  <div className="Save_Simulate_button_outer">
                    <div className="plan_results_main_outer_right">
                      <div className="Simulate_button_group">
                        <button onClick={() => this.handleSimulate(false)}>
                          <img src={Simulate_icon_1} alt="" title="" /> Simulate
                        </button>
                        <button onClick={() => this.handleSimulate(true)}>
                          <img src={Simulate_icon_2} alt="" title="" /> Optimize
                        </button>
                        <button onClick={this.handleSave}>
                          <img src={Save_icon} alt="" title="" /> Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {isLastStep && solutionsParentTab === 0 && (
                  <div className="Save_Simulate_button_outer">
                    <div className="plan_results_main_outer_right">
                      <div className="Simulate_button_group">
                        <button
                          onClick={() =>
                            this.handleAddProductsToPortfolio(false)
                          }
                          className={
                            remainingBalance < 0
                              ? 'common-button secondary-button'
                              : 'common-button'
                          }
                          disabled={remainingBalance < 0}
                        >
                          <img src={Simulate_icon_1} alt="" title="" /> Simulate
                        </button>
                        <button
                          onClick={() =>
                            this.handleAddProductsToPortfolio(true)
                          }
                          className={
                            remainingBalance < 0
                              ? 'common-button secondary-button'
                              : 'common-button'
                          }
                          disabled={remainingBalance < 0}
                        >
                          <img src={Simulate_icon_2} alt="" title="" /> Optimize
                        </button>
                        <button
                          onClick={this.handleSave}
                          className={
                            remainingBalance < 0
                              ? 'common-button secondary-button'
                              : 'common-button'
                          }
                          disabled={remainingBalance < 0}
                        >
                          <img src={Save_icon} alt="" title="" /> Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="divider_line"></div>
          <div key="right" className="carousel-itemm">
            <div className="inner">
              <div className="client_right solution_tab_content">
                <div className="client_main_right">
                  <div className="client_main_right_left">
                    <TabsComponent
                      renderType={renderType}
                      updateRenderType={this.updateRenderType}
                    />

                    <div className="chrono-tabs-wrapper">
                      {renderType !== 'Custom' ? (
                        <div className="chrono-parent-tab">
                          <Tabs
                            value={parentTabValue}
                            onChange={this.handleParentTabChange}
                            aria-label="basic tabs example"
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            TabIndicatorProps={{
                              style: {
                                display: 'none',
                              },
                            }}
                          >
                            {tabInfo &&
                              tabInfo.length > 0 &&
                              tabInfo.map((item, index) => (
                                <Tab
                                  iconPosition="start"
                                  label={item.tabName}
                                  {...a11yProps(index)}
                                  sx={{ padding: '10px !important' }}
                                />
                              ))}
                          </Tabs>
                          {tabInfo &&
                            tabInfo.length > 0 &&
                            this.renderSubMenuContainer()}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      className={
                        renderType !== 'Custom'
                          ? 'result_scroll_div'
                          : 'result_scroll_div result_scroll_div_custom'
                      }
                    >
                      <HorizonDetails
                        isSolutions={true}
                        isV1={false}
                        isV2={true}
                        renderType={renderType}
                        isNewSolutions={true}
                        renderMainType={'solutions'}
                        leftParentTabValue={solutionsParentTab}
                        leftSubTabValue={solutionsSubTab}
                      />
                      {this.renderContentContainer()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Left and Right Arrows */}
        {showLeftNav && (
          <div
            className="arrow left"
            id="prevButton"
            onClick={this.showPreviousItem}
          >
            <DescriptionIcon />
          </div>
        )}
        {showRightNav && (
          <div
            className="arrow right"
            id="nextButton"
            onClick={this.showNextItem}
          >
            <BarChartIcon />
          </div>
        )}
      </div>
    );
  };

  handleSave = () => {
    const { planDetailsByPlanId, triggerSaveAndUpdatePlanDetailsbyPlanId } =
      this.props;
    const version = 'v2';
    triggerSaveAndUpdatePlanDetailsbyPlanId({
      version,
      planDetailsByPlanId,
    });
  };

  render() {
    const {
      simulatePlanDetailsLoading,
      isAssetAllocationListByClientIdLoading,
    } = this.props;
    const { noChangeLoader } = this.state;
    return (
      <>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            simulatePlanDetailsLoading ||
            isAssetAllocationListByClientIdLoading ||
            noChangeLoader
          }
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="main-content-wrapper">
          <div className="container-fluid main-content-wrapper-inner">
            <div className="row">{this.renderSolutionsContainer()}</div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userDetails: getLoggedInUserDetails(state),
    clients: state.clientInfoReducer.clients || [],
    planDetailsByPlanId: planDetailsByPlanIdSelector(state),
    termLifeInsuranceProducts: termLifeInsuranceProductsSelector(state),
    wholeLifeInsuranceProducts: wholeLifeInsuranceProductsSelector(state),
    longTermCareInsuranceProducts: longTermCareInsuranceProductsSelector(state),
    umbrellaInsuranceProducts: umbrellaInsuranceProductsSelector(state),
    propertyInsuranceProducts: propertyInsuranceProductsSelector(state),
    autoInsuranceProducts: autoInsuranceProductsSelector(state),
    plannerDetailsLoading: plannerDetailsLoadingSelector(state),
    isOutputResponseLoading: outputResponseLoadingSelector(state),
    isPlanDetailsSaved: plannerDetailsSaveStatus(state),
    planDetailsSimulated: plannerDetailsSimulateStatus(state),
    expenseFactorDetails: expenseFactorDetailsSelector(state),
    charts: outputResponseChartsByPlanIdSelector(state) || [],
    tables: outputResponseTablesByPlanIdSelector(state) || [],
    data: outputResponseDataByPlanIdSelector(state) || [],
    solutions: outputResponseSolutionsByPlanIdSelector(state) || {},
    selectedDisplayTerm: selectedDisplayTermForClientIdSelector(state),
    selectedHorizonTerm: selectedHorizonTermForClientIdSelector(state),
    selectedClientId: selectedClientIdSelector(state),
    selectedPlan: selectedPlanIdForClientIdSelector(state),
    simulatePlanDetailsLoading: simulatePlanDetailsLoadingSelector(state),
    planner: outputResponsePlannerDetailsByPlanIdSelector(state),
    selectedChart: selectedChartSelector(state) || '',
    pushPinCharts: getPushPinCharts(state),
    allocation: state.clientInfoReducer.allocation || [],
    isAssetAllocationListByClientIdLoading:
      state.clientInfoReducer.isAssetAllocationListByClientIdLoading,
    beforeAfterAssetMixValues: beforeAfterAssetMixValuesSelector(state) || [],
    assetAllocationByProductIdLoading:
      assetAllocationByProductIdLoadingSelector(state),
    assetMixValues: getAssetAllocationByProductIdSelector(state),
    recommendedAssetAllocationValues:
      getRecommendedAssetAllocationByClientIdSelector(state),
    investmentProducts: getInvestmentProductsByClientIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    triggerUpdatePlannerDetailsinRedux: (payload) =>
      dispatch(simulatePlanDetailsByPlanIdSuccessAction(payload)),
    triggerSetTermInsuranceProducts: (payload) =>
      dispatch(termLifeInsuranceProductsSuccessAction(payload)),
    triggerSetWholeInsuranceProducts: (payload) =>
      dispatch(wholeLifeInsuranceProductsSuccessAction(payload)),
    triggerSetLongTermCareInsuranceProducts: (payload) =>
      dispatch(longTermCareInsuranceProductsSuccessAction(payload)),
    triggerSetUmbrellaInsuranceProducts: (payload) =>
      dispatch(umbrellaInsuranceProductsSuccessAction(payload)),
    triggerSetPropertyInsuranceProducts: (payload) =>
      dispatch(propertyInsuranceProductsSuccessAction(payload)),
    triggerSetAutoInsuranceProducts: (payload) =>
      dispatch(autoInsuranceProductsSuccessAction(payload)),
    triggerSimulatePlanDetailsByPlanId: (payload) =>
      dispatch(simulatePlanDetailsByPlanId(payload)),
    triggerSaveAndUpdatePlanDetailsbyPlanId: (payload) =>
      dispatch(saveAndUpdatePlanDetailsByPlanId(payload)),
    getSavedSelectedPlanIdDetails: (payload) =>
      dispatch(getPlansDetailsAction(payload)),
    triggerSetSelectedChart: (payload) =>
      dispatch(setSelectedChartAction(payload)),
    triggerSetSelectedTable: (payload) =>
      dispatch(setSelectedTableAction(payload)),
    triggerSetSelectedData: (payload) =>
      dispatch(setSelectedDataAction(payload)),
    triggerSetBeforeAfterAssetMixValues: (payload) =>
      dispatch(setBeforeAfterAssetMixAction(payload)),
    triggerPushPinCharts: (payload) => dispatch(pushPinCharts(payload)),
    triggerGetAssetAllocationByProductId: (payload) =>
      dispatch(getAssetAllocationByProductId(payload)),
    triggerGetRecommendedAssetAllocListByClientId: (payload) =>
      dispatch(getRecommendedAssetAllocListByClientId(payload)),
    triggerGetAssetAllocListByClientId: (payload) =>
      dispatch(getAssetAllocListByClientIdAction(payload)),
    triggerSelectInvestmentProductsByClientId: (payload) =>
      dispatch(getInvestmentProductsByClientIdAction(payload)),
    triggerSetAssetAllocListByClientIdAction: (payload) =>
      dispatch(setAssetAllocListByClientIdAction(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(NewSolutions));
