import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import JourneyButtons from '../../components/shared/JourneyButtons';
import { Grid } from '@material-ui/core';
import Controls from '../../components/controls/Controls';
import { ExpenseTypes, lifestyleTypes } from '../../constants/dashboard';
import { isEqual } from 'lodash';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const radioOptions = [
  { title: 'Yes', id: true },
  { title: 'No', id: false },
];
const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fiCard: {
    height: 400,
  },
  media: {
    height: 50,
  },
});

const percentageFields = [
  'rentalPercent',
  'discRetFactor1',
  'discPercentOfAgg',
];
const expenseFields = ['expense'];

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
export default function BuildExpensesLivingComponent(props) {
  const {
    updateExpenseDetailsInRedux,
    storeValues,
    isV2,
    setLastStepInParent,
    infoCore,
  } = props;
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isBlurCalled, setIsBlurCalled] = useState(false);
  const [expenseTab, setExpenseTab] = useState('');
  const [formDetails, setFormDetails] = useState({});
  const [stepName, setStepName] = useState({
    label: 'Rental',
    value: 'rental',
  });
  const [retired, setRetired] = useState(false);

  const prevFormDetails = usePrevious(formDetails);
  const prevIsLastStep = usePrevious(isLastStep);

  const transformValue = (val) => {
    let fieldValue = val;
    if (val !== null && val !== undefined && val !== '') {
      if (val.toString().includes('%')) {
        const percentFieldValue = val.split('%')[0];
        fieldValue =
          percentFieldValue !== '' && !isNaN(percentFieldValue)
            ? parseFloat(percentFieldValue) / 100
            : 0;
      }
      fieldValue = parseFloat(parseFloat(fieldValue) * 100).toFixed(2);
      return `${fieldValue}%`;
    }
    return `0.00%`;
  };

  useEffect(() => {
    const {
      basicsFirst = {},
      basicsSecond = {},
      taxFilingStatus = '',
    } = infoCore || {};
    let retired = false;
    let membersCount = 0;
    if (
      basicsFirst &&
      Object.keys(basicsFirst).length > 0 &&
      basicsFirst.age > 0 &&
      basicsFirst.name
    ) {
      membersCount += 1;
      if (
        (basicsSecond &&
          Object.keys(basicsSecond).length > 0 &&
          basicsSecond.age > 0 &&
          basicsSecond.name) ||
        taxFilingStatus.toLowerCase() === 'mfj'
      ) {
        membersCount += 1;
      }
    }
    if (
      Object.keys(basicsFirst).length > 0 &&
      Object.keys(basicsSecond).length > 0
    ) {
      if (
        membersCount >= 1 &&
        basicsFirst['age'] &&
        basicsFirst['retirementAge'] &&
        basicsFirst['age'] >= basicsFirst['retirementAge']
      ) {
        retired = true;
        if (membersCount > 1) {
          if (
            basicsSecond['age'] &&
            basicsSecond['retirementAge'] &&
            basicsSecond['age'] >= basicsSecond['retirementAge']
          ) {
            retired = true;
          } else {
            retired = false;
          }
        }
      }
    } else if (
      membersCount === 1 &&
      (Object.keys(basicsFirst).length === 0 ||
        Object.keys(basicsSecond).length === 0)
    ) {
      if (
        basicsFirst['age'] &&
        basicsFirst['retirementAge'] &&
        basicsFirst['age'] > 0 &&
        basicsFirst['age'] >= basicsFirst['retirementAge']
      ) {
        retired = true;
      } else if (
        basicsSecond['age'] &&
        basicsSecond['retirementAge'] &&
        basicsSecond['age'] > 0 &&
        basicsSecond['age'] >= basicsSecond['retirementAge']
      ) {
        retired = true;
      }
    }
    setRetired(retired);
  }, [infoCore]);

  useEffect(() => {
    const tempFormDetails = {
      discPercentOfAgg:
        (storeValues.discPercentOfAgg &&
          transformValue(storeValues.discPercentOfAgg)) ||
        '0%',
      disc:
        (storeValues.disc[`disc${[stepName.value]}`] &&
          storeValues.disc[`disc${[stepName.value]}`]) ||
        false,
      useAggregateCost:
        (storeValues.useAggNonHousingExpense &&
          storeValues.useAggNonHousingExpense) ||
        false,
      lifestyle: (storeValues.lifestyle && storeValues.lifestyle) || '',
      useDiscretionaryFlag:
        (storeValues.useDiscretionaryFlag &&
          storeValues.useDiscretionaryFlag) ||
        false,
      rentalPercent: retired
        ? '100%'
        : (storeValues.retFactor[`retirement${[stepName.value]}`] &&
            transformValue(
              storeValues.retFactor[`retirement${[stepName.value]}`]
            )) ||
          '0%',
      discRetFactor1:
        (retired
          ? '100%'
          : storeValues.discRetFactor1 &&
            transformValue(storeValues.discRetFactor1)) || '0%',
      expense: isV2
        ? handleBlurOnNumbers(
            storeValues.amounts[stepName.value],
            'expense',
            'onLoad',
            'currency'
          )
        : handleBlurOnNumbers(
            storeValues.expenses[stepName.value],
            'expense',
            'onLoad',
            'currency'
          ),
    };
    const localExpenseTab =
      storeValues.lifestyle && storeValues.lifestyle.toLowerCase() === 'custom'
        ? storeValues.useAggNonHousingExpense
          ? 'aggregate'
          : 'itemized'
        : 'lifestyle';
    setExpenseTab(localExpenseTab);
    if (
      localExpenseTab === 'lifestyle' &&
      !isLastStep &&
      isLastStep !== prevIsLastStep
    ) {
      setIsLastStep(true);
    }
    setFormDetails((old) => ({
      ...old,
      ...tempFormDetails,
    }));
  }, [storeValues, stepName, isV2, isLastStep, prevIsLastStep]);

  const updateStepName = useCallback(
    (stepNo) => {
      const { useAggregateCost } = formDetails;
      let tempStepName = stepName;
      switch (stepNo) {
        case 1:
          tempStepName = useAggregateCost
            ? {
                label: 'Aggregate Non Housing',
                value: 'aggNonHousing',
              }
            : { label: 'Groceries & Staples', value: 'food' };
          break;
        case 2:
          tempStepName = useAggregateCost
            ? { label: 'Rental', value: 'rental' }
            : {
                label: 'Phones & Entertainment',
                value: 'phonesAndEnt',
              };
          break;
        case 3:
          tempStepName = { label: 'Travel', value: 'travel' };
          break;
        case 4:
          tempStepName = { label: 'Restaurants', value: 'restaurants' };
          break;
        case 5:
          tempStepName = {
            label: 'Personal / Pet / Other care',
            value: 'insurancesNonProp',
          };
          break;
        case 6:
          tempStepName = {
            label:
              'Health & Fitness (excluding what is included in Insurances)',
            value: 'healthAndFitness',
          };
          break;
        case 7:
          tempStepName = {
            label: 'Utilities',
            value: 'utils',
          };
          break;
        case 8:
          tempStepName = {
            label:
              'Transportation (excluding what is included in Vehicles and Insurances)',
            value: 'cars',
          };
          break;
        case 9:
          tempStepName = {
            label: 'Shopping & Miscellaneous',
            value: 'shoppingAndMisc',
          };
          break;
        default:
          tempStepName = { label: 'Rental', value: 'rental' };
          break;
      }
      return tempStepName;
    },
    [stepName, formDetails]
  );

  useEffect(() => {
    const tempStepName = updateStepName(currentStep);
    if (!isEqual(tempStepName, stepName)) {
      setStepName(tempStepName);
    }
    if (isLastStep && prevIsLastStep !== isLastStep) {
      setLastStepInParent(true);
    } else if (!isLastStep && prevIsLastStep !== isLastStep) {
      setLastStepInParent(false);
    }
  }, [
    currentStep,
    updateStepName,
    stepName,
    isLastStep,
    setLastStepInParent,
    prevIsLastStep,
  ]);

  const handleNext = useCallback(
    (data, tab) => {
      if (!isLastStep) {
        const stepNo = tab ? 0 : currentStep === 10 ? 10 : currentStep + 1;
        if (
          stepNo === 10 ||
          (stepNo === 9 && retired) ||
          (data.useAggregateCost && stepNo === 1 && retired) ||
          (data.useAggregateCost && stepNo === 2 && !retired)
        ) {
          setIsLastStep(true);
        }
        setCurrentStep(stepNo);
      }
      updateExpenseDetailsInRedux(data, stepName.value, isLastStep);
      setIsBlurCalled(false);
    },
    [
      currentStep,
      stepName.value,
      updateExpenseDetailsInRedux,
      isLastStep,
      retired,
    ]
  );

  const handlePrevious = useCallback(
    (data, tab) => {
      const stepNo = tab ? 0 : currentStep < 1 ? 1 : currentStep - 1;
      setCurrentStep(stepNo);
      setIsLastStep(false);
      updateExpenseDetailsInRedux(data, stepName.value);
      setIsBlurCalled(false);
    },
    [updateExpenseDetailsInRedux, currentStep, stepName.value]
  );

  const onReduxUpdate = useCallback(
    (from, newFormDetails, tab) => {
      const tempFormDetails = newFormDetails ? newFormDetails : formDetails;
      const tempFormDetailsArray = Object.entries(tempFormDetails).map((m) => {
        return { name: m[0], value: convertBackToApiType(m[1]) };
      });
      const tempFormDetailsObject = tempFormDetailsArray.reduce(
        (previousObject, currentObject) => {
          return Object.assign(previousObject, {
            [currentObject.name]: currentObject.value,
          });
        },
        {}
      );
      from === 'next'
        ? handleNext(tempFormDetailsObject, tab)
        : handlePrevious(tempFormDetailsObject, tab);
    },
    [formDetails, handleNext, handlePrevious]
  );

  useEffect(() => {
    if (isLastStep && isBlurCalled && !isEqual(prevFormDetails, formDetails)) {
      onReduxUpdate('next');
    }
  }, [onReduxUpdate, isLastStep, formDetails, prevFormDetails, isBlurCalled]);

  const convertBackToApiType = (value) => {
    let convertedValue =
      typeof value !== 'boolean'
        ? value.toString().includes('%')
          ? value.split('%')[0]
          : value.toString().includes('$')
          ? parseFloat(value.split('$')[1].replace(/,/g, ''))
          : value !== ''
          ? value
          : ''
        : value;
    const newFieldValue =
      typeof value !== 'boolean' && value.toString().includes('%')
        ? convertedValue !== '' &&
          !isNaN(convertedValue) &&
          parseFloat(convertedValue) / 100
        : convertedValue;
    return newFieldValue;
  };

  const handleFormFieldChange = (e, from, type) => {
    let fieldValue =
      e && type === 'radio'
        ? e.target.checked && e.target.defaultValue === 'true'
          ? true
          : false
        : e.target.value;
    if (type === 'percent') {
      if (fieldValue.toString().includes('%')) {
        let percentFieldValue = fieldValue.split('%')[0];
        if (percentFieldValue !== '' && !isNaN(percentFieldValue)) {
          fieldValue = percentFieldValue;
        }
      }
    } else if (type === 'currency') {
      if (fieldValue.toString().includes('$')) {
        let currencyFieldValue = fieldValue.split('$')[1];
        currencyFieldValue = currencyFieldValue.replace(/,/g, '');
        if (currencyFieldValue !== '' && !isNaN(currencyFieldValue)) {
          fieldValue = currencyFieldValue;
        }
      }
    } else if (type === 'number') {
      if (
        fieldValue !== '' &&
        fieldValue !== null &&
        fieldValue !== undefined
      ) {
        if (isNaN(fieldValue)) {
          fieldValue = 0;
        }
      }
    }
    setIsBlurCalled(false);
    setFormDetails((old) => ({
      ...old,
      [from]: fieldValue,
    }));
  };

  const transformValue1 = (value, field) => {
    if (value) {
      if (percentageFields.includes(field)) {
        let percentFieldValue = '';
        if (isNaN(value) && value.includes('%')) {
          percentFieldValue = parseFloat(
            parseFloat(value.split('%')[0]) % 100
          ).toFixed(0);
        } else {
          percentFieldValue = parseFloat(parseFloat(value) * 100).toFixed(0);
        }
        return `${percentFieldValue}%`;
      } else {
        let numVal = value;
        if (isNaN(numVal) && numVal.includes('$')) {
          numVal = parseFloat(numVal.split('$')[1].replace(/,/g, ''));
        }
        const numFormat = Intl.NumberFormat('en-US');
        const newNumformat = numFormat.format(numVal);
        return `$${newNumformat}`;
      }
    }
  };

  const handleBlur = (event, field) => {
    let { value } = event && event.target;
    let percentFieldValue = '';
    if (value !== null && value !== undefined && value !== '') {
      if (value.toString().includes('%')) {
        percentFieldValue = value.split('%')[0];
        if (
          percentFieldValue !== '' &&
          !isNaN(percentFieldValue) &&
          Number(percentFieldValue) >= 0
        ) {
          percentFieldValue = parseFloat(percentFieldValue).toFixed(2);
          value = `${percentFieldValue}%`;
        } else {
          value = `${0}%`;
        }
      } else if (!isNaN(value) && Number(value) >= 0) {
        percentFieldValue = parseFloat(value).toFixed(2);
        value = `${percentFieldValue}%`;
      } else {
        value = `${0}%`;
      }
    } else {
      value = `${0}%`;
    }
    setIsBlurCalled(true);
    setFormDetails((old) => ({
      ...old,
      [field]: value,
    }));
  };

  const handleBlurOnNumbers = (event, field, from, type) => {
    let value = from ? event : (event && event.target.value) || '';
    if (type === 'currency') {
      const numFormat = Intl.NumberFormat('en-US');
      let newNumformat = numFormat.format(0);
      let currencyFieldValue = '';
      if (value !== null && value !== undefined && value !== '') {
        if (value.toString().includes('$')) {
          currencyFieldValue = value.split('$')[1];
          currencyFieldValue = currencyFieldValue.replace(/,/g, '');
          if (
            currencyFieldValue !== '' &&
            !isNaN(currencyFieldValue) &&
            Number(currencyFieldValue) >= 0
          ) {
            newNumformat = numFormat.format(currencyFieldValue);
          }
        } else if (!isNaN(value) && Number(value) >= 0) {
          newNumformat = numFormat.format(value);
        }
        value = `$${newNumformat}`;
      } else {
        value = `$${newNumformat}`;
      }
    }
    if (type === 'number') {
      if (value !== '' && value !== null && value !== undefined) {
        if (!isNaN(value) && Number(value) >= 0) {
          value = Number(value);
        } else {
          value = 0;
        }
      } else {
        value = 0;
      }
    }
    if (!from) {
      setIsBlurCalled(true);
    } else {
      setIsBlurCalled(false);
    }
    if (!from) {
      setFormDetails((old) => ({
        ...old,
        [field]: value,
      }));
    } else {
      return value;
    }
  };

  const updateExpenseTab = (e, tab, i) => {
    if (tab) {
      const selectedTab = tab.toLowerCase() || '';
      let isAggregate = false;
      let { useDiscretionaryFlag, lifestyle } = formDetails;
      switch (selectedTab) {
        case 'lifestyle':
          isAggregate = formDetails.useAggregateCost; // dont change this field, when lifestyle is selected
          lifestyle = lifestyleTypes[0].value;
          break;
        case 'aggregate':
          isAggregate = true;
          lifestyle = 'custom';
          //useDiscretionaryFlag = true;
          break;
        case 'itemized':
          isAggregate = false;
          lifestyle = 'custom';
          break;
        default:
          break;
      }
      const tempFormDetails = {
        ...formDetails,
        useAggregateCost: isAggregate,
        lifestyle,
        // useDiscretionaryFlag,
      };
      setExpenseTab(selectedTab);
      setCurrentStep(0);
      setFormDetails(tempFormDetails);
      if (selectedTab === 'lifestyle') {
        setIsLastStep(true);
      } else if (isLastStep) {
        setIsLastStep(false);
      }
      onReduxUpdate('next', tempFormDetails, selectedTab);
    }
  };

  const onLifestyleChange = (event) => {
    const { value: lifestyle } = event.target;
    const tempFormDetails = {
      ...formDetails,
      lifestyle,
    };
    setFormDetails(tempFormDetails);
    onReduxUpdate('next', tempFormDetails);
  };

  const renderSteps = () => {
    return (
      <>
        <Grid container className="expenses_warning">
          <Grid>
            <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
              <Alert severity="info">
                <p>
                  These expenses are in addition to what is captured in Kids /
                  Vehicles / Insurances / Vacations / Debt / Assets
                </p>
              </Alert>
            </Stack>
          </Grid>
        </Grid>
        <div className="chart_filter">
          <ul className="tabs">
            {ExpenseTypes.map((m, i) => {
              return (
                <li
                  className={
                    expenseTab === m.toLowerCase() ? 'tab active' : 'tab'
                  }
                >
                  <button onClick={(e) => updateExpenseTab(e, m, i)}>
                    {m}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="expenses_grid_main_outer">
          {expenseTab === 'lifestyle' ? (
            <Grid container className="expenses_grid_outer">
              {/* <Grid
                item
                xs={12}
                style={{ fontSize: '14px', fontWeight: '600', padding: '5px' }}
              >
                Choose your lifestyle
              </Grid> */}
              <Grid item sm={6} xs={12}>
                <Controls.Select
                  name="Lifestyle"
                  placeholder="Choose Lifestyle"
                  value={formDetails.lifestyle}
                  options={lifestyleTypes.map((p) => {
                    return { label: p.label, value: p.value };
                  })}
                  onChange={onLifestyleChange}
                />
              </Grid>
            </Grid>
          ) : expenseTab === 'aggregate' ? (
            <Grid container className="expenses_grid_outer">
              <Grid
                item
                xs={9}
                className=""
                style={{ fontSize: '12px', paddingRight: '15px' }}
              >
                Annual Rental expense (if you own a primary home, enter a
                potential rental expense if you were to rent today)
              </Grid>

              <Grid item xs={3}>
                <input
                  name="Annualized housing expense"
                  placeholder="Expenses: Non-housing cost"
                  className=""
                  value={formDetails.expense}
                  type="text"
                  min={0}
                  onChange={(event) =>
                    handleFormFieldChange(event, 'expense', 'currency')
                  }
                  onBlur={(event) =>
                    handleBlurOnNumbers(event, 'expense', undefined, 'currency')
                  }
                />
              </Grid>

              {/* new */}

              <Grid
                item
                xs={9}
                gap={15}
                className=""
                style={{
                  marginTop: '10px',
                  paddingRight: '15px',
                  fontSize: '12px',
                }}
              >
                Aggregate non-housing annual expenses
              </Grid>
              <Grid item xs={3}>
                <input
                  name="Aggregate non-housing annual expenses"
                  className=""
                  value={formDetails.expense}
                  type={'text'}
                  onChange={(event) =>
                    handleFormFieldChange(event, 'expense', 'currency')
                  }
                  onBlur={(event) =>
                    handleBlurOnNumbers(event, 'expense', undefined, 'currency')
                  }
                />
              </Grid>
              <Grid
                item
                xs={9}
                gap={15}
                className=""
                style={{
                  paddingRight: '15px',
                  marginTop: '10px',
                  fontSize: '12px',
                }}
              >
                What percent of this could be discretionary?
              </Grid>
              <Grid item xs={3}>
                <input
                  name="Discretionary percentage"
                  className=""
                  value={formDetails.discPercentOfAgg}
                  onBlur={(event) => handleBlur(event, 'discPercentOfAgg')}
                  type={'text'}
                  onChange={(event) =>
                    handleFormFieldChange(event, 'discPercentOfAgg', 'percent')
                  }
                />
              </Grid>

              <Grid
                item
                xs={9}
                className=""
                style={{
                  marginTop: '10px',
                  paddingRight: '15px',
                  fontSize: '12px',
                }}
              >
                What percent of pre-retirement rental expenses do you expect
                during phase-1 of retirement? (if you were to liquidate your
                primary home)
              </Grid>
              <Grid item xs={3}>
                <input
                  name="Aggregate non-housing annual expenses"
                  className=""
                  disabled={retired}
                  value={formDetails.rentalPercent}
                  onBlur={(event) => handleBlur(event, 'rentalPercent')}
                  type={'text'}
                  onChange={(event) =>
                    handleFormFieldChange(event, 'rentalPercent', 'percent')
                  }
                />
              </Grid>
              <Grid
                item
                xs={9}
                className=""
                style={{
                  marginTop: '10px',
                  paddingRight: '15px',
                  fontSize: '12px',
                }}
              >
                What percent of pre-retirement non-housing discretionary
                expenses do you expect during retirement?
              </Grid>
              <Grid item xs={3}>
                <input
                  name="Discretionary percentage"
                  className=""
                  disabled={retired}
                  value={formDetails.discRetFactor1}
                  type={'text'}
                  onBlur={(event) => handleBlur(event, 'discRetFactor1')}
                  onChange={(event) =>
                    handleFormFieldChange(event, 'discRetFactor1', 'percent')
                  }
                />
              </Grid>
            </Grid>
          ) : formDetails.useAggregateCost ? (
            currentStep === 1 ? (
              <Grid container className="expenses_grid_outer">
                {/* <Grid
                  item
                  xs={9}
                  gap={15}
                  className=""
                  style={{
                    marginTop: '10px', 
                    paddingRight: '15px', 
                    fontSize: '12px',
                  }}
                >
                  Aggregate non-housing annual expenses
                </Grid>
                <Grid item xs={3}>
                  <input
                    name="Aggregate non-housing annual expenses"
                    className=""
                    value={formDetails.expense}
                    type={'text'}
                    onChange={(event) =>
                      handleFormFieldChange(event, 'expense', 'currency')
                    }
                    onBlur={(event) =>
                      handleBlurOnNumbers(
                        event,
                        'expense',
                        undefined,
                        'currency'
                      )
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={9} 
                  gap={15}
                  className=""
                  style={{
                    paddingRight: '15px', 
                    marginTop: '10px',
                    fontSize: '12px',
                  }}
                >
                  What percent of this could be discretionary?
                </Grid>
                <Grid item xs={3}>
                  <input
                    name="Discretionary percentage"
                    className=""
                    value={formDetails.discPercentOfAgg}
                    onBlur={(event) => handleBlur(event, 'discPercentOfAgg')}
                    type={'text'}
                    onChange={(event) =>
                      handleFormFieldChange(
                        event,
                        'discPercentOfAgg',
                        'percent'
                      )
                    }
                  />
                </Grid>

                <Grid
                item
                xs={9}
                className=""
                style={{
                  marginTop: '10px', 
                  paddingRight: '15px', 
                  fontSize: '12px',
                }}
              >
                What percent of pre-retirement rental expenses do you expect during phase-1 of retirement? (if you were to liquidate your primary home)
              </Grid>
              <Grid item xs={3}>
                <input
                  name="Aggregate non-housing annual expenses"
                  className=""
                  disabled={retired}
                  value={formDetails.rentalPercent}
                  onBlur={(event) => handleBlur(event, 'rentalPercent')}
                  type={'text'}
                  onChange={(event) =>
                    handleFormFieldChange(event, 'rentalPercent', 'percent')
                  }
                />
              </Grid>
              <Grid
                item
                xs={9}
                className=""
                style={{
                  marginTop: '10px', 
                  paddingRight: '15px', 
                  fontSize: '12px',
                }}
              >
                What percent of pre-retirement non-housing discretionary
                expenses do you expect during retirement?
              </Grid>
              <Grid item xs={3}>
                <input
                  name="Discretionary percentage"
                  className=""
                  disabled={retired}
                  value={formDetails.discRetFactor1}
                  type={'text'}
                  onBlur={(event) => handleBlur(event, 'discRetFactor1')}
                  onChange={(event) =>
                    handleFormFieldChange(event, 'discRetFactor1', 'percent')
                  }
                />
              </Grid> */}
              </Grid>
            ) : (
              <></>
            )
          ) : currentStep === 10 && !retired ? (
            <Grid container className="expenses_grid_outer">
              <Grid
                item
                xs={12}
                style={{ fontSize: '14px', fontWeight: '600', padding: '5px' }}
              >
                Adjust Expenses for Retirement
              </Grid>
              <Grid
                item
                xs={9}
                className=""
                style={{
                  marginRight: '15px',
                  marginTop: '10px',
                  fontSize: '12px',
                }}
              >
                What percent of pre-retirement rental expenses do you expect
                during phase-1 of retirement? (if you were to liquidate your
                primary home)
              </Grid>
              <Grid item xs={3}>
                <input
                  name="Aggregate non-housing annual expenses"
                  className=""
                  disabled={retired}
                  value={formDetails.rentalPercent}
                  type={'text'}
                  onChange={(event) =>
                    handleFormFieldChange(event, 'rentalPercent', 'percent')
                  }
                  onBlur={(event) => handleBlur(event, 'rentalPercent')}
                />
              </Grid>
              <Grid
                item
                xs={9}
                className=""
                style={{
                  marginRight: '15px',
                  marginTop: '10px',
                  fontSize: '12px',
                }}
              >
                What percent of pre-retirement non-housing discretionary
                expenses do you expect during retirement?
              </Grid>
              <Grid item xs={3}>
                <input
                  name="Discretionary percentage"
                  className="planner-input"
                  disabled={retired}
                  value={formDetails.discRetFactor1}
                  type={'text'}
                  onChange={(event) =>
                    handleFormFieldChange(event, 'discRetFactor1', 'percent')
                  }
                  onBlur={(event) => handleBlur(event, 'discRetFactor1')}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container className="expenses_grid_outer">
              <Grid
                item
                xs={8}
                className=""
                style={{
                  marginRight: '15px',
                  marginTop: '10px',
                  fontSize: '12px',
                }}
              >
                {stepName.label} expense (annually)
                {/* Transportation expenses, excluding what is included in Vehicles and Insurances (annually) */}
              </Grid>
              <Grid item xs={3}>
                <input
                  name={stepName.value}
                  className=""
                  value={formDetails.expense}
                  type={'text'}
                  onChange={(event) =>
                    handleFormFieldChange(event, 'expense', 'currency')
                  }
                  onBlur={(event) =>
                    handleBlurOnNumbers(event, 'expense', undefined, 'currency')
                  }
                />
              </Grid>
              <Grid
                item
                xs={8}
                className=""
                style={{
                  marginRight: '15px',
                  marginTop: '10px',
                  fontSize: '12px',
                }}
              >
                Would you be willing to reduce this amount if you were to have a
                budget deficit?
              </Grid>
              <Grid item xs={3}>
                <Controls.RadioGroup
                  name="radioButton"
                  value={formDetails.disc || false}
                  rowType={true}
                  onChange={(event) =>
                    handleFormFieldChange(event, 'disc', 'radio')
                  }
                  items={radioOptions}
                ></Controls.RadioGroup>
              </Grid>
            </Grid>
          )}
          <JourneyButtons
            isLastStep={isLastStep}
            currentStep={currentStep}
            expenseTab={expenseTab}
            lifestyle={formDetails.lifestyle}
            isAggregate={formDetails.useAggregateCost}
            handleNext={() => onReduxUpdate('next')}
            handlePrevious={() => onReduxUpdate('previous')}
          />
        </div>
      </>
    );
  };

  return renderSteps();
}
