import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import {
  renderMembersInputRow,
  renderSelectOption,
  transformRothChoicesBackToUIType,
} from '../../helpers/BuildHelperComponentV3';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const BuildIncomeInvestmentComponent = (props) => {
  const {
    state,
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    setLastStepInParent,
  } = props;

  const {
    moduleEstate: { rothChoices = {} } = {},
    moduleFamily: { infoCore = {} } = {},
  } = localPlanDetailsByPlanId;

  const [rothChoicesDetails, setRothChoicesDetails] = useState(rothChoices);

  const withDrawRothFirst = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  useEffect(() => {
    if (rothChoices && Object.keys(rothChoices).length > 0) {
      let tempRothChoices = transformRothChoicesBackToUIType(rothChoices);
      setRothChoicesDetails(tempRothChoices);
    }
  }, [localPlanDetailsByPlanId]);

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  return (
    <>
      <p>
        <strong>Family rental income</strong>
      </p>
      <Alert severity="info">Rental income will be captured in properties / rental</Alert>

      <hr />
    
      <div className="insured_main_outer">
        <div className="row align-items-center">
            <div className="col-lg-9 col-sm-9 col-8 insured_content_outer">
              <p>
                <strong>Family dividend income</strong>
              </p>
            </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-9 col-sm-9 col-8 insured_content_outer">
            <p>From what age would you like to withdraw dividends?</p>
          </div>
          <div className="col-lg-3 col-sm-3 col-4 insured_radio_group">
            <input type="text" id="tlName" name="tlName" required="" className="custom-input" placeholder="" value="" data-listener-added_7ac6b3a3="true"></input>
          </div>
        </div>

        <div className="row align-items-center">
          <div className='col-12'>
            <Alert severity="info">Actual dividend income will depend upon your investment accounts that you may input in Investments/Markets, and market expectations that the Advisor may input as part of their profile.</Alert>
          </div>
        </div>
      </div>

      <hr />

      <div className="insured_main_outer">
        <div className="row align-items-center">
            <div className="col-lg-9 col-sm-9 col-8 insured_content_outer">
              <p>
                <strong>Family retirement fund withdrawals</strong>
              </p>
            </div>
        </div>
        {renderSelectOption(
          'text',
          'ddlMemOneWithdrawRothFirst',
          'withdrawRothFirst',
          'When withdrawing funds from Retirement accounts, would you like to withdraw first from ROTH?',
          withDrawRothFirst,
          rothChoicesDetails,
          'rothChoices',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleEstate',
          state.membersCount === 1 ? false : true,
          setRothChoicesDetails
        )}
        <div class="row align-items-center">
          <div className='col-12'>
            <Alert severity="info">Funds may be withdrawn from Retirement accounts for budgetary purposes in addition to RMD from traditional accounts.</Alert>
          </div>
        </div>

        <div className='tab_main_nav'>
            <div className='tab_left_nav'>
              <button>
              <ArrowBackIcon /> Previous
              </button>
            </div>
            <div className='tab_right_nav'>
              <button>
                Next <ArrowForwardIcon />
              </button>
            </div>
          </div>
      </div>
    </>
  );
};

export default BuildIncomeInvestmentComponent;
