import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { planDetailsByPlanIdSelector } from '../../selectors/plannerDetailsSelector';
import { Grid } from '@mui/material'; // Import dialog components
import Controls from '../controls/Controls';
import { validateMemberNames } from '../../helpers/ClientHelper';
import { validateAgeFieId } from '../../utils/validators';
import MuiAlert from '@mui/material/Alert';
import { vehiclesFormFields } from '../../constants/Client';
import { PropertyNamesMap } from '../../constants/PropertyNames';
import {
  generateProps,
  percentageConversionToUI,
  formatValueByType,
  formatValuesForUI,
} from '../../helpers/SolutionsHelper';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const VehiclesContentGoals = forwardRef((props, ref) => {
  const { commonProps = {}, compoundingMenuItems = [] } = props;
  const {
    modalType,
    handleModalOpen,
    category,
    goal = {},
    setIsFormValid,
    formDetails,
  } = commonProps;
  const planDetailsByPlanId = useSelector((state) =>
    planDetailsByPlanIdSelector(state)
  );

  const [validatorMessage, setValidatorMessage] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState({});

  const [vehiclesFormFieldsInfo, setVehiclesFormFieldsInfo] =
    useState(vehiclesFormFields);

  const isEditingRef = useRef(false);

  useImperativeHandle(ref, () => ({
    getFormObject() {
      return selectedVehicle;
    },
  }));

  useEffect(() => {
    let tempVehiclesFormFields = [...vehiclesFormFields];
    if (compoundingMenuItems && compoundingMenuItems.length > 0) {
      tempVehiclesFormFields = tempVehiclesFormFields.map((f) => {
        if (f.key === 'compounding') {
          return { ...f, options: compoundingMenuItems };
        }
        return f;
      });
    }
    setVehiclesFormFieldsInfo(tempVehiclesFormFields);
  }, [setVehiclesFormFieldsInfo, compoundingMenuItems]);

  const generatePropsMemoized = useCallback(
    (keyVal, key) =>
      generateProps(keyVal, key, 'vehicles', planDetailsByPlanId),
    [planDetailsByPlanId] // Dependencies for memoization
  );

  useEffect(() => {
    if (isEditingRef.current) return;
    const vehicle = formDetails?.vehicles.find((k) => k.name === goal.name);
    if (
      !vehicle &&
      modalType === 'add' &&
      Object.keys(selectedVehicle).length === 0
    ) {
      const newRecord = Object.keys(PropertyNamesMap['vehicles']).reduce(
        (acc, key) => {
          const keyVal = PropertyNamesMap['vehicles'][key];
          acc[key] = generatePropsMemoized(keyVal, key);
          return acc;
        },
        {}
      );

      if (Object.keys(selectedVehicle).length === 0) {
        const formattedNewRecord = formatValuesForUI(
          newRecord,
          vehiclesFormFields
        );
        if (
          JSON.stringify(formattedNewRecord) !== JSON.stringify(selectedVehicle)
        ) {
          setSelectedVehicle(formattedNewRecord);
        }
      }
    }

    if (vehicle && Object.keys(selectedVehicle).length === 0) {
      const formattedVehicle = formatValuesForUI(vehicle, vehiclesFormFields);
      if (
        JSON.stringify(formattedVehicle) !== JSON.stringify(selectedVehicle)
      ) {
        setSelectedVehicle(formattedVehicle); // Only update if necessary
      }
    }
  }, [
    formDetails.vehicles,
    goal.name,
    modalType,
    selectedVehicle,
    generatePropsMemoized,
  ]);

  const validateVehicleNames = useCallback(
    (value) => {
      const existingVehicle = formDetails?.vehicles.find(
        (vehicle) => vehicle.name === value
      );
      if (
        modalType === 'add' &&
        existingVehicle &&
        existingVehicle.name === selectedVehicle.name
      ) {
        return false;
      }
      return true;
    },
    [selectedVehicle, modalType, formDetails?.vehicles]
  );

  const getIsValidFieldValue = useCallback(
    (key) => {
      const value = selectedVehicle[key];
      let isValid = true;
      switch (key) {
        case 'annualExpense':
        case 'annualCollegeExpenseNow':
        case 'value529Plan':
          isValid = value;
          break;
        case 'collegeExpenseGrowth':
        case 'peakReturn529Plan':
          isValid = value;
          break;
        case 'collegeYears':
          isValid = value && parseInt(value) > 0;
          break;
        case 'collegeAge':
          isValid = value && parseInt(value) > 17;
          break;
        case 'yearBorn':
          isValid = value && parseInt(value) > 1900;
          break;
        case 'name':
          isValid = value !== '' ? validateVehicleNames(value) : false;
          break;
        default:
          break;
      }
      return isValid;
    },
    [selectedVehicle, validateVehicleNames]
  );

  const checkFormValidity = useCallback(() => {
    const allFields = ['name'];
    const isValid = allFields.every((field) => getIsValidFieldValue(field));
    setIsFormValid(isValid);
  }, [getIsValidFieldValue, setIsFormValid]);

  const handleBlur = (field) => {
    const fieldType = vehiclesFormFieldsInfo.find((ft) => ft.key === field);
    let formattedValue = selectedVehicle[field];
    if (fieldType && fieldType.dataType === 'percent') {
      // Apply the formatting only on blur
      formattedValue = percentageConversionToUI(selectedVehicle[field]);
    }
    setSelectedVehicle({ ...selectedVehicle, [field]: formattedValue }); // Update the selected vehicle state
    isEditingRef.current = false;
  };

  const handleOnChange = (event, key) => {
    isEditingRef.current = true;
    const { value } = event?.target || '';
    const fieldType = vehiclesFormFieldsInfo.find((ft) => ft.key === key);
    let formattedValue = value;
    if (fieldType) {
      if (fieldType.dataType === 'percent') {
        // Allow the raw value to be typed without adding "%" on every key press
        formattedValue = value; // Keep it raw for now during typing
      } else {
        formattedValue = formatValueByType(value, fieldType.dataType);
      }
    }
    const vehicle = { ...selectedVehicle, [key]: formattedValue };
    // Update selected vehicle state to reflect changes in UI
    setSelectedVehicle(vehicle);
  };

  useEffect(() => {
    checkFormValidity();
  }, [formDetails, checkFormValidity, selectedVehicle]);

  const handleDateChange = (date, field) => {
    if (date) {
      // Convert date to the appropriate format if necessary
      const formattedDate =
        field.dataType === 'year'
          ? date.getFullYear().toString() // For year view, use the year part only
          : date.toISOString().split('T')[0]; // For full date view, use 'yyyy-MM-dd'
      isEditingRef.current = true;
      const fieldType = vehiclesFormFieldsInfo.find(
        (ft) => ft.key === field.key
      );
      if (fieldType) {
        const vehicle = {
          ...selectedVehicle,
          [field.key]: formattedDate,
        };
        setSelectedVehicle(vehicle);
      }
    }
  };

  const getDateValue = (date, field) => {
    const formattedValue = (() => {
      if (field.dataType === 'year') {
        // For 'year' view, parse and return just the year part
        const year = parseInt(date, 10);
        return year ? new Date(year, 0, 1) : null; // January 1 of the given year, in UTC
      } else {
        // For 'yyyy-MM-dd' view, directly parse the value
        return date ? new Date(date) : null; // Ensure the date is treated as UTC
      }
    })();

    const parsedValue =
      formattedValue && !isNaN(formattedValue.getTime())
        ? formattedValue
        : null;
    return parsedValue;
  };

  return (
    <>
      <div className="signup-title-container">
        <span className="signup-title">
          {modalType === 'add' ? 'Add New Member' : 'Update Member'}
        </span>
        <CloseIcon
          className="signup-title-close-icon"
          onClick={() => handleModalOpen(false)}
        />
      </div>
      <div className="signup-body-container">
        <div className="insured_main_outer">
          {vehiclesFormFieldsInfo.map((field) => {
            switch (field.type) {
              case 'text':
                return (
                  <div className="row align-items-center">
                    <div
                      className={`col-lg-8 insured_content_outer labelStyle 
          }`}
                    >
                      <p className={`${field.isRequired ? 'required' : ''}`}>
                        {field.name}
                      </p>
                    </div>
                    <div className="col-lg-3 insured_radio_group">
                      <input
                        type={field.type}
                        id={field.key}
                        name={field.key}
                        required={true}
                        disabled={modalType === 'edit' && field.isDisabled}
                        value={selectedVehicle[field.key]}
                        className={'custom-input'} // Apply the appropriate class
                        onChange={(event) => handleOnChange(event, field.key)}
                        onBlur={() => handleBlur(field.key)}
                        placeholder=""
                      />
                    </div>
                    {selectedVehicle &&
                      Object.keys(selectedVehicle).length > 0 &&
                      !getIsValidFieldValue(field.key) && (
                        <p className="error-text">
                          {selectedVehicle[field.key]
                            ? `Invalid ${field.name}`
                            : field.errorMessage}
                        </p>
                      )}
                  </div>
                );
              case 'select':
                return (
                  <div className="row align-items-center">
                    <div className={`col-lg-8 insured_content_outer`}>
                      <p className={`${field.isRequired ? 'required' : ''}`}>
                        {field.name}
                      </p>
                    </div>
                    <div className={`col-lg-3 insured_radio_group`}>
                      <Controls.Select
                        name={field.key}
                        id={field.key}
                        value={selectedVehicle[field.key]}
                        onChange={(event, index) =>
                          handleOnChange(event, field.key)
                        }
                        className={'custom-input'}
                        // disabled={!state.isEditable}
                        options={field.options.map((c) => {
                          return {
                            value: c.value,
                            label: c.label,
                          };
                        })}
                      />
                    </div>
                    {/* {isMemberFieldInValid && (
                      <p className="error-text">
                        {isMemberFieldInValid.validationMessage}
                      </p>
                    )} */}
                  </div>
                );
              case 'date':
                return (
                  <div className="row align-items-center">
                    <div className="col-lg-8 insured_content_outer">
                      <p className={`${field.isRequired ? 'required' : ''}`}>
                        {field.name}
                      </p>
                    </div>
                    <div className="col-lg-3 insured_radio_group">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          views={[field.dataType]}
                          className="planner-input long-text-input"
                          format={
                            field.dataType === 'year' ? 'yyyy' : 'yyyy-MM-dd'
                          }
                          autoOk
                          variant="inline"
                          InputAdornmentProps={{ position: 'start' }}
                          value={getDateValue(
                            selectedVehicle[field.key],
                            field
                          )}
                          onChange={(date) => handleDateChange(date, field)}
                          inputProps={{ style: { height: '39px' } }}
                          //disabled={!state.isEditable}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    {/* {isFieldInValid && (
                      <p className="error-text">
                        {isFieldInValid.validationMessage}
                      </p>
                    )} */}
                  </div>
                );
              default:
                return null;
            }
          })}
        </div>
      </div>
    </>
  );
});
export default VehiclesContentGoals;
