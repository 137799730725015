import {
  GET_CLIENTS_BY_ADVISOR_ID,
  GET_CLIENTS_BY_ADVISOR_ID_SUCCESS,
  GET_CLIENTS_BY_ADVISOR_ID_ERROR,
  SET_SELECTED_CLIENT_ID,
  SET_ALL_PLANNER_MENU_ITEMS,
  SET_ALL_TAX_SLABS,
  SET_EXPENSE_FACTOR_DETAILS,
  CLEAR_SELECTED_CLIENT_ID,
  GET_ADVISOR_ASSETS_UNDER_MANAGEMENT,
  GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_COMPANY_AUM_ERROR,
  GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_COMPANY_AUM_SUCCESS,
  GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_COMPANY_AUM,
  GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_SUCCESS,
  GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_ERROR,
  GET_ADVISOR_STATS_BY_CLIENT_TYPE,
  GET_ADVISOR_STATS_BY_CLIENT_TYPE_SUCCESS,
  CLEAR_ADVISOR_ASSETS_UNDER_MANAGEMENT,
  GET_ADVISOR_STATS_BY_CLIENT_TYPE_ERROR,
  ADVISOR_STATS_LOADING,
  ADVISOR_ASSETS_LOADING,
  GET_INV_MARKET_EXPECTATIONS_BY_ADVISOR,
  GET_INV_MARKET_EXPECTATIONS_BY_ADVISOR_SUCCESS,
  GET_INV_MARKET_EXPECTATIONS_BY_ADVISOR_ERROR,
  GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS,
  GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS_ERROR,
  GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS_SUCCESS,
  GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS_LOADING,
  GET_CLIENTS_MEETINGS_FOR_NUM_DAYS,
  GET_CLIENTS_MEETINGS_FOR_NUM_DAYS_ERROR,
  GET_CLIENTS_MEETINGS_FOR_NUM_DAYS_SUCCESS,
  GET_CLIENTS_MEETINGS_FOR_NUM_DAYS_LOADING,
  SET_TENURE_ACTION,
  SET_CLIENT_TENURE_ACTION,
  GET_FORECAST_INVMKTEXP_LIST_BY_ADVISOR_ID_ERROR,
  GET_FORECAST_INVMKTEXP_LIST_BY_ADVISOR_ID_SUCCESS,
  GET_FORECAST_INVMKTEXP_LIST_BY_ADVISOR_ID,
  GET_ASSET_ALLOC_LIST_BY_CLIENT_ID_ERROR,
  GET_ASSET_ALLOC_LIST_BY_CLIENT_ID_SUCCESS,
  GET_ASSET_ALLOC_LIST_BY_CLIENT_ID,
  SET_ALLOCATION_ID,
  SET_FORECAST_ID,
  SET_ASSET_ALLOC_LIST_BY_CLIENT_ID_LOADING_ACTION,
  SET_ASSET_ALLOC_LIST_BY_CLIENT_ID,
} from '../actions/clientsInfoAction';

const initialState = {
  clients: [],
  selectedClient: '',
  allMenuItems: {},
  allTaxSlabs: [],
  expenseFactorDetails: [],
  advisorAssetsUnderManagement: {},
  companyAUM: {},
  advisorStatsByClientType: [],
  isAdvisorStatsLoading: false,
  isAdvisorAssetsLoading: false,
  invMarketExpectationsByAdvisorId: {},
  clientsNotUpdated: [],
  isClientsNotUpdatedLoading: false,
  clientMeetings: [],
  isClientsMeetingsLoading: false,
  tenure: 0,
  clientTenure: 1000,
  forecast: [],
  allocation: [],
  selectedForecastId: '',
  selectedAllocationId: '',
  isAssetAllocationListByClientIdLoading: false,
  // isInvMarketExpectationsByAdvisorIdLoading: false,
};

export default function clientsInfo(state = initialState, action) {
  switch (action.type) {
    case SET_ASSET_ALLOC_LIST_BY_CLIENT_ID_LOADING_ACTION:
      return {
        ...state,
        isAssetAllocationListByClientIdLoading: action.payload,
      };
    case GET_CLIENTS_BY_ADVISOR_ID:
      return {
        ...state,
        clients: [],
      };
    case GET_CLIENTS_BY_ADVISOR_ID_SUCCESS:
      return {
        ...state,
        clients: action.payload,
      };
    case GET_CLIENTS_BY_ADVISOR_ID_ERROR:
      return {
        ...state,
        clients: action.payload,
      };
    case SET_TENURE_ACTION:
      return {
        ...state,
        tenure: action.payload,
      };
    case SET_CLIENT_TENURE_ACTION:
      return {
        ...state,
        clientTenure: action.payload,
      };
    case GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS:
      return {
        ...state,
        clientsNotUpdated: [],
      };
    case GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS_SUCCESS:
      return {
        ...state,
        clientsNotUpdated: action.payload,
      };
    case GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS_LOADING:
      return {
        ...state,
        isClientsNotUpdatedLoading: action.payload,
      };
    case GET_CLIENTS_NOT_UPDATED_FOR_NUM_DAYS_ERROR:
      return {
        ...state,
        clientsNotUpdated: action.payload,
      };
    case GET_CLIENTS_MEETINGS_FOR_NUM_DAYS:
      return {
        ...state,
        clientMeetings: [],
      };
    case GET_CLIENTS_MEETINGS_FOR_NUM_DAYS_SUCCESS:
      return {
        ...state,
        clientMeetings: action.payload,
      };
    case GET_CLIENTS_MEETINGS_FOR_NUM_DAYS_LOADING:
      return {
        ...state,
        isClientMeetingsLoading: action.payload,
      };
    case GET_CLIENTS_MEETINGS_FOR_NUM_DAYS_ERROR:
      return {
        ...state,
        clientMeetings: action.payload,
      };
    case GET_INV_MARKET_EXPECTATIONS_BY_ADVISOR:
      return {
        ...state,
        invMarketExpectationsByAdvisorId: {},
      };
    case GET_INV_MARKET_EXPECTATIONS_BY_ADVISOR_SUCCESS:
      return {
        ...state,
        invMarketExpectationsByAdvisorId: action.payload,
      };
    case GET_INV_MARKET_EXPECTATIONS_BY_ADVISOR_ERROR:
      return {
        ...state,
        invMarketExpectationsByAdvisorId: action.payload,
      };
    case GET_ADVISOR_ASSETS_UNDER_MANAGEMENT:
      return {
        ...state,
        advisorAssetsUnderManagement: {},
      };
    case CLEAR_ADVISOR_ASSETS_UNDER_MANAGEMENT:
      return {
        ...state,
        advisorAssetsUnderManagement: {},
      };
    case GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_SUCCESS:
      return {
        ...state,
        advisorAssetsUnderManagement: action.payload,
      };
    case GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_ERROR:
      return {
        ...state,
        advisorAssetsUnderManagement: {},
      };
    case GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_COMPANY_AUM:
      return {
        ...state,
        companyAUM: {},
      };
    case GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_COMPANY_AUM_SUCCESS:
      return {
        ...state,
        companyAUM: action.payload,
      };
    case GET_ADVISOR_ASSETS_UNDER_MANAGEMENT_COMPANY_AUM_ERROR:
      return {
        ...state,
        companyAUM: {},
      };
    case GET_ADVISOR_STATS_BY_CLIENT_TYPE:
      return {
        ...state,
        advisorStatsByClientType: [],
      };
    case GET_ADVISOR_STATS_BY_CLIENT_TYPE_SUCCESS:
      return {
        ...state,
        advisorStatsByClientType: action.payload,
      };
    case GET_ADVISOR_STATS_BY_CLIENT_TYPE_ERROR:
      return {
        ...state,
        advisorStatsByClientType: [],
      };
    case SET_FORECAST_ID: {
      return {
        ...state,
        selectedForecastId: action.payload,
      };
    }
    case SET_ALLOCATION_ID: {
      return {
        ...state,
        selectedAllocationId: action.payload,
      };
    }
    case GET_FORECAST_INVMKTEXP_LIST_BY_ADVISOR_ID:
      return {
        ...state,
        forecast: [],
      };
    case GET_FORECAST_INVMKTEXP_LIST_BY_ADVISOR_ID_SUCCESS:
      return {
        ...state,
        forecast: action.payload,
      };
    case GET_FORECAST_INVMKTEXP_LIST_BY_ADVISOR_ID_ERROR:
      return {
        ...state,
        forecast: [],
      };
    case GET_ASSET_ALLOC_LIST_BY_CLIENT_ID:
      return {
        ...state,
        allocation: [],
      };
    case SET_ASSET_ALLOC_LIST_BY_CLIENT_ID:
      return {
        ...state,
        allocation: [],
      };
    case GET_ASSET_ALLOC_LIST_BY_CLIENT_ID_SUCCESS:
      return {
        ...state,
        allocation: action.payload,
      };
    case GET_ASSET_ALLOC_LIST_BY_CLIENT_ID_ERROR:
      return {
        ...state,
        allocation: [],
      };
    case SET_SELECTED_CLIENT_ID: {
      return {
        ...state,
        selectedClient: action.payload,
      };
    }
    case SET_ALL_PLANNER_MENU_ITEMS: {
      return {
        ...state,
        allMenuItems: action.payload,
      };
    }
    case SET_ALL_TAX_SLABS: {
      return {
        ...state,
        allTaxSlabs: action.payload,
      };
    }
    case SET_EXPENSE_FACTOR_DETAILS: {
      return {
        ...state,
        expenseFactorDetails: action.payload,
      };
    }
    case CLEAR_SELECTED_CLIENT_ID: {
      return {
        ...initialState,
      };
    }
    case ADVISOR_STATS_LOADING:
      return {
        ...state,
        isAdvisorStatsLoading: action.payload,
      };
    case ADVISOR_ASSETS_LOADING:
      return {
        ...state,
        isAdvisorAssetsLoading: action.payload,
      };
    // case INV_MARKET_EXPECTATIONS_BY_ADVISOR_LOADING:
    //   return {
    //     ...state,
    //     isInvMarketExpectationsByAdvisorIdLoading: action.payload,
    //   };
    default:
      return state;
  }
}
