import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { compareDesc, isValid } from 'date-fns';

export const getUserSystemIANATimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const getUserSystemTimezoneOffset = () => {
  const offset = new Date().getTimezoneOffset();
  const hours = Math.abs(Number.parseInt(offset / 60, 10));
  const minutes = Math.abs(offset % 60);
  return `${offset > 0 ? '-' : '+'}${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
};

export const getSortedPlansByModifiedDate = (plans) => {
  let tempPlans = plans.map((m) => {
    return {
      ...m,
      modifiedDate:
        m.modifiedDate !== null && isValid(new Date(m.modifiedDate))
          ? m.modifiedDate
          : new Date(+0), //for null => gives 1970 year
    };
  });
  return tempPlans.sort((a, b) =>
    compareDesc(new Date(a.modifiedDate), new Date(b.modifiedDate))
  );
};

// Will be used with "post/put/update/delete" rest endpoints

export const updateDateTimeStampsToUTC = (dataKeyToUpdate, payload) => {
  Object.entries(payload).forEach(([key, value]) => {
    if (dataKeyToUpdate === key) {
      if (payload[dataKeyToUpdate]) {
        payload[dataKeyToUpdate] = `${getUserSystemIANATimezone()} to UTC`;
      }
    } else if (typeof value === 'object') {
      updateDateTimeStampsToUTC(dataKeyToUpdate, value);
    }
  });
};

// Will be used with "get" rest endpoints

export const updateDateTimeStampsToUserTimezone = (
  dateFormatConfig,
  response
) => {
  let date = '';
  const { key, dateFormat } = dateFormatConfig;
  Object.entries(response).forEach(([dateKey, value]) => {
    date = response[dateKey];
    if (key === dateKey) {
      if (date && typeof date === 'string') {
        // date = isValid(new Date(date))
        //   ? format(
        //       utcToZonedTime(
        //         new Date(`${date} UTC`).toISOString(),
        //         getUserSystemTimezoneOffset()
        //       ),
        //       dateFormat
        //     )
        //   : date;
        let tempDate = isValid(new Date(date))
          ? new Date(date).getTime() -
            new Date(date).getTimezoneOffset() * 60 * 1000
          : date;
        response[dateKey] = new Date(date).toISOString().split('T')[0];
      }
    } else if (value !== null && typeof value === 'object') {
      updateDateTimeStampsToUserTimezone(dateFormatConfig, value);
    }
  });
};

export const convertMeetingDateTimeToLocal = (meetingDetail) => {
  const { meetingDate } = meetingDetail;
  const currentDateObj = new Date(Date.parse((meetingDate)));
  const localDate = ("0" + currentDateObj.getDate()).slice(-2);
  const localMonth = ("0" + (currentDateObj.getMonth() + 1)).slice(-2);
  const localYear = currentDateObj.getFullYear();
  const hours = ("0" + currentDateObj.getHours()).slice(-2);
  const minutes = ("0" + currentDateObj.getMinutes()).slice(-2);
  const meetingDateInLocal = `${localYear}-${localMonth}-${localDate}T${hours}:${minutes}:00`;
  return {
    ...meetingDetail,
    meetingDate: meetingDateInLocal
  }
};

export const transformMeetingDateTimeToUTC = (meetingDetails) => {
  // const meetingHour = new Date(meetingTime).getHours();
  // const meetingMinutes = new Date(meetingTime).getMinutes();
  // const meetingDateTimeInLocal = new Date(meetingDate).setHours(meetingHour, meetingMinutes);
  // const meetingDateTimeINUTC = getDateTimeInUTC(meetingDateTimeInLocal);
  // return meetingDateTimeINUTC;
  const { meetingDate }  = meetingDetails;
  const [ meetingDateTime ] = meetingDate.split('.')
  return {
    ...meetingDetails,
     meetingDate: new Date(meetingDateTime).toISOString()
  }
};
