import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import {
    renderFormInputRow,
    renderFormInputRowName,
    renderSelectAccountDropdown,
    renderFormDateField,
    DeleteConfirmation,
    renderFormSelectOption,
    renderFooterSubmitCancelButtons,
} from '../../helpers/BuildHelperComponentV3';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export const BuildExpensesLoansComponent = (props) => {
    const {
        state,
        concatContent,
        allMenuItems,
        setLastStepInParent,
        isFormValid,
        formErrors,
    } = props;

    const moduleObject = {
        moduleTab: 'moduleDebt',
        mainTab: 'infoStudentLoans',
        subTab: 'studentLoans',
        countVal: 'numSL',
    };


    const { formDetails, isEditable, isAddNew } = state;

    const finalExistingItems = concatContent(
        moduleObject.moduleTab,
        moduleObject.mainTab,
        moduleObject.subTab
    );

    const defaultName =
        (formDetails &&
            formDetails[moduleObject.subTab] &&
            formDetails[moduleObject.subTab].name) ||
        '';

    // Use the delete confirmation hook
    const {
        isDeleteConfirmationOpen,
        openDeleteConfirmation,
        nameValidationError,
        setNameValidationError,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
    } = useDeleteConfirmation();

    useEffect(() => {
        if (isEditable || isAddNew) {
            setLastStepInParent(false);
        } else {
            setLastStepInParent(true);
        }
    }, [isEditable, isAddNew, setLastStepInParent]);

    return (
        <>
            <div className='Accordion_outer faq-container-new'>
                <Accordion className='faq-content-new'>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Student Loans</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div className="Select_account_main_outer" style={{ marginBottom: 10 }}>
                                {renderSelectAccountDropdown(
                                    finalExistingItems,
                                    defaultName,
                                    openDeleteConfirmation,
                                    props,
                                    state,
                                    moduleObject
                                )}
                            </div>
                            {(isAddNew || isEditable) && (
                                <div className="insured_main_outer">
                                    {renderFormInputRowName(
                                        'text',
                                        'tlName',
                                        'name',
                                        'short name of loan',
                                        'text',
                                        state,
                                        moduleObject,
                                        props,
                                        finalExistingItems,
                                        true,
                                        formErrors,
                                        isAddNew
                                    )}
                                </div>
                            )}
                            {(isAddNew || (finalExistingItems && finalExistingItems.length > 0)) && (
                                <div className="insured_main_outer">
                                    {renderFormInputRow(
                                        'text',
                                        'tlLoanAmount',
                                        'loanAmount',
                                        'Loan amount',
                                        'currency',
                                        moduleObject,
                                        state,
                                        props
                                    )}
                                    {renderFormInputRow(
                                        'text',
                                        'tlBalance',
                                        'balance',
                                        'Current balance',
                                        'currency',
                                        moduleObject,
                                        state,
                                        props
                                    )}
                                    {renderFormInputRow(
                                        'text',
                                        'tlApr',
                                        'apr',
                                        'Annual percentage rate(APR)',
                                        'percentage',
                                        moduleObject,
                                        state,
                                        props
                                    )}
                                    {renderFormInputRow(
                                        'text',
                                        'tlTermMonths',
                                        'termMonths',
                                        'Loan term months',
                                        'number',
                                        moduleObject,
                                        state,
                                        props
                                    )}
                                    {renderFormDateField(
                                        'interestStartDate',
                                        'Date when interest starts accruing',
                                        'text',
                                        moduleObject,
                                        state,
                                        props,
                                        'date'
                                    )}
                                    {renderFormDateField(
                                        'paymentStartDate',
                                        'Date when first payment is due',
                                        'text',
                                        moduleObject,
                                        state,
                                        props,
                                        'date'
                                    )}
                                    {isAddNew || isEditable ? (
                                        <>
                                            {renderFormSelectOption(
                                                'text',
                                                'tlCompounding',
                                                'compounding',
                                                'Interest will compound',
                                                allMenuItems.compoundingMenuItems,
                                                moduleObject,
                                                state,
                                                props
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {renderFormInputRow(
                                                'text',
                                                'tlCompounding',
                                                'compounding',
                                                'Interest will compound',
                                                'textDropdown',
                                                moduleObject,
                                                state,
                                                props
                                            )}
                                        </>
                                    )}
                                    {renderFormInputRow(
                                        'text',
                                        'tlMonthlyPayment',
                                        'monthlyPayment',
                                        'Monthly payment (will be computed if left as zero)',
                                        'currency',
                                        moduleObject,
                                        state,
                                        props
                                    )}
                                    {renderFooterSubmitCancelButtons(
                                        state,
                                        moduleObject,
                                        props,
                                        finalExistingItems,
                                        isFormValid
                                    )}
                                </div>
                            )}
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion className='faq-content-new'>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Other Loans</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>

                            <div className="Select_account_main_outer" style={{ marginBottom: 10 }}>
                                {renderSelectAccountDropdown(
                                    finalExistingItems,
                                    defaultName,
                                    openDeleteConfirmation,
                                    props,
                                    state,
                                    moduleObject,
                                )}
                            </div>
                            {(isAddNew || isEditable) && (
                                <div className="insured_main_outer">
                                    {renderFormInputRowName(
                                        'text',
                                        'tlName',
                                        'name',
                                        'short name of loan',
                                        'text',
                                        state,
                                        moduleObject,
                                        props,
                                        finalExistingItems,
                                        true,
                                        formErrors,
                                        isAddNew
                                    )}
                                </div>
                            )}
                            {(isAddNew || (finalExistingItems && finalExistingItems.length > 0)) && (
                                <div className="insured_main_outer">
                                    {renderFormInputRow(
                                        'text',
                                        'tlLoanAmount',
                                        'loanAmount',
                                        'Loan amount',
                                        'currency',
                                        moduleObject,
                                        state,
                                        props
                                    )}
                                    {renderFormInputRow(
                                        'text',
                                        'tlBalance',
                                        'balance',
                                        'Current balance',
                                        'currency',
                                        moduleObject,
                                        state,
                                        props
                                    )}
                                    {renderFormInputRow(
                                        'text',
                                        'tlApr',
                                        'apr',
                                        'Annual percentage rate(APR)',
                                        'percentage',
                                        moduleObject,
                                        state,
                                        props
                                    )}
                                    {renderFormInputRow(
                                        'text',
                                        'tlTermMonths',
                                        'termMonths',
                                        'Loan term months',
                                        'number',
                                        moduleObject,
                                        state,
                                        props
                                    )}
                                    {renderFormDateField(
                                        'interestStartDate',
                                        'Date when interest starts accruing',
                                        'text',
                                        moduleObject,
                                        state,
                                        props,
                                        'date'
                                    )}
                                    {renderFormDateField(
                                        'paymentStartDate',
                                        'Date when first payment is due',
                                        'text',
                                        moduleObject,
                                        state,
                                        props,
                                        'date'
                                    )}
                                    {isAddNew || isEditable ? (
                                        <>
                                            {renderFormSelectOption(
                                                'text',
                                                'tlCompounding',
                                                'compounding',
                                                'Interest will compound',
                                                allMenuItems.compoundingMenuItems,
                                                moduleObject,
                                                state,
                                                props
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {renderFormInputRow(
                                                'text',
                                                'tlCompounding',
                                                'compounding',
                                                'Interest will compound',
                                                'textDropdown',
                                                moduleObject,
                                                state,
                                                props
                                            )}
                                        </>
                                    )}
                                    {renderFormInputRow(
                                        'text',
                                        'tlMonthlyPayment',
                                        'monthlyPayment',
                                        'Monthly payment (will be computed if left as zero)',
                                        'currency',
                                        moduleObject,
                                        state,
                                        props
                                    )}
                                    {renderFooterSubmitCancelButtons(
                                        state,
                                        moduleObject,
                                        props,
                                        finalExistingItems,
                                        isFormValid
                                    )}
                                </div>
                            )}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>


            <Alert severity="info">Mortgages will be captured in Properties</Alert>




            <div className='tab_main_nav'>
                <div className='tab_left_nav'>
                    <button>
                        <ArrowBackIcon /> Previous
                    </button>
                </div>
                <div className='tab_right_nav'>
                    <button>
                        Next <ArrowForwardIcon />
                    </button>
                </div>
            </div>

            {/* Delete Confirmation Dialog */}
            {DeleteConfirmation(
                props,
                isDeleteConfirmationOpen,
                closeDeleteConfirmation,
                handleDeleteConfirmation,
                nameToDelete,
                moduleObject
            )}
        </>
    );
};

export default BuildExpensesLoansComponent;
