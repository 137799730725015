import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import edit_pencil from '../images/edit_pencil.svg';
import { Grid, Button, Tooltip } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import pdf_page_plus_icon from '../images/pdf_page_plus_icon.svg';
import FormControlLabel from '@mui/material/FormControlLabel';
import { categoryOrder } from '../constants/dashboard';
import { planDetailsByPlanIdSelector } from '../selectors/plannerDetailsSelector';
import { simulatePlanDetailsByPlanIdSuccessAction } from '../redux/actions/plannerDetailsAction';
import ConfirmModal from './shared/ConfirmModal';
import CoreComponentGoals from '../components/Goals/CoreComponentGoals';
import {
  transformInfoCoreBackToUIType,
  getAndUpdatePensionContribYrs,
} from '../helpers/ClientHelper';

function VerticalTabs(props) {
  const dispatch = useDispatch();
  const { goals = [], isSimulateClicked, setIsSimulateClicked } = props;
  const [groupedData, setGroupedData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [modalType, setModalType] = useState('edit');
  const [category, setCategory] = useState('');
  const [goal, setGoal] = useState({});
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [formDetails, setFormDetails] = useState({});
  const planDetailsByPlanId = useSelector((state) =>
    planDetailsByPlanIdSelector(state)
  );
  const [membersCount, setMembersCount] = useState(0);
  const groupByCategory = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const sortCategories = (groupedData, order) => {
    // return order.reduce((sorted, category) => {
    //   if (groupedData[category]) {
    //     sorted[category] = groupedData[category];
    //   }
    //   return sorted;
    // }, {});
    const result = {};
    order.forEach((category) => {
      result[category.value] = groupedData[category.label] || [];
    });
    return result;
  };

  const updatedGroupedObject = (array1, array2, invprops) => {
    // Iterate through array1
    const updatedArray = array1.map((item1) => {
      // Find matching element in array2 based on name
      const matchingItem = array2.find((item2) =>
        !invprops ? item1.name === item2.name : item1.name === item2.prop.name
      );
      if (matchingItem) {
        return {
          ...item1,
          deactivatedInSolution: !invprops
            ? matchingItem.deactivatedInSolution
            : matchingItem.prop.deactivatedInSolution,
        };
      }
      return item1;
    });
    return updatedArray;
  };

  const updateGroupedDataBasedOnPlan = useCallback(
    (sortedGroupedData) => {
      const updatedGroupedData = { ...sortedGroupedData };
      const groupedDataKeys =
        (sortedGroupedData && Object.keys(sortedGroupedData)) || [];
      if (groupedDataKeys.length > 0) {
        groupedDataKeys.forEach((key) => {
          switch (key?.toLowerCase()) {
            case 'kids':
              const { moduleFamily: { infoKids: { kids = [] } = {} } = {} } =
                planDetailsByPlanId || {};
              updatedGroupedData[key] = updatedGroupedObject(
                sortedGroupedData[key],
                kids
              );
              break;
            case 'primaryhome':
              const {
                moduleInvestments: { primaryHome: { prop = {} } = {} } = {},
              } = planDetailsByPlanId || {};
              updatedGroupedData[key] = updatedGroupedObject(
                sortedGroupedData[key],
                [prop]
              );
              break;
            case 'secondhomes':
              const {
                moduleInvestments: { secondHomes: { props = [] } = {} } = {},
              } = planDetailsByPlanId || {};
              updatedGroupedData[key] = updatedGroupedObject(
                sortedGroupedData[key],
                props
              );
              break;
            case 'invprops':
              const {
                moduleInvestments: {
                  invProps: { props: invprops = [] } = {},
                } = {},
              } = planDetailsByPlanId || {};
              updatedGroupedData[key] = updatedGroupedObject(
                sortedGroupedData[key],
                invprops,
                'invprops'
              );
              break;
            case 'vacations':
              const {
                moduleFamily: { infoVacations: { vacations = [] } = {} } = {},
              } = planDetailsByPlanId || {};
              updatedGroupedData[key] = updatedGroupedObject(
                sortedGroupedData[key],
                vacations
              );
              break;
            case 'vehicles':
              const {
                moduleFamily: { infoVehicles: { vehicles = [] } = {} } = {},
              } = planDetailsByPlanId || {};
              updatedGroupedData[key] = updatedGroupedObject(
                sortedGroupedData[key],
                vehicles
              );
              break;
            default:
              break;
          }
        });
      }
      return updatedGroupedData;
    },
    [planDetailsByPlanId]
  ); // No dependencies here if it doesn't depend on props/state

  const getMembersCount = useCallback(() => {
    const { moduleFamily: { infoCore = {} } = {} } = planDetailsByPlanId;
    const {
      basicsFirst = {},
      basicsSecond = {},
      taxFilingStatus = '',
    } = infoCore;
    let membersCount = 0;
    if (basicsFirst.age > 0 && basicsFirst.name) {
      membersCount += 1;
      if (
        (basicsSecond.age > 0 && basicsSecond.name) ||
        taxFilingStatus.toLowerCase() === 'mfj'
      ) {
        membersCount += 1;
      }
    }
    return membersCount;
  }, [planDetailsByPlanId]);

  useEffect(() => {
    if (
      goals?.length > 0 &&
      planDetailsByPlanId &&
      Object.keys(planDetailsByPlanId).length > 0
    ) {
      const groupedData = groupByCategory(goals, 'goalCategory');
      const sortedGroupedData = sortCategories(groupedData, categoryOrder);
      const updatedGroupedData =
        updateGroupedDataBasedOnPlan(sortedGroupedData);
      setGroupedData(updatedGroupedData);
      setMembersCount(getMembersCount());
    }
  }, [
    goals,
    planDetailsByPlanId,
    updateGroupedDataBasedOnPlan,
    getMembersCount,
  ]);

  useEffect(() => {
    if (isSimulateClicked) {
      setSelectedGoals([]);
      setGoal({});
    }
  }, [isSimulateClicked]);

  const updatePlanDetailsOnIncludeCheck = (
    moduleName,
    infoTabName,
    tabName,
    item,
    checked,
    invProps
  ) => {
    const updatedPlanDetails = {
      ...planDetailsByPlanId,
      [moduleName]: {
        ...planDetailsByPlanId[moduleName],
        [infoTabName]: {
          ...planDetailsByPlanId[moduleName][infoTabName],
          [tabName]: planDetailsByPlanId[moduleName][infoTabName][tabName].map(
            (obj) => {
              const { prop = {}, name = '' } = obj;
              const { name: invPropName = '' } =
                Object.keys(prop).length > 0 && prop;
              if (invProps && invPropName === item.name) {
                // Return a new object with deactivatedInSolution updated
                return {
                  ...obj,
                  prop: { ...prop, deactivatedInSolution: !checked },
                };
              } else if (!invProps && name === item.name) {
                return {
                  ...obj,
                  deactivatedInSolution: !checked,
                };
              }
              // Return the original kid object if name does not match
              return obj;
            }
          ),
        },
      },
    };
    dispatch(
      simulatePlanDetailsByPlanIdSuccessAction({
        plannerData: updatedPlanDetails,
      })
    );
  };

  // Handle change for each CheckBox
  const handleActivateCheck = (event, category, item) => {
    const { checked } = event.target;
    //basic approach 1
    // const tempGroupedData = { ...groupedData };

    // const index = tempGroupedData[category]?.indexOf(
    //   tempGroupedData[category]?.find((f) => f.name === item.name)
    // );
    // tempGroupedData[category][index].deactivatedInSolution = checked;
    // setGroupedData(tempGroupedData);

    // approach 2
    // setGroupedData((prevGroupedData) => {
    //   const updatedGroupedData = { ...prevGroupedData };
    //   const categoryItems = updatedGroupedData[category];

    //   if (categoryItems) {
    //     const index = categoryItems.findIndex((f) => f.name === item.name);
    //     if (index !== -1) {
    //       const updatedItem = {
    //         ...categoryItems[index],
    //         deactivatedInSolution: checked,
    //       };

    //       const updatedCategoryItems = [
    //         ...categoryItems.slice(0, index),
    //         updatedItem,
    //         ...categoryItems.slice(index + 1),
    //       ];

    //       updatedGroupedData[category] = updatedCategoryItems;
    //     }
    //   }
    //   return updatedGroupedData;
    // });
    // approach 3
    let tempGroupedData = {
      ...groupedData,
      [category]: groupedData[category].map((categoryItem) => {
        if (categoryItem.name === item.name) {
          return {
            ...categoryItem,
            deactivatedInSolution: !checked,
          };
        }
        return categoryItem;
      }),
    };
    setGroupedData(tempGroupedData);
    switch (category.toLowerCase()) {
      case 'kids':
        const { moduleFamily: { infoKids: { kids = [] } = {} } = {} } =
          planDetailsByPlanId || {};
        // const kidsIndex = kids.findIndex((k) => k.name === item.name);
        // if (kidsIndex !== -1) {
        //   tempPlanDetailsByPlanId.moduleFamily.infoKids.kids[
        //     kidsIndex
        //   ].deactivatedInSolution = checked;
        // }
        // Create a copy of planDetailsByPlanId to avoid mutation
        updatePlanDetailsOnIncludeCheck(
          'moduleFamily',
          'infoKids',
          'kids',
          item,
          checked
        );
        // const updatedPlanDetails = {
        //   ...planDetailsByPlanId,
        //   moduleFamily: {
        //     ...planDetailsByPlanId.moduleFamily,
        //     infoKids: {
        //       ...planDetailsByPlanId.moduleFamily.infoKids,
        //       kids: kids.map((kid) => {
        //         if (kid.name === item.name) {
        //           // Return a new object with deactivatedInSolution updated
        //           return {
        //             ...kid,
        //             deactivatedInSolution: checked,
        //           };
        //         }
        //         // Return the original kid object if name does not match
        //         return kid;
        //       }),
        //     },
        //   },
        // };
        break;
      case 'primaryhome':
        updatePlanDetailsOnIncludeCheck(
          'moduleInvestments',
          'primaryHome',
          'prop',
          item,
          checked
        );
        break;
      case 'secondhomes':
        updatePlanDetailsOnIncludeCheck(
          'moduleInvestments',
          'secondHomes',
          'props',
          item,
          checked
        );
        break;
      case 'invprops':
        updatePlanDetailsOnIncludeCheck(
          'moduleInvestments',
          'invProps',
          'props',
          item,
          checked,
          'invProps'
        );
        break;
      case 'vacations':
        updatePlanDetailsOnIncludeCheck(
          'moduleFamily',
          'infoVacations',
          'vacations',
          item,
          checked
        );
        break;
      case 'vehicles':
        updatePlanDetailsOnIncludeCheck(
          'moduleFamily',
          'infoVehicles',
          'vehicles',
          item,
          checked
        );
        break;
      default:
        break;
    }

    // setGroupedData((prevGroupedData) => {
    //   return {
    //     ...prevGroupedData,
    //     [category]: prevGroupedData[category].map((categoryItem) => {
    //       if (categoryItem.name === item.name) {
    //         return {
    //           ...categoryItem,
    //           deactivatedInSolution: checked,
    //         };
    //       }
    //       return categoryItem;
    //     }),
    //   };
    // });
  };

  const colorHealthFlag = (color = '') => {
    switch (color && color.toLowerCase()) {
      case 'green':
        return 'green_color';
      case 'yellow':
        return 'yellow_color';
      case 'red':
        return 'red_color';
      case 'orange':
        return 'orange_color';
      case 'grey':
      case 'gray':
        return 'grey_color';
      case 'lightgreen':
        return 'lightgreen_color';
      default:
        return 'grey_color';
    }
  };

  const handleModalOpen = (val, clickedOnUpdate = '') => {
    setIsModalOpen(val);
    if (!val && clickedOnUpdate === '') {
      const newSelectedGoals = selectedGoals.filter(
        (g) => g.name !== goal.name
      );
      setSelectedGoals(newSelectedGoals);
    }
  };

  const editGoal = (goal, editType, category) => {
    handleModalOpen(true);
    setModalType(editType);
    setCategory(category);
    setGoal(goal);
  };

  const filterOutIsValidPropertiesFromFormDetailsModal = () => {
    return Object.keys(formDetails).reduce((acc, key) => {
      if (!key.startsWith('is')) {
        acc[key] = formDetails[key];
      }
      return acc;
    }, {});
  };

  const updateInfoCore = () => {
    let tempPlanDetailsByPlanId = { ...planDetailsByPlanId };
    const { moduleFamily: { infoCore = {} } = {}, moduleFamily = {} } =
      tempPlanDetailsByPlanId;
    let tempInfoCore = { ...infoCore };
    const memberOne = formDetails.isMemberOne;
    const tempFormDetails = filterOutIsValidPropertiesFromFormDetailsModal();
    const {
      basicsFirst = {},
      basicsSecond = {},
      baselineBasicsFirst = {},
      baselineBasicsSecond = {},
      defCompFirst = {},
      defCompSecond = {},
      eqCompFirst = {},
      eqCompSecond = {},
      incSavFirst = {},
      incSavSecond = {},
      retChoicesFirst = {},
      retChoicesSecond = {},
      taxFilingStatus = '',
    } = infoCore;

    if (modalType === 'add') {
      const objectsToCopy = {
        basics: { first: basicsFirst, second: basicsSecond },
        defComp: { first: defCompFirst, second: defCompSecond },
        eqComp: { first: eqCompFirst, second: eqCompSecond },
        incSav: { first: incSavFirst, second: incSavSecond },
        retChoices: { first: retChoicesFirst, second: retChoicesSecond },
      };
      for (const key in objectsToCopy) {
        const { first, second } = objectsToCopy[key];
        for (const property in first) {
          second[property] =
            key === 'retChoices' && property === 'socialSecAge' ? 67 : '';
        }
        tempInfoCore[`${key}Second`] = second;
      }
      tempPlanDetailsByPlanId = {
        ...tempPlanDetailsByPlanId,
        moduleFamily: {
          ...moduleFamily,
          infoCore: {
            ...tempInfoCore,
            basicsSecond: { ...tempFormDetails },
            taxFilingStatus: 'MFJ',
          },
        },
      };
    } else {
      if (memberOne === 'basicsFirst') {
        const { lockedInSolution = false } = basicsFirst;
        tempInfoCore = {
          ...tempInfoCore,
          baselineBasicsFirst: !lockedInSolution
            ? { ...basicsFirst }
            : { ...baselineBasicsFirst },
          basicsFirst: { ...tempFormDetails, lockedInSolution: true },
        };
      } else {
        const { lockedInSolution = false } = basicsSecond;
        tempInfoCore = {
          ...tempInfoCore,
          baselineBasicsSecond: !lockedInSolution
            ? { ...basicsSecond }
            : { ...baselineBasicsSecond },
          basicsSecond: { ...tempFormDetails, lockedInSolution: true },
        };
      }
      tempInfoCore = getAndUpdatePensionContribYrs(tempInfoCore, membersCount);
      if (membersCount === 1) {
        tempInfoCore = { ...tempInfoCore, taxFilingStatus: 'Single' };
      } else if (
        membersCount === 2 &&
        (taxFilingStatus.toLowerCase() === 'single' || taxFilingStatus === '')
      ) {
        tempInfoCore = { ...tempInfoCore, taxFilingStatus: 'MFJ' };
      }
      tempPlanDetailsByPlanId = {
        ...tempPlanDetailsByPlanId,
        moduleFamily: {
          ...moduleFamily,
          infoCore: {
            ...tempInfoCore,
          },
        },
      };
    }
    dispatch(
      simulatePlanDetailsByPlanIdSuccessAction({
        plannerData: tempPlanDetailsByPlanId,
      })
    );
    if (selectedGoals.indexOf(goal) === -1) {
      selectedGoals.push(goal);
      setSelectedGoals(selectedGoals);
    }
    handleModalOpen(false, 'clickedOnUpdate'); //to distinguish b/w normal modal close and close the modal after dispatch
    setIsSimulateClicked(false);
  };

  const updatePlanDetails = () => {
    switch (category.toLowerCase()) {
      case 'retirement':
        updateInfoCore();
        break;
      default:
        break;
    }
  };

  const renderContentDetails = () => {
    const commonProps = {
      modalType,
      category,
      handleModalOpen,
      goal,
      setIsFormValid,
      setFormDetails,
    };
    switch (category) {
      case 'retirement':
        return <CoreComponentGoals commonProps={commonProps} />;
      // case 'kids':
      //   return <KidsContentGoals />;
      // case 'vacation':
      //   return <VacationGoalDetails />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="solution_goal_verticaltab_main_outer">
        {Object.keys(groupedData).map((gCategory) => (
          <div className="solution_goal_content">
            <div className="solution_goal_title">
              <h4>
                {categoryOrder.find((f) => f.value === gCategory).label || ''}
              </h4>
            </div>
            <div className="solution_goal_right">
              {groupedData[gCategory]?.map((item) => (
                <div className="solution_goal_right_row">
                  {gCategory?.toLowerCase() === 'retirement' ? (
                    <div className="solution_goal_column">
                      <h5>Retirement Age</h5>
                      <div className="solution_goal_right_box">
                        <button
                          className="action-limit-width"
                          disabled={selectedGoals.find(
                            (g) => g.name === item.name
                          )}
                          onClick={() => editGoal(item, 'edit', gCategory)}
                        >
                          <img src={edit_pencil} alt="" title="" />
                        </button>
                        <label>{item?.name}</label>
                        <input
                          placeholder="Value"
                          type="text"
                          value={item.retirementAge}
                          disabled={selectedGoals.find(
                            (g) => g.name === item.name
                          )}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="solution_goal_column">
                      <h5>Include</h5>
                      <div className="solution_goal_right_box">
                        <button
                          className="action-limit-width"
                          onClick={() => editGoal(item, 'edit', gCategory)}
                          disabled={selectedGoals.find(
                            (g) => g.name === item.name
                          )}
                        >
                          <img src={edit_pencil} alt="" title="" />
                        </button>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!item.deactivatedInSolution}
                              onChange={(e) =>
                                handleActivateCheck(e, gCategory, item)
                              }
                              disabled={selectedGoals.find(
                                (g) => g.name === item.name
                              )}
                              name={item.name}
                              color="primary"
                            />
                          }
                          label={item.name}
                        />
                      </div>
                    </div>
                  )}
                  <div className="solution_goal_column">
                    <h5>Goal Year</h5>
                    <input
                      placeholder="Year"
                      disabled
                      type="text"
                      title={item.desc}
                      value={item.year}
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Health</h5>
                    <input
                      placeholder=""
                      className={
                        // category === gCategory &&
                        selectedGoals.find((g) => g.name === item.name)
                          ? 'grey_color'
                          : colorHealthFlag(item.healthFlag)
                      }
                      disabled
                      type="text"
                    />
                  </div>
                  {gCategory?.toLowerCase() !== 'retirement' && (
                    <div className="solution_goal_column">
                      <h5>Financial Impact</h5>
                      <input
                        placeholder="Financial Impact"
                        disabled
                        type="text"
                        value={item.financialImpact || '$0'}
                      />
                    </div>
                  )}
                </div>
              ))}
              {(gCategory?.toLowerCase() !== 'retirement' ||
                (gCategory?.toLowerCase() === 'primaryhome' &&
                  groupedData[gCategory].length === 0)) && (
                <div className="solution_goal_right_row add_btn_row">
                  <Button
                    variant="contained"
                    size="Medium"
                    className="add_button"
                    onClick={() => editGoal(undefined, 'add', gCategory)}
                  >
                    <img src={pdf_page_plus_icon} alt="" title="" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && (
        <ConfirmModal
          content={renderContentDetails()}
          open={isModalOpen}
          setOpen={handleModalOpen}
          onConfirm={updatePlanDetails}
          okLabel={modalType === 'add' ? 'Add' : 'Update'}
          size="sm"
          isOkButtonDisabled={!isFormValid} // true disables the button and false enables it to update
        />
      )}
    </>
  );
}

export default VerticalTabs;
