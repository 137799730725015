import { put, call, takeLatest } from 'redux-saga/effects';
import {
  getUserDetailsSuccessAction,
  getUserDetailsErrorAction,
  GET_USER_DETAILS,
  setIsAuthenticated,
  clearUserDetailsAction,
} from '../actions/userAction';
import { getWhoAmI } from '../../services/dashboardSvc';
//import { getClientsByAdvisorIdAction } from '../actions/clientsInfoAction';

export default function* triggerGetUserDetails(payload) {
  try {
    const {
      payload: { callback, ...rest },
    } = payload;
    const { status, data } = yield call(getWhoAmI, rest);
    const userDetails = (data && data) || {};
    const { loginMsg = '' } = userDetails;
    if (status === 200) {
      yield put(getUserDetailsSuccessAction(userDetails));
      yield put(setIsAuthenticated(true));
      const {
        emailId,
        isActive,
        isAdmin,
        isApproved,
        loginSuccess,
        name,
        privacyAccepted,
        termsAccepted,
        userType,
        restrictedPages,
        shortLocation,
        accessLevel,
        companyName,
      } = userDetails;
      const sessionObject = {
        emailId: emailId,
        isActive: isActive,
        isAdmin: isAdmin,
        isApproved: isApproved,
        loginMsg: loginMsg,
        loginSuccess: loginSuccess,
        name: name,
        privacyAccepted: privacyAccepted,
        termsAccepted: termsAccepted,
        userType: userType,
        shortLocation: shortLocation,
        accessLevel: accessLevel,
        restrictedPages: restrictedPages,
        companyName: companyName,
      };
      //remove above and below code and use redux to save data instead of browser session
      sessionStorage.setItem('userDetails', JSON.stringify(sessionObject));
      callback(userDetails);
    } else {
      yield put(getUserDetailsErrorAction(userDetails));
      yield put(setIsAuthenticated(false));
      sessionStorage.clear();
      localStorage.clear();
      // yield put(clearUserDetailsAction());
    }
  } catch (err) {
    let errInfo = {};
    if (err && err.errorInfo) {
      errInfo = err.errorInfo;
    }
    yield put(getUserDetailsErrorAction(errInfo));
    yield put(setIsAuthenticated(false));
    // yield put(clearUserDetailsAction());
    sessionStorage.clear();
    localStorage.clear();
  }
}

export function* watchUserDetails() {
  yield takeLatest(GET_USER_DETAILS, triggerGetUserDetails);
}
