import React, { useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DeleteConfirmation } from '../../helpers/BuildHelperComponentV3';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook
import { ExpenseInsuranceAccordionNames } from '../../constants/BuildTabComponentV3';

const [TERMLIFEINS, WHOLELIFEINS, UMBRELLAINS, LONGTERMCARE, HEALTHCARE] =
  ExpenseInsuranceAccordionNames;

export const BuildExpensesInsurancesComponent = (props) => {
  const {
    state,
    concatContent,
    memberOptions,
    setLastStepInParent,
    isFormValid,
    formErrors,
    handleAccordionChange,
    expandedPanel,
    renderTermLifeInsuranceComponent,
    renderWholeLifeInsuranceComponent,
    renderUmbrellaLifeInsuranceComponent,
    renderLongTermCareInsuranceComponent,
    renderHealthCareInsuranceComponent,
  } = props;

  const { formDetails, isEditable, isAddNew } = state;

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);

  return (
    <>
      <div className="Accordion_outer faq-container-new">
        <Accordion
          className="faq-content-new"
          expanded={expandedPanel === TERMLIFEINS}
          onChange={handleAccordionChange(TERMLIFEINS)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Term Life</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandedPanel === TERMLIFEINS &&
              renderTermLifeInsuranceComponent()}
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="faq-content-new"
          expanded={expandedPanel === WHOLELIFEINS}
          onChange={handleAccordionChange(WHOLELIFEINS)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Whole Life</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandedPanel === WHOLELIFEINS &&
              renderWholeLifeInsuranceComponent()}
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="faq-content-new"
          expanded={expandedPanel === UMBRELLAINS}
          onChange={handleAccordionChange(UMBRELLAINS)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Umbrella</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandedPanel === UMBRELLAINS &&
              renderUmbrellaLifeInsuranceComponent()}{' '}
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="faq-content-new"
          expanded={expandedPanel === LONGTERMCARE}
          onChange={handleAccordionChange(LONGTERMCARE)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography>Long Term Care</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandedPanel === LONGTERMCARE &&
              renderLongTermCareInsuranceComponent()}
            {/* <Typography>
              <div
                className="Select_account_main_outer"
                style={{ marginBottom: 10 }}
              >
                {renderSelectAccountDropdown(
                  finalExistingItems,
                  defaultName,
                  openDeleteConfirmation,
                  props,
                  state,
                  moduleObject
                )}
              </div>
              {(isAddNew || isEditable) && (
                <div className="insured_main_outer">
                  {renderFormInputRowName(
                    'text',
                    'tlName',
                    'name',
                    'Short name of insurance',
                    'text',
                    state,
                    moduleObject,
                    props,
                    finalExistingItems,
                    true,
                    formErrors,
                    isAddNew
                  )}
                </div>
              )}
              {(isAddNew ||
                (finalExistingItems && finalExistingItems.length > 0)) && (
                <div className="insured_main_outer">
                  {renderFormInputRow(
                    'text',
                    'tlInsured',
                    'insured',
                    'Who is covered?',
                    'text',
                    moduleObject,
                    state,
                    props,
                    undefined,
                    true,
                    true
                  )}
                  {renderFormInputRow(
                    'text',
                    'tlCoverage',
                    'coverage',
                    'Liability Coverage Amount',
                    'currency',
                    moduleObject,
                    state,
                    props,
                    undefined,
                    !isEditable, //isDisabled,
                    true, // isMandatory
                    formErrors
                  )}
                  {renderFormDateField(
                    'coverageStartYr',
                    'Year when coverage started or expected to start',
                    'number',
                    moduleObject,
                    state,
                    props,
                    'year',
                    true
                  )}
                  {renderFormInputRow(
                    'text',
                    'tlPremium',
                    'premium',
                    'Annual Premium',
                    'currency',
                    moduleObject,
                    state,
                    props,
                    undefined,
                    !isEditable, //isDisabled,
                    true, // isMandatory
                    formErrors
                  )}
                  {renderFormInputRow(
                    'text',
                    'tlPremiumGrowth',
                    'premiumGrowth',
                    'Annual growth of premium',
                    'percentage',
                    moduleObject,
                    state,
                    props,
                    undefined,
                    !isEditable, //isDisabled,
                    false, // isMandatory
                    formErrors
                  )}
                  {renderFooterSubmitCancelButtons(
                    state,
                    moduleObject,
                    props,
                    finalExistingItems,
                    isFormValid
                  )}
                </div>
              )}
            </Typography> */}
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="faq-content-new"
          expanded={expandedPanel === HEALTHCARE}
          onChange={handleAccordionChange(HEALTHCARE)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography>Health Care</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandedPanel === HEALTHCARE &&
              renderHealthCareInsuranceComponent()}
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="tab_main_nav">
        <div className="tab_left_nav">
          <button>
            <ArrowBackIcon /> Previous
          </button>
        </div>
        <div className="tab_right_nav">
          <button>
            Next <ArrowForwardIcon />
          </button>
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      {/* {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )} */}
    </>
  );
};

export default BuildExpensesInsurancesComponent;
