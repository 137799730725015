export const correlationOptions = [
  { id: '1', title: 'Stock' },
  { id: '2', title: 'Bond' },
  { id: '3', title: 'Short Term' },
  { id: '4', title: 'Cash' },
  { id: '5', title: 'Intl Stock' },
  { id: '6', title: 'Intl Bond' },
  { id: '7', title: 'Emkt Stock' },
  { id: '8', title: 'Emkt Bond' },
  { id: '9', title: 'Commodities' },
  { id: '10', title: 'REITs' },
  { id: '11', title: 'Alternatives' },
  { id: '12', title: 'Crypto' },
];

export const percentageFields = [
  'stockPercent',
  'bondPercent',
  'shortTermPercent',
  'cashPercent',
  'reitPercent',
  'altPercent',
  'commoditiesPercent',
  'cryptoPercent',
  'invReturn',
  'invVolatility',
];

export const tenures = [
  //   { label: '1 year', value: 365 },
  //   { label: '9 Months', value: 270 },
  //   { label: '6 Months', value: 180 },
  { label: 'All', value: 0 },
];

export const clientTenures = [
  { label: 'All', value: 1000 },
  { label: '1 week', value: 7 },
  { label: '3 week', value: 21 },
  { label: 'Today', value: 0 },
];
