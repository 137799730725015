import React, { useState, useEffect } from 'react';
import {
  renderDoubleInputRow,
  transformAlertsBackToUIType,
} from '../../helpers/BuildHelperComponentV3';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const BuildSettingsAlertsComponent = (props) => {
  const {
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    setLastStepInParent,
  } = props;

  const { moduleSettings: { alerts = {} } = {} } = localPlanDetailsByPlanId;
  const [alertsDetails, setAlertsDetails] = useState(alerts);

  useEffect(() => {
    if (alerts && Object.keys(alerts).length > 0) {
      let tempAlerts = transformAlertsBackToUIType(alerts);
      setAlertsDetails(tempAlerts);
    }
  }, [localPlanDetailsByPlanId]);

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  return (
    <>
      <div className="insured_main_outer">
        <div className="row align-items-center personal_div_first_row">
          <div className="col-lg-6 col-12">
            <p>
              <strong>Alert Type</strong>
            </p>
          </div>
          <div className="col-lg-3 col-6">
            <p style={{ textAlign: 'center', color: '#56A080' }}>
              <strong style={{ fontWeight: 'bold' }}>Alert 1</strong>
            </p>
          </div>
          <div className="col-lg-3 col-6">
            <p style={{ textAlign: 'center', color: '#56A080' }}>
              <strong style={{ fontWeight: 'bold' }}>Alert 2</strong>
            </p>
          </div>
        </div>
      </div>
      <div className="insured_main_outer">
        {renderDoubleInputRow(
          'text',
          'tldebtRateYellow',
          'debtRateYellowThreshold',
          'tldebtRateRed',
          'debtRateRedThreshold',
          'Debt rate',
          'percentage',
          alertsDetails,
          'alerts',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          setAlertsDetails
        )}
        {renderDoubleInputRow(
          'text',
          'tlInvReturnYellowHigh',
          'invReturnYellowHigh',
          'tlInvReturnRed',
          'invReturnRedHigh',
          'Investment return (upper bracket)',
          'percentage',
          alertsDetails,
          'alerts',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          setAlertsDetails
        )}
        {renderDoubleInputRow(
          'text',
          'tlInvReturnYellowLow',
          'invReturnYellowLow',
          'tlInvReturnRedLow',
          'invReturnRedLow',
          'Investment return (lower bracket)',
          'percentage',
          alertsDetails,
          'alerts',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          setAlertsDetails
        )}
        {renderDoubleInputRow(
          'text',
          'tlInsuranceYellow',
          'insuranceYellowThreshold',
          'tlInsuranceRed',
          'insuranceRedThreshold',
          'Insurance sufficiency',
          'percentage',
          alertsDetails,
          'alerts',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          setAlertsDetails
        )}
      </div>

      <div className="tab_main_nav">
        <div className="tab_left_nav">
          <button>
            <ArrowBackIcon /> Previous
          </button>
        </div>
        <div className="tab_right_nav">
          <button>
            Next <ArrowForwardIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default BuildSettingsAlertsComponent;
