export const API_FAILURE_MESSAGE = 'Something went wrong, please try again';
export const API_TIMED_OUT_MESSAGE = 'Request timed out, please try again';

export const getPlannerFormInitialMember = (tabLabel) => {
  return {
    label: `${tabLabel}-1`,
    key: 1,
  };
};

export const DATATYPES = {
  PERCENTAGE: 'percentage',
  STRING: 'string',
};

export const NUMBER_OF_RUNS = [
  {
    label: '0',
    value: 0,
  },
  {
    label: '1000',
    value: 1000,
  },
  {
    label: '2000',
    value: 2000,
  },
  {
    label: '3000',
    value: 3000,
  },
  {
    label: '4000',
    value: 4000,
  },
  {
    label: '5000',
    value: 5000,
  },
  {
    label: '6000',
    value: 6000,
  },
  {
    label: '7000',
    value: 7000,
  },
  {
    label: '8000',
    value: 8000,
  },
  {
    label: '9000',
    value: 9000,
  },
  {
    label: '10000',
    value: 10000,
  },
];

export const USERTYPES = {
  CLIENT: 'client',
  ADVISOR: 'advisor',
  DIRECT: 'direct',
};

export const SET_PASSWORD_PATH = '/setPassword';

export const USER_TYPES = {
  BUSINESS_ADMIN: "business admin"
}