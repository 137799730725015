import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Avatar from '@material-ui/core/Avatar';
import history from '../../../history';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';
import ChatIcon from '@mui/icons-material/Chat';
import MenuIcon from '@mui/icons-material/Menu';
import Controls from '../../controls/Controls';
import greenrLogo from '../../../images/Greenr_logo.png';
import {
  clearUserDetailsAction,
  setIsAuthenticated,
} from '../../../redux/actions/userAction';
import { clearOutputResponseAction } from '../../../redux/actions/outputResponseDetailsAction';
import { clearSelectedClientId } from '../../../redux/actions/clientsInfoAction';
import { clearSelectedClientPlanDetails } from '../../../redux/actions/plansAction';
import { clearPlanDetailsFromStore } from '../../../redux/actions/plannerDetailsAction';
import {
  newSideMenuItems,
  newBaseSideMenuItems,
} from '../../../constants/dashboard';
import { NavLink, useLocation } from 'react-router-dom';
import {
  getClientsByAdvisorIdSelector,
  getPlansByClientIdSelector,
  allTaxSlabsSelector,
  allPlannerMenuItemsSelector,
} from '../../../selectors/plannerDetailsSelector';
import { selectedClientIdSelector } from '../../../selectors/clientsInfoSelector';
import { getLoggedInUserDetails } from '../../../selectors/loginUserSelector';
import {
  selectedPlanIdForClientIdSelector,
  planDetailsByPlanIdSelector,
} from '../../../selectors/plannerDetailsSelector';
import { NUMBER_OF_RUNS, USERTYPES } from '../../../constants/common';
import { transformAllMenuItems } from '../../../redux/transformers/apiToClientResponseTransformer';
import {
  getAllPlannerMenuItems,
  getAllTaxSlabs,
  sendEmail,
} from '../../../services/dashboardSvc';
import {
  getAllMenuItemsSuccess,
  getAllTaxSlabsSuccess,
} from '../../../redux/actions/clientsInfoAction';
import { isEqual } from 'lodash';
// import FeedbackRoundedIcon from '@mui/icons-material/FeedbackRounded';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FeedbackDialog from '../../shared/DialogBox';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Dialog, DialogTitle, DialogActions } from '@mui/material'; // Import dialog components
import ConfirmModal from '../../shared/ConfirmModal';
import CloseIcon from '@material-ui/icons/Close';

let sendEmailSuccessMsg = '';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function NewHeader(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const { IsUserBusinessUser } = props;

  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [openConnectToAdvisorDialog, setOpenConnectToAdvisorDialog] =
    useState(false);
  const [openConnectToAdvDialog, setOpenConnectToAdvDialog] = useState(false);
  const [feedbackInput, setFeedbackInput] = useState('');
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [advisorName, setAdvisorName] = useState('');
  const [accessLevel, setAccessLevel] = useState('');
  const [openMailSuccessSnackbar, setOpenMailSuccessSnackbar] = useState(false);
  const [openFailedSnackbar, setOpenFailedSnackbar] = useState(false);
  const [initialState, setInitialState] = useState({
    name: '',
    phoneNumber: '',
    zipCode: '',
    emailAddress: '',
  });
  const [isMailLoading, setIsMailLoading] = useState(false);
  const clients = useSelector((state) => getClientsByAdvisorIdSelector(state));
  const clientsNotUpdated = useSelector(
    (state) => state.clientInfoReducer.clientsNotUpdated || []
  );
  const [updatedSideMenuItems, setUpdatedSideMenuItems] =
    useState(newBaseSideMenuItems);
  const plans = useSelector((state) => getPlansByClientIdSelector(state));
  const planDetailsByPlanId = useSelector((state) =>
    planDetailsByPlanIdSelector(state)
  );
  const selectedPlanId = useSelector((state) =>
    selectedPlanIdForClientIdSelector(state)
  );
  const selectedClientId = useSelector((state) =>
    selectedClientIdSelector(state)
  );
  const menuItems = useSelector((state) => allPlannerMenuItemsSelector(state));
  const taxSlabs = useSelector((state) => allTaxSlabsSelector(state));
  const userDetails = useSelector((state) => getLoggedInUserDetails(state));
  const userType =
    userDetails && userDetails.userType
      ? userDetails.userType.toLowerCase()
      : '';
  const isDirectUser = userType === 'direct';
  const isDirectOrClientUser = userType === 'client' || userType === 'direct';
  const [submenuIndex, setSubmenuIndex] = useState(null);

  // useEffect(() => {
  //   console.log(' in dd mount ', IsUserBusinessUser, updatedSideMenuItems);
  //   const newMenuItems = updatedSideMenuItems.map((item) => {
  //     if (item.value === 'home') {
  //       return {
  //         ...item,
  //         link: IsUserBusinessUser ? '/companyHome' : item.link,
  //       };
  //     }
  //     return item;
  //   });
  //   setUpdatedSideMenuItems(newMenuItems);
  // }, []);

  // Bhargavi, I've modified this piece of code, I was getting error to include dependencies and causing max state depth so modified, u may change if needed
  // above commented is your code
  useEffect(() => {
    console.log(' in dd mount ', IsUserBusinessUser, updatedSideMenuItems);
    const newMenuItems = updatedSideMenuItems.map((item) => {
      if (item.value === 'home') {
        return {
          ...item,
          link: IsUserBusinessUser ? '/companyHome' : item.link,
        };
      }
      return item;
    });
    // Check if the new menu items are different from the current state
    const hasChanged =
      JSON.stringify(newMenuItems) !== JSON.stringify(updatedSideMenuItems);
    // Only update state if there is a change
    if (hasChanged) {
      setUpdatedSideMenuItems(newMenuItems);
    }
  }, [IsUserBusinessUser, updatedSideMenuItems]);

  useEffect(() => {
    let selectedClientName = '';
    const isUserLoggedIn =
      userDetails && Object.keys(userDetails).length > 0 ? true : false;
    selectedClientName = userDetails?.name || '';
    if (isUserLoggedIn && userType === USERTYPES.CLIENT) {
      let tempSideMenuItems = [...newSideMenuItems];
      let restrictedPages = [...userDetails.restrictedPages];
      tempSideMenuItems = tempSideMenuItems.filter(
        (menu) => !restrictedPages.includes(menu.value)
      );
      if (!isEqual(updatedSideMenuItems, tempSideMenuItems)) {
        setUpdatedSideMenuItems(tempSideMenuItems);
      }
    } else if (
      isUserLoggedIn &&
      (clientsNotUpdated.length > 0 || isDirectOrClientUser) &&
      selectedClientId !== '' &&
      planDetailsByPlanId &&
      Object.keys(planDetailsByPlanId).length > 0
    ) {
      if (!isDirectOrClientUser) {
        let tempSideMenuItems = [...newSideMenuItems];
        if (!isEqual(updatedSideMenuItems, tempSideMenuItems)) {
          setUpdatedSideMenuItems(tempSideMenuItems);
        }
      } else {
        let tempSideMenuItems = [...newSideMenuItems];
        let restrictedPages = [...userDetails.restrictedPages];
        tempSideMenuItems = tempSideMenuItems.filter(
          (menu) => !restrictedPages.includes(menu.value)
        );
        if (!isEqual(updatedSideMenuItems, tempSideMenuItems)) {
          setUpdatedSideMenuItems(tempSideMenuItems);
        }
      }
      const clientObj = clientsNotUpdated.find(
        (client) => client.clientId === selectedClientId
      );
      selectedClientName = isDirectOrClientUser
        ? userDetails?.name
        : clientObj && Object.keys(clientObj).length > 0
        ? clientObj.clientName || clientObj.clientId
        : '';
    }
    if (
      isUserLoggedIn &&
      !isDirectOrClientUser &&
      advisorName !== userDetails.name
    ) {
      // to check user details
      setAdvisorName(userDetails.name);
    }
    if (isDirectUser) {
      setAccessLevel(userDetails.accessLevel);
    }

    if (selectedClientName && selectedClientName !== selectedClient) {
      setSelectedClient(selectedClientName);
    }
  }, [
    dispatch,
    clientsNotUpdated,
    selectedClientId,
    userDetails,
    updatedSideMenuItems,
    planDetailsByPlanId,
    isDirectOrClientUser,
    isDirectUser,
    userType,
    advisorName,
    selectedClient,
  ]);

  useEffect(() => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex);
    }
  }, [selectedIndex]);

  useEffect(() => {
    let selectedPlanName = '';
    if (plans.length > 0 && selectedPlanId !== '') {
      const planObj = plans.find((plan) => plan.value === selectedPlanId);
      selectedPlanName =
        planObj && Object.keys(planObj).length > 0 ? planObj.label : '';
    }
    setSelectedPlan(selectedPlanName);
  }, [plans, selectedPlanId]);

  useEffect(() => {
    const getAllMenuItems = async () => {
      const { status, data: allMenuItems } = await getAllPlannerMenuItems();
      if (status === 200 && allMenuItems) {
        dispatch(getAllMenuItemsSuccess(transformAllMenuItems(allMenuItems)));
      }
    };
    if (sessionStorage.getItem('userDetails') !== null) {
      //if (userDetails && Object.keys(userDetails).length > 0) { //use this logic and remove data from session storage
      if (Object.keys(menuItems).length === 0) {
        getAllMenuItems();
      }
    } else {
      history.push('/');
    }
  }, [dispatch, menuItems]);

  useEffect(() => {
    const getTaxSlabs = async () => {
      const { status, data: allTaxSlabs } = await getAllTaxSlabs();
      if (status === 200 && allTaxSlabs.length > 0) {
        dispatch(getAllTaxSlabsSuccess(allTaxSlabs));
      }
    };
    if (sessionStorage.getItem('userDetails') !== null) {
      //if (userDetails && Object.keys(userDetails).length > 0) { //use this logic and remove data from session storage
      if (taxSlabs.length === 0) {
        getTaxSlabs();
      }
    } else {
      history.push('/');
    }
  }, [dispatch, taxSlabs]);

  const navbarRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setCollapsed1(true);
        setCollapsed2(true);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [navbarRef]);

  const setFeedbackDialog = (dialogState) => {
    setOpenFeedbackDialog(dialogState);
    setFeedbackInput('');
  };

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    dispatch(clearUserDetailsAction());
    dispatch(clearOutputResponseAction());
    dispatch(clearSelectedClientId());
    dispatch(clearSelectedClientPlanDetails());
    dispatch(clearPlanDetailsFromStore());
    history.push('/');
  };

  const renderSideMenuIcons = (Icon) => {
    return <Icon className="list-item-icon" />;
  };

  const setConnectToAdvisorDialog = (openConnectToAdvisorDialog) => {
    setOpenConnectToAdvisorDialog(openConnectToAdvisorDialog);
    setInitialState({
      name: '',
      phoneNumber: '',
      zipCode: '',
      emailAddress: '',
    });
  };

  const setConnectToAdvDialog = () => {
    setOpenConnectToAdvDialog(false);
  };

  const openConnectToAdvDialogControl = () => {
    setOpenConnectToAdvDialog(true);
  };

  const setIsSnackbarOpen = (openMailSuccessSnackbar) => {
    setOpenMailSuccessSnackbar(openMailSuccessSnackbar);
  };

  const setIsFailedSnackbarOpen = (openFailedSnackbar) => {
    setOpenFailedSnackbar(openFailedSnackbar);
  };

  const getFeedbackMailBody = (mailBodyObj) => {
    const { firstName, lastName, emailId, mobileNumber, feedbackInput } =
      mailBodyObj;
    return (
      'Hi Team,' +
      '<br><br>' +
      'Below is the feedback from User: <br>' +
      feedbackInput +
      '<br><br>First Name:' +
      '\t' +
      firstName +
      '<br>' +
      'Last Name:' +
      '\t' +
      lastName +
      '<br>' +
      'Email:' +
      '\t' +
      emailId +
      '<br>' +
      'Mobile:' +
      '\t' +
      mobileNumber +
      '<br><br>' +
      'Thank You,' +
      '<br>' +
      "Green'R"
    );
  };

  const getConnectToAdvisorMailBody = (mailBodyObj) => {
    const { name, phoneNumber, zipCode, emailAddress } = mailBodyObj;
    return (
      'Hi Team,' +
      '<br><br>' +
      name +
      '\t wants to connect to Advisor<br>' +
      '<br><br>Name:' +
      '\t' +
      name +
      '<br>' +
      'Email:' +
      '\t' +
      emailAddress +
      '<br>' +
      'Mobile:' +
      '\t' +
      phoneNumber +
      '<br>' +
      'Zip Code:' +
      '\t' +
      zipCode +
      '<br><br>' +
      'Thank You,' +
      '<br>' +
      "Green'R"
    );
  };

  const handleListItemClick = (event, index) => {
    if (planDetailsByPlanId && Object.keys(planDetailsByPlanId).length <= 0) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(index);
    }
  };

  const setIsLoading = (isMailLoading) => {
    setIsMailLoading(isMailLoading);
  };

  const triggerFeedbackMailToAdmin = async ({
    emailId,
    mobileNumber,
    firstName,
    lastName,
  }) => {
    const reqPayload = {
      createdDate: '2022-12-21T09:44:53.809Z',
      message: getFeedbackMailBody({
        emailId,
        mobileNumber,
        firstName,
        lastName,
        feedbackInput,
      }),
      notificationId: '',
      subject: 'Feedback',
      toAddress: 'info@greenrfinancial.com,marketing@greenrfinancial.com',
    };
    const { status } = await sendEmail(reqPayload);
    if (status === 200) {
      sendEmailSuccessMsg =
        'Thank you for your feedback. We will get back to you shortly through email.';
      setIsLoading(false);
      setFeedbackDialog(false);
      setIsSnackbarOpen(true);
    } else {
      setFeedbackDialog(true);
      setIsFailedSnackbarOpen(true);
      setIsLoading(false);
    }
  };

  const sendFeedback = async () => {
    setIsLoading(true);
    const {
      name: firstName,
      lastName = '',
      emailId,
      mobileNumber,
    } = userDetails;
    const userName =
      lastName !== '' ? `${firstName}, ${lastName}` : `${firstName}`;
    const reqObj = {
      emailId: emailId,
      mobileNumber: mobileNumber,
      name: userName,
      firstName: firstName,
      lastName: lastName,
    };
    try {
      setIsLoading(true);
      triggerFeedbackMailToAdmin(reqObj);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const setFeedbackInputValue = (value) => {
    setFeedbackInput(value);
  };

  const triggerConnectToAdvisorMailToAdmin = async () => {
    const { name, phoneNumber, zipCode, emailAddress } = initialState;
    const reqPayload = {
      createdDate: '2022-12-21T09:44:53.809Z',
      message: getConnectToAdvisorMailBody({
        name,
        phoneNumber,
        zipCode,
        emailAddress,
      }),
      notificationId: '',
      subject: 'Connect To Advisor',
      toAddress: 'info@greenrfinancial.com, marketing@greenrfinancial.com',
    };
    const { status } = await sendEmail(reqPayload);
    if (status === 200) {
      setIsLoading(false);
      setConnectToAdvisorDialog(false);
      sendEmailSuccessMsg =
        'Thank you for your interest. We will get back to you shortly through email.';
      setIsSnackbarOpen(true);
    } else {
      setFeedbackDialog(true);
      setIsFailedSnackbarOpen(true);
      setIsLoading(false);
    }
  };

  const sendConnectToAdvisorEmail = async () => {
    const { loggedInUserDetails: userDetails = {} } = props;
    setIsLoading(true);
    const {
      name: firstName,
      lastName = '',
      emailId,
      mobileNumber,
    } = userDetails;
    const userName = `${firstName}, ${lastName}`;
    const reqObj = {
      emailId: emailId,
      mobileNumber: mobileNumber,
      name: userName,
      firstName: firstName,
      lastName: lastName,
    };
    try {
      setIsLoading(true);
      triggerConnectToAdvisorMailToAdmin();
    } catch (e) {
      setIsLoading(false);
    }
  };

  const setFormFieldValue = (event, name) => {
    const { value } = event.target;
    setInitialState((old) => ({
      ...old,
      [name]: value,
    }));
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbarOpen(false);
    setIsFailedSnackbarOpen(false);
  };

  // Desktop Dropdown rigt top corner user icon
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCollapsed1(true);
    setCollapsed2(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderFeedbackContent = () => {
    return (
      <>
        <div className="signup-title-container">
          <span className="signup-title">Feedback</span>
          <CloseIcon
            className="signup-title-close-icon"
            onClick={() => setFeedbackDialog(false)}
          />
        </div>
        <div className="signup-body-container">
          <TextareaAutosize
            minRows={4}
            value={feedbackInput}
            onChange={(event) => setFeedbackInputValue(event.target.value)}
          />
        </div>
      </>
    );
  };

  // Main_menu
  const [mainMenuCollapsed1, setCollapsed1] = useState(true);

  const toggleNavbar1 = () => {
    setCollapsed1(!mainMenuCollapsed1);
    setCollapsed2(true);
  };

  const handleNavLinkClick1 = (event, key) => {
    if (key === 'solutions') {
      // implemented sub menu, so preventing routing on click of solutions
      event.preventDefault();
    } else {
      setCollapsed1(true); // Collapse the navbar when a nav-link is clicked
    }
  };

  // User info
  const [collapsed2, setCollapsed2] = useState(true);

  const toggleNavbar2 = () => {
    setCollapsed2(!collapsed2);
    setCollapsed1(true);
  };

  const handleNavLinkClick2 = () => {
    setCollapsed2(true); // Collapse the navbar when a nav-link is clicked
  };

  return (
    <>
      <div className="master-main-wrapper" ref={navbarRef}>
        <div className="main-header">
          <nav className="navbar navbar-expand-lg navbar-light fixed-top">
            <div className="container-fluid">
              <NavLink
                to={IsUserBusinessUser ? '/companyHome' : '/home'}
                className="navbar-brand"
                key={`home`}
              >
                <img src={greenrLogo} className="d-lg-block" alt="" title="" />
              </NavLink>

              <button
                onClick={toggleNavbar1}
                aria-expanded={!mainMenuCollapsed1 ? 'true' : 'false'}
                className={`navbar-toggler ${
                  mainMenuCollapsed1 ? '' : 'collapsed'
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent1"
                aria-controls="navbarSupportedContent1"
                // aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <MenuIcon />
              </button>

              <button
                onClick={toggleNavbar2}
                aria-expanded={!collapsed2 ? 'true' : 'false'}
                className={`navbar-toggler ${collapsed2 ? '' : 'collapsed'}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent2"
                aria-controls="navbarSupportedContent2"
                aria-label="Toggle navigation"
              >
                <InfoIcon />
              </button>
              <div
                className={`navbar-collapse collapse ${
                  collapsed2 ? '' : 'show'
                }`}
                id="navbarSupportedContent2"
              >
                <ul className="navbar-nav m-auto navbar-right-top align-items-sm-center">
                  {!isDirectOrClientUser && (
                    <li className="nav-item" onClick={handleNavLinkClick2}>
                      Advisor: <span>{advisorName}</span>
                    </li>
                  )}
                  <li className="nav-item" onClick={handleNavLinkClick2}>
                    Client: <span>{selectedClient}</span>
                  </li>
                  {isDirectUser && (
                    <li className="nav-item" onClick={handleNavLinkClick2}>
                      Access Level: <span>{accessLevel}</span>
                    </li>
                  )}
                  <li className="nav-item">
                    Plan: <span>{selectedPlan}</span>
                  </li>
                  {isDirectUser && (
                    <li>
                      <button
                        className="btn btn-primary btn-xs"
                        type="button"
                        onClick={() => openConnectToAdvDialogControl()}
                      >
                        Connect to Advisor
                      </button>
                    </li>
                  )}
                  {/* <li className="feedback">
                    
                  </li>
                  <li className="setting_icon">
                    
                  </li>

                  <li className="log_out">
                   
                  </li> */}
                </ul>
              </div>

              <div className="user_icon_div">
                <Button
                  id="basic-button"
                  className="user-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <AccountCircleIcon />
                </Button>
                <Menu
                  id="basic-menu"
                  className="user-button-menu-options"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleClose}>
                    <Button
                      variant="text"
                      onClick={() => setFeedbackDialog(true)}
                      startIcon={<ChatIcon />}
                    >
                      <span>Provide Feedback</span>
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    {/* <IconButton className="avatar">
                        <Avatar>
                          <SettingsOutlinedIcon />
                        </Avatar>
                      </IconButton>
                      Services */}

                    <Button
                      variant="text"
                      //onClick={logout}
                      startIcon={<SettingsOutlinedIcon />}
                    >
                      <span>Settings</span>
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    {/* <Controls.Button
                          variant="text"
                          text={<LogoutIcon />}
                          onClick={logout}
                        />
                        Logout */}

                    <Button
                      variant="text"
                      onClick={logout}
                      startIcon={<LogoutIcon />}
                    >
                      <span>Logout</span>
                    </Button>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </nav>
        </div>
        <div className="nav-left-sidebar sidebar-dark">
          <div className="menu-list">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid">
                {/* <button
                  onClick={toggleNavbar}
                  aria-expanded={!collapsed ? 'true' : 'false'}
                  className={`navbar-toggler ${collapsed ? '' : 'collapsed'}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button> */}
                {/* <button
                  className="d-xl-none d-lg-none header_mobile_view_button"
                  variant="text"
                  onClick={() => setFeedbackDialog(true)}
                  startIcon={<FeedbackRoundedIcon />}
                >
                  <FeedbackRoundedIcon />
                  <span>Provide Feedback</span>
                </button>
                <button className="d-xl-none d-lg-none header_mobile_view_button">
                  <IconButton className="avatar">
                    <Avatar>
                      <SettingsOutlinedIcon />
                    </Avatar>
                  </IconButton>
                </button> */}
                <div
                  className={`navbar-collapse collapse ${
                    mainMenuCollapsed1 ? '' : 'show'
                  }`}
                  id="navbarSupportedContent1"
                >
                  <ul className="navbar-nav flex-column">
                    {updatedSideMenuItems.map((item, index) => (
                      <li
                        className="nav-item"
                        onMouseEnter={
                          item.subMenu ? () => setSubmenuIndex(index) : null
                        }
                        onMouseLeave={
                          item.subMenu ? () => setSubmenuIndex(null) : null
                        }
                        onClick={(event) => handleListItemClick(event, index)}
                        key={`${index + 1}`}
                      >
                        {item.key === 'solutions' ? (
                          <span
                            className="nav-link"
                            onClick={(event) =>
                              handleNavLinkClick1(event, item.key)
                            }
                            role="button"
                            aria-disabled="true"
                          >
                            {renderSideMenuIcons(item.icon)}
                            {item.label}
                          </span>
                        ) : (
                          <NavLink
                            to={item.link}
                            end={item.link === '/home'} // Ensure 'Home' gets exact match
                            className={({ isActive }) => {
                              return isActive && item.link !== '/'
                                ? 'nav-link active'
                                : 'nav-link';
                            }}
                            onClick={(event) =>
                              handleNavLinkClick1(event, item.value)
                            }
                          >
                            {renderSideMenuIcons(item.icon)}
                            {item.label}
                          </NavLink>
                        )}
                        {item.subMenu && submenuIndex === index && (
                          <ul className="navbar-nav flex-column submenu">
                            {item.subMenu.map((subItem, subIndex) => (
                              <li
                                className="nav-item"
                                key={`${subItem.key}-${subIndex}`}
                              >
                                <NavLink
                                  to={subItem.link}
                                  className="nav-link"
                                  // className={({ isActive }) => {
                                  //   const exactPath =
                                  //     location &&
                                  //     location.pathname + location.search;
                                  //   return exactPath?.toLowerCase() ===
                                  //     subItem?.link?.toLowerCase()
                                  //     ? 'nav-link active'
                                  //     : 'nav-link';
                                  // }}
                                  onClick={(event) =>
                                    handleNavLinkClick1(event, subItem.key)
                                  }
                                >
                                  {subItem.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {openFeedbackDialog && (
        <ConfirmModal
          content={renderFeedbackContent()}
          open={openFeedbackDialog}
          setOpen={setFeedbackDialog}
          onConfirm={sendFeedback}
          okLabel="Send"
          size="md"
          isOkButtonDisabled={!feedbackInput}
        />
      )}

      {/* <FeedbackDialog
        confirmLabel="Send"
        dialogOpen={openFeedbackDialog}
        handleCancel={() => setFeedbackDialog(false)}
        handleConfirm={sendFeedback}
      >
        <p>Enter your feedback here</p>
        <TextareaAutosize
          minRows={4}
          value={feedbackInput}
          onChange={(event) => setFeedbackInputValue(event.target.value)}
        />
      </FeedbackDialog> */}
      <FeedbackDialog
        confirmLabel="Connect"
        dialogOpen={openConnectToAdvisorDialog}
        handleCancel={() => setConnectToAdvisorDialog(false)}
        handleConfirm={sendConnectToAdvisorEmail}
        isConfirmButtonDisabled={!initialState.name}
      >
        <Grid container className="signup-form-grid" spacing={2}>
          <Grid item xs={5}>
            <Controls.Input
              name="name"
              label="Name"
              value={initialState.name}
              type="text"
              onChange={(event, name) => setFormFieldValue(event, name)}
            />
          </Grid>
          <Grid item xs={5}>
            <Controls.Input
              name="phoneNumber"
              label="Mobile Number"
              value={initialState.phoneNumber}
              type="text"
              onChange={(event, name) => setFormFieldValue(event, name)}
            />
          </Grid>
          <Grid item xs={5}>
            <Controls.Input
              name="emailAddress"
              label="Email Id"
              value={initialState.emailAddress}
              type="text"
              onChange={(event, name) => setFormFieldValue(event, name)}
            />
          </Grid>
          <Grid item xs={5}>
            <Controls.Input
              name="zipCode"
              label="Zip Code"
              value={initialState.zipCode}
              type="text"
              onChange={(event, name) => setFormFieldValue(event, name)}
            />
          </Grid>
        </Grid>
      </FeedbackDialog>
      <Snackbar
        open={openMailSuccessSnackbar}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          {/* Thank you for showing interest, our team will surely get in touch
            with you. */}
          {sendEmailSuccessMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openFailedSnackbar}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          Something went wrong, please try again later!
        </Alert>
      </Snackbar>
      <Dialog
        className="dialog-container-reports"
        open={openConnectToAdvDialog}
        onClose={() => setConnectToAdvDialog()}
      >
        <DialogTitle>Under Construction...</DialogTitle>
        <DialogActions>
          <Button
            className="action-btn primary-action-btn"
            onClick={() => setConnectToAdvDialog()}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
